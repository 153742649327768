import React from 'react';
import { useSelector } from 'react-redux';
import MaintenanceHistoric from './MaintenanceHistoric';
import MaintenanceProblemsNiple from './ProblemsNiple';
import MaintenanceSolutionsNiple from "./SolutionsNiple";
import { Container } from './style';
import MaintenanceSubProblemsNiple from './SubProblemsNiple';
import MaintenaceSuspicions from "./Suspicions";
import MaintenanceTimeline from './Timeline';
const Maintenances: React.FC = () => {

    const page = useSelector(state => state.page);

    return (
        <Container>

            {page.data === "Suspeitas" && <MaintenaceSuspicions />}
            {page.data === "Problemas" && <MaintenanceProblemsNiple />}
            {page.data === "Subproblemas" && <MaintenanceSubProblemsNiple />}
            {page.data === "Soluções" && <MaintenanceSolutionsNiple />}
            {page.data === "Timeline" && <MaintenanceTimeline />}
            {page.data === "Histórico" && <MaintenanceHistoric />}

        </Container>
    )

}

export default Maintenances;  