import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px 0px 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media(max-width: 725px) {
        padding: 20px 30px 15px 30px;
    }
    
`;

export const Search = styled.div`
    background: var(--white);
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border: 1px solid #ddd;
    width: calc(100% - 300px);
    height: 55px;
    position: relative;

    @media(max-width: 725px) {
        width: calc(100% - 50px);
    }

    > input {
        height: 100%;
        width: 100%;
        padding: 15px 15px 15px 45px;
        font-size: 20px;
        color: var(--black2);
    }

    > span {
        position: absolute;
        top: 15px;
        left: 10px;
        height: 100%;
        width: 40px;
        color: var(--gray);
    }

    > div {
        position: absolute;
        right: 15px;
        border-radius: 5px;
        top: 10px;
        border: 2px solid var(--like);
        padding: 5px 15px 5px 15px;
        color: var(--like);

        @media(max-width: 725px) {
        display: none;
    }

        >span {
            position: absolute;
            cursor: pointer;
            z-index: 3;
            top: -8px;
            right: -8px;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            background: var(--white);
            border: 2px solid var(--like);
            justify-content: center;
            text-align: center;
            color: var(--black2);
            font-size: 11px;
            font-weight: bold;
        }
    }
`;

export const Alerts = styled.div`
    width: 50px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border: 1px solid #ddd;
    position: relative;
    cursor: pointer;

    > .notf {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: #ff0000;
        height: 25px;
        width: 25px;
        border-radius: 100px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ProfileInfo = styled.div`
    width: 180px;
    height: 55px;
    background: var(--white);
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    overflow: hidden;

    @media(max-width: 725px) {
        width: 50px;
        justify-content: center;
    }

    > span {
        background-color: #f1f1f1;
        height: 42px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 4px;
    }

    > strong {
        color:  var(--black2);
        font-weight: normal;
        font-size: 12px;
        padding-left: 15px;
        @media(max-width: 725px) {
            display: none;
        }

    }
`;


export const AutoComplete = styled.div`
    background: var(--primary);
    height: min(auto, 300px);
    width: calc(100% - 200px);

    @media(max-width: 725px) {
        width: calc(100% - 50px);
    }

`;