import React from 'react';
import { useSelector } from 'react-redux';
import CommandLock from './CommandLock';
import CommandMaxtrackOff from './CommandMaxtrackOff';
import CommandPanic from './CommandPanic';
import CommandRecalibrate from './CommandRecalibrate';
import CommandReset from './CommandReset';
import CommandTest from './CommandTest';
import CommandUnLock from './CommandUnLock';
import {
    Container, Info, TitleInfo
} from './style';


const Command = () => {

    const page = useSelector(state => state.page);

    return (
        <Container>

            <TitleInfo>Enviar comando: {page.data}</TitleInfo>

            <Info>

                {page.data === "Reset" && <CommandReset />}
                {page.data === "Teste" && <CommandTest />}
                {page.data === "Recalibrar" && <CommandRecalibrate />}
                {page.data === "Baixar pânico" && <CommandPanic />}
                {page.data === "Bloqueio" && <CommandLock />}
                {page.data === "Desbloqueio" && <CommandUnLock />}
                {page.data === "Posicionar desligado" && <CommandMaxtrackOff />}

            </Info>

        </Container>
    )

}

export default Command;