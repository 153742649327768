import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
  Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
  Container
} from './style';

const FieldsFixedExpenses = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)

  const arrTypePayments = useSelector(state => state.typePayments)
  const arrTypeExpenses = useSelector(state => state.typeExpenses)

  const objSelected = useSelector(state => state.objSelected)

  const [id, setId] = useState(0)
  const [provider_id, setProviderId] = useState(0)
  const [provider_name, setProviderName] = useState("")
  const [type_expense_id, setTypeExpenseId] = useState(0)

  const [payment_type, setPaymentType] = useState("BOLETO")
  const [expiration_day, setExpirationDay] = useState(5)
  const [document_value, setDocumentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [active, setActive] = useState(true)

  const [myRef, setMyRef] = useState()

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")

  function handlerSwitch() {
    setActive(!active)
  }

  useEffect(() => {

    setTextAlert("")

    if (arrTypePayments.length === 0) {
      findTypePayments()
    }

    if (arrTypeExpenses.length === 0) {
      findTypeExpenses()
    }

    if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
      setId(objSelected.id)

      if (objSelected.provider) {
        setProviderId(objSelected.provider.id)
        setProviderName(objSelected.provider.name)
      }

      // setClientId(objSelected.client_id)
      // setClientName(getClientName(clients, objSelected.client_id))

      setTypeExpenseId(objSelected.type_expense_id)
      setPaymentType(objSelected.payment_type)
      setExpirationDay(objSelected.expiration_day)
      setDocumentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
      setActive(objSelected.active)
      setTextDesc("Atualizar")
    }
    else {
      setId(0)
      setProviderId(0)
      setProviderName("")
      // setClientId(0)
      // setClientName("")
      setTypeExpenseId(0)
      setPaymentType("BOLETO")
      setExpirationDay(5)
      setDocumentValue({ formattedValue: "", value: 0, floatValue: 0 })
      setActive(true)
      setTextDesc("Adicionar")
    }

  }, [objSelected])

  async function findTypeExpenses() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_expenses', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      // console.log(responseJson)

      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_EXPENSES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function findTypePayments() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_payments', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_PAYMENTS", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function saveData() {

    if (!registerPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para cadastros")
      return
    }

    if (provider_id === 0) {
      setTextAlert("Informe um Fornecedor")
      return
    }

    if (type_expense_id === 0) {
      setTextAlert("Informe o Tipo de Despesa")
      return
    }

    if (document_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor do documento")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'fixed_expenses' : `fixed_expenses/${id}/update`

    try {

      let response = await fetch(DEFAULT_URL + url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          provider_id: provider_id,
          type_expense_id: type_expense_id,
          payment_type: payment_type,
          expiration_day: expiration_day,
          document_value: document_value.floatValue,
          active: active
        })
      });

      let responseJson = await response.json();
      console.log(responseJson)
      setTextButton("Salvar")

      if (responseJson.success) {
        dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

      setTextAlert("Erro ao efetuar operação.")

    } catch (error) {
      console.error(error);
    }

  }

  return (
    <Container>

      <Title>{textDesc}</Title>

      <AutoComplete
        label={"Fornecedor"}
        finder={"providers"}
        item={{ id: provider_id, name: provider_name }}
        setSelected={(item) => {
          setProviderId(item.id)
          setProviderName(item.search)
        }}
      />

      <Field>

        <label>Tipo:</label>
        <CheckBox
          ref={el => setMyRef(el)}>
          {arrTypeExpenses.map((e, i) => {

            if (type_expense_id === e.id) {
              if (i >= 5) myRef.scrollTo(i * 90, 0)
              else myRef.scrollTo(0, 0)
            }

            if (e.active) {
              const name = (e.name.length > 20) ? e.name.substr(0, 19) + ".." : e.name
              return (
                <Check
                  key={e.id}
                  onClick={() => setTypeExpenseId(e.id)}
                  className={(type_expense_id === e.id) ? 'selected' : ''}>
                  {name}
                </Check>)
            }

          })}

        </CheckBox>

      </Field>

      <Field>
        <label>Meio de pagamento:</label>
        <CheckBox>
          {arrTypePayments.map((e, i) => {
            if (e.active) {
              const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
              return (
                <Check
                  key={e.id}
                  onClick={() => setPaymentType(e.name)}
                  className={(payment_type === e.name) ? 'selected' : ''}>
                  {name}
                </Check>)
            }
          })}
        </CheckBox>
      </Field>

      <Field>
        <label>Vencimento:</label>
        <input
          type="text"
          placeholder="Vencimento"
          value={expiration_day}
          onChange={event => setExpirationDay(event.target.value)} />
      </Field>

      <Field>
        <label>Valor do documento:</label>

        <NumberFormat
          value={document_value.formattedValue}
          thousandSeparator={"."}
          prefix={'R$ '}
          decimalScale={2}
          placeholder={"R$"}
          decimalSeparator={","}
          fixedDecimalScale={true}
          allowNegative={false}
          allowedDecimalSeparators={["."]}
          onValueChange={(values) => {
            setDocumentValue(values)
          }} />
      </Field>

      <Select>
        <label>Status:</label>
        <Switch onChange={() => handlerSwitch()} checked={active} />
      </Select>

      <ViewSubmit>

        {textAlert !== "" &&
          <Alert>
            {textAlert}
          </Alert>}
        {textAlert === "" && <div />}

        <Button onClick={() => saveData()}>
          {textButton}
        </Button>

      </ViewSubmit>

    </Container>
  )

}

export default FieldsFixedExpenses;
