import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width: 95vw;
    top: 2.5vh;
    height: 95vh;
    left: 2.5vw;
    background-color: #fff;
    border-radius: 5px;
    z-index: 199;
    padding: 15px;

    > span {

        position: absolute;
        z-index: 100;
        top: -10px;
        right: -10px;
        width: 35px;
        height: 35px;
        background-color: #fff;
        border-radius: 50px;
        color: var(--like);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        border: 2px solid var(--like);
    }

    > label {
        
    }
`;