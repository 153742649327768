import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';


const FieldsRenter = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [city_id, setCityId] = useState(0)
    const [city_name, setCityName] = useState("")

    const [id, setId] = useState(0)
    const [name, setName] = useState("")
    const [identifier, setIdentifier] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [cep, setCep] = useState("")
    const [address, setAddress] = useState("")
    const [district, setDistrict] = useState("")
    const [responsibleCpf, setResponsibleCpf] = useState("")
    const [responsibleName, setResponsibleName] = useState("")
    const [street_number, setStreetNumber] = useState("")
    const [ie, setIe] = useState("")
    const [site, setSite] = useState("")

    const [type, setType] = useState("PJ")
    const [active, setActive] = useState(true)
    const [bad_payer, setBadPayer] = useState(false);

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active);
    }

    function handlerSwitchPayer() {
        setBadPayer(!bad_payer);
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)
            setName(objSelected.name)

            if (objSelected.identifier) {
                if (objSelected.identifier.length === 18) setType("PJ")
                else setType("PF")
            }

            if (objSelected.city) {
                setCityId(objSelected.city.id)
                setCityName(objSelected.city.name)
            }

            setIdentifier(objSelected.identifier)
            setEmail(objSelected.email)
            setNumber(objSelected.number)
            setCep(objSelected.cep)
            setAddress(objSelected.address)
            setDistrict(objSelected.district)
            setActive(objSelected.active)
            setBadPayer(objSelected.bad_payer)
            setResponsibleCpf(objSelected.responsible_cpf ?? "")
            setResponsibleName(objSelected.responsible_name ?? "")
            setStreetNumber(objSelected.street_number ?? "")
            setIe(objSelected.ie ?? "")
            setSite(objSelected.site ?? "")
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setCityId(0)
            setCityName("")
            setName("")
            setIdentifier("")
            setEmail("")
            setNumber("")
            setCep("")
            setAddress("")
            setDistrict("")
            setType("PJ")
            setActive(true)
            setBadPayer(false)
            setResponsibleCpf("")
            setResponsibleName("")
            setStreetNumber("")
            setIe("")
            setSite("")
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (name.trim() === "") {
            setTextAlert("Informe o campo Nome")
            return
        }

        if (identifier.trim() === "") {
            setTextAlert("Informe o campo Identificador")
            return
        }

        if (email.trim() === "") {
            setTextAlert("Informe o campo Email")
            return
        }

        if (city_id === 0) {
            setTextAlert("Informe o campo Cidade")
            return
        }

        if (cep.trim() === "") {
            setTextAlert("Informe o campo CEP")
            return
        }

        if (address.trim() === "") {
            setTextAlert("Informe o campo Endereço")
            return
        }

        if (district.trim() === "") {
            setTextAlert("Informe o campo Bairro")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'renters' : `renters/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    city_id: city_id,
                    name: name,
                    identifier: identifier,
                    email: email,
                    number: number,
                    cep: cep,
                    address: address,
                    district: district,
                    active: active,
                    bad_payer: bad_payer,
                    responsible_cpf: type === "PJ" ? responsibleCpf : identifier,
                    responsible_name: type === "PJ" ? responsibleName : name,
                    street_number: street_number,
                    ie: ie,
                    site: site
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_name') {
                setTextAlert("Nome já existente")
                dispatch({ type: "SET_SEARCH", data: name })
                return
            }

            if (message === 'contains_identifier') {
                setTextAlert("Identificador já existente")
                dispatch({ type: "SET_SEARCH", data: identifier })
                return
            }

            if (message === 'contains_email') {
                setTextAlert("Email já existente")
                dispatch({ type: "SET_SEARCH", data: email })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Nome:</label>

                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />

            </Field>

            <Field>
                <label>I.E:</label>

                <input
                    type="text"
                    placeholder="I.E"
                    value={ie}
                    onChange={event => setIe(event.target.value)} />

            </Field>

            <Field>
                <label>Site:</label>

                <input
                    type="text"
                    placeholder="Site"
                    value={site}
                    onChange={event => setSite(event.target.value)} />

            </Field>

            <Field>
                <label>Tipo:</label>
                <CheckBox>
                    <Check onClick={() => setType("PJ")} className={(type === "PJ") ? 'selected' : ''}>JURÍDICA</Check>
                    <Check onClick={() => setType("PF")} className={(type === "PF") ? 'selected' : ''}>FÍSICA</Check>
                </CheckBox>
            </Field>

            <Field>
                <label>Identificador:</label>

                {(type == "PJ") &&

                    <InputMask
                        mask="99.999.999/9999-99"
                        type="text"
                        placeholder="CPNJ"
                        value={identifier}
                        onChange={event => setIdentifier(event.target.value)} />
                }

                {(type == "PF") &&

                    <InputMask
                        mask="999.999.999-99"
                        type="text"
                        placeholder="CPF"
                        value={identifier}
                        onChange={event => setIdentifier(event.target.value)} />
                }

            </Field>

            {(type == "PJ") && (
              <>
                <Field>
                  <label>Nome do responsável:</label>

                  <input
                      type="text"
                      placeholder="Nome"
                      value={responsibleName}
                      onChange={event => setResponsibleName(event.target.value)} />

                </Field>
                <Field>
                  <label>CPF do responsável:</label>

                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    placeholder="CPF"
                    value={responsibleCpf}
                    onChange={event => setResponsibleCpf(event.target.value)} />

                </Field>
              </>
            )}

            <Field>
                <label>Email:</label>

                <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={event => setEmail(event.target.value)} />

            </Field>

            <Field>
                <label>Telefone:</label>

                <InputMask
                    mask="+99 (99) 99999-9999"
                    type="text"
                    placeholder="Telefone"
                    value={number}
                    onChange={event => setNumber(event.target.value)} />

            </Field>

            <AutoComplete
                label={"Cidade"}
                finder={"cities"}
                item={{ id: city_id, name: city_name }}
                setSelected={(item) => {
                    setCityId(item.id)
                    setCityName(item.search)
                }}
            />

            <Field>
                <label>CEP:</label>

                <InputMask
                    mask="99999-999"
                    type="text"
                    placeholder="CEP"
                    value={cep}
                    onChange={event => setCep(event.target.value)} />

            </Field>

            <Field>
                <label>Endereço:</label>

                <input
                    type="text"
                    placeholder="Endereço"
                    value={address}
                    onChange={event => setAddress(event.target.value)} />

            </Field>

            <Field>
                <label>Bairro:</label>

                <input
                    type="text"
                    placeholder="Bairro"
                    value={district}
                    onChange={event => setDistrict(event.target.value)} />

            </Field>

            <Field>
                <label>Número:</label>

                <input
                    type="text"
                    placeholder="Número"
                    value={street_number}
                    onChange={event => setStreetNumber(event.target.value)} />

            </Field>

            <Select>
                <label>Bom Pagador:</label>
                <Switch onChange={() => handlerSwitchPayer()} checked={!bad_payer} />
            </Select>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsRenter;
