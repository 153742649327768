import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';




const FieldsTracker: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [n_board_id, setNBoardId] = useState(0)
    const [n_board_serial, setNBoardSerial] = useState("")
    const [chip_id, setChipId] = useState(0)
    const [chip_number, setChipNumber] = useState("")
    const [ownership_id, setOwnershipId] = useState(0)
    const [ownership_name, setOwnershipName] = useState("")

    const [serial, setSerial] = useState("")
    const [brand, setBrand] = useState("")
    const [model, setModel] = useState("")
    const [cost, setCost] = useState({ formattedValue: "50,00", value: 50.0, floatValue: 50.0 })
    const [note, setNote] = useState("")
    const [allow_niple, setAllowNiple] = useState(true)
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)

            if (objSelected.n_board) {
                setNBoardId(objSelected.n_board.id)
                setNBoardSerial(objSelected.n_board.serial)
            }
            else {
                setNBoardId(0)
                setNBoardSerial("")
            }

            if (objSelected.chip) {
                setChipId(objSelected.chip.id)
                setChipNumber(objSelected.chip.number)
            }
            else {
                setChipId(0)
                setChipNumber("")
            }

            if (objSelected.ownership_id) {
                setOwnershipId(objSelected.ownership.id)
                setOwnershipName(objSelected.ownership.name)
            }
            else {
                setOwnershipId(0)
                setOwnershipName("")
            }

            setSerial(objSelected.serial)
            setBrand(objSelected.brand)
            setModel(objSelected.model)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })

            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")

            setAllowNiple(objSelected.allow_niple)
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setNBoardId(0)
            setChipId(0)
            setOwnershipId(0)
            setOwnershipName("")
            setSerial("")
            setBrand("")
            setModel("")
            setCost({ formattedValue: "50,00", value: 50.0, floatValue: 50.0 })
            setNote("")
            setAllowNiple(true)
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (serial.trim() === "") {
            setTextAlert("Informe o campo Serial")
            return
        }

        // if (serial.length < 30) {
        //     setTextAlert("Serial inválido")
        //     return
        // }

        if (brand.trim() === "") {
            setTextAlert("Informe o campo Marca")
            return
        }

        if (model.trim() === "") {
            setTextAlert("Informe o campo Modelo")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'trackers' : `trackers/${id}/update`
        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    serial: serial,
                    n_board_id: (n_board_id === 0) ? null : n_board_id,
                    chip_id: (chip_id === 0) ? null : chip_id,
                    ownership_id: (ownership_id === 0) ? null : ownership_id,
                    brand: brand,
                    model: model,
                    cost: cost.floatValue,
                    note: note,
                    allow_niple: allow_niple,
                    active: active
                })
            });

            let responseJson = await response.json();
            //console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message == 'findByPk_niple') {
                setTextAlert("Existe um Niple com esse vícunlo")
                return
            }

            if (message === 'contains_serial') {
                setTextAlert("Serial já existente")
                dispatch({ type: "SET_SEARCH", data: serial })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Serial:</label>

                <input
                    type="text"
                    placeholder="Serial"
                    maxLength={20}
                    value={serial}
                    onChange={event => setSerial(event.target.value)} />

            </Field>

            <AutoComplete
                label={"NBoard"}
                finder={"n_boards"}
                item={{ id: n_board_id, name: n_board_serial }}
                setSelected={(item) => {
                    setNBoardId(item.id)
                    setNBoardSerial(item.search)
                }}
            />

            <AutoComplete
                label={"Chip"}
                finder={"chips"}
                item={{ id: chip_id, name: chip_number }}
                setSelected={(item) => {
                    setChipId(item.id)
                    setChipNumber(item.search)
                }}
            />

            <AutoComplete
                label={"Posse"}
                finder={"users"}
                item={{ id: ownership_id, name: ownership_name }}
                setSelected={(item) => {
                    setOwnershipId(item.id)
                    setOwnershipName(item.search)
                }}
            />

            <Field>
                <label>Marca:</label>
                <input
                    type="text"
                    placeholder="Marca"
                    value={brand}
                    onChange={event => setBrand(event.target.value)}
                />
            </Field>

            <Field>
                <label>Modelo:</label>
                <input
                    type="text"
                    placeholder="Modelo"
                    value={model}
                    onChange={event => setModel(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
            </Field>

            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Select>
                <label>RS232:</label>
                <Switch onChange={() => setAllowNiple(!allow_niple)} checked={allow_niple} />
            </Select>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsTracker;