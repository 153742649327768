import styled, { css } from 'styled-components'

export const Container = styled.div`
    height: 100%;
    width: 100%; 
    padding: 0px 20px 15px 20px;
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 35px;
    letter-spacing: 2px;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const Table = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 0 0;
`;

export const Head = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #eee;
`;

const fontHead = css`
    display: flex;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    padding: 10px;
    border: 1px solid #eee;
    border-top-width: 0;
`;

export const Hash = styled.div`
    ${fontHead};
    width: 45px;
    justify-content: center;
    border-right-width: 0;
`;

export const Name = styled.div`
    ${fontHead};
    width: 20%;
    border-right-width: 0;

    @media(max-width: 725px) {
        width: 50%;
    }
`;

export const Integers = styled.div`
    ${fontHead};
    width: 8%;
    justify-content: center;
    border-right-width: 0;

    @media(max-width: 725px) {
        display: none;
    }

`;

export const Cost = styled.div`
    ${fontHead};
    width: 10%;
    justify-content: flex-end;
    border-right-width: 0;

    @media(max-width: 725px) {
        display: none;
    }
`;

export const Status = styled.div`
    ${fontHead};
    width: 20%;

    @media(max-width: 725px) {
        width: 50%;
    }
`;

export const ItemRow = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: 'GothamPro';
`

export const HtmlStatus = styled.div`
    padding: 10px;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    font-family: 'GothamProBold';
    justify-content: center;
    align-items: center;
`
