import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    setRemittanceFile: ["remittanceFile"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  remittanceFile: false
};

const setRemittanceFile = (state = INITIAL_STATE, action) => {
    const { remittanceFile } = action;
  return {
    ...state,
    remittanceFile
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_REMITTANCE_FILE]: setRemittanceFile
});