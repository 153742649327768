import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, GridItem, GridView, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';



const FieldsMaintenance: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const clients = useSelector(state => state.clients)
    const objSelected = useSelector(state => state.objSelected)

    const [maintenance_services, setMaintenanceServices] = useState([])

    const [id, setId] = useState(0)
    const [vehicle_id, setVehicleId] = useState(0)
    const [vehicle_identifier, setVehicleIdentifier] = useState("")
    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")

    const [technician, setTechnician] = useState("")
    const [date, setDate] = useState("")
    const [service_rendered, setServiceRendered] = useState("")
    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [note, setNote] = useState("")
    const [routine, setRoutine] = useState(true)
    const [observation_date, setObservationDate] = useState("")
    const [services, setServices] = useState([]);
    const [status, setStatus] = useState("AGENDAR")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setRoutine(!routine)
    }

    function handleService(item) {

        const clone = Object.assign([], services);
        var index = clone.findIndex(s => s == item.id);
        if (index > -1) {
            clone.splice(index, 1);
        } else {
            clone.push(item.id);
        }

        setServices(clone);
    }

    useEffect(() => {
        Moment.locale('pt-br');
        findServices()
    }, [])

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)

            if (objSelected.vehicle) {
                setVehicleId(objSelected.vehicle.id)
                setVehicleIdentifier(objSelected.vehicle.identifier)
            }
            else {
                setVehicleId(0)
                setVehicleIdentifier("")
            }

            setClientId(objSelected.client_id)
            setClientName(getClientName(clients, objSelected.client_id))

            setTechnician(objSelected.technician)
            setDate(objSelected.date)
            setServiceRendered(objSelected.service_rendered)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")
            setRoutine(objSelected.routine)

            if (objSelected.observation_date) {
                setObservationDate(Moment(objSelected.observation_date).format("DD/MM/YYYY"))
            }

            if (objSelected.services) {
                setServices(objSelected.services.split(","))
            } else {
                setServices([])
            }


            setStatus(objSelected.status)

            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setVehicleId(0)
            setVehicleIdentifier("")
            setClientId(0)
            setClientName("")
            setTechnician("")
            setDate("")
            setServiceRendered("")
            setCost({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
            setNote("")
            setRoutine(true)
            setServices([])
            setStatus("AGENDAR")
            setObservationDate("")
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function findServices() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_service', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setMaintenanceServices(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (vehicle_id == 0) {
            setTextAlert("Informe o campo Veículo")
            return
        }

        if (observation_date.trim() === "") {
            setTextAlert("Informe o campo Data da Ocorrência")
            return
        }

        if (client_id === 0) {
            setTextAlert("Informe o campo Cliente")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'maintenances' : `maintenances/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: (client_id === 0) ? null : client_id,
                    vehicle_id: (vehicle_id === 0) ? null : vehicle_id,
                    technician: technician,
                    date: date,
                    service_rendered: service_rendered,
                    observation_date: Moment(observation_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                    services: services.toString(),
                    status: status,
                    cost: cost.floatValue,
                    note: note,
                    routine: routine
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Cliente"}
                finder={"clients"}
                item={{ id: client_id, name: client_name }}
                setSelected={(item) => {
                    setClientId(item.id)
                    setClientName(item.search)
                }}
            />

            <AutoComplete
                label={"Veículo"}
                finder={"vehicles"}
                item={{ id: vehicle_id, name: vehicle_identifier }}
                setSelected={(item) => {
                    setVehicleId(item.id)
                    setVehicleIdentifier(item.search)
                }}
            />

            <Field>
                <label>Data da ocorrência:</label>

                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data da ocorrência"
                    value={observation_date}
                    onChange={event => setObservationDate(event.target.value)} />

            </Field>

            <Field>
                <label>Técnico:</label>
                <input
                    type="text"
                    placeholder="Técnico"
                    value={technician}
                    onChange={event => setTechnician(event.target.value)} />
            </Field>

            <Field>
                <label>Data:</label>
                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data"
                    value={date}
                    onChange={event => setDate(event.target.value)} />
            </Field>

            <Field>
                <label>Serviços prestados:</label>

                <GridView>
                    {maintenance_services.map(item => {
                        return (
                            <GridItem key={`services_${item.name}`} onClick={() => handleService(item)} className={services.find(s => s == item.id) ? "selected" : ""}>
                                <p>{item.name}</p>
                            </GridItem>
                        )
                    })}

                </GridView>

            </Field>

            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
            </Field>


            <Select>
                <label>Rotina:</label>
                <Switch onChange={() => handlerSwitch()} checked={routine} />
            </Select>

            <Field>
                <label>Status:</label>
                <CheckBox>

                    <Check onClick={() => { setStatus("AGENDAR") }} style={{ border: (status === "AGENDAR") ? "2px solid #e82653" : "" }}>
                        AGENDAR
                    </Check>

                    <Check onClick={() => { setStatus("AGENDADO") }} style={{ border: (status === "AGENDADO") ? "2px solid #e82653" : "" }}>
                        AGENDADO
                    </Check>

                    <Check onClick={() => { setStatus("EM OBSERVAÇÃO") }} style={{ border: (status === "EM OBSERVAÇÃO") ? "2px solid #e82653" : "" }}>
                        EM OBSERVAÇÃO
                    </Check>

                    <Check onClick={() => { setStatus("CONCLUIDA") }} style={{ border: (status === "CONCLUIDA") ? "2px solid #e82653" : "" }}>
                        CONCLUIDA
                    </Check>

                    <Check onClick={() => { setStatus("PARADO") }} style={{ border: (status === "PARADO") ? "2px solid #e82653" : "" }}>
                        PARADO
                    </Check>

                </CheckBox>

            </Field>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsMaintenance;