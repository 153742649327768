import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Container,
    Row,
    Title
} from './style';

import Moment from 'moment/min/moment-with-locales';
// import { Chart } from "react-google-charts";

const GraphExpenses: React.FC = ({ client_id, callback }) => {

    const objSelected = useSelector(state => state.objSelected)

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    useEffect(() => {

        Moment.locale('pt-br');

        var arrayAux = []
        var arrayAux2 = []

        objSelected.map(item => {

            var d = parseInt(item.due_date.split("-")[2])
            if (typeof arrayAux[d] === 'undefined') {
                arrayAux[d] = [Moment(item.due_date).format("DD/MM/YYYY"), parseFloat(item.document_value)]
                arrayAux2[d] = [parseFloat(item.document_value), parseFloat(item.document_value)]
            }
            else {
                arrayAux[d][1] = arrayAux[d][1] + parseFloat(item.document_value)
                arrayAux2[d][1] = arrayAux2[d][1] + parseFloat(item.document_value)
                arrayAux2[d][0] = arrayAux2[d][0] + parseFloat(item.document_value)
            }

        })

        var filtered = arrayAux.filter(function (el) {
            return el != null;
        });

        var filtered2 = arrayAux2.filter(function (el) {
            return el != null;
        });

        var aux = []
        filtered2 = filtered2.map((item, i) => {
            aux[i] = [`R$ ${item[0].toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`, item[1]]
        })

        filtered = [["Data", "Quantidade"], ...filtered]
        filtered2 = [["Valor", "Quantidade"], ...aux]
        //console.log(filtered2)

        setData(filtered2)
        setData2(filtered)

    }, [objSelected])

    return (
        <Container>

            <Title>ANÁLISES: CONTAS A PAGAR</Title>
            <Row />

            {/* <Graph>

                <ViewGraph>

                    {data.length > 0 && <Chart
                        width={'100%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div>Carregando gráfico</div>}
                        data={data}
                        options={{
                            title: 'Receitas',
                            pieHole: 0.7,
                            pieSliceText: 'none'
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />}
                </ViewGraph>

                <Row2 />

                <ViewGraph>
                    {data2.length > 0 && <Chart
                        width={'100%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div>Carregando gráfico</div>}
                        data={data2}
                        options={{
                            title: 'Datas',
                            pieHole: 0.7,
                            pieSliceText: 'none'
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />}
                </ViewGraph>
            </Graph> */}

        </Container>
    )

}

export default GraphExpenses;