import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../../styles/CrudLayout';
import AutoComplete from '../../../AutoComplete';
import {
    Container
} from './style';

const FieldsSubProblemsNiple: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)

    const [problem_niple_id, setProblemNipleId] = useState(0)
    const [problem_niple_name, setProblemNipleName] = useState("")

    const [name, setName] = useState("")
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)
            setName(objSelected.name)
            setActive(objSelected.active)

            setProblemNipleId(objSelected.problem_niple.id)
            setProblemNipleName(objSelected.problem_niple.name)

            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setName("")
            setActive(true)

            setProblemNipleId(0)
            setProblemNipleName("")

            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (problem_niple_id === "")
            return setTextAlert("Informe o campo Problema")

        if (name.trim() === "")
            return setTextAlert("Informe o campo Nome")

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'sub_problems_niple' : `sub_problems_niple/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    problem_niple_id: problem_niple_id,
                    name: name,
                    active: active
                })
            });

            let responseJson = await response.json();
            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Problema"}
                finder={"problems_niple"}
                item={{ id: problem_niple_id, name: problem_niple_name }}
                setSelected={(item) => {
                    setProblemNipleId(item.id)
                    setProblemNipleName(item.search)
                }}
            />

            <Field>
                <label>Nome:</label>
                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />
            </Field>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsSubProblemsNiple;