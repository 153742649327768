
import React, { useState } from 'react';
import { Container, HeaderBar } from './style';
import MaintenanceTimelineNiple from './TimelineNiple';


const selectedColor = "#000";

const MaintenanceTimeline: React.FC = () => {

  const [headerSelected, setHeaderSelected] = useState("NIPLE")


  return (
    <Container>

      <HeaderBar>

        <div
          style={headerSelected === "NIPLE" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("NIPLE")}
        >
          Niple
        </div>

        <div
          style={headerSelected === "VEICULO" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("VEICULO")}
        >
          Veículo
        </div>

        <div
          style={headerSelected === "EMBARCACAO" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("EMBARCACAO")}
        >
          Embarcação
        </div>

        <div
          style={headerSelected === "SAAS" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("SAAS")}>
          Saas.
        </div>


      </HeaderBar>


      {headerSelected === "NIPLE" && <MaintenanceTimelineNiple />}


    </Container>
  )

}

export default MaintenanceTimeline;