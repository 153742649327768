import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';

const ReportChassi: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [responseAll, setResponseAll] = useState([])

    const [totalChassi, setTotalChassi] = useState(0)
    const [totalLinked, setTotalLinked] = useState([])
    const [totalUnlinked, setTotalUnlinked] = useState([])
    const [totalInactives, setTotalInactives] = useState([])
    const [totalLots, setTotalLots] = useState([])
    const [lotNumber, setLotNumber] = useState(0)

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostLinked, setTotalCostLinked] = useState(0)
    const [totalCostUnlinked, setTotalCostUnlinked] = useState(0)
    const [totalCostInactive, setTotalCostInactive] = useState(0)

    useEffect(() => {
        findDetails()
    }, [])

    useEffect(() => {

        var totalChassi = 0;
        var totalLinked = [];
        var totalUnlinked = [];
        var totalInactive = [];
        var totalLots = [];
        var maxLot = 0;

        var totalCost = 0;
        var totalCostLinked = 0;
        var totalCostUnlinked = 0;
        var totalCostInactive = 0;

        responseAll.map(chassi => {

            if (search == "" || chassi.lot_number.includes(search) || chassi.serial.toLowerCase().includes(search.toLowerCase()) || chassi?.social_reason?.toLowerCase().includes(search.toLowerCase())) {

                const lot = chassi.lot_number.split(".")[2]
                if (parseInt(lot) > maxLot) {
                    maxLot = lot;
                }

                if (!totalLots[lot]) {
                    totalLots[lot] = [chassi]
                } else {
                    totalLots[lot].push(chassi)
                }

                if (chassi.active) {
                    totalChassi += 1;
                    totalCost = parseFloat(totalCost) + parseFloat(chassi.cost);

                    if (chassi.tracker) {
                        totalLinked.push(chassi);
                        totalCostLinked = parseFloat(totalCostLinked) + parseFloat(chassi.cost);
                    }
                    else {
                        totalUnlinked.push(chassi);
                        totalCostUnlinked = parseFloat(totalCostUnlinked) + parseFloat(chassi.cost);
                    }
                }
                else {
                    totalInactive.push(chassi);
                    totalCostInactive = parseFloat(totalCostInactive) + parseFloat(chassi.cost);
                }
            }

        })

        var filtered = totalLots.filter(function (el) {
            return el != null;
        });

        setTotalChassi(totalChassi)
        setTotalLinked(totalLinked)
        setTotalUnlinked(totalUnlinked)
        setTotalInactives(totalInactive)
        setTotalLots(filtered)
        setLotNumber(maxLot)

        setTotalCost(totalCost)
        setTotalCostLinked(totalCostLinked)
        setTotalCostUnlinked(totalCostUnlinked)
        setTotalCostInactive(totalCostInactive)


    }, [search, responseAll])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'chassis/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setResponseAll(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>Chassis</strong> <label>Total: {totalChassi}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Lotes</strong> <label>Total: {lotNumber}</label><span></span></Totals>
                {totalLots.map((lot, key) => {
                    var cost = 0;
                    lot.map(l => {
                        cost = parseFloat(cost) + parseFloat(l.cost);
                    })
                    return (
                        <Totals><strong>Lote: {key + 1}</strong> <label>Total: {lot.length}</label><span>R$ {cost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                    )
                })}
                <Totals><span></span></Totals>
                <Totals><strong>Chassis Niple</strong> <label>Total: {totalLinked.length}</label><span>R$ {totalCostLinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnlinked.length}</label><span style={{ color: 'red' }}>R$ {totalCostUnlinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativos</strong> <label>Total: {totalInactives.length}</label><span>R$ {totalCostInactive.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>


                <List>

                    <strong>Chassis: Niple</strong>

                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Empresa</strong>
                            <strong>Rastreador</strong>
                            <strong>N Board</strong>
                            <strong>Chassi</strong>
                            <strong>Lote</strong>
                            <label>Custo</label>
                        </div>

                        {totalLinked.map((chassi, i) => {

                            const name = (chassi.social_reason) ? chassi.social_reason : chassi.name;
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{name}</span>
                                    <span>{chassi.tracker}</span>
                                    <span>{chassi.n_board}</span>
                                    <span>{chassi.serial}</span>
                                    <span>{chassi.lot_number}</span>
                                    <label>R$ {parseFloat(chassi.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Chassis: Disponíveis</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Chassi</strong>
                            <strong>Lote</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalUnlinked.map((chassi, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chassi.serial}</span>
                                    <span>{chassi.lot_number}</span>
                                    <span>{(chassi.note) ? chassi.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</span>
                                    <label>R$ {parseFloat(chassi.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Chassis: Inativos</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Chassi</strong>
                            <strong>Lote</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalInactives.map((chassi, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chassi.serial}</span>
                                    <span>{chassi.lot_number}</span>
                                    <span>{(chassi.note) ? chassi.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</span>
                                    <label>R$ {parseFloat(chassi.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>



        </Container>
    )

}

export default ReportChassi;