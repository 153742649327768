import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_URL } from '../../../helper';
import { Container, Iframe } from "./style";

const PDFTicket: React.FC = () => {

  const user = useSelector(state => state.user)
  const objSelected = useSelector(state => state.objSelected)
  const [ticket, setTicket] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    executeLoadPdf();
  }, []); /* eslint-disable-line */

  const executeLoadPdf = async () => {
    fetch(`${DEFAULT_URL}revenues/ticket/${objSelected.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      }
    })
      .then((e) => {
        e.blob()
          .then(blob => {
            setTicket(window.URL.createObjectURL(blob));
          })
          .catch(e => setError(`Arquivo não encontrado.`))
      })
      .catch(e => setError(`Arquivo não encontrado.`));
  };

  return (
    <Container>
      {ticket && <Iframe src={ticket} title={`BOLETO: ${objSelected.renter.name}`} />}
      {error && <span>{error}</span>}
    </Container>
  );
}

export default PDFTicket;