import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
`;

export const Row = styled.div`
    height: 2px;
    background-color: #f1f1f1;
    border-radius: 2px;
    margin: 10px 0;
`;

export const Row2 = styled.div`
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 2px;
    margin: 0 20px 0 20px;
`;

export const HorizontalField = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
`;

export const FieldHorizontal = styled.div`
    display: flex;
    overflow: scroll;
    flex-direction: column;
    min-height: 60px;
    max-height: 140px;
    min-width: 30%;
    margin-bottom: 10px;
    position: relative;

    > label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-left: 2px;
        color: var(--black2);
    }

    > input {
        background: var(--secondary);
        height: 40px;
        border-radius: 5px;
        padding: 0 10px 0 10px;
        font-size: 15px;
        color: var(--black2);
    }
`;

export const InfoReturns = styled.div`

    padding: 10px;
    padding-top: 25px;
    padding-bottom: 50px;

    > p {
        font-size: 15px;
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #999;
        margin-bottom: 5px;

        > p {
            overflow: hidden;
            height : 18px;
            font-size: 12px;
        }
    }
`

export const ViewButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Button = styled.button`
    padding: 10px 10px 9px 10px;
    border-radius: 5px;
    width: 190px;
    height: 35px;
    background: var(--details);
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-family: 'GothamProBold';

    :disabled {
        opacity: .5;
        filter: alpha(opacity=50);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        -moz-opacity: .5;
        -khtml-opacity: .5;
        -o-opacity: .5;
        -webkit-opacity: .5;
    }
`;

export const ButtonToggle = styled(Button)`
    margin-left: 20px;
    opacity: .5;
    background: var(--bancosicoob);
    &.active {
        opacity: 1 !important;
    }
`;

export const ButtonSicoob = styled(Button)`
    margin-right: 20px;
    background: var(--bancosicoob);
`;

export const ButtonInter = styled(Button)`
    margin-right: 20px;
    background: var(--bancointer);
`;

export const InfoHeader = styled.div`

    width: 100%;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    padding: 0 10px 0 10px;

    > label {
        width: 70%;
        font-weight: bold;
    }

    > span {
        width: 10%;
        font-weight: bold;
        text-align: center;
    }

    > strong {
        width: 10%;
        font-weight: bold;
        text-align: right;
    }

`;

export const InfoPayment = styled.div`
    height: auto;
    background-color: var(--primary);
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    position: relative;
    margin: 5px 0 5px 0;
    display: flex;
    flex-direction: row;
    font-size: 13px;

    > label {
        width: 70%;
    }

    > span {
        width: 10%;
        text-align: center;
    }

`;

export const Ticket = styled.div`
    position: relative;
    justify-content: center;
`;

export const TicketNumber = styled.div`
        position: absolute;
        border-radius: 100px;
        border: 2px solid var(--like);
        color: var(--like);
        background-color: var(--white);
        top: 0px;
        left: 10px;
        z-index: 100;
        height: 20px;
        width: 20px;
        padding-top: 1px;
        font-size: 11px;
        text-align: center;
        font-weight: bold;
    `;

export const TicketInfo = styled.div`

    margin-left: 20px;
    width: 105px;
    height: 60px;
    top: 5px;
    border-radius: 5px;
    background-color: #f9f9f9;
    border: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    padding: 2px;
    position: relative;
    color: var(--black2);

    > p {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        width: 100%;
    }

    > span {
        overflow: hidden;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
    }
`;
