import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Chart } from "react-google-charts";
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, DROPDOWN_YEAR, formatNumber } from '../../../helper';
import DropDown from '../../DropDown';
import Modal from '../../Modal';
import { DateDiv, Header } from '../FinancialApprovals/style';
import {
    Container, Content,
    Row, Totals
} from './style';

const DF_SOCIOS_RENTER = [{ id: 42, aux: 1 }, { id: 44, aux: 4 }, { id: 43, aux: 2 }, { id: 58, aux: 5 }, { id: 59, aux: 7 }];
const DF_SOCIOS_PROVIDER = [{ id: 1, aux: 1 }, { id: 31, aux: 4 }, { id: 3, aux: 2 }, { id: 28, aux: 5 }, { id: 33, aux: 7 }];

const FinancialBalance = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)

    const [yearSelected, setYearSelected] = useState(DROPDOWN_YEAR.find(y => y.name === Moment().format("YYYY")))

    const [modal, setModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("");

    const scrollView = useRef();

    const [socios, setSocios] = useState([])
    const [socioSelected, setSocioSelected] = useState(false)

    const [arrayRevenues, setArrayRevenues] = useState([])
    const [arrayExpenses, setArrayExpenses] = useState([])
    const [totalAll, setTotalAll] = useState(0)

    const [dataM, setDataM] = useState([])
    const [showDesc, setShowDesc] = useState(false)

    useEffect(() => {
        Moment.locale('pt-br');
        findSocios();

    }, [])

    useEffect(() => {

        if (yearSelected && socioSelected) {
            findRevenues(socioSelected.id, yearSelected)
        }

    }, [client])

    useEffect(() => {

        var auxMonth = [];
        var totalBills = 0;

        if (arrayRevenues.length > 0) {
            arrayRevenues.map(item => {

                item.type = "revenues"
                item.expand = false;

                if (item.payment_date) {
                    totalBills = totalBills + parseFloat(item.payment_value)
                    const d = parseInt(item.payment_date.split("-")[1])
                    if (typeof auxMonth[d] === 'undefined') auxMonth[d] = [item]
                    else auxMonth[d].push(item)
                }


            })

        }

        if (arrayExpenses.length > 0) {

            arrayExpenses.map(item => {

                item.type = "expenses"
                item.expand = false;

                if (item.payment_date) {

                    totalBills = totalBills - parseFloat(item.payment_value)
                    const d = parseInt(item.payment_date.split("-")[1])
                    if (typeof auxMonth[d] === 'undefined') auxMonth[d] = [item]
                    else auxMonth[d].push(item)

                }




            })


        }

        setDataM(auxMonth)
        setTotalAll(totalBills)

        dispatch({ type: "SET_LOADER", data: false })

    }, [arrayExpenses, arrayRevenues])


    async function findSocios() {

        try {

            let response = await fetch(DEFAULT_URL + 'users', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var s = [];
                responseJson.data.map(item => {
                    if (item.active && item.command_password === "1000") {
                        s.push(item)
                    }
                });

                setSocios(s);
                setSocioSelected(s[0].id)
                findRevenues(s[0].id, yearSelected)
                return;
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }


    }

    async function findRevenues(user_id, year) {

        setArrayRevenues([]);
        setSocioSelected(user_id);

        const renter = DF_SOCIOS_RENTER.find(i => i.aux === user_id);

        try {

            let response = await fetch(DEFAULT_URL + 'revenues/renter', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client.id,
                    renter_id: renter.id,
                    year: year.name
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrayRevenues(responseJson.data);
                findExpenses(user_id, year);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findExpenses(user_id, year) {

        const provider = DF_SOCIOS_PROVIDER.find(i => i.aux == user_id);

        try {

            let response = await fetch(DEFAULT_URL + 'expenses/provider', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client.id,
                    provider_id: provider.id,
                    year: year.name
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrayExpenses(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    const openModal = (layout) => {

        dispatch({ type: "SET_DARK", data: true })
        setModalTitle(layout);
        setModal(true);

        scrollView.current.scrollIntoView()

    }

    return (
        <Container
            ref={scrollView}
        >

            {modal &&
                <Modal
                    onClose={() => setModal(false)}
                    layout={modalTitle}
                    client_id={client.id}
                    callback={() => {

                    }}
                />}

            <Row />

            <Header>

                <DateDiv>
                    {yearSelected && <DropDown width={100} item={yearSelected.name} array={DROPDOWN_YEAR} onChange={(item) => {
                        setYearSelected(item)
                        findRevenues(item)
                    }} />}
                </DateDiv>

            </Header>

            <Content>

                {socios.map(item => {
                    if (item.active && item.command_password === "1000") {
                        return (
                            <div
                                style={{ background: (item.id == socioSelected) ? "#aaa" : "#f1f1f1" }}
                                onClick={() => findRevenues(item.id, yearSelected)}
                            >
                                {item.name}
                            </div>
                        )
                    }
                })}

            </Content>

            <Row />

            <Totals>

                <FontAwesomeIcon icon={faListOl}
                    onClick={() => {
                        setShowDesc(!showDesc);
                    }}
                    style={{ width: 17, height: 17, right: 20, top: 10, cursor: 'pointer' }} />

                <div>

                    {dataM.map((month, i) => {

                        var lastDay = new Date(parseInt(yearSelected.name), i, 0);

                        var mAux = i;
                        if (i.toString().length == 1)
                            mAux = `0${i}`

                        var tRevenues = 0;
                        var tExpenses = 0;

                        return (
                            <div>
                                <p>Período 01/{mAux}/{yearSelected.name} até {lastDay.getDate()}/{mAux}/{yearSelected.name}</p>

                                {month.map(item => {
                                    if (item.type === "revenues")
                                        tRevenues = tRevenues + parseFloat(item.payment_value);
                                    else if (item.type === "expenses")
                                        tExpenses = tExpenses + parseFloat(item.payment_value);

                                    if (showDesc)
                                        return (<div className='totals' style={{ color: (item.type === "revenues") ? "black" : "red" }}>
                                            <p>{item.note}</p>
                                            <span>{formatNumber(item.payment_value)}</span>
                                        </div>)
                                })}

                                <div>SUPRIMENTO: {formatNumber(tRevenues)}</div>
                                <div>DEVOLUÇÃO: {formatNumber(tExpenses)}</div>
                                <div style={{ background: "#fff" }}>SALDO: {formatNumber(tRevenues - tExpenses)}</div>

                            </div>
                        )

                    })}

                    <div className='totals'>SALDO DO ANO: {formatNumber(totalAll)}</div>

                </div>

            </Totals>

        </Container>
    )

}

export default FinancialBalance;