
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import {
  Container,
  ExcelDiv,
  Info,
  Itens,
  RowExcel,
  TitleExcel
} from './style';

const FinancialProvision: React.FC = () => {

  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)
  const arrTypeRevenues = useSelector(state => state.typeRevenues)

  const [typeRevenueSelected, setTypeRevenueSelected] = useState(null)
  const [arrRevenues, setArrRevenues] = useState([])

  const [totalPayed, setTotalPayed] = useState(0)

  useEffect(() => {

    Moment.locale('pt-br');

    if (arrTypeRevenues.length === 0)
      findTypeRevenues()

  }, [])

  useEffect(() => {
    if (typeRevenueSelected !== null)
      findRevenueByType(typeRevenueSelected);
  }, [typeRevenueSelected])


  async function findTypeRevenues() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_revenues', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_REVENUES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function findRevenueByType(type_revenue) {

    try {

      let response = await fetch(DEFAULT_URL + 'revenues/type_revenue', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          type_revenue: type_revenue.name
        })
      });

      let responseJson = await response.json();
      if (responseJson.success) {

        var aux = [];
        var totalPayed = 0;

        responseJson.data.map(item => {

          if (!item.canceled && item.payment_date) {

            totalPayed = totalPayed + parseFloat(item.payment_value);

            var monthLy = parseInt(item.due_date.split("-")[0] + item.due_date.split("-")[1])
            if (typeof aux[monthLy] === 'undefined') {
              aux[monthLy] = [item]
            }
            else {
              aux[monthLy].push(item)
            }
          }

        })

        setTotalPayed(totalPayed);
        setArrRevenues(aux.reverse());

        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>

      <Info>

        {typeRevenueSelected === null && arrTypeRevenues.filter(ar => ar.active).map(item => {
          return (
            <span onClick={() => setTypeRevenueSelected(item)}>
              {item.name}
            </span>
          )
        })}

        {typeRevenueSelected !== null && arrRevenues.length === 0 && <p>
          Carregando...
        </p>}

        {typeRevenueSelected !== null && arrRevenues.length > 0 &&
          <div style={{ width: "100%" }}>

            <div style={{ fontWeight: "bold", marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between" }}>
              <p>{typeRevenueSelected.name} - <span onClick={() => setTypeRevenueSelected(null)}> VOLTAR </span> </p> <p>TOTAL: {formatNumber(totalPayed)}</p>
            </div>

            <ExcelDiv>

              {arrRevenues.map(date => {

                var monthPay = 0;
                date.map(item => {
                  monthPay = monthPay + parseFloat(item.payment_value);
                })

                return (
                  <div style={{ marginBottom: 40 }}>
                    <TitleExcel>
                      <strong style={{ color: "#fff" }}>Data: {date[0].due_date.split("-")[1] + "/" + date[0].due_date.split("-")[0]} </strong>
                      <strong style={{ color: "#fff" }}>{formatNumber(monthPay)} </strong>
                    </TitleExcel>
                    <Itens>
                      <p className='reciver'>PAGADOR</p>
                      <p className='date'>DATA</p>
                      <p className='payment'>VALOR DOCUMENTO</p>
                      <p className='payment'>VALOR PAGO</p>
                      <p>DESCRIÇÃO</p>
                    </Itens>
                    {date.map(item => {
                      return (
                        <RowExcel>
                          <p className='reciver'>{item.renter.name}</p>
                          <p className='date'>{Moment(item.payment_date).format("DD/MM/YYYY")}</p>
                          <p className='payment'>{formatNumber(item.document_value)}</p>
                          <p className='payment'>{formatNumber(item.payment_value)}</p>
                          <p>{item.note}</p>
                        </RowExcel>
                      )
                    })}

                  </div>
                )

              })}

            </ExcelDiv>

          </div>}

      </Info>

    </Container>
  )

}

export default FinancialProvision;
