import React from 'react';
import { useSelector } from 'react-redux';
import FinancialApprovals from './FinancialApprovals';
import FinancialBalance from './FinancialBalance';
import FinancialBills from './FinancialBills';
import FinancialCard from './FinancialCard';
import FinancialInvestments from './FinancialInvestments';
import FinancialPayer from './FinancialPayer';
import FinancialProvision from './FinancialProvision';
import FinancialRequests from './FinancialRequests';

import {
  Container, Info, TitleInfo
} from './style';


const Financial = () => {

  const page = useSelector(state => state.page)

  return (
    <Container>

      {page.data !== "Contas" && <TitleInfo>Financeiro: {page.data}</TitleInfo>}

      <Info>

        {page.data === "Aprovações" && <FinancialApprovals />}
        {page.data === "Contas" && <FinancialBills />}
        {/* {page.data === "Veículos Geral" && <FinancialRevenues />} */}
        {/* {page.data === "Total Veículos" && <FinancialDetailsVehicle />} */}
        {/* {page.data === "Veículos" && <FinancialRenters />} */}
        {page.data === "Cartão de crédito" && <FinancialCard />}
        {page.data === "Carteira de investimentos" && <FinancialInvestments />}
        {page.data === "Balancete de sócio" && <FinancialBalance />}
        {page.data === "Requisições" && <FinancialRequests />}
        {/* {page.data === "Análises" && <FinancialAnalize />} */}
        {page.data === "Pagamentos" && <FinancialPayer />}
        {page.data === "Recebimentos" && <FinancialProvision />}
        {/* {page.data === "Serviços de Software" && <FinancialSaas />} */}

      </Info>

    </Container>
  )

}

export default Financial;
