import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName } from '../../../helper';
import { Client, Container, Cost, Date, Details, Technician, Vehicle } from './style';



const GridMaintenance: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const clients = useSelector(state => state.clients)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenances', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {

            if (search === "" || item.vehicle.identifier.toUpperCase().includes(search.toUpperCase()) || item.technician.toUpperCase().includes(search.toUpperCase())) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    <Client style={{ fontWeight: 'bold' }}>Cliente</Client>
                    <Vehicle style={{ fontWeight: 'bold' }}>Veículo</Vehicle>
                    <Date style={{ fontWeight: 'bold' }}>Data</Date>
                    <Technician style={{ fontWeight: 'bold' }}>Técnico</Technician>
                    {/* <Note style={{ fontWeight: 'bold' }}>Serviço</Note> */}
                    <Cost style={{ fontWeight: 'bold' }}>Custo</Cost>
                    <Technician style={{ fontWeight: 'bold' }}>Status</Technician>
                    {/* <Active style={{ fontWeight: 'bold' }}>Rotina</Active> */}
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                if (search === "" || item.vehicle.identifier.toUpperCase().includes(search.toUpperCase()) || item.technician.toUpperCase().includes(search.toUpperCase())) {

                    return (
                        <div key={`maintenances_${item.id}`} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                <Client>{getClientName(clients, item.client_id)}</Client>
                                <Vehicle>{item.vehicle.identifier}</Vehicle>
                                <Date>{item.date}</Date>
                                <Technician>{item.technician}</Technician>
                                {/* <Note> {item.service_rendered}</Note> */}
                                <Cost>
                                    <NumberFormat
                                        value={item.cost}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        prefix={"R$ "}
                                        disabled={true}
                                        style={{ textAlign: 'center', fontSize: 11, width: '100%' }}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                    />
                                </Cost>
                                <Technician>{item.status}</Technician>
                                {/* <Active>{item.routine ? <CircleActive /> : <CircleInactive />}</Active> */}

                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridMaintenance;