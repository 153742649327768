import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
  Alert, Button, Field,
  Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
  Container
} from './style';

const FieldsTypeSaas = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const objSelected = useSelector(state => state.objSelected)

  const [id, setId] = useState(0)
  const [name, setName] = useState("")

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")

  useEffect(() => {

    setTextAlert("")

    if (Object.keys(objSelected).length > 0) {
      setId(objSelected.id)
      setName(objSelected.name)
      setTextDesc("Atualizar")
    }
    else {
      setId(0)
      setName("")
      setTextDesc("Adicionar")
    }

  }, [objSelected])

  async function saveData() {

    if (!registerPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para cadastros")
      return
    }

    if (name.trim() === "") {
      setTextAlert("Informe o campo Nome")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'type_saas' : `type_saas/${id}/update`

    try {

      let response = await fetch(DEFAULT_URL + url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          name: name
        })
      });

      let responseJson = await response.json();
      console.log(responseJson)

      setTextButton("Salvar")

      if (responseJson.success) {
        dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

      if (message === 'contains_name') {
        setTextAlert("Nome já existente")
        dispatch({ type: "SET_SEARCH", data: name })
        return
      }

      setTextAlert("Erro ao efetuar operação.")

    } catch (error) {
      console.error(error);
    }

  }

  return (
    <Container>

      <Title>{textDesc}</Title>

      <Field>
        <label>Nome:</label>

        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={event => setName(event.target.value)} />

      </Field>

      <ViewSubmit>

        {textAlert !== "" &&
          <Alert>
            {textAlert}
          </Alert>}
        {textAlert === "" && <div />}

        <Button onClick={() => saveData()}>
          {textButton}
        </Button>

      </ViewSubmit>


    </Container>
  )

}

export default FieldsTypeSaas;
