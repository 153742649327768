import styled, { css } from 'styled-components';

export const Title = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: bold;
    height: 40px;
    padding-top: 10px;
`;

export const Horizontal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
`;

export const FieldCost = styled.div`
    display: flex;
    background-color: #ddd;
    border-radius: 10px;
    flex-direction: column;
    min-height: 70px;
    width: 190px;
    padding: 10px;
    margin: 0 0 15px 0;

    > label {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 15px;
        margin-left: 2px;
        color: var(--black2);
    }

    > span {
        font-size: 16px;
        color: var(--black2);
        text-align: right;
    }

`;

export const Field = styled.div`
    display: flex;
    width: auto;
    flex-direction: column;
    min-height: 60px;
    max-height: 140px;
    margin-bottom: 10px;
    position: relative;

    ::-webkit-scrollbar {
        display: none;
    }

    > label {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-left: 2px;
        color: var(--black2);
    }

    > span {
        font-size: 16px;
        color: var(--black2);
    }

    > input {
        border: 1px solid #ddd;
        background: var(--newGray);
        height: 40px;
        border-radius: 5px;
        padding: 0 10px 0 10px;
        font-size: 16px;
        color: var(--black2);
        overflow: hidden;
    }   

    > textarea { 
        border: 1px solid #ddd;
        background: var(--newGray);
        height: 80px;
        max-height: 100px;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        font-size: 15px;
        color: var(--black2);
    }

`;

export const HorizontalField = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
`;

export const ResponseAutoComplete = styled.div`
    z-index: 2;
    width: 100%;
    max-height: 140px;
    border-radius: 0px 0px 5px 5px;    
    display: flex;
    flex-direction: column;
    border: 3px solid var(--white);
    overflow-x: hidden;

    > span {
        background: var(--secondary);
        padding: 10px;
        margin: 5px 0 5px 0;
        cursor: pointer;
    }
`;

export const SelectAutoComplete = styled.div`
    position: absolute;
    top: 20px;
    display: flex;
    border: 2px solid var(--like);
    border-radius: 5px;
    width: auto;
    padding: 5px 20px 5px 15px;
    color: var(--like);

    >span {
        position: absolute;
        cursor: pointer;
        z-index: 3;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: var(--white);
        border: 2px solid var(--like);
        justify-content: center;
        text-align: center;
        color: var(--black2);
        font-size: 11px;
        font-weight: bold;
    }
`;

export const Select = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 60px;

    > label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-right: 12px;
        color: var(--black2);
    }
`;

export const CheckBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    margin-top: 5px;

    ::-webkit-scrollbar {
        display: none;
    }

    .selected {
        border: 2px solid #e82653;
        background-color: rgba(232, 38, 83, 0.1);
    }
`;

export const Check = styled.div`
    margin-right: 10px;
    border-radius: 8px;
    padding: 0px 7px;
    height: 35px;
    border: 2px solid #ddd;
    font-weight: normal;
    text-align: center;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    color: #000;
`;

export const ViewSubmit = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    height: 40px;
    width: 100%;
    z-index: 2;
`;

export const Alert = styled.div`
    border-radius: 5px;
    border: 1px solid var(--like);
    color: var(--like);
    font-weight: bold;
    font-size: 13px;
    padding: 10px 15px 5px 15px;
    cursor: not-allowed;
    user-select: none;
`;

export const Button = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    height: 35px;
    justify-content: center;
    background: var(--black2);
    border-radius: 5px;
    color: var(--white);
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    border-radius: 100px; 
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const GridView = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    height: 230px;
    overflow: auto;

    .selected {
        background-color: var(--like);
        color: #fff !important;
    }
`;

export const GridItem = styled.div`
    border-radius: 5px;
    border: 2px solid var(--like);
    color: var(--like);
    justify-content: center;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    margin: 3px;
    height: 25px;

    > p {
        font-size: 11px;
        font-weight: bold;
    }


`;