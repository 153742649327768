import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Container
} from './style';


const FieldsSensorBoard: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [lot_number, setLotNumber] = useState("73672.")
    const [note, setNote] = useState("")
    const [cost, setCost] = useState({ formattedValue: "56,80", value: 56.80, floatValue: 56.80 })
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)
            setLotNumber(objSelected.lot_number)
            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")

            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setLotNumber("73672.")
            setNote("")
            setCost({ formattedValue: "56,80", value: 56.80, floatValue: 56.80 })
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (lot_number.trim() === "" || lot_number.trim() == "73672.") {
            setTextAlert("Informe o campo Número do lote")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'sensor-boards' : `sensor-boards/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    lot_number: lot_number,
                    note: note,
                    cost: cost.floatValue,
                    active: active
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_lot_number') {
                setTextAlert("Numero de lote já existente")
                dispatch({ type: "SET_SEARCH", data: lot_number })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Número do lote:</label>
                <input
                    type="text"
                    placeholder="Lote"
                    value={lot_number}
                    onChange={event => setLotNumber(event.target.value)} />
            </Field>


            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
                {/* <input
                    type="text"
                    placeholder="Custo"
                    value={cost}
                    onChange={event => setCost(event.target.value)}
                /> */}
            </Field>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsSensorBoard;