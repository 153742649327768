import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import requestFinishedSheets from '../../../api/service/MaintenanceSheets/FindFinished';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
  Container,
  Info, List, Totals
} from './style';

const MaintenanceHistoric: React.FC = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const search = useSelector(state => state.search)

  const suspicions = useSelector(state => state.suspicions)
  const problems = useSelector(state => state.problems)
  const subproblems = useSelector(state => state.subproblems)
  const solutions = useSelector(state => state.solutions)

  const [modal, setModal] = useState(false)

  const [responseAll, setResponseAll] = useState([])

  const [totalItem, setTotalItem] = useState(0)
  const [totalRotine, setTotalRotine] = useState([])
  const [totalErros, setTotalErros] = useState([])

  const [totalCost, setTotalCost] = useState(0)
  const [totalCostRotine, setTotalCostRotine] = useState(0)
  const [totalCostErros, setTotalCostErros] = useState(0)

  const [maintenances, setMaintenances] = useState([])
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {

    Moment.locale('pt-br');

    if (suspicions.length === 0)
      getSuspicions()
    if (problems.length === 0)
      getProblems()
    if (subproblems.length === 0)
      getSubproblems()
    if (solutions.length === 0)
      getSolutions()

    findDetails()
  }, [])

  const getSuspicions = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'suspicions', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SUSPICIONS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  const getProblems = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'problems_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_PROBLEMS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  const getSubproblems = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'sub_problems_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SUBPROBLEMS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  }

  const getSolutions = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'solutions_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SOLUTIONS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {

    var test = responseAll;

    var totalItem = 0;
    var totalCost = 0;

    var aux = [];
    test.map(item => {

      if (search === "" || item.vehicle.identifier.toLowerCase().includes(search.toLowerCase())) {

        totalItem += 1;
        totalCost = totalCost + parseFloat(item.cost);
        var monthLy = parseInt(item.end_date.split("-")[0] + item.end_date.split("-")[1])
        if (typeof aux[monthLy] === 'undefined') {
          aux[monthLy] = [item]
        }
        else {
          aux[monthLy].push(item)
        }
      }

    })

    aux = aux.reverse();

    setMaintenances(aux)

    setTotalItem(totalItem)
    setTotalRotine(totalRotine)
    setTotalErros(totalErros)

    setTotalCost(totalCost)
    setTotalCostRotine(totalCostRotine)
    setTotalCostErros(totalCostErros)


  }, [search, responseAll])

  async function findDetails() {

    const response = await requestFinishedSheets(user.token);
    console.log(response);

    if (response.status !== 200) {
      let { message } = response.error.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }
    }

    setResponseAll(response.data);

  }


  const openModal = (type, view, obj) => {

    if (obj != null) {
      const clone = Object.assign(obj, {});
      clone.view = view;
      clone.isOld = true;
      clone.maintenance = [obj];
      dispatch({ type: "SET_OBJ_SELECTED", data: clone });
    }

    dispatch({ type: "SET_MODAL", data: { display: true, view: type } })
    dispatch({ type: "SET_DARK", data: true })

  }

  return (
    <Container>

      <Info>

        <Totals><strong>Manutenções</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
        <Totals><span></span></Totals>

        {maintenances.map((item, name) => {

          var totalGeral = 0;

          return (

            <List>
              <strong>{item[0].end_date.split("-")[1] + "/" + item[0].end_date.split("-")[0]} </strong>

              <div>

                <div style={{ background: "#eee" }}>
                  <strong>Veículo</strong>
                  <strong>Data</strong>
                  <info style={{ fontWeight: 'bold' }}>Problemas</info>
                  <info style={{ fontWeight: 'bold' }}>Soluções</info>
                  <label>Custo</label>
                </div>

                {item.map((it, i) => {

                  totalGeral = totalGeral + parseFloat(it.cost);

                  var subproblemsName = "";
                  it.maintenance_subproblems.subproblems.split(",").map(x => {
                    var f = subproblems.find(s => s.id == x);
                    if (f)
                      subproblemsName += `${f.name}, `
                  })


                  var solutionsName = "";
                  it.maintenance_solutions.solutions.split(",").map(x => {
                    var f = solutions.find(s => s.id == x);
                    if (f)
                      solutionsName += `${f.name}, `
                  })

                  return (
                    <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff", cursor: "pointer" }} onDoubleClick={() => openModal("NOVA MANUTENÇÃO", "maintenance", it)}>
                      <span>{it.vehicle.identifier}</span>
                      <span>{Moment(it.end_date).format("DD/MM/YYYY")}</span>
                      <info>{subproblemsName.substring(0, subproblemsName.length - 2)}</info>
                      <info>{solutionsName.substring(0, solutionsName.length - 2)}</info>
                      <label style={{ color: 'red' }}>R$ {it.cost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                    </div>
                  )

                })}

                <div style={{ background: "#ccc" }}>
                  <strong>Total</strong>
                  <span></span>
                  <info></info>
                  <label></label>
                  <label style={{ color: 'red' }}>R$ {parseFloat(totalGeral).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                </div>

              </div>

            </List>
          )
        })}


      </Info>

    </Container>
  )

}

export default MaintenanceHistoric;
