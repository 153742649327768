import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
`;

export const Row = styled.div`
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    margin: 10px 0;
`;

export const Row2 = styled.div`
    width: 2px;
    background-color: #fff;
    border-radius: 2px;
    margin: 0 20px 0 20px;
`;

export const Title = styled.div`
    font-size: 18px;
    color: var(--black2);
    height: 30px;
`;

export const Graph = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

export const ViewGraph = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
`