import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import Moment from 'moment/min/moment-with-locales';
import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_LIMIT_ITENS_REPORTS } from '../../../helper';
import PDFStyle from '../../../styles/PDFStyle';
import Footer from '../PDFLayout/Footer';
import Header from '../PDFLayout/Header';





const PDFExpense: React.FC = ({ onClose, layout, client_id, callback }) => {

    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    var color = "#f1f1f1"
    var pages = 0;
    var totalItens = 0
    var total = 0
    var arrayPages = []
    var cntArray = 0
    var row = 0

    objSelected.map((item, i) => {

        if (!item.canceled) {
            totalItens++;
            row++;
            total = total + parseFloat(item.document_value);

            if (row < DEFAULT_LIMIT_ITENS_REPORTS) {
                if (typeof arrayPages[cntArray] === 'undefined') {
                    arrayPages[cntArray] = [item]
                } else {
                    arrayPages[cntArray].push(item)
                }
            } else {
                arrayPages[cntArray].push(item)
                cntArray++;
                row = 0;
            }
        }
    })

    pages = cntArray + 1;

    return (
        <PDFViewer style={{ width: "100%", height: "100%" }}>
            <Document title="CONTAS A PAGAR" author="THREE PIXELS SISTEMAS" creator="CLOCT" producer="CLOCT">

                {arrayPages.map((itemPage, page) => {

                    return (
                        <Page size="A4" style={PDFStyle.page}>

                            <Header title={"Contas a pagar"} user={user.name} />

                            <div style={PDFStyle.body}>

                                <div style={{ ...PDFStyle.row, borderBottom: "1px solid #444", backgroundColor: "#fff", color: "#222" }}>
                                    <Text style={{ width: "65%", paddingLeft: 5, fontWeight: "bold", ...PDFStyle.title }}>FORNECEDOR</Text>
                                    <Text style={{ width: "20%", textAlign: 'right', fontWeight: "bold", ...PDFStyle.title }}>VENCIMENTO</Text>
                                    <Text style={{ width: "15%", textAlign: "right", fontWeight: "bold", paddingRight: 5, ...PDFStyle.title }}>VALOR</Text>
                                </div>

                                {itemPage.map((item, i) => {

                                    color = (i % 2 === 0) ? "#ddd" : "#fff"

                                    var name = (item.provider.name.length < 55) ? item.provider.name : item.provider.name.substr(0, 55) + ".."

                                    return (
                                        <div style={{ ...PDFStyle.row, backgroundColor: color }}>
                                            <Text style={{ width: "65%", paddingLeft: 5, ...PDFStyle.desc }}>{name}</Text>
                                            <Text style={{ width: "20%", textAlign: 'right', ...PDFStyle.desc }}>{Moment(item.due_date).format("DD/MM/YYYY")}</Text>
                                            <Text style={{ width: "15%", textAlign: "right", ...PDFStyle.desc, paddingRight: 5 }}>R$ {item.document_value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                        </div>
                                    )
                                })}

                                {page === (arrayPages.length - 1) &&
                                    <div style={{ ...PDFStyle.row, borderTop: "1px solid #444", backgroundColor: "#fff", color: "#222" }}>
                                        <Text style={{ width: "65%", paddingLeft: 5, ...PDFStyle.desc }}>TOTAL: {totalItens}</Text>
                                        <Text style={{ width: "20%", textAlign: 'right', ...PDFStyle.desc }}></Text>
                                        <Text style={{ width: "15%", textAlign: "right", ...PDFStyle.desc, paddingRight: 5 }}>R$ {total.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                    </div>
                                }

                            </div>

                            <Footer page={page} pages={pages} />

                        </Page>)

                })}

            </Document>

        </PDFViewer>
    )
}

export default PDFExpense;