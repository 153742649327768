import { faFileCode, faSyncAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValid } from "@fnando/cnpj";
import { porExtenso } from "numero-por-extenso";

import { DEFAULT_URL } from '../../../../helper';

import AutoComplete from '../../../AutoComplete';

import {
    Container, Details, Grid, Info, NBoard, PDFInfo
} from './style';
import {
  Field, CheckBox, Check
} from '../../../../styles/CrudLayout';

import PDFSponsoring from '../../PDFSponsoring';

const DEFAULT_CLIENT = {
  address: "ENDEREÇO",
  identifier: {
    value: "CNPJ",
    type: "CNPJ"
  },
  name: "NOME",
  responsible: {
    cpf: "CPF",
    name: "RESPONSÁVEL",
    type: "CPF"
  }
}

const DEFAULT_RENTER = {
  address: "ENDEREÇO",
  identifier: {
    value: "CNPJ",
    type: "CNPJ"
  },
  name: "NOME",
  responsible: {
    cpf: "CPF",
    name: "RESPONSÁVEL",
    type: "CPF"
  }
}

const PDFsSponsoring: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [arrayItens, setArrayItens] = useState([])

    const [date, setDate] = useState("")
    const [xmlDiv, setXmlDiv] = useState(null)
    const [ftpFiles, setFtpFiles] = useState([])

    const [serialSelected, setSerialSelected] = useState(false)
    const [textPassword, setTextPassword] = useState("")

    const [update, setUpdate] = useState(true)

    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")

    const [contract_id, setContractId] = useState(0)
    const [contract_name, setContractName] = useState("")

    const [renter_id, setRenterId] = useState(0)
    const [renter_name, setRenterName] = useState("")

    const [fidelity, setFidelity] = useState("6 (seis meses)");
    const [payment, setPayment] = useState("");

    const [client, setClient] = useState(DEFAULT_CLIENT);
    const [renter, setRenter] = useState(DEFAULT_RENTER);

    useEffect(() => {
        Moment.locale('pt-br');
        setDate(Moment(new Date()).add(1, 'days').format('YYYY-MM-DD 23:59:59'))
    }, []);

    async function findById(url, id) {
      try {
        let response = await fetch(DEFAULT_URL + url + "/" + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            }
        });

        let responseJson = await response.json();
        if (responseJson.success) return responseJson.data;
      } catch (error) {
          console.error(error);
      }
      return null;
  }

    return (
        <Container>

            <Info>

                <Grid>
                    <div style={{ background: '#eeeeee' }}>
                        <Details>
                            <NBoard style={{ fontWeight: 'bold' }}>Parâmetros</NBoard>
                        </Details>
                    </div>

                    <div style={{ padding: "15px" }}>
                      <AutoComplete
                        label={"Empresa Patrocinada"}
                        finder={"renters"}
                        item={{ id: client_id, name: client_name }}
                        setSelected={async (item) => {
                          if (item && item.id && item.search) {
                            setClientId(item.id);
                            setClientName(item.search);
                            const renter_data = await findById("renters", item.id);

                            const responsibleValue = renter_data.responsible_cpf ? (String(renter_data.responsible_cpf).trim() !== "" ? String(renter_data.responsible_cpf).trim() : renter_data.identifier) : renter_data.identifier;
                            const responsibleName = renter_data.responsible_name ? (String(renter_data.responsible_name).trim() !== "" ? String(renter_data.responsible_name).trim().toUpperCase() : String(`${renter_data.name}`).toUpperCase()) : String(`${renter_data.name}`).toUpperCase();
                            setClient(prev => ({
                              ...prev,
                              address: String(`${renter_data.address}, ${renter_data.street_number}, ${renter_data.district}, ${renter_data.city.name} - ${renter_data.city.state.initials}`).toUpperCase(),
                              identifier: {
                                value: renter_data.identifier,
                                type: isValid(renter_data.identifier) ? "CNPJ" : "CPF"
                              },
                              name: String(`${renter_data.name}`).toUpperCase(),
                              responsible: {
                                value: responsibleValue,
                                name: responsibleName,
                                type: isValid(responsibleValue) ? "CNPJ" : "CPF"
                              }
                            }));
                          }
                          else {
                            setClientId(0);
                            setClientName("");
                            setClient(DEFAULT_CLIENT);
                          }
                        }}
                      />

                    <AutoComplete
                      label={"Contrato"}
                      finder={"contracts"}
                      item={{ id: contract_id, name: contract_name }}
                      setSelected={async (item) => {
                          if (item && item.id && item.search) {
                            setContractId(item.id)
                            setContractName(item.search)
                            const contract_data = await findById("contracts", item.id);
                            const { renter, fidelity, monthly_payment, yearly_payment, bill_type } = contract_data;

                            setFidelity(`${fidelity} (${porExtenso(fidelity)} meses)`);
                            setPayment(`${bill_type === "MENSAL" ? `mensal de R$ ${monthly_payment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} (${porExtenso(monthly_payment, "monetario")})` : `anual de R$ ${yearly_payment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} (${porExtenso(yearly_payment, "monetario")})`}`);

                            const responsibleValue = renter.responsible_cpf ? (String(renter.responsible_cpf).trim() !== "" ? String(renter.responsible_cpf).trim() : renter.identifier) : renter.identifier;
                            const responsibleName = renter.responsible_name ? (String(renter.responsible_name).trim() !== "" ? String(renter.responsible_name).trim().toUpperCase() : String(`${renter.name}`).toUpperCase()) : String(`${renter.name}`).toUpperCase();
                            setRenter(prev => ({
                              ...prev,
                              address: String(`${renter.address}, ${renter.street_number}, ${renter.district}, ${renter.city.name} - ${renter.city.state.initials}`).toUpperCase(),
                              identifier: {
                                value: renter.identifier,
                                type: isValid(renter.identifier) ? "CNPJ" : "CPF"
                              },
                              name: String(`${renter.name}`).toUpperCase(),
                              responsible: {
                                value: responsibleValue,
                                name: responsibleName,
                                type: isValid(responsibleValue) ? "CNPJ" : "CPF"
                              }
                            }));
                          }
                          else {
                            setContractId(0);
                            setContractName("");
                            setRenterId(0);
                            setRenterName("");
                            setRenter(DEFAULT_RENTER);
                          }
                      }}
                    />

                  </div>

                </Grid>

                <PDFInfo>
                  {
                    ((client_id !== 0 && contract_id !== 0) ? (
                      <PDFSponsoring
                        client={client}
                        renter={renter}
                        fidelity={fidelity}
                        payment={payment}
                      />
                    ) : (
                      <p style={{ color: "#ffffff" }}>Selecione a empresa e o contrato.</p>
                    ))
                  }
                </PDFInfo>

            </Info>

        </Container>
    )

}

export default PDFsSponsoring;
