import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  margin: auto;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
`;