import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Alert, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Button, Container, FieldHorizontal, HorizontalField,
    Row,
    Row2
} from './style';

const FieldsCreditCard = ({ client_id, callback }) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const arrTypeExpenses = useSelector(state => state.typeExpenses)
    const arrTypePayments = useSelector(state => state.typePayments)

    const [id, setId] = useState(0)

    const [provider_id, setProviderId] = useState(0)
    const [provider_name, setProviderName] = useState("")

    const [type_expense_id, setTypeExpenseId] = useState(0)
    const [arrayTypeExpenses, setArrayTypeExpenses] = useState([])

    const [financial_payer_id, setFinancialPayerId] = useState(0)
    const [arrayFinancialPayer, setArrayFinancialPayer] = useState([])

    const [payment_type, setPaymentType] = useState("CARTÃO")
    const [document_number, setDocumentNumber] = useState("")
    const [due_date, setDueDate] = useState("")
    const [document_value, setDocumentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
    const [payment_value, setPaymentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
    const [payment_date, setPaymentDate] = useState("")
    const [note, setNote] = useState("")
    const [canceled, setCanceled] = useState(false)
    const [cancel_reason, setCancelReason] = useState("")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")
    const [textErrorButtons, setTextErrorButtons] = useState("")

    const [myRef, setMyRef] = useState()

    function handlerSwitch() {
        setCanceled(!canceled)
    }

    useEffect(() => {

        Moment.locale('pt-br');
        setTextAlert("")

        if (arrTypeExpenses.length === 0) {
            findTypeExpenses()
        }

        if (arrTypePayments.length === 0) {
            findTypePayments()
        }

        if (Object.keys(objSelected).length > 0) {

            if (!objSelected.clone) {
                setId(objSelected.id)
                setDueDate(Moment(objSelected.due_date).format("DD/MM/YYYY"))
                setTextDesc("Atualizar")

                if (objSelected.payment_date) {
                    setPaymentDate(Moment(objSelected.payment_date).format("DD/MM/YYYY"))
                    setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
                }
            }

            if (objSelected.clone) {
                setDueDate(Moment(objSelected.due_date).add(1, 'M').format("DD/MM/YYYY"))
                if (objSelected.payment_date) {
                    //setPaymentDate(Moment(objSelected.payment_date).add(1, 'M').format("DD/MM/YYYY"))
                    //setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
                }
            }

            if (!objSelected.payment_date && objSelected.payoff) {
                setPaymentDate(Moment().format("DD/MM/YYYY"))
                setPaymentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
            }

            setDocumentNumber(objSelected.document_number)
            setProviderId(objSelected.provider.id)
            setProviderName(objSelected.provider.name)
            setTypeExpenseId(objSelected.type_expense_id)
            setFinancialPayerId(objSelected.financial_payer_id)
            setPaymentType(objSelected.payment_type)
            setDocumentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })

            setNote(objSelected.note)
            setCanceled(objSelected.canceled)
            setCancelReason(objSelected.cancel_reason)

        }

        findFinancialPayer()

    }, [objSelected])

    async function findTypeExpenses() {

        try {

            let response = await fetch(DEFAULT_URL + 'type_expenses', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            if (responseJson.success) {
                dispatch({ type: "SET_TYPE_EXPENSES", data: responseJson.data })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function findTypePayments() {

        try {

            let response = await fetch(DEFAULT_URL + 'type_payments', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            if (responseJson.success) {
                dispatch({ type: "SET_TYPE_PAYMENTS", data: responseJson.data })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findFinancialPayer() {

        try {

            let response = await fetch(DEFAULT_URL + 'financial_payer/actives', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            if (responseJson.success) {
                setArrayFinancialPayer(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function saveData() {

        if (provider_id === 0) {
            setTextAlert("Informe um Fornecedor")
            return
        }

        if (financial_payer_id === 0) {
            setTextAlert("Informe um Pagador")
            return
        }

        if (document_number === "") {
            setTextAlert("Informe o campo Número da nota")
            return
        }

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        if (payment_value.formattedValue !== "" && payment_date === "") {
            setTextAlert("Informe o campo Data de pagamento")
            return
        }

        if (payment_date !== "" && payment_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor de pagamento")
            return
        }

        var f_payment_date = ""
        if (payment_date !== "") {
            f_payment_date = Moment(payment_date, 'DD/MM/YYYY')
            if (!f_payment_date.isValid()) {
                setTextAlert("Data de pagamento inválida")
                return
            }
        }

        if (canceled && cancel_reason === "") {
            setTextAlert("Informe o motivo do cancelamento")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'expenses' : `expenses/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    provider_id: provider_id,
                    financial_payer_id: financial_payer_id,
                    type_expense_id: type_expense_id,
                    payment_type: payment_type,
                    document_number: document_number,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: document_value.floatValue,
                    payment_value: payment_value.floatValue,
                    payment_date: (payment_date != "") ? f_payment_date.format("YYYY-MM-DD") : null,
                    note: note,
                    system: false,
                    cancel_reason: cancel_reason,
                    canceled: canceled
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc.toUpperCase()}: </Title>
            <Row />

            {textErrorButtons != "" &&
                <label style={{ color: 'red' }}>
                    {textErrorButtons}
                </label>
            }

            <Row />

            <>
                <AutoComplete
                    label={"Fornecedor"}
                    finder={"providers"}
                    item={{ id: provider_id, name: provider_name }}
                    setSelected={(item) => {
                        setProviderId(item.id)
                        setProviderName(item.search)
                    }}
                />
                <Row />

                <HorizontalField>

                    <FieldHorizontal>
                        <label>Tipo:</label>
                        <CheckBox
                            ref={el => setMyRef(el)}>
                            {arrTypeExpenses.map((e, i) => {

                                if (type_expense_id === e.id) {
                                    if (i > 6) myRef.scrollTo(i * 90, 0)
                                }

                                if (e.active) {
                                    const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                                    return (
                                        <Check
                                            key={e.id}
                                            onClick={() => setTypeExpenseId(e.id)}
                                            className={(type_expense_id === e.id) ? 'selected' : ''}>
                                            {name}
                                        </Check>)
                                }
                            })}
                        </CheckBox>
                    </FieldHorizontal>

                    <Row2 />

                    <FieldHorizontal>
                        <label>Tipo Pagamento:</label>
                        <CheckBox>

                            <Check onClick={() => { setPaymentType("CARTÃO") }} className={'selected'}>
                                CARTÃO
                            </Check>

                        </CheckBox>

                    </FieldHorizontal>

                </HorizontalField>
                <Row />

                <FieldHorizontal>
                    <label>Pagador:</label>
                    <CheckBox
                        ref={el => setMyRef(el)}>
                        {arrayFinancialPayer.map((e, i) => {
                            if (e.active) {
                                return (
                                    <Check
                                        key={e.id}
                                        onClick={() => setFinancialPayerId(e.id)}
                                        className={(financial_payer_id === e.id) ? 'selected' : ''}>
                                        {e.name}
                                    </Check>)
                            }
                        })}
                    </CheckBox>
                </FieldHorizontal>

                <HorizontalField>

                    <FieldHorizontal>
                        <label>Número da nota:</label>

                        <input
                            type="text"
                            placeholder="Número da nota"
                            value={document_number}
                            onChange={event => setDocumentNumber(event.target.value)} />

                    </FieldHorizontal>

                    <Row2 />

                    <FieldHorizontal>
                        <label>Valor do documento:</label>

                        <NumberFormat
                            value={document_value.formattedValue}
                            thousandSeparator={"."}
                            prefix={'R$ '}
                            decimalScale={2}
                            placeholder={"R$"}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowedDecimalSeparators={["."]}
                            onValueChange={(values) => {
                                setDocumentValue(values)
                            }} />

                    </FieldHorizontal>

                    <Row2 />

                    <FieldHorizontal>
                        <label>Data de vencimento:</label>

                        <InputMask
                            mask="99/99/9999"
                            type="text"
                            placeholder="Data de vencimento"
                            value={due_date}
                            onChange={event => setDueDate(event.target.value)} />

                    </FieldHorizontal>

                </HorizontalField>

                <Field>
                    <label>Descrição:</label>

                    <textarea
                        type="text"
                        value={note}
                        onChange={event => setNote(event.target.value)} />

                </Field>

                <Row />

                <Select>
                    <label>Cancelado:</label>
                    <Switch onChange={() => handlerSwitch()} checked={canceled} />
                </Select>

                {canceled &&
                    <Field>
                        <label>Motivo do cancelamento:</label>

                        <textarea
                            type="text"
                            value={cancel_reason}
                            onChange={event => setCancelReason(event.target.value)} />

                    </Field>}

                <ViewSubmit>

                    {textAlert !== "" &&
                        <Alert>
                            {textAlert}
                        </Alert>}
                    {textAlert === "" && <div />}

                    <Button onClick={() => saveData()}>
                        {textButton}
                    </Button>

                </ViewSubmit>
            </>


        </Container>
    )

}

export default FieldsCreditCard;