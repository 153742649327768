import { Document, Image, Page, PDFViewer, Text } from '@react-pdf/renderer';
import Moment from 'moment/min/moment-with-locales';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PDFStyle from '../../../styles/PDFStyle';
import FooterFixed from '../PDFLayout/FooterFixed';
import Header from '../PDFLayout/Header';


const DIV = {
  padding: 0,
  maring: 0,
  verticalAlign: "middle",
  boxSizing: "border-box",
  position: "relative",
  flexWrap: "nowrap",
  flexDirection: "col"
}

const P = {
  ...DIV,
  fontFamily: "Helvetica",
  width: "100%",
  fontSize: "12px",
  lineHight: 1,
  textAlign: "justify",
  fontWeight: "normal",
  padding: "0",
  margin: "0",
  color: "#000000"
}

/**
 * Header
 */
const HEADER = {
  ...DIV,
  width: "100%",
  height: "135px",
  overflow: "hidden",
  flexDirection: "col"
}

const HEADER_DIV = {
  width: "100%",
  overflow: "hidden",
  flexDirection: "row"
}

const HEADER_LOGO = {
  ...DIV,
  flex: 1,
  height: "90px",
  border: "1px solid #000",
  borderRadius: "5px",
  justifyContent: "center",
  alignItems: "center"
}

const HEADER_LOGO_IMG = {
  ...DIV,
  maxWidth: "100px",
  maxHeight: "100px"
}

const HEADER_INFORMATION = {
  ...DIV,
  padding: "5px 2px",
  width: "220px",
  height: "90px",
  border: "1px solid #000",
  borderRadius: "5px",
  flex: "0 0 220px"
}

const HEADER_INFORMATION_TEXT = {
  ...P,
  fontFamily: "Helvetica-Bold",
  fontSize: "8px",
  margin: "0 0 5px 0",
  textAlign: "center",
  width: "100%"
}

const HEADER_INFORMATION_TERM = {
  ...DIV,
  padding: "5px 2px",
  flex: 1,
  height: "90px",
  border: "1px solid #000",
  borderRadius: "5px"
}

const HEADER_INFORMATION_TERM_TEXT = {
  ...P,
  fontFamily: "Helvetica-Bold",
  fontSize: "8px",
  margin: "0 0 5px 0",
  textAlign: "center",
  width: "100%"
}

const HEADER_INFORMATION_TITLE = {
  ...DIV,
  fontFamily: "Helvetica-Bold",
  margin: "15px 0 0 0",
  width: "100%",
  flex: 1,
  textAlign: "center",
  fontSize: "13px",
  textDecoration: "underline",
  textTransform: "uppercase"
}

/**
 * Content
 */
const CONTENT = {
  ...DIV,
  width: "100%"
}

const CONTENT_CLIENT_TEXT = {
  ...P,
  marginBottom: "2px",
  textAlign: "left"
};

const CONTENT_CLIENT_TEXT_TITLE = {
  ...CONTENT_CLIENT_TEXT,
  fontSize: "7px"
}

const CONTENT_CLIENT_TEXT_CONTENT = {
  ...CONTENT_CLIENT_TEXT,
  fontFamily: "Helvetica-Bold",
  fontSize: "8px"
}

const CONTENT_CLIENT_INFORMATION = { //content-client-information
  ...DIV,
  padding: "0",
  marginTop: "-1px",
  flexDirection: "row",
  width: "100%",
  height: "30px"
}

const CONTENT_CLIENT_INFORMATION_ITEM = {
  padding: "5px",
  border: "1px solid #000",
  borderRadius: "5px",
  flexDirection: "col"
}

const CONTENT_CLIENT_INFORMATION_FULL = {
  ...CONTENT_CLIENT_INFORMATION,
  ...CONTENT_CLIENT_INFORMATION_ITEM
}

const CONTENT_CLIENT_INFORMATION_BOX = {
  ...CONTENT_CLIENT_INFORMATION,
  ...CONTENT_CLIENT_INFORMATION_ITEM,
  padding: 0,
  border: 0,
  flexDirection: "row"
}

const CONTENT_CLIENT_INFORMATION_SHARED_FULL = {
  ...CONTENT_CLIENT_INFORMATION,
  ...CONTENT_CLIENT_INFORMATION_ITEM,
  marginTop: "0",
  marginLeft: "-1px",
  flex: 1
}

const CONTENT_CLIENT_INFORMATION_SHARED_WIDTH = (width) => ({
  ...CONTENT_CLIENT_INFORMATION,
  ...CONTENT_CLIENT_INFORMATION_ITEM,
  marginTop: "0",
  marginLeft: "0",
  width,
  flex: `0 0 ${width}`
});

const CONTENT_ITEMS_INFORMATION_BOX = {
  padding: "5px",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "col",
  height: "25px"
}

const CONTENT_ITEMS_INFORMATION_CONTENT = {
  fontFamily: "Helvetica",
  padding: "0",
  margin: "0",
  fontSize: "7px",
  textAlign: "center",
  lineHeight: "1"
}

const CONTENT_ITEMS_INFORMATION_DESCRIPTION = {
  fontFamily: "Helvetica-Bold",
  padding: "0",
  marginBottom: "6px",
  fontSize: "8px",
  textAlign: "justify",
  lineHeight: "1"
}

const MONTH = [
  "janeiro",
  "fevereito",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro"
];

//${this.date.to({ date, format: "DD/MM/YYYY", tz: "America/Sao_Paulo" })}
/* <img src="${`file://${require.resolve(`${this.config.dir.templates}/logo.png`)}`}" alt="Logo Three Pixels" /> */

const HeaderTerm = ({ client, renter, page, pages }) => {
  const  { number } = renter;
  const { name, identifier, ie, email, site } = client;
  const { address, cep, district, city, streetNumber } = client.addressDetailed;
  return  (
    <div style={{...HEADER}}>
      <div style={{...HEADER_DIV}}>
        <div style={{...HEADER_LOGO}}>
          <Image style={{...HEADER_LOGO_IMG}} src={"/logo3pixels.png"} />
        </div>
        <div style={{...HEADER_INFORMATION}}>
          <Text style={{...HEADER_INFORMATION_TEXT}}>{name}</Text>
          <Text style={{...HEADER_INFORMATION_TEXT, textDecoration: "underline" }}>{site}</Text>
          <Text style={{...HEADER_INFORMATION_TEXT}}>{identifier.type}: {identifier.value} - I.E: {ie}</Text>
          <Text style={{...HEADER_INFORMATION_TEXT}}>{address} - {streetNumber} - CEP: {cep}</Text>
          <Text style={{...HEADER_INFORMATION_TEXT}}>{district} - {city.name}-{city.state.initials} - {city.state.name}</Text>
          <Text style={{...HEADER_INFORMATION_TEXT, textDecoration: "underline" }}>{email}</Text>
        </div>
        <div style={{...HEADER_INFORMATION_TERM}}>
          <Text style={{...HEADER_INFORMATION_TERM_TEXT }}>NÚMERO:</Text>
          <Text style={{...HEADER_INFORMATION_TERM_TEXT, fontSize: "22px" }}>{number}</Text>
          <Text style={{...HEADER_INFORMATION_TERM_TEXT}}> </Text>
          <Text style={{...HEADER_INFORMATION_TERM_TEXT}}>Data: {Moment(new Date()).format("DD/MM/YYYY")}</Text>
          <Text style={{...HEADER_INFORMATION_TERM_TEXT}}>Página {page}/{pages}</Text>
        </div>
      </div>
      <div style={{...HEADER_DIV}}>
        <Text style={{...HEADER_INFORMATION_TITLE}}>TERMO DE NEGOCIAÇÃO</Text>
      </div>
    </div>
  )
}

const PDFsSponsoringTerm: React.FC = ({ renter, client, fidelity, payment }) => {

    const user = useSelector(state => state.user)
    const pages = 1;

    const [date, setDate] = useState(new Date());

    const { address, streetNumber, cep, district, city } = renter.addressDetailed;

    return (
        <PDFViewer style={{ width: "100%", height: "100%" }}>
            <Document title='CONTRATO DE PATROCÍNIO PUBLICITÁRIO/"SPONSORING"' author="THREE PIXELS SISTEMAS" creator="CLOCT" producer="CLOCT">
              <Page size="A4" style={{...PDFStyle.page, lineHeight: 1.5 }}>
                <HeaderTerm client={client} renter={renter} page={1} pages={2} />

                <div style={CONTENT}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL}}>
                    <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Nome Fantasia ou Nome:</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.name}</Text>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL}}>
                    <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Razão Social ou Nome:</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.name}</Text>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("298px")}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Endereço:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{address}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("85.4px"), marginLeft: "-1px" }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Número:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{streetNumber}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL}} class="zipCode">
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>CEP:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{cep}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("298px")}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Bairro:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{district}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Cidade:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{city.name}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("64.3px"), marginLeft: "-1px" }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>UF:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{city.state.initials}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("298px")}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>CNPJ ou CPF:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.identifier.value}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Insc. Est. ou RG.:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.ie}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("298px")}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Email:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.email}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Fone:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.phone}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("298px")}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>Condições de pagamento:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.paymentTerms}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL}}>
                      <Text style={{...CONTENT_CLIENT_TEXT_TITLE}}>NF:</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT}}>{renter.email}</Text>
                    </div>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "10px" }}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "25px"}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("59.4px"), ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>QUANTIDADE</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("238.6px"), marginLeft: "-1px", ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>DESCRIÇÃO</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>R$ UNIT.</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>TOTAL</Text>
                    </div>
                  </div>
                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "25px"}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("59.4px"), ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>1</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("238.6px"), marginLeft: "-1px", ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>INSTALAÇÃO</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>{renter.textAllInstall}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>{renter.valueAllInstall}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "25px"}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("59.4px"), ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>1</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_WIDTH("238.6px"), marginLeft: "-1px", ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>PATROCÍNIO PUBLICITÁRIO/“SPONSORING” - {renter.textMonthYearTypeSl}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>{renter.textAllContract}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, ...CONTENT_ITEMS_INFORMATION_BOX }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT}}>{renter.valueAllContract}</Text>
                    </div>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "10px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, textDecoration: "underline"}}>DESCRIÇÃO DA NEGOCIAÇÃO:</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, lineHeight: "1.5"}}>- O vencimento da {renter.textMonthYearType} ocorrerá no próximo (DV = dia do vencimento). É proprocional aos dias de uso, sendo levada em consideração a data de instalação de cada bem.</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0" }}>- O vencimento das demais {renter.textMonthYearTypePl} ocorrerá todo (DV = dia do vencimento) de cada {renter.textMonthYearTypeSs}.</Text>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "5px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0" }}>Prazo de fidelidade do CONTRATO DE PATROCÍNIO PUBLICITÁRIO/“SPONSORING”: {renter.loyaltyPeriod}</Text>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "5px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0" }}>Opção dos aparelhos e acessórios instalados são: {renter.chargeType}</Text>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "5px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0", lineHeight: "1.5" }}>O CLIENTE declara estar ciente de que as operações que correspondem à aceitação deste TERMO serão registradas no banco de dados da THREE PIXELS, juntamente com a data e hora da manifestação do aceite, podendo tal informação ser utilizada como prova, independente de qualquer outro tipo de formalidade. Este instrumento é regido pelo CONTRATO DE PATROCÍNIO PUBLICITÁRIO/“SPONSORING”, enviado para o CLIENTE, o qual declara conhecer e concordar com sua totalidade, fornecendo seu ciente e aceite em todas as cláusulas e condições deste.</Text>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "5px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION }}>MF = MESES FIDELIDADE, MU = MESES DE USO, VBM = VALOR BASE MULTA, QB = QUANTIDADE DE BENS</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0" }}>Multa Contratual: ((MF - MU) * (VBM * QB)) / 3</Text>
                  </div>
                </div>

                <div style={{...CONTENT, marginTop: "5px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto" }}>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0" }}>Concordo com os termos citados acima, assinado no local ou digitalizado por email.</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "30px" }}></Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>___________________________________________________________</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>{renter.name}</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{renter.responsible.name}</Text>
                    <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{renter.responsible.type} nº {renter.responsible.value}</Text>
                  </div>
                </div>

              </Page>

              <Page size="A4" style={{...PDFStyle.page, lineHeight: 1.5 }}>
                <HeaderTerm client={client} renter={renter} page={1} pages={2} />

                <div style={{...CONTENT, marginTop: "10px"}}>
                  <div style={{...CONTENT_CLIENT_INFORMATION_FULL, height: "auto", flexDirection: "col", width: "100%" }}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "auto", flexDirection: "col", width: "100%" }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_CONTENT, margin: "0" }}>Razão Social ou Nome:</Text>
                      <Text style={{...HEADER_INFORMATION_TITLE, textDecoration: "none", margin: "0" }}>{renter.name}</Text>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "auto", flexDirection: "row", width: "100%",  margin: "35px 0 0 0", padding: "0" }}>
                      <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, flexDirection: "row", height: "auto", alignItems: "center", justifyContent: "center", border: "0", padding: "0" }}>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#eeeeee", flex: 1 }}>CT</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#eeeeee", width: "100px", flex: "0 0 100px" }}>BEM (QB)</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#eeeeee", flex: 1 }}>{renter.textMonthYearTypeHh}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#eeeeee", flex: 1 }}>COND. PAG.</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#eeeeee", flex: 1 }}>DIA VENC.</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#eeeeee", flex: 1 }}>VAL. INST.</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#eeeeee", flex: 1 }}>VAL. DESINST.</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#eeeeee", flex: 1 }}>MULTA (VBM)</Text>
                      </div>
                    </div>
                    <div style={{...CONTENT_CLIENT_INFORMATION_BOX, height: "auto", flexDirection: "row", width: "100%", padding: "0" }}>
                      <div style={{...CONTENT_CLIENT_INFORMATION_SHARED_FULL, flexDirection: "row", height: "auto", alignItems: "center", justifyContent: "center", border: "0", padding: "0" }}>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#ffffff", flex: 1 }}>{renter.contractNumber}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#ffffff", width: "100px", flex: "0 0 100px" }}>PATROCÍNIO PUBLICITÁRIO/“SPONSORING”</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#ffffff", flex: 1 }}>{renter.valueAllContract}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#ffffff", flex: 1 }}>{renter.paymentType}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "center", backgroundColor: "#ffffff", flex: 1 }}>{renter.expirationDay}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#ffffff", flex: 1 }}>{renter.valueAllInstall}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#ffffff", flex: 1 }}>{renter.valueAllUninstall}</Text>
                        <Text style={{...CONTENT_CLIENT_TEXT_TITLE, height: "auto", padding: "5px 1px", lineHeight: "1", fontSize: "7px", textAlign: "right", backgroundColor: "#ffffff", flex: 1 }}>{renter.assessment}</Text>
                      </div>
                    </div>
                  </div>

                  <div style={{...CONTENT, marginTop: "35px"}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto", border: 0 }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "30px" }}></Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>___________________________________________________________</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>{client.name}</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{client.responsible.name}</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{client.responsible.type} nº {client.responsible.value}</Text>
                    </div>
                  </div>

                  <div style={{...CONTENT, marginTop: "35px"}}>
                    <div style={{...CONTENT_CLIENT_INFORMATION_FULL, padding: "6px", height: "auto", border: 0 }}>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "30px" }}></Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>___________________________________________________________</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center" }}>{renter.name}</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "3px", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{renter.responsible.name}</Text>
                      <Text style={{...CONTENT_CLIENT_TEXT_CONTENT, ...CONTENT_ITEMS_INFORMATION_DESCRIPTION, marginBottom: "0", width: "100%", textAlign: "center", fontFamily: "Helvetica" }}>{renter.responsible.type} nº {renter.responsible.value}</Text>
                    </div>
                  </div>
                </div>

              </Page>

            </Document>

        </PDFViewer>
    )
}

export default PDFsSponsoringTerm;
