// import { faBug, faExclamation } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useDispatch } from "react-redux";
//IconCountBug, IconCountWarning

import axios from "axios";
import Moment from "moment/min/moment-with-locales";
import React, { useEffect, useRef, useState } from "react";
// import { GoogleMap, OverlayView, withGoogleMap, withScriptjs } from "react-google-maps";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
// import Lottie from "react-lottie";
import js from "../../assets/js/map2.json";
import bouceJSON from "../../assets/lotties/bound_red.json";
import HistoryItem from "./components/HistoryItem";
import HistoryGroupItem from "./components/HistoryGroupItem";
import { ContainerGlobal, ContentHeader, ContentCards, Cards, CardView, Container, Content, LinearDetail, ContentMap } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faCheck, faEraser, faTimes } from "@fortawesome/free-solid-svg-icons";

import  { getItem, sortReason } from "./utils/Funcs";
import { socket as socketMain } from "./utils/Socket";

import "react-loading-skeleton/dist/skeleton.css";
import MarkerCluster from "./components/MarkerCluster";
import Marker from "./components/Marker";

const AUDIO_ALERT = process.env.REACT_APP_APP_PUBLIC_URL + "/assets/alert.mp3";
const AUDIO_END = process.env.REACT_APP_APP_PUBLIC_URL + "/assets/end.mp3";
const AUDIO_END_ERROR = process.env.REACT_APP_APP_PUBLIC_URL + "/assets/end-error.mp3";
const AUDIO_START = process.env.REACT_APP_APP_PUBLIC_URL + "/assets/start.mp3";

const alert = "#eeeeee";
const warning = "#cc0404";
const ok = "#039422";

const synth = window.speechSynthesis;

const AUDIO_QUEUE = [];

const __default_center__ = { lat: -14.2800653, lng: -46.9647527 };
const __default_zoom__ = 4.78;
const __default_min_zoom__ = 3;
const __default_max_zoom__ = 19;
const __default_cluster_max_zoom__ = 18;

function hasNextAudio() {
  return AUDIO_QUEUE.length > 0;
}

async function playNext() {
  if (hasNextAudio()) {
    const audio = AUDIO_QUEUE[0];
    await audio.play();
    AUDIO_QUEUE.splice(0, 1);
    return setTimeout(playNext, 100);
  }
  setTimeout(playNext, 1000);
}

function makeItemsHistoryHeader(currentItems, items) {
  const board = items.board;
  const histories = items.history;
  for (const item of histories) {
    const itemInfo = {
      color: item.color ?? alert,
      count: 1,
      group: item.group ? item.group : item.name,
      name: item.name,
      type: item.type
    };
    if (itemInfo.group) {
      const groupIndex = currentItems.findIndex(result => result.name === itemInfo.group);
      if (groupIndex !== -1) {
        const boardGroupIndex = currentItems[groupIndex].items.findIndex(result => result.board === board);
        if (boardGroupIndex === -1) {
          currentItems[groupIndex].count += itemInfo.count;
          currentItems[groupIndex].items.push({ board, ...itemInfo });
        }
      }
      else {
        currentItems.push({
          color: itemInfo.color ?? alert,
          count: itemInfo.count,
          items: [{ board, ...itemInfo }],
          name: itemInfo.group,
          type: itemInfo.type
        });
      }
    }
    else currentItems.push(itemInfo);
  }
  return currentItems;
}

function makeItemsHistory(items) {
  const results = [];
  for (const item of items) {
    const itemInfo = {
      color: item.color ?? alert,
      count: item.count,
      coordinate: item.coordinate,
      date: item.date,
      group: item.group ? item.group : null,
      message: item.message,
      name: item.name,
      priority: item.priority,
      type: item.type,
      voice: item.voice ? true : false
    };
    if (itemInfo.group) {
      const groupIndex = results.findIndex(result => result.name === itemInfo.group);
      if (groupIndex !== -1) {
        results[groupIndex].count += itemInfo.count;
        results[groupIndex].items.push(itemInfo);
      }
      else {
        results.push({
          color: itemInfo.color ?? alert,
          count: itemInfo.count,
          items: [itemInfo],
          name: itemInfo.group,
          type: item.type
        });
      }
    }
    else results.push(itemInfo);
  }
  return results;
}

function hexToRgb(hex, prop = true) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) { return r + r + g + g + b + b; });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return [255,255,255];
  let r = parseInt(result[1], 16);
  if (prop) r = r > 0 ? (r / 255) : r;
  let g = parseInt(result[2], 16);
  if (prop) g = g > 0 ? (g / 255) : g;
  let b = parseInt(result[3], 16);
  if (prop) b = b > 0 ? (b / 255) : b;
  return [r,g,b];
}

const Panel = () => {
  const socket = socketMain();

  const [bounds, setBounds] = useState(null);
  const [mapBounds, setMapBounds] = useState(false);
  const [audioStatus, setAudioStatus] = useState(false);
  const [voiceStatus, setVoiceStatus] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [currentDataCount, setCurrentDataCount] = useState([]);
  const [currentDataCountSelected, setCurrentDataCountSelected] = useState(null);
  const [currentDataSource, setCurrentDataSource] = useState([]);
  const [currentEndAt, setCurrentEndAt] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentRemove, setCurrentRemove] = useState(null);
  const [currentRemoveConfirm, setCurrentRemoveConfirm] = useState(false);
  const [currentSpeak, setCurrentSpeak] = useState(null);
  const [currentSpeakSuspicion, setCurrentSpeakSuspicion] = useState(null);
  const [currentSpeakAuto, setCurrentSpeakAuto] = useState(false);
  const [currentStartAt, setCurrentStartAt] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentStatusVoice, setCurrentStatusVoice] = useState(false);
  const [currentVoices, setCurrentVoices] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastStatus, setLastStatus] = useState(null);
  const [lastStatusMessage, setLastStatusMessage] = useState(null);
  const [lastStatusVoice, setLastStatusVoice] = useState(false);
  const [ready, setReady] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [options, setOptions] = useState({
    mapTypeId: "DEF",
    minZoom: __default_min_zoom__,
    maxZoom: __default_max_zoom__,
    restriction: null,
    styles: js
  });

  // const dispatch = useDispatch();
  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  // const [clicked, setClicked] = useState("");

  async function handleConfirmRemove(id) {
    if (currentRemoveConfirm) return;
    setCurrentRemoveConfirm(true);
    const url = process.env.REACT_APP_APP_API_JARVIS_URL;
    const response = await axios.post(`${url}/patrimony/health/remove`, { vehicleId: id })
      .then(response => response.data)
      .catch(error => ({
        date: new Date(),
        error,
        success: false
      }));

    if (response.success) {
      setCurrentRemove(null);
      setCurrentRemoveConfirm(false);
      return;
    }
    setCurrentRemoveConfirm(false);
  }

  function getBounceLottie(color) {
    const colorDec = hexToRgb(color, true);
    let bounce = JSON.stringify(bouceJSON);
    bounce = bounce.replace(/0.7569,0.149,0.2431/g, colorDec.join(","));
    return {
      loop: true,
      autoplay: true,
      animationData: JSON.parse(bounce),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  function populateVoiceList() {
    if (typeof synth === "undefined") return;
    const voices = synth.getVoices();
    setCurrentVoices(voices.filter(v => v.lang === "pt-BR"));
  }

  function speakStart(text, currentVoices, callbackStart = undefined, callbackEnd = undefined) {
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = "pt-br";
    speech.rate = 0.8;
    speech.volume = 1.0;
    speech.voice = currentVoices ? currentVoices[0] : null;
    AUDIO_QUEUE.push({
      play: async () => {
        return new Promise((res, rej) => {
          speech.onend = () => {
            if (callbackEnd) callbackEnd();
            setVoiceStatus(false);
            return res();
          };
          synth.speak(speech);
          if (callbackStart) callbackStart();
          setVoiceStatus(true);
        });
      }
    });
    return speech;
  }

  function playSound(src, callbackStart = undefined, callbackEnd = undefined) {
    AUDIO_QUEUE.push({
      play: async () => {
        const audio = new Audio(src);
        audio.volume = 1.0;
        return new Promise((res, rej) => {
          audio.onended = () => {
            if (callbackEnd) callbackEnd();
            setAudioStatus(false);
            return res();
          };
          const playPromise = audio.play();
          if (playPromise) {
            if (callbackStart) callbackStart();
            setAudioStatus(true);
            playPromise
              .then(_ => console.log("playback play"))
              .catch(error => {
                console.log(error);
                console.log("playback prevented");
              });
          }
          else {
            setAudioStatus(false);
            console.log("Error not promise");
          }
        });
      }
    });
  }

  const getPoints = () => {
    if (mapReady && ready && !mapBounds) {
      setMapBounds(true);
      reloadBounds();
    }
    return getPositionsSource().map(position => {
      return {
        type: "Feature",
        properties: { cluster: false, position },
        geometry: {
          type: "Point",
          coordinates: [Number(position.coordinate.lng),Number(position.coordinate.lat)]
        }
      };
    });
  };

  function getPositionsSource() {
    if (currentSpeak) {
      const currentData = currentDataSource.filter(x => x.board === currentSpeak);
      if (currentSpeakSuspicion) return currentData.filter(x => x.type === currentSpeakSuspicion);
      return currentData;
    }
    else if (currentDataCountSelected) return currentDataSource.filter(x => (x.group === currentDataCountSelected || x.name === currentDataCountSelected) || (x.board === currentSpeak));
    return currentDataSource.filter(x => x.itemIndex === 0);
  };

  function getBounds() {
    if (mapReady) {
      const pointsBounds = new mapsRef.current.LatLngBounds();
      if (getPositionsSource().length > 0) {
        getPositionsSource().map(position => (pointsBounds.extend(new mapsRef.current.LatLng(position.coordinate.lat, position.coordinate.lng))));
        return pointsBounds;
      }
      else {
        mapRef.current.setCenter(__default_center__);
        mapRef.current.setZoom(__default_zoom__);
      }
    }
    return false;
  };

  function reloadBounds() {
    const bounds = getBounds();
    if (bounds) mapRef.current.fitBounds(bounds);
  };

  const getMapTypeId = ({ type }) => {
    switch(type) {
    case "SAT":
      return "hybrid";
    default:
      return "roadmap";
    }
  };

  const isBoardSelected = (board) => {
    return currentSpeak === board;
  };

  const isBoardSuspicionSelected = (suspicion) => {
    return suspicion === currentSpeakSuspicion;
  };

  const selectBoard = (board, auto = false) => {
    if (!auto && (currentSpeakAuto && (currentSpeak !== null))) return;
    if (currentSpeak !== board) {
      setCurrentSpeak(board);
      setCurrentSpeakSuspicion(null);
      if (auto) setCurrentSpeakAuto(true);
      return;
    }
    setCurrentSpeak(null);
    setCurrentSpeakSuspicion(null);
    if (auto) setCurrentSpeakAuto(false);
  };

  const selectBoardSuspicion = (suspicion, auto = false) => {
    if (!auto && (currentSpeakAuto && (currentSpeak !== null))) return;
    if (currentSpeakSuspicion !== suspicion) {
      setCurrentSpeakSuspicion(suspicion);
      if (auto) setCurrentSpeakAuto(true);
      return;
    }
    setCurrentSpeakSuspicion(null);
    if (auto) setCurrentSpeakAuto(false);
  };

  useEffect(() => {
    Moment.locale("pt-br");
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    function onHealthReportNotify(value) {
      if (ready && value.id) {
        if (value.id === "HR_START" || value.id === "HR_PROGRESS") {
          if (!currentStatus) {
            setCurrentStatus(true);
            if (value.id === "HR_START") setCurrentStatusVoice(true);
            else setCurrentStatusVoice(false);
            setLastStatus(null);
            setLastStatusMessage(null);
            setLastStatusVoice(false);
          }
          if (!currentEndAt) setCurrentEndAt(getItem(value.data, "period.endAt", null));
          if (!currentStartAt) setCurrentStartAt(getItem(value.data, "period.startAt", null));
          setCurrentProgress(getItem(value.data, "progress.percent", 0));
        }
        if ((value.id === "HR_END_OK") || (value.id === "HR_END_ERROR")) {
          setCurrentStatus(false);
          if (value.id === "HR_END_OK") {
            setLastStatus(true);
            setLastStatusMessage(null);
          }
          if (value.id === "HR_END_ERROR") {
            setLastStatus(false);
            setLastStatusMessage(getItem(value.data, "error", null));
          }
          setLastStatusVoice(true);
        }
        if (value.id === "HR_PATRIMONY_RESULTS") {
          const patrimonyResultHistory = sortReason(
            getItem(value.data, "result.suspicions.accumulated", [])
            .map((item, itemIndex) => ({
              color: item.color ?? alert,
              count: item.count,
              coordinate: item.coords && item.coords.length > 0 ? { lat: Number(item.coords[0]), lng: Number(item.coords[1]) } : null,
              date: Number(item.date) > 0 ? new Date(Number(item.date)) : new Date(),
              group: item.group ? item.group : null,
              message: item.message,
              name: item.name,
              priority: item.priority,
              type: item.type,
              voice: item.voice ? true : false,
              itemIndex
            }))
          );
          const patrimonyResultReason = sortReason(
            getItem(value.data, "result.suspicions.current", [])
            .map((item, itemIndex) => ({
              color: item.color ?? alert,
              count: item.count,
              coordinate: item.coords && item.coords.length > 0 ? { lat: Number(item.coords[0]), lng: Number(item.coords[1]) } : null,
              date: Number(item.date) > 0 ? new Date(Number(item.date)) : new Date(),
              group: item.group ? item.group : null,
              message: item.message,
              name: item.name,
              priority: item.priority,
              type: item.type,
              voice: item.voice ? true : false,
              itemIndex
            }))
          );

          const patrimonyResult = {
            id: getItem(value.data, "patrimony.id", ""),
            board: getItem(value.data, "patrimony.description", ""),
            city: getItem(value.data, "patrimony.city", ""),
            color: warning,
            countCritical: getItem(value.data, "result.suspicions.accumulated", []).filter(x => x.priority === "CRI").length,
            countVerify: getItem(value.data, "result.suspicions.accumulated", []).filter(x => x.priority === "VER").length,
            coordinate: null,
            created: new Date(getItem(value.data, "result.status.created", Date.now())),
            emp: getItem(value.data, "patrimony.renter", ""),
            history: makeItemsHistory(JSON.parse(JSON.stringify(patrimonyResultHistory))),
            historyRaw: sortReason(JSON.parse(JSON.stringify(patrimonyResultHistory))),
            reason: JSON.parse(JSON.stringify(patrimonyResultReason)),
            selectedReason: null,
            type: getItem(value.data, "result.status.current", "VER") === "VER" ? 2 : 1,
            typeChange: getItem(value.data, "result.status.currentChange", false),
            updated: new Date(getItem(value.data, "result.status.updated", Date.now()))
          };
          if (patrimonyResult.reason && patrimonyResult.reason.length > 0) {
            const filterResult = patrimonyResult.reason.find(patrimonyResultItem => patrimonyResultItem.coordinate !== null);
            if (filterResult) {
              patrimonyResult.color = filterResult.color;
              patrimonyResult.coordinate = filterResult.coordinate;
              patrimonyResult.selectedReason = JSON.parse(JSON.stringify(filterResult));
            }
          }
          const reasonCritical = patrimonyResult.reason.filter(reasonItem => reasonItem.voice === true).map(reasonCriticalItem => [reasonCriticalItem.message, reasonCriticalItem.count, reasonCriticalItem.type]);
          if (reasonCritical.length > 0) {
            playSound(AUDIO_ALERT);
            speakStart(`Atenção, novo alerta, veículo, ${patrimonyResult.board}, cliente, ${patrimonyResult.emp}`, currentVoices, function() {  selectBoard(patrimonyResult.board, true); });
            for (const reasonCriticalItem of reasonCritical) {
              const message = reasonCriticalItem[0];
              const count = reasonCriticalItem[1];
              const type = reasonCriticalItem[2];
              let countMessage = count;
              if (count === 1) countMessage = "uma";
              if (count === 2) countMessage = "duas";
              speakStart(message, currentVoices, function() { selectBoardSuspicion(type, true); });
              speakStart(countMessage, currentVoices);
              speakStart(count === 1 ? "vez" : "vezes", currentVoices);
            }
            speakStart(" ", currentVoices, undefined, function() { selectBoard(null, true);  });
          }
          setCurrentData(prevState => {
            const prevStateCopy = JSON.parse(JSON.stringify(prevState));
            const findExistsIndex = prevStateCopy.findIndex(prevStateItem => prevStateItem.board === patrimonyResult.board);
            if (findExistsIndex !== -1) prevStateCopy[findExistsIndex] = patrimonyResult;
            else prevStateCopy.push(patrimonyResult);
            return prevStateCopy.sort((a, b) => {
              const dateA = JSON.parse(JSON.stringify({ date: a.updated })).date;
              const dateB = JSON.parse(JSON.stringify({ date: b.updated })).date;
              if (dateA && dateB) return dateB.localeCompare(dateA);
              return undefined;
            });
          });
        }
      }
    }
    function onHealthReportNotifyInitial(values) {
      if (!ready) {
        for (const value of values) {
          if (value.id === "HR_PATRIMONY_RESULTS") {
            const patrimonyResultHistory = sortReason(
              getItem(value.data, "result.suspicions.accumulated", [])
              .map((item, itemIndex) => ({
                color: item.color ?? alert,
                count: item.count,
                coordinate: item.coords && item.coords.length > 0 ? { lat: Number(item.coords[0]), lng: Number(item.coords[1]) } : null,
                date: Number(item.date) > 0 ? new Date(Number(item.date)) : new Date(),
                group: item.group ? item.group : null,
                message: item.message,
                name: item.name,
                priority: item.priority,
                type: item.type,
                voice: item.voice ? true : false,
                itemIndex
              }))
            );
            const patrimonyResultReason = sortReason(
              getItem(value.data, "result.suspicions.current", [])
              .map((item, itemIndex) => ({
                color: item.color ?? alert,
                count: item.count,
                coordinate: item.coords && item.coords.length > 0 ? { lat: Number(item.coords[0]), lng: Number(item.coords[1]) } : null,
                date: Number(item.date) > 0 ? new Date(Number(item.date)) : new Date(),
                group: item.group ? item.group : null,
                message: item.message,
                name: item.name,
                priority: item.priority,
                type: item.type,
                voice: item.voice ? true : false,
                itemIndex
              }))
            );

            const patrimonyResult = {
              id: getItem(value.data, "patrimony.id", ""),
              board: getItem(value.data, "patrimony.description", ""),
              city: getItem(value.data, "patrimony.city", ""),
              color: warning,
              countCritical: getItem(value.data, "result.suspicions.accumulated", []).filter(x => x.priority === "CRI").length,
              countVerify: getItem(value.data, "result.suspicions.accumulated", []).filter(x => x.priority === "VER").length,
              coordinate: null,
              created: new Date(getItem(value.data, "result.status.created", Date.now())),
              emp: getItem(value.data, "patrimony.renter", ""),
              history: makeItemsHistory(JSON.parse(JSON.stringify(patrimonyResultHistory))),
              historyRaw: sortReason(JSON.parse(JSON.stringify(patrimonyResultHistory))),
              reason: JSON.parse(JSON.stringify(patrimonyResultReason)),
              selectedReason: null,
              type: getItem(value.data, "result.status.current", "VER") === "VER" ? 2 : 1,
              typeChange: getItem(value.data, "result.status.currentChange", false),
              updated: new Date(getItem(value.data, "result.status.updated", Date.now()))
            };
            if (patrimonyResult.reason && patrimonyResult.reason.length > 0) {
              const filterResult = patrimonyResult.reason.find(patrimonyResultItem => patrimonyResultItem.coordinate !== null);
              if (filterResult) {
                patrimonyResult.color = filterResult.color;
                patrimonyResult.coordinate = filterResult.coordinate;
                patrimonyResult.selectedReason = JSON.parse(JSON.stringify(filterResult));
              }
            }

            // const reasonCritical = patrimonyResult.reason.filter(reasonItem => reasonItem.voice === true).map(reasonCriticalItem => [reasonCriticalItem.message, reasonCriticalItem.count, reasonCriticalItem.type]);
            // if (reasonCritical.length > 0) {
            //   playSound(AUDIO_ALERT);
            //   speakStart(`Atenção, novo alerta, veículo, ${patrimonyResult.board}, cliente, ${patrimonyResult.emp}`, currentVoices, function() {  selectBoard(patrimonyResult.board, true); });
            //   for (const reasonCriticalItem of reasonCritical) {
            //     const message = reasonCriticalItem[0];
            //     const count = reasonCriticalItem[1];
            //     const type = reasonCriticalItem[2];
            //     let countMessage = count;
            //     if (count === 1) countMessage = "uma";
            //     if (count === 2) countMessage = "duas";
            //     speakStart(message, currentVoices, function() { selectBoardSuspicion(type, true); });
            //     speakStart(countMessage, currentVoices);
            //     speakStart(count === 1 ? "vez" : "vezes", currentVoices);
            //   }
            //   speakStart(" ", currentVoices, undefined, function() { selectBoard(null, true);  });
            // }

            setCurrentData(prevState => {
              const prevStateCopy = JSON.parse(JSON.stringify(prevState));
              const findExistsIndex = prevStateCopy.findIndex(prevStateItem => prevStateItem.board === patrimonyResult.board);
              if (findExistsIndex !== -1) prevStateCopy[findExistsIndex] = patrimonyResult;
              else prevStateCopy.push(patrimonyResult);
              return prevStateCopy;
            });
          }
        }
        setCurrentData(prevState => {
          const prevStateCopy = JSON.parse(JSON.stringify(prevState));
          return prevStateCopy.sort((a, b) => {
            const dateA = JSON.parse(JSON.stringify({ date: a.updated })).date;
            const dateB = JSON.parse(JSON.stringify({ date: b.updated })).date;
            if (dateA && dateB) return dateB.localeCompare(dateA);
            return undefined;
          });
        });
        setReady(true);
      }
    }

    populateVoiceList();
    if (typeof synth !== "undefined" && synth.onvoiceschanged !== undefined) synth.onvoiceschanged = populateVoiceList;
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("healthReportNotify", onHealthReportNotify);
    socket.on("healthReportNotifyInitial", onHealthReportNotifyInitial);

    const intervalAudioQueue = setTimeout(playNext, 1000);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("healthReportNotify", onHealthReportNotify);
      socket.off("healthReportNotifyInitial", onHealthReportNotifyInitial);
      clearInterval(intervalAudioQueue);
    };
  }, [ready]); //eslint-disable-line

  useEffect(() => {
    const items = [];
    const historiesRaw = JSON.parse(JSON.stringify(currentData)).map(currentDataItem => ({ board: currentDataItem.board, history: currentDataItem.historyRaw }));
    const reasons = JSON.parse(JSON.stringify(currentData)).map(currentDataItem => currentDataItem.historyRaw.filter(historyRawItem => historyRawItem.coordinate !== null).map(historyRawItem => ({ ...historyRawItem, board: currentDataItem.board }))).flat(1);
    for (const historyRaw of historiesRaw) {
      makeItemsHistoryHeader(items, historyRaw);
    }
    setCurrentDataCount(items.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      if (nameA && nameB) return nameA.localeCompare(nameB);
      return undefined;
    }));
    setCurrentDataSource(reasons);
  }, [currentData]);

  useEffect(() => {
    reloadBounds();
  }, [currentSpeak, currentSpeakSuspicion, currentDataCountSelected]); //eslint-disable-line

  useEffect(() => {
    if (currentStatusVoice) {
      playSound(AUDIO_START);
      setCurrentStatusVoice(false);
    }
  }, [currentStatusVoice]); //eslint-disable-line

  useEffect(() => {
    if (lastStatusVoice) {
      if (lastStatus === true) playSound(AUDIO_END);
      else if (lastStatus === false) playSound(AUDIO_END_ERROR);
      setLastStatusVoice(false);
    }
  }, [lastStatusVoice]); //eslint-disable-line

  const CurrentDataItem = (item) => {
    const itemCOPY = JSON.parse(JSON.stringify(item));
    const currentSpeakItem = currentSpeak === item.board;
    let borderColor = warning;
    if (item.type === 2) borderColor = alert
    return (
      <CardView className={currentSpeakItem ? "current-card-view" : ""}>
        <div className={`${currentSpeakItem ? "current-card-view-selected" : ""} ${(currentSpeakItem && currentSpeakAuto) ? "shake" : ""}`} style={{ borderColor: borderColor }}>
          {
            ((item.type === 2 && !currentRemove) && (
              <div className="current-card-main-toggle remove">
                <button className={`${currentSpeakItem ? "active" : ""}`} type="button" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCurrentRemove(itemCOPY.id);
                }}>
                  <FontAwesomeIcon icon={faEraser} />
                </button>
              </div>
            ))
          }
          {
            (((currentRemove === itemCOPY.id)) && (
              <div className="remove-card">
                {
                  ((!currentRemoveConfirm) && (
                    <div>
                      <button className={`${currentSpeakItem ? "active" : ""}`} type="button" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCurrentRemove(null);
                      }}><FontAwesomeIcon icon={faTimes} /></button>
                      <button className={`${currentSpeakItem ? "active" : ""}`} type="button" onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await handleConfirmRemove(itemCOPY.id);
                      }}><FontAwesomeIcon icon={faCheck} /></button>
                    </div>
                  ))
                }
                <p>{currentRemoveConfirm ? "Aguarde..." : "Deseja verificar esta suspeita?" }</p>
              </div>
            ))
          }
          <div className="current-card-main-toggle">
            <button className={`${currentSpeakItem ? "active" : ""}`} type="button" onClick={() => selectBoard(item.board)}>
              <FontAwesomeIcon icon={faBinoculars} />
            </button>
          </div>
          <div className="time" style={{ backgroundColor: "#222" }}>
            <p>{Moment(new Date(itemCOPY.updated)).fromNow()}</p>
          </div>
          <div className="row">
            <p className="board">{item.board}</p>
          </div>
          <div className="row">
            <p className="emp">{item.emp}</p>
          </div>
          <div className="row">
            <p className="city">{item.city}</p>
          </div>
          <div className="column">
            <p className="info">Data:</p>
            <p className="date">{Moment(new Date(itemCOPY.updated)).format("DD/MM/YYYY HH:mm:ss")}</p>
          </div>
          {/* {
            ((currentSpeakItem) && (
              <div className="column">
                <p className="info">Suspeitas:</p>
                <div className="history">
                  <ul>
                    {
                      item.reason.filter(reasonItem => reasonItem.voice === true).map((hist) => {
                        return <HistoryItem item={item} hist={hist} key={`item-history-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(hist.name)).toString("hex")}`} />
                      })
                    }
                  </ul>
                </div>
              </div>
            ))
          } */}
          {/* {
            ((!currentSpeakItem) && (
              <div className="column">
                <p className="info">Suspeitas:</p>
                <div className="history">
                  <ul>
                    {
                      item.history.map((hist) => {
                        if (typeof hist.items && Array.isArray(hist.items) && hist.items.length > 0) return <HistoryGroupItem item={item} hist={hist} key={`item-history-group-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(hist.name)).toString("hex")}`} />;
                        return <HistoryItem item={item} hist={hist} key={`item-history-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(hist.name)).toString("hex")}`} />
                      })
                    }
                  </ul>
                </div>
              </div>
            ))
          } */}
          <div className="column">
            <p className="info">Suspeitas:</p>
            <div className="history">
              <ul>
                {
                  item.history.map((hist) => {
                    if (typeof hist.items && Array.isArray(hist.items) && hist.items.length > 0) return <HistoryGroupItem
                      isBoardSelected={(...args) => isBoardSelected(...args)}
                      isBoardSuspicionSelected={(...args) => isBoardSuspicionSelected(...args)}
                      selectBoard={(...args) => selectBoard(...args)}
                      selectBoardSuspicion={(...args) => selectBoardSuspicion(...args)}
                      item={item}
                      hist={hist}
                      key={`item-history-group-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(hist.name)).toString("hex")}`}
                    />;
                    return <HistoryItem
                      isBoardSelected={(...args) => isBoardSelected(...args)}
                      isBoardSuspicionSelected={(...args) => isBoardSuspicionSelected(...args)}
                      selectBoard={(...args) => selectBoard(...args)}
                      selectBoardSuspicion={(...args) => selectBoardSuspicion(...args)}
                      item={item}
                      hist={hist}
                      key={`item-history-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(hist.name)).toString("hex")}`}
                    />
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </CardView>
    )
  };

  const points = mapReady ? getPoints() : [];
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: __default_cluster_max_zoom__
    }
  });

  return (
    <Container>
      <ContainerGlobal />
      <Content>
        <ContentHeader>
          <div className="status">
            <div style={{ backgroundColor: isConnected ? ok : warning }} />
            <p>Socket: {isConnected ? "Conectado" : "Desconectado"}</p>
          </div>
          <div className="voicer">
            <div style={{ backgroundColor: (voiceStatus ? ok : alert), cursor: ((audioStatus || voiceStatus) ? "not-allowed" : "pointer") }} onClick={() => {
              if (!audioStatus && !voiceStatus) {
                speakStart("Teste de voz...");
                speakStart("Testando...");
                speakStart("Som...");
                speakStart("So...");
                speakStart("Som...");
                speakStart("Um,dois,três...");
              }
            }} />
            <p>Voicer: {synth.paused ? "Pausado" : (voiceStatus ? "Falando" : "Aguardando")}</p>
          </div>
          <div className="voicer">
            <div style={{ backgroundColor: (audioStatus ? ok : alert), cursor: ((audioStatus || voiceStatus) ? "not-allowed" : "pointer") }} onClick={() => {
              if (!audioStatus && !voiceStatus) {
                playSound(AUDIO_START);
                playSound(AUDIO_ALERT);
                playSound(AUDIO_END);
                playSound(AUDIO_END_ERROR);
              }
            }} />
            <p>Áudio: {(audioStatus ? "Reproduzindo" : "Aguardando")}</p>
          </div>
          <div className="report">
            <div style={{ backgroundColor: warning }} />
            <p>Críticos: {currentData.filter(currentDataItem => currentDataItem.type === 1).length}</p>
          </div>
          <div className="report">
            <div style={{ backgroundColor: alert }} />
            <p>Verificações: {currentData.filter(currentDataItem => currentDataItem.type === 2).length}</p>
          </div>
          <div className="diagnostic">
            <div style={{ backgroundColor: currentStatus ? ok : alert }} />
            <p>
              Diagnóstico: {currentStatus ? `${currentProgress}%` : "Aguardando"} {((currentStatus) && (<>Período: {currentStartAt ? Moment(new Date(currentStartAt)).format("DD/MM/YYYY HH:mm") : null} - {currentEndAt ? Moment(new Date(currentEndAt)).format("HH:mm") : null}</>))}
            </p>
          </div>
          {
            ((lastStatus !== null) && (
              <div className="diagnostic">
                <div style={{ backgroundColor: lastStatus ? ok : warning }} />
                <p>Diagnóstico anterior: {lastStatus ? "Concluído com sucesso" : (lastStatusMessage ? JSON.stringify(lastStatusMessage) : "Concluído com erro")}</p>
              </div>
            ))
          }
        </ContentHeader>
        <ContentHeader style={{ borderTop: "1px solid #ffffff" }}>
          {
            currentDataCount.map((hist) => {
              return (
                <div className={`report report-selected ${currentDataCountSelected === hist.name ? "selected" : ""}`} onClick={() => setCurrentDataCountSelected(prevState => {
                  if (prevState === hist.name) return null;
                  return hist.name;
                })} key={`item-report-header-${Buffer.from(String(hist.type)).toString("hex")}`}>
                  <div style={{ backgroundColor: hist.color }} />
                  <p>{hist.name}: {hist.count}</p>
                </div>
              )
            })
          }
        </ContentHeader>
        <ContentCards>
          <Cards>
            {
              ((!currentDataCountSelected) && (
                <>
                  {currentData.filter(x => x.countCritical > 0).map((item) => <CurrentDataItem {...item} key={`item-${Buffer.from(String(item.board)).toString("hex")}-critical-${Date.now()}`} />)}
                  {currentData.filter(x => x.countCritical === 0 && x.countVerify > 0).map((item) => <CurrentDataItem {...item} key={`item-${Buffer.from(String(item.board)).toString("hex")}-verify-${Date.now()}`} />)}
                </>
              ))
            }
            {
              ((currentDataCountSelected) && (
                <>
                  {currentData.filter(x => x.countCritical > 0 && ((x.history.findIndex(historyItem => historyItem.name === currentDataCountSelected) !== -1) || (x.board === currentSpeak))).map((item) => <CurrentDataItem {...item} key={`item-${Buffer.from(String(item.board)).toString("hex")}-critical-search-${Date.now()}`} />)}
                  {currentData.filter(x => (x.countCritical === 0 && x.countVerify > 0) && ((x.history.findIndex(historyItem => historyItem.name === currentDataCountSelected) !== -1) || (x.board === currentSpeak))).map((item) => <CurrentDataItem {...item} key={`item-${Buffer.from(String(item.board)).toString("hex")}-verify-search-${Date.now()}`} />)}
                </>
              ))
            }
          </Cards>
          <ContentMap>
            <LinearDetail />
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA76NXN5k1GOuor8BmXLAQh12c9kkkg59s",
                libraries: ["places", "geometry", "drawing"]
              }}
              defaultCenter={__default_center__}
              defaultZoom={__default_zoom__}
              yesIWantToUseGoogleMapApiInternals
              options={{
                disableDefaultUI: true,
                mapTypeId: getMapTypeId({ type: options.mapTypeId }),
                minZoom: options.minZoom,
                maxZoom: options.maxZoom,
                restriction: options.restriction,
                styles: options.styles
              }}
              onGoogleApiLoaded={({ map, maps, places }) => {
                mapRef.current = map;
                mapsRef.current = maps;
                setMapReady(true);
              }}
              onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat
                ]);
              }}>
                {
                  clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                      cluster: isCluster,
                      point_count: pointCount
                    } = cluster.properties;
                    if (isCluster) {
                      return (
                        <MarkerCluster
                          key={`position-cluster-${cluster.id}`}
                          lat={latitude}
                          lng={longitude}
                          size={(20 + (pointCount / points.length) * 20)}
                          count={pointCount}
                          onClick={() => {
                            const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), __default_max_zoom__);
                            mapRef.current.setZoom(expansionZoom);
                            mapRef.current.panTo({ lat: latitude, lng: longitude });
                          }}
                        />
                      );
                    }
                    const position = cluster.properties.position;
                    return (
                      <Marker
                        key={`position-${Buffer.from(String(position.board)).toString("hex")}-${Buffer.from(String(position.type)).toString("hex")}`}
                        lat={latitude}
                        lng={longitude}
                        onClick={() => {
                          if (!isBoardSelected(position.board)) selectBoard(position.board);
                          selectBoardSuspicion(position.type);
                        }}
                        position={position}
                        selected={isBoardSelected(position.board) && isBoardSuspicionSelected(position.type)}
                      />
                    );
                  })
                }
            </GoogleMapReact>
          </ContentMap>
        </ContentCards>
      </Content>
    </Container>
  )
}

export default Panel;
