import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
`;

export const Row = styled.div`
    height: 2px;
    background-color: #f1f1f1;
    border-radius: 2px;
    margin: 10px 0;
`;

export const Row2 = styled.div`
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 2px;
    margin: 0 20px 0 20px;
`;

export const HorizontalField = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
`;

export const FieldHorizontal = styled.div`
    display: flex;
    overflow: scroll;
    flex-direction: column;
    min-height: 60px;
    max-height: 140px;
    min-width: 30%;
    margin-bottom: 10px;
    position: relative;

    > label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-left: 2px;
        color: var(--black2);
    }

    > input {
        background: var(--secondary);
        height: 40px;
        border-radius: 5px;
        padding: 0 10px 0 10px;
        font-size: 15px;
        color: var(--black2);
    }   
`;

export const ViewButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Button = styled.div`
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    width: 200px;
    background: var(--details);
    color: var(--white);
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
`;

export const InfoHeader = styled.div`

    width: 100%;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    padding: 0 10px 0 10px;

    > label {
        width: 60%;
        font-weight: bold;
    }

    > span {
        width: 30%;
        font-weight: bold;
        text-align: left;
    }

    > strong {
        width: 15%;
        font-weight: bold;
        text-align: right;
    }

`;

export const InfoPayment = styled.div`
    height: auto;
    background-color: var(--primary);
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    position: relative;
    margin: 5px 0 5px 0;
    display: flex;
    flex-direction: row;
    font-size: 13px;

    > label {
        width: 60%; 
    }

    > span {
        width: 30%;
        text-align: left;
    }

    > strong {
        width: 15%;
        font-weight: bold;
        text-align: right;
    }

`;