import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName, officierPermission, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';


const FieldsNiple = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const clients = useSelector(state => state.clients)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)

    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")
    const [tracker_id, setTrackerId] = useState(0)
    const [tracker_serial, setTrackerSerial] = useState("")
    const [rfid_id, setRfidId] = useState(0)
    const [rfid_serial, setRfidSerial] = useState("")
    const [chassi_id, setChassiId] = useState("")
    const [chassi_serial, setChassiSerial] = useState("")
    const [adjunct_id, setAdjunctId] = useState(0)
    const [adjunct_name, setAdjunctName] = useState("")
    const [battery_bank_id, setBatteryBackId] = useState(0)
    const [battery_bank_name, setBatteryBackName] = useState("")
    const [motor_id, setMotorId] = useState(0)
    const [motor_name, setMotorName] = useState("")
    const [sensor_board_id, setSensorBoardId] = useState(0)
    const [sensor_board_name, setSensorBoardName] = useState("")
    const [ownership_id, setOwnershipId] = useState(0)
    const [ownership_name, setOwnershipName] = useState("")

    const [note, setNote] = useState("")
    const [backdoor_sensor, setBackDoorSensor] = useState(false)
    const [active, setActive] = useState(true)
    const [status, setStatus] = useState("PRONTO")

    const [lastStatus, setLastStatus] = useState("");

    const [mounted_by_id, setMountedById] = useState(0)
    const [mounted_by_name, setMountedByName] = useState("")

    const [checked_by_id, setCheckedById] = useState(0)
    const [checked_by_name, setCheckedByName] = useState("")

    const [noteChanges, setNoteChanges] = useState("")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerBackDoor() {
        setBackDoorSensor(!backdoor_sensor)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)
            setClientId(objSelected.client_id)
            setClientName(getClientName(clients, objSelected.client_id))

            if (objSelected.tracker) {
                setTrackerId(objSelected.tracker.id)
                setTrackerSerial(`${objSelected.tracker.serial} | ${objSelected.tracker.n_board?.serial}`)
            }
            else {
                setTrackerId(0)
                setTrackerSerial("")
            }

            if (objSelected.rfid) {
                setRfidId(objSelected.rfid.id)
                setRfidSerial(objSelected.rfid.serial)
            }
            else {
                setRfidId(0)
                setRfidSerial("")
            }

            if (objSelected.chassi) {
                setChassiId(objSelected.chassi.id)
                setChassiSerial(objSelected.chassi.serial)
            }
            else {
                setChassiId(0)
                setChassiSerial("")
            }

            if (objSelected.adjunct) {
                setAdjunctId(objSelected.adjunct.id)
                setAdjunctName(objSelected.adjunct.name)
            }
            else {
                setAdjunctId(0)
                setAdjunctName("")
            }

            if (objSelected.battery_bank) {
                setBatteryBackId(objSelected.battery_bank.id)
                setBatteryBackName(objSelected.battery_bank.lot_number)
            }
            else {
                setBatteryBackId(0)
                setBatteryBackName("")
            }

            if (objSelected.motor) {
                setMotorId(objSelected.motor.id)
                setMotorName(objSelected.motor.lot_number)
            }
            else {
                setMotorId(0)
                setMotorName("")
            }

            if (objSelected.sensor_board) {
                setSensorBoardId(objSelected.sensor_board.id)
                setSensorBoardName(objSelected.sensor_board.lot_number)
            }
            else {
                setSensorBoardId(0)
                setSensorBoardName("")
            }

            if (objSelected.ownership_id) {
                setOwnershipId(objSelected.ownership.id)
                setOwnershipName(objSelected.ownership.name)
            }
            else {
                setOwnershipId(0)
                setOwnershipName("")
            }

            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")

            setNoteChanges("")
            setBackDoorSensor(objSelected.backdoor_sensor)
            setActive(objSelected.active)
            setStatus(objSelected.status)
            setLastStatus(objSelected.status)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setClientId(0)
            setClientName("")
            setTrackerId(0)
            setRfidId(0)
            setChassiId(0)
            setAdjunctId(0)
            setAdjunctName("")
            setBatteryBackId(0)
            setBatteryBackName("")
            setMotorId(0)
            setMotorName("")
            setSensorBoardId(0)
            setSensorBoardName("")
            setOwnershipId(0)
            setOwnershipName("")
            setNote("")
            setBackDoorSensor(false)
            setActive(true)
            setLastStatus("")
            setNoteChanges("")
            setStatus("PRONTO")
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile))
            return setTextAlert("Usuário sem permissão para cadastros")

        if (status === "PRONTO" && !officierPermission(user.profile)) {
            return setTextAlert("Usuário sem permissão para aferir equipamento")
        }

        if (lastStatus === "EM REPARO" && status === "PRONTO") {
            return setTextAlert("Necessário passar pela etapa de verificação")
        }

        if (client_id === 0)
            return setTextAlert("Informe o campo Cliente")

        if (status === "PRONTO" && lastStatus === "NECESSÁRIO VERIFICAÇÃO") {
            if (mounted_by_id === 0)
                return setTextAlert("Informe o nome do montador");

            if (checked_by_id === 0)
                return setTextAlert("Informe o nome do responsável pela aferição")
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        let progres = true;
        if (status !== lastStatus && id !== 0) {

            if (noteChanges.trim() === "") {
                setTextButton("Enviar")
                return setTextAlert("Informe o motivo para troca de status")
            }


            let response = await fetch(DEFAULT_URL + 'log_niples', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    niple_id: id,
                    mounted_by_id: mounted_by_id === 0 ? null : mounted_by_id,
                    checked_by_id: checked_by_id === 0 ? null : checked_by_id,
                    send_by_id: user.id,
                    status: status,
                    note: noteChanges
                })
            })

            let responseJson = await response.json();
            if (!responseJson.success) {
                progres = false;
            }
        }

        if (!progres) {
            setTextButton("Enviar")
            return setTextAlert("Erro ao cadastrar LOG.")
        }

        const url = (id === 0) ? 'niples' : `niples/${id}/update`
        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    id_serial: (id === 0) ? `NP_${tracker_serial.split("|")[1].trim()}` : `NP_${objSelected.id_serial.split("_")[1].trim()}`,
                    client_id: (client_id === 0) ? null : client_id,
                    user_id: user.id,
                    niple_desc: tracker_serial,
                    tracker_id: (tracker_id === 0) ? null : tracker_id,
                    rfid_id: (rfid_id === 0) ? null : rfid_id,
                    chassi_id: (chassi_id === 0) ? null : chassi_id,
                    adjunct_id: (adjunct_id === 0) ? null : adjunct_id,
                    battery_bank_id: (battery_bank_id === 0) ? null : battery_bank_id,
                    motor_id: (motor_id === 0) ? null : motor_id,
                    sensor_board_id: (sensor_board_id === 0) ? null : sensor_board_id,
                    ownership_id: (ownership_id === 0) ? null : ownership_id,
                    note: note,
                    backdoor_sensor: backdoor_sensor,
                    active: active,
                    status: (id === 0) ? "NECESSÁRIO VERIFICAÇÃO" : status
                })
            });

            let responseJson = await response.json();
            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Cliente"}
                finder={"clients"}
                item={{ id: client_id, name: client_name }}
                setSelected={(item) => {
                    setClientId(item.id)
                    setClientName(item.search)
                }}
            />

            <AutoComplete
                label={"Rastreador"}
                finder={"trackers_n"}
                item={{ id: tracker_id, name: tracker_serial }}
                setSelected={(item) => {
                    setTrackerId(item.id)
                    setTrackerSerial(item.search)
                }}
            />

            <AutoComplete
                label={"TAG NFC"}
                finder={"rfid"}
                item={{ id: rfid_id, name: rfid_serial }}
                setSelected={(item) => {
                    setRfidId(item.id)
                    setRfidSerial(item.search)
                }}
            />

            <AutoComplete
                label={"Banco de baterias"}
                finder={"battery-bank"}
                item={{ id: battery_bank_id, name: battery_bank_name }}
                setSelected={(item) => {
                    setBatteryBackId(item.id)
                    setBatteryBackName(item.search)
                }}
            />

            <AutoComplete
                label={"Motor"}
                finder={"motors"}
                item={{ id: motor_id, name: motor_name }}
                setSelected={(item) => {
                    setMotorId(item.id)
                    setMotorName(item.search)
                }}
            />

            <AutoComplete
                label={"Placa de sensor"}
                finder={"sensor-boards"}
                item={{ id: sensor_board_id, name: sensor_board_name }}
                setSelected={(item) => {
                    setSensorBoardId(item.id)
                    setSensorBoardName(item.search)
                }}
            />

            <AutoComplete
                label={"Complemento"}
                finder={"adjuncts"}
                item={{ id: adjunct_id, name: adjunct_name }}
                setSelected={(item) => {
                    setAdjunctId(item.id)
                    setAdjunctName(item.search)
                }}
            />

            <AutoComplete
                label={"Chassi"}
                finder={"chassis"}
                item={{ id: chassi_id, name: chassi_serial }}
                setSelected={(item) => {
                    setChassiId(item.id)
                    setChassiSerial(item.search)
                }}
            />

            <AutoComplete
                label={"Posse"}
                finder={"users"}
                item={{ id: ownership_id, name: ownership_name }}
                setSelected={(item) => {
                    setOwnershipId(item.id)
                    setOwnershipName(item.search)
                }}
            />

            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Select>
                <label>S. BackDoor:</label>
                <Switch onChange={() => handlerBackDoor()} checked={backdoor_sensor} />
            </Select>

            {id !== 0 && <Field>
                <label>Status:</label>
                <CheckBox>

                    <Check onClick={() => { setStatus("NECESSÁRIO VERIFICAÇÃO") }} style={{ border: (status === "NECESSÁRIO VERIFICAÇÃO") ? "2px solid #e82653" : "" }}>
                        NECESSÁRIO VERIFICAÇÃO
                    </Check>

                    <Check onClick={() => { setStatus("EM REPARO") }} style={{ border: (status === "EM REPARO") ? "2px solid #e82653" : "" }}>
                        EM REPARO
                    </Check>

                    <Check onClick={() => { setStatus("PRONTO") }} style={{ border: (status === "PRONTO") ? "2px solid #e82653" : "" }}>
                        PRONTO
                    </Check>

                </CheckBox>

            </Field>
            }

            {(lastStatus === "NECESSÁRIO VERIFICAÇÃO" && status === "PRONTO") && <>

                <Title>INFORMAÇÕES DE MUDANÇA DE STATUS</Title>
                <br></br>
                <AutoComplete
                    label={"Montado por"}
                    finder={"users"}
                    item={{ id: mounted_by_id, name: mounted_by_name }}
                    setSelected={(item) => {
                        setMountedById(item.id)
                        setMountedByName(item.search)
                    }}
                />

                <AutoComplete
                    label={"Aferido por"}
                    finder={"users"}
                    item={{ id: checked_by_id, name: checked_by_name }}
                    setSelected={(item) => {
                        setCheckedById(item.id)
                        setCheckedByName(item.search)
                    }}
                />

            </>}

            {lastStatus !== status &&
                <Field>
                    <label>Informe o motivo da troca de status:</label>
                    <textarea
                        type="text"
                        placeholder="Adicione uma observação"
                        value={noteChanges}
                        onChange={event => setNoteChanges(event.target.value)}
                    />
                </Field>}

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>

        </Container>
    )

}

export default FieldsNiple;