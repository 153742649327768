import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../../styles/CrudLayout';
import { FieldHorizontal } from "../../../Fields/FieldsExpense/style";
import {
    Container
} from './style';



const auxItens = ["JUNTA", "SELOS", "CABEÇOTE", "ESCOTILHA", "CHASSI", "PINGUELETO"]

const FieldsSuspicions: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)

    const [name, setName] = useState("")
    const [itens, setItens] = useState([])
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)
            setName(objSelected.name)
            if (objSelected.itens)
                setItens(objSelected.itens.split(","))
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setName("")
            setItens([])
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    function handleService(item) {

        const clone = Object.assign([], itens);
        var index = clone.findIndex(s => s == item);
        if (index > -1) {
            clone.splice(index, 1);
        } else {
            clone.push(item);
        }

        setItens(clone);
    }

    async function saveData() {

        if (!registerPermission(user.profile))
            return setTextAlert("Usuário sem permissão para cadastros")

        if (name.trim() === "")
            return setTextAlert("Informe o campo Nome")

        if (itens.length === 0)
            return setTextAlert("Informe o campo Itens")

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'suspicions' : `suspicions/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    name: name,
                    itens: itens.toString(),
                    active: active
                })
            });

            let responseJson = await response.json();
            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Nome:</label>
                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />
            </Field>

            <FieldHorizontal>
                <label>Para solucionar:</label>
                <CheckBox>
                    {auxItens.map((e, i) => {
                        return (
                            <Check
                                key={e}
                                onClick={() => handleService(e)}
                                style={{ border: (itens.find(s => s == e)) ? "2px solid #e82653" : "" }}>
                                {e}
                            </Check>)

                    })}
                </CheckBox>

            </FieldHorizontal>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsSuspicions;