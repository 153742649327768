import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: auto;
    flex-direction: column;
    min-height: 60px;
    max-height: 200px;
    overflow: scroll;
    margin-bottom: 10px;
    position: relative;

    > label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-left: 2px;
        color: var(--black2);
    }

    > input {
        border: 1px solid #ddd;
        background: var(--newGray);
        height: 40px;
        border-radius: 5px;
        padding: 0 10px 0 10px;
        font-size: 16px;
        color: var(--black2);
    }   

`;

export const ResponseAutoComplete = styled.div`
    z-index: 2;
    width: 100%;
    max-height: 190px;
    border-radius: 0px 0px 5px 5px;    
    display: flex;
    flex-direction: column;
    border: 3px solid var(--white);
    overflow-x: hidden;

    > span {
        background: var(--secondary);
        padding: 10px;
        margin: 5px 0 5px 0;
        cursor: pointer;
    }
`;

export const SelectAutoComplete = styled.div`
    position: absolute;
    top: 25px;
    display: flex;
    border: 2px solid var(--like);
    border-radius: 8px;
    width: auto;
    padding: 5px 20px 5px 15px;
    color: var(--like);
    white-space: nowrap;

    > span {
        position: absolute;
        cursor: pointer;
        z-index: 3;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: var(--white);
        /* background-color: rgba(232, 38, 83, 0.1); */
        border: 2px solid var(--like);
        justify-content: center;
        text-align: center;
        color: var(--black2);
        font-size: 11px;
        font-weight: bold;
    }
`;





