import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Button
} from '../../../styles/CrudLayout';
import Modal from '../../Modal';
import {
    Container, Cost, Hash, Head, HtmlStatus, Integers, ItemRow, Name, Status, Table
} from './style';


const StockSubcomponents: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [textButton, setTextButton] = useState("Adicionar")

    const [modal, setModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])


    useEffect(() => {
        findDetails()
    }, [])

    useEffect(() => {
        if (Object.keys(objSelected).length > 0) {
            setTextButton("Editar")
        }
        else {
            setTextButton("Adicionar")
        }

    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {
            if (search === "" || item.name.toUpperCase().includes(search.toUpperCase())) {
                t++;
            }
        })
        setTotal(t);

    }, [search])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'subcomponents', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            console.log(responseJson)

            if (responseJson.success) {
                setTotal(responseJson.data.lenght);
                setArrayItens(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    function addItem() {
        dispatch({ type: "SET_DARK", data: true })
        setModal(true)
    }

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });

    }

    function getStatus(min, max, available, showAvailable) {

        if (available == 0) {
            return (
                <HtmlStatus style={{ backgroundColor: 'red' }}>
                    {showAvailable ? available : "SEM ESTOQUE"}
                </HtmlStatus>
            )
        }

        else if (available < min) {
            return (
                <HtmlStatus style={{ backgroundColor: '#ffbb00' }}>
                    {showAvailable ? available : "NECESSÁRIO REPOSIÇÃO"}
                </HtmlStatus>
            )
        }

        else if (available >= min && available <= max) {
            return (
                <HtmlStatus style={{ backgroundColor: '#2c83d6' }}>
                    {showAvailable ? available : "CONFORTÁVEL"}
                </HtmlStatus>
            )
        }

        else if (available > max) {
            return (
                <HtmlStatus style={{ backgroundColor: '#7A7A7A' }}>
                    {showAvailable ? available : "EM EXCESSO"}
                </HtmlStatus>
            )
        }

    }

    return (
        <Container>

            {modal &&
                <Modal
                    onClose={() => setModal(false)}
                    layout={`ADICIONAR SUBCOMPONENTE`}
                    callback={() => {
                        findDetails()
                    }}
                />}

            <Button onClick={() => addItem()}>
                {textButton}
            </Button>

            <Table>

                <Head>

                    <Hash style={{ fontFamily: 'GothamProBold' }}># {total}</Hash>
                    <Name style={{ fontFamily: 'GothamProBold' }}>Nome</Name>
                    <Integers style={{ fontFamily: 'GothamProBold' }}>Disponível</Integers>
                    <Integers style={{ fontFamily: 'GothamProBold' }}>Entrada</Integers>
                    <Integers style={{ fontFamily: 'GothamProBold' }}>Saída</Integers>
                    <Cost style={{ fontFamily: 'GothamProBold' }}>Custo Médio</Cost>
                    <Cost style={{ fontFamily: 'GothamProBold' }}>Custo Total</Cost>
                    <Integers style={{ fontFamily: 'GothamProBold' }}>Mínimo</Integers>
                    <Integers style={{ fontFamily: 'GothamProBold' }}>Máximo</Integers>
                    <Status style={{ fontFamily: 'GothamProBold' }}>Status</Status>

                </Head>

                {arrayItens.map((item, i) => {

                    if (search === "" || item.name.toUpperCase().includes(search.toUpperCase())) {

                        return (

                            <ItemRow key={i} style={{ background: (i % 2 === 0) ? '#fff' : "#f9f9f9", border: (item === objSelected) ? '1px solid #e82653' : 'none' }} onClick={() => handlerClickRow(item)}>
                                <Hash>{item.id}</Hash>
                                <Name>{item.name}</Name>
                                <Integers>{getStatus(item.min, item.max, item.available, true)}</Integers>
                                <Integers>{item.ingress}</Integers>
                                <Integers>{item.issue}</Integers>
                                <Cost>
                                    <NumberFormat
                                        value={item.cost}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        prefix={"R$ "}
                                        disabled={true}
                                        style={{ textAlign: 'right', width: '100%' }}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                    />
                                </Cost>
                                <Cost>
                                    <NumberFormat
                                        value={(parseFloat(item.cost) * parseInt(item.ingress))}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        prefix={"R$ "}
                                        disabled={true}
                                        style={{ textAlign: 'right', width: '100%' }}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                    />
                                </Cost>
                                <Integers>{item.min}</Integers>
                                <Integers>{item.max}</Integers>
                                <Status>{getStatus(item.min, item.max, item.available, false)}</Status>
                            </ItemRow>

                        )
                    }
                })}


            </Table>


        </Container>
    )

}

export default StockSubcomponents;