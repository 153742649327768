import api from "../..";

export default async function requestPictures(body) {

  const reponse = await api.post(
    "https://apintracker.threepixels.com.br/api/v1/patrimony/find-all",
    body,
    {
      headers: {
        "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlYmM4MjI3NTZhYWUzMDAxMmQ0YThmNSIsImlzTG9naW4iOnRydWUsImlhdCI6MTYzOTM5NzQxNCwiZXhwIjozMTU3MzY3ODM5ODE0fQ.aPzrSBhUKxXr1uCV7HjZSUGyov9jUtI5zT0CXVGleBE`
      }
    }
  )
    .then(res => {

      return {
        data: res.data.data,
        status: res.status,
        error: {}
      };

    })
    .catch(e => {

      return {
        data: false,
        status: 500,
        error: e.response?.data
      };

    });

  return reponse;
}
