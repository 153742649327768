import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%; 
    padding: 0px 0px 15px 0px;
`;

export const Content = styled.div`

    margin: 0 30px 15px 30px;
    padding: 20px 0 20px 0;
    border-radius: 5px;
    display: flex;

    > div {
        margin: 0 0 0 10px;
        font-size: 20px;
        font-weight: bold;
        height: 40px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
    }
    
`;

export const Row = styled.div`
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    margin: 0 20px 0 20px;
`;

export const Totals = styled.div`
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 20px;

    > div {

        background-color: #f1f1f1;
        width: 100%;
        overflow: hidden; 
        border: 1px solid #ddd;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        
        > div {
            
            position: relative;
            border: 2px solid #fff;
            margin: 20px 10px;
            overflow-y: auto;
            overflow-x: hidden;

            > p {
                background-color: #fff;
                height: 40px;
                color: #000;
                padding: 10px 0 0 10px;
                font-weight:bold;
                border-radius: 5px;
            }

            > div {
                background-color: #f9f9f9;
                overflow: hidden;
                color: #000;
                padding: 7px 0 7px 10px;
                font-weight:normal;
                display: flex;
                width: 320px;
                
            }

        }

       
    }



    .table {
        height: calc(100% - 80px);
        overflow-y: auto;

        > div {
            display: flex;
            background-color: #fff;
            height: 35px;
            align-items: center;
            border-radius: 5px;
            margin:10px;
            justify-content: space-between;
            padding: 0 10px;
            font-weight: bold;
        }
    }

    .totals {
        height: 40px;
        background-color: #f9f9f9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-top: 20px;
        font-weight: bold;
    }
`;