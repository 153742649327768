import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../helper';
import Modal from '../Modal';
import {
    CardMaintenance,
    Column,
    Container
} from './style';

const alert = "#ca9501";
const warning = "#ff1212";
const ok = "#039422";

const TimelineMaintenances: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [modal, setModal] = useState(false)

    const [lastModified, setLastModified] = useState("")
    const [buttonText, setButtonText] = useState("Informar conferência")
    const [lastReport, setLastReport] = useState(null)

    const [limit, setLimit] = useState([])
    const [availableToMark, setAvailableToMark] = useState([])
    const [recents, setRecents] = useState([])

    const [stopped, setTopped] = useState([]);
    const [needed, setNeeded] = useState([])
    const [agended, setAgended] = useState([])
    const [observation, setObservation] = useState([])

    useEffect(() => {

        Moment.locale('pt-br');
        findMaintenancesTimeline();
        findLastReportUpdate();

    }, []);

    function openModal(item) {
        dispatch({ type: "SET_OBJ_SELECTED", data: item });
        setModal(true)
        dispatch({ type: "SET_DARK", data: true })
    }

    async function findLastReportUpdate() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_report/last', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            if (responseJson.success) {
                console.log()

                setLastReport({
                    user: responseJson.data[0].user.name,
                    date: responseJson.data[0].updatedAt
                });

            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findMaintenancesTimeline() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenances/timeline', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            var stopped = [];
            var needed = [];
            var agended = [];
            var observation = [];
            var limits = [];
            var availableToMark = [];
            var recents = [];

            if (responseJson.success) {

                if (responseJson.data.length > 0) {
                    setLastModified(responseJson.data[0].updated_at);
                }

                var filterMaintenances = [];
                responseJson.data.map(item => {
                    if (item.status !== "CONCLUIDA") {
                        filterMaintenances.push(item);
                    }
                })

                responseJson.data.map(item => {
                    if (!filterMaintenances.find(a => a.vehicle_id == item.vehicle_id)) {
                        filterMaintenances.push(item);
                    }
                })

                filterMaintenances.map(item => {

                    if (item.status === "AGENDAR") {
                        needed.push(item);
                    }
                    else if (item.status === "PARADO") {
                        stopped.push(item);
                    }
                    else if (item.status === "AGENDADO") {
                        agended.push(item);
                    }
                    else if (item.status === "EM OBSERVAÇÃO") {
                        observation.push(item);
                    }
                    else if (item.status === "CONCLUIDA") {

                        let timeMaintenance = (item.pg_date) ? item.pg_date : item.install_date;
                        if (item.type === "CARRO") {
                            recents.push(item);
                        }
                        else if (Moment(new Date()).subtract(3, 'month').isAfter(Moment(timeMaintenance))) {
                            availableToMark.push(item);
                        }
                        else {
                            if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance))) {
                                limits.push(item);
                            } else {
                                recents.push(item);
                            }
                        }
                    }
                })

                setTopped(stopped.reverse());
                setNeeded(needed.reverse());
                setAgended(agended.reverse());
                setObservation(observation.reverse());
                setAvailableToMark(availableToMark.reverse());
                setRecents(recents);
                setLimit(limits.reverse());

                findAllNiples(limits, availableToMark, recents);
                return;
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function findAllNiples(limit, availableToMark, recents) {

        try {

            let response = await fetch(DEFAULT_URL + 'vehicles/1/find_maintenances', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var limits = limit;
                var availableToMarks = availableToMark;
                var recent = recents;

                responseJson.data.map(item => {
                    let timeMaintenance = item.install_date;
                    if (Moment(new Date()).subtract(3, 'month').isAfter(Moment(timeMaintenance))) {
                        availableToMarks.push(item);
                    }
                    else {
                        if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance))) {
                            limits.push(item);
                        } else {
                            recent.push(item);
                        }
                    }
                })

                setAvailableToMark(availableToMarks);
                setRecents(recent);
                setLimit(limits);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    const renderCardView = (type, item) => {

        let timeMaintenance = item.pg_date;
        var dateInfo = "Última";
        var color = alert;

        if (type === "AGENDAR" || type === "PARADO") {
            dateInfo = "Ocorrência";
            timeMaintenance = item.observation_date;
        }
        else if (type === "AGENDADOS") {
            dateInfo = "Manutenção";
            color = ok;
        }
        else if (type === "EM OBSERVAÇÃO") {
            dateInfo = "Desde";
            timeMaintenance = item.observation_date;
        }
        else if (type === "VERIFICAR") {
            timeMaintenance = (item.pg_date) ? item.pg_date : item.install_date;
        }
        else if (type === "CONFORTÁVEIS" || type === "REALIZADAS") {
            timeMaintenance = (item.pg_date) ? item.pg_date : item.install_date;
            color = ok;
        }

        if (Moment(new Date()).subtract(1, 'month').isAfter(Moment(timeMaintenance)) && (type === "AGENDAR" || type === "EM OBSERVAÇÃO"))
            color = warning;
        if (Moment(new Date()).subtract(5, 'month').isAfter(Moment(timeMaintenance)) && (type === "VERIFICAR" || type === "PARADO"))
            color = warning;
        if (Moment(new Date()).subtract(2, 'month').isAfter(Moment(timeMaintenance)) && type === "CONFORTÁVEIS")
            color = alert;

        let time = Moment(timeMaintenance).fromNow();
        if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase()) || item.name.toUpperCase().includes(search.toUpperCase())) {

            return (
                <CardMaintenance onClick={() => openModal(item)}>
                    <div style={{ backgroundColor: color }}>{time.replace("há", "")}</div>
                    <p style={{ fontSize: 15 }}>{item.identifier}</p>
                    <p>{item.name}</p>
                    <p>{item.city_name}</p>
                    <p>{dateInfo}: {Moment(timeMaintenance).format("DD/MM/YYYY")}</p>
                    <div class="tooltiptext"><p>Inf.:</p><textarea multiple type={"text"} disabled value={item.note} /></div>
                </CardMaintenance>
            )
        }

    }

    async function updateLastReport() {

        if (buttonText == "Informar conferência") {

            setButtonText("Aguarde...")

            try {

                let response = await fetch(DEFAULT_URL + "maintenance_report", {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.token
                    },
                    body: JSON.stringify({
                        user_id: user.id,
                    })
                });

                let responseJson = await response.json();
                if (responseJson.success) {

                    setButtonText("Informar conferência")
                    setLastReport({
                        user: user.name,
                        date: responseJson.data.newItem.updatedAt
                    });

                    return
                }


            } catch (error) {
                console.error(error);
            }
        }

    }

    return (
        <Container>

            <div className='updates'>

                <div>
                    {lastReport != null && <p>Conferido por: {lastReport.user} em {Moment(lastReport.date).format("DD/MM/YYYY HH:mm")}</p>}
                    {lastModified !== "" && <p>Última modificação: {Moment(lastModified).format("DD/MM/YYYY HH:mm")}</p>}
                </div>

                <button onClick={() => updateLastReport()}>
                    <span>{buttonText}</span>
                </button>

            </div>

            {modal &&
                <Modal
                    onClose={() => setModal(false)}
                    layout={"MANUTENÇÃO"}
                    client_id={0}
                    callback={() => {
                        findMaintenancesTimeline();
                    }}
                />}

            <div className='no-wrap'>

                <Column>
                    <p>PARADOS ({stopped.length})</p>
                    <div>
                        {stopped.map(item => renderCardView("PARADO", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>AGENDAR ({needed.length})</p>
                    <div>
                        {needed.map(item => renderCardView("AGENDAR", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>AGENDADOS ({agended.length})</p>
                    <div>
                        {agended.map(item => renderCardView("AGENDADOS", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>EM OBSERVAÇÃO ({observation.length})</p>
                    <div>
                        {observation.map(item => renderCardView("EM OBSERVAÇÃO", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>VERIFICAR EQUIPAMENTOS ({availableToMark.length})</p>
                    <div>
                        {availableToMark.map(item => renderCardView("VERIFICAR", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>CONFORTÁVEIS ({limit.length})</p>
                    <div>
                        {limit.map(item => renderCardView("CONFORTÁVEIS", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column>
                    <p>REALIZADAS RECENTE ({recents.length})</p>
                    <div>
                        {recents.map(item => renderCardView("REALIZADAS", item))}
                    </div>
                    <div style={{ height: 80 }} />
                </Column>

                <Column style={{ backgroundColor: "#fff", borderWidth: 0, width: 20 }}></Column>

            </div>

        </Container>
    )

}

export default TimelineMaintenances;