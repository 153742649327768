import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active, BackDoor, Container,
    Details, NBoard, RFID, Tracker
} from './style';


const alert = "#ca9501";
const warning = "#ff1212";
const ok = "#039422";

const GridNiple = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'niples', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        let tracker_s = "", n_board_s = "", h_board_s = "";
        arrayItens.map((item, i) => {
            if (item.tracker) {
                tracker_s = item.tracker.serial;
                // if (item.tracker.chip)
                //     chip_n = item.tracker.chip.number
                if (item.tracker.n_board) {
                    n_board_s = item.tracker.n_board.serial
                    if (item.tracker.n_board.h_board)
                        h_board_s = item.tracker.n_board?.h_board?.serial
                }
            }

            const rfid_s = (item.rfid) ? item.rfid.serial : '';
            const chassi_s = (item.chassi) ? item.chassi.serial : '';

            if (search === "" || item.id_serial.includes(search) || tracker_s.includes(search) || n_board_s.includes(search) || h_board_s.includes(search) || rfid_s.includes(search) || chassi_s.includes(search)) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    <NBoard style={{ fontWeight: 'bold' }}>ID</NBoard>
                    <NBoard style={{ fontWeight: 'bold' }}>NBoard</NBoard>
                    <Tracker style={{ fontWeight: 'bold' }}>Rastreador</Tracker>
                    {/* <Chip style={{ fontWeight: 'bold' }}>Chip</Chip> */}
                    {/* <HBoard style={{ fontWeight: 'bold' }}>HBoard</HBoard> */}
                    <RFID style={{ fontWeight: 'bold' }}>TAG</RFID>
                    {/* <Tracker style={{ fontWeight: 'bold' }}>Chassi</Tracker> */}
                    <BackDoor style={{ fontWeight: 'bold' }}>B. Sensor</BackDoor>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                let tracker_s = "", n_board_s = "", h_board_s = "";
                if (item.tracker) {
                    tracker_s = item.tracker.serial;
                    // if (item.tracker.chip)
                    //     chip_n = item.tracker.chip.number
                    if (item.tracker.n_board) {
                        n_board_s = item.tracker.n_board.serial
                        if (item.tracker.n_board.h_board)
                            h_board_s = item.tracker.n_board?.h_board?.serial
                    }
                }

                const rfid_s = (item.rfid) ? item.rfid.serial : '';
                const chassi_s = (item.chassi) ? item.chassi.serial : '';

                var sts_color = ok;
                if (item.status === "NECESSÁRIO VERIFICAÇÃO")
                    sts_color = alert;
                else if (item.status === "EM REPARO")
                    sts_color = warning;

                if (search === "" || item.id_serial.includes(search) || tracker_s.includes(search) || n_board_s.includes(search) || h_board_s.includes(search) || rfid_s.includes(search) || chassi_s.includes(search)) {

                    return (
                        <div key={`niple_${item.id}`} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                <NBoard>{item.id_serial}</NBoard>
                                <NBoard>{n_board_s}</NBoard>
                                <Tracker>{tracker_s}</Tracker>
                                {/* <Chip>{chip_n}</Chip> 
                                <HBoard>{h_board_s}</HBoard>*/}
                                <RFID>{rfid_s}</RFID>
                                {/* <Tracker>{chassi_s}</Tracker> */}
                                <BackDoor>{item.backdoor_sensor ? <CircleActive /> : <CircleInactive />}</BackDoor>
                                <Active><CircleActive style={{ backgroundColor: sts_color, width: 25 }} /></Active>
                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridNiple;