import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--primary);
`;

export const Legend = styled.div`
    margin: 0 0 0 35px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 35px;
    font-family: 'GothamProBold';
    letter-spacing: 1px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    
    width: 100%;
    height: calc(100vh - 130px);
    overflow-x: hidden;

    @media(max-width: 1280px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

`;

