import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Container
} from './style';


const FieldsRfid: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [serial, setSerial] = useState("")
    const [lot_number, setLotNumber] = useState("7343.")
    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)
            setSerial(objSelected.serial)
            setLotNumber(objSelected.lot_number)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setSerial("")
            setLotNumber("7343.")
            setCost({ formattedValue: "1,50", value: 1.5, floatValue: 1.5 })
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (serial.trim() === "") {
            setTextAlert("Informe o campo Serial")
            return
        }

        if (serial.length !== 14) {
            setTextAlert("Serial inválido")
            return
        }

        if (lot_number.trim() === "" || lot_number.trim() === "7343.") {
            setTextAlert("Informe o campo Número do lote")
            return
        }

        if (lot_number.length < 6) {
            setTextAlert("Número de lote inválido")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'rfid' : `rfid/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    serial: serial,
                    lot_number: lot_number,
                    cost: cost.floatValue,
                    active: active
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_serial') {
                setTextAlert("Serial já existente")
                dispatch({ type: "SET_SEARCH", data: serial })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Serial:</label>

                <input
                    type="text"
                    placeholder="Serial"
                    maxLength={14}
                    value={serial}
                    onChange={event => setSerial(event.target.value)} />

            </Field>

            <Field>
                <label>Número do lote:</label>
                <input
                    type="text"
                    placeholder="Lote"
                    value={lot_number}
                    onChange={event => setLotNumber(event.target.value)} />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
            </Field>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsRfid;