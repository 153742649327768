import { faCheckCircle, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ehDiaUtil from "@lfreneda/eh-dia-util";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, financialPermission } from '../../../helper';
import {
  Alert, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
  Button, ButtonInter,
  ButtonToggle, Container, FieldHorizontal, HorizontalField, InfoHeader,
  InfoPayment, InfoReturns,
  Row,
  Row2, Ticket, TicketInfo, TicketNumber, ViewButtons
} from './style';



const toFixed = (num, fixed) => {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  let newNum = Number(num.toString().match(re)[0]);
  if (!isNaN(newNum)) return newNum;
  return num;
};

const FieldsRevenues = ({ client_id, callback, monthSelected, yearSelected }) => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const remittanceApi = useSelector(state => state.remittanceApi.remittanceApi)
  const remittanceFile = useSelector(state => state.remittanceFile.remittanceFile)
  const objSelected = useSelector(state => state.objSelected)

  const arrTypeRevenues = useSelector(state => state.typeRevenues)
  const arrTypePayments = useSelector(state => state.typePayments)

  const [showRevenues, setShowRevenues] = useState(true)
  const [showPayments, setShowPayments] = useState(false)
  const [showRemittances, setShowRemittances] = useState(false)

  const [financialInformations, setFinancialInformations] = useState([])
  const [remittances, setRemittances] = useState([])
  const [remittancesSelected, setRemittancesSelected] = useState([])
  const [contracts, setContracts] = useState([])
  const [totalGeral, setTotalGeral] = useState(0)

  const [id, setId] = useState(0)
  const [renter_id, setRenterId] = useState(0)
  const [renter_name, setRenterName] = useState("")

  const [revenue_type, setRevenueType] = useState("VENDA")
  const [payment_type, setPaymentType] = useState("BOLETO")
  const [document_date, setDocumentDate] = useState(Moment().format("DD/MM/YYYY"))
  const [unit_value, setUnitValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [quantity, setQuantity] = useState(1)
  const [document_value, setDocumentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [due_date, setDueDate] = useState("")
  const [due_date_valid, setDueDateValid] = useState(false);
  const [payment_value, setPaymentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [payment_date, setPaymentDate] = useState("")
  const [advance_payment, setAdvancePayment] = useState(false)
  const [advance_payment_type, setAdvancePaymentType] = useState("PER")
  const [advance_payment_value, setAdvancePaymentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [note, setNote] = useState("")
  const [canceled, setCanceled] = useState(false)
  const [cancel_reason, setCancelReason] = useState("")

  const [twice, setTwice] = useState("À VISTA")
  const [twice_quantity, setTwiceQuantity] = useState(2)
  const [twice_fees, setTwiceFees] = useState(0)
  const [array_twices, setArrayTwices] = useState([])

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")
  const [textErrorButtons, setTextErrorButtons] = useState("")

  const [stringdate, setStringDate] = useState("")

  function handlerSwitch() {
    setCanceled(!canceled)
  }

  function handlerAdvancePayemnt() {
    if (advance_payment) {
      setAdvancePaymentType("PER");
      setAdvancePaymentValue({ formattedValue: "", value: 0, floatValue: 0 });
    }
    else {
      if (advance_payment_value.formattedValue === "") {
        setAdvancePaymentValue({ formattedValue: "5", value: 5, floatValue: 5 });
      }
    }
    setAdvancePayment(!advance_payment)
  }

  useEffect(() => {

    Moment.locale('pt-br');
    setTextAlert("")

    setStringDate(`${monthSelected.name} de ${yearSelected.number}`);

    if (arrTypeRevenues.length === 0) {
      findTypeRevenues()
    }

    if (arrTypePayments.length === 0) {
      findTypePayments()
    }

    if (Object.keys(objSelected).length > 0) {

      if (!objSelected.clone) {

        setId(objSelected.id)
        setDueDate(Moment(objSelected.due_date).format("DD/MM/YYYY"))
        setDueDateValid(ehDiaUtil(Moment(objSelected.due_date).format("YYYY-MM-DD")));
        setDocumentDate(Moment(objSelected.document_date).format("DD/MM/YYYY"))
        setTextDesc("Atualizar")

        if (objSelected.payment_date) {
          setPaymentDate(Moment(objSelected.payment_date).format("DD/MM/YYYY"))
          setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
        }
      }

      if (objSelected.clone) {
        setDueDate(Moment(objSelected.due_date).add(1, 'M').format("DD/MM/YYYY"))
        setDueDateValid(ehDiaUtil(Moment(objSelected.due_date).add(1, 'M').format("YYYY-MM-DD")));
        if (objSelected.payment_date) {
          // setPaymentDate(Moment(objSelected.payment_date).add(1, 'M').format("DD/MM/YYYY"))
          // setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
        }
      }

      if (objSelected.renter) {
        setRenterId(objSelected.renter.id)
        setRenterName(objSelected.renter.name)
      }

      if (!objSelected.payment_date && objSelected.payoff) {
        setPaymentDate(Moment().format("DD/MM/YYYY"))
        setPaymentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
      }

      setRevenueType(objSelected.revenue_type)
      setPaymentType(objSelected.payment_type)
      setUnitValue({ formattedValue: objSelected.unit_value, value: objSelected.unit_value, floatValue: objSelected.unit_value })
      setQuantity(objSelected.quantity)
      setDocumentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
      setAdvancePayment(objSelected.advance_payment)
      setAdvancePaymentType(objSelected.advance_payment_type)
      setAdvancePaymentValue({ formattedValue: objSelected.advance_payment_value, value: objSelected.advance_payment_value, floatValue: objSelected.advance_payment_value })
      setNote(objSelected.note)
      setCanceled(objSelected.canceled)
      setCancelReason(objSelected.cancel_reason)

    }

  }, [objSelected]) /* eslint-disable-line */

  useEffect(() => {
    var total = quantity * unit_value.floatValue;
    setDocumentValue({ formattedValue: `R$ ${total}`, value: total, floatValue: total })
  }, [quantity, unit_value])

  useEffect(() => {
    if (twice === "A PRAZO")
      setAdvancePayment(false)
  }, [twice])

  useEffect(() => {
    if (showRevenues) {
      setTextErrorButtons("");
    }
  }, [showRevenues]);

  useEffect(() => {

    //console.log(unit_value.floatValue + "x" + twice_quantity + "x" + (twice_fees / 100))

    var juros = (twice_fees === 0 || twice_fees === "") ? 0 : twice_quantity * unit_value.floatValue * (twice_fees / 100);

    var total = (quantity * unit_value.floatValue) + juros;
    var parcelas = total / twice_quantity;

    var i = 0;
    var aux = [];

    while (i < twice_quantity) {

      var dt = (due_date !== "") ? Moment(due_date, 'DD/MM/YYYY').add(i, 'months').format('DD/MM/YYYY') : ""
      if (dt !== "" && due_date.indexOf("_") === -1 && !ehDiaUtil(Moment(dt, 'DD/MM/YYYY').format("YYYY-MM-DD"))) {
        do {
          dt = Moment(dt, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
          console.log(dt);
        } while (!ehDiaUtil(Moment(dt, 'DD/MM/YYYY').format("YYYY-MM-DD")));
      }

      aux.push({
        parcela: i + 1,
        data: dt,
        valor: { formattedValue: parcelas, value: parcelas, floatValue: parcelas }
      })

      i++;
    }

    setArrayTwices(aux)

  }, [quantity, twice_quantity, unit_value, due_date, twice_fees])

  useEffect(() => {
    if (remittanceApi && !showRevenues) {
      if (remittanceApi.success) {
        setShowPayments(false);
        setShowRemittances(false);
        setTextErrorButtons(
          <>
            <InfoReturns>
              <div>
                <p style={{ width: "55%" }}>Entradas: {remittanceApi.returns.enter.length}</p>
                <p style={{ width: "30%", textAlign: "end" }}>Valor</p>
              </div>
              {remittanceApi.returns.enter.map(item =>
                <div>
                  <p style={{ width: "55%" }}>{item.renter.name.substr(0, 25)}</p>
                  <p style={{ width: "30%", textAlign: "end" }}>R$ {item.unit_value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                </div>
              )}
            </InfoReturns>
            <InfoReturns>
              <div>
                <p style={{ width: "55%" }}>Erros: {remittanceApi.returns.error.length}</p>
                <p style={{ width: "30%", textAlign: "end" }}>Valor</p>
              </div>
              {remittanceApi.returns.error.map(item =>
                <div>
                  <p style={{ width: "55%" }}>{item.renter.name.substr(0, 25)}</p>
                  <p style={{ width: "30%", textAlign: "end" }}>R$ {item.unit_value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                </div>
              )}
            </InfoReturns>
          </>
        );
      }
      else {
        setShowPayments(false);
        setShowRemittances(false);
        setTextErrorButtons(`Erro: ${remittanceApi.error.message} | ${remittanceApi.error.stack}`);
      }
    }
  }, [remittanceApi]) /* eslint-disable-line */

  useEffect(() => {
    if (remittanceFile) {
      if (remittanceFile.success) {
        fetch(`${DEFAULT_URL}revenues/remittances-file-download`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
          body: JSON.stringify({
            file: remittanceFile.file
          })
        })
          .then(e => {
            setShowPayments(false)
            setShowRemittances(false)
            setTextErrorButtons(`Sucesso: Arquivo gerado com sucesso.`)
            e.blob().then(blob => {
              const fileSplit = remittanceFile.file.split("/");
              let blobUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.href = blobUrl;
              anchor.target = "_blank";
              anchor.download = `${fileSplit[1]}.txt`;
              anchor.click();
              URL.revokeObjectURL(blobUrl);
              dispatch({ type: "SET_REMITTANCE_FILE", data: { remittanceFile: false } });
            });
          })
          .catch(e => {
            setShowPayments(false)
            setShowRemittances(false)
            setTextErrorButtons(`Erro: Arquivo não encontrado.`)
          })
      }
      else {
        setShowPayments(false)
        setShowRemittances(false)
        setTextErrorButtons(`Erro: ${remittanceFile.error.message} | ${remittanceFile.error.stack}`)
      }
    }
  }, [remittanceFile]) /* eslint-disable-line */

  async function findTypeRevenues() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_revenues', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_REVENUES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function findTypePayments() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_payments', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_PAYMENTS", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function saveData() {

    if (!financialPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para financeiro")
      return
    }

    if (renter_id === 0) {
      setTextAlert("Informe um campo Cliente")
      return
    }

    if (unit_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor unitário")
      return;
    }

    if (quantity === "" || quantity === 0) {
      setTextAlert("Informe o campo Quantidade")
      return;
    }

    if (document_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor do documento")
      return
    }

    if (due_date === "") {
      setTextAlert("Informe o campo Data de vencimento")
      return
    }

    const f_due_date = Moment(due_date, 'DD/MM/YYYY');
    if (!f_due_date.isValid()) {
      setTextAlert("Data de vencimento inválida")
      return;
    }

    if (!due_date_valid) {
      setTextAlert("Data de vencimento deve ser dia útil")
      return
    }

    if (payment_value.formattedValue !== "" && payment_date === "") {
      setTextAlert("Informe o campo Data de pagamento")
      return
    }

    if (payment_date !== "" && payment_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor de pagamento")
      return
    }

    var f_payment_date = ""
    if (payment_date !== "") {
      f_payment_date = Moment(payment_date, 'DD/MM/YYYY')
      if (!f_payment_date.isValid()) {
        setTextAlert("Data de pagamento inválida")
        return
      }
    }

    if (canceled && cancel_reason === "") {
      setTextAlert("Informe o motivo do cancelamento")
      return
    }

    if (advance_payment && advance_payment_value.formattedValue === "") {
      setTextAlert("Informe o valor do desconto")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'revenues' : `revenues/${id}/update`

    if (twice === "À VISTA") {

      try {

        let response = await fetch(DEFAULT_URL + url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
          body: JSON.stringify({
            client_id: client_id,
            renter_id: renter_id,
            revenue_type: revenue_type,
            payment_type: payment_type,
            document_date: Moment(document_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
            unit_value: toFixed(unit_value.floatValue, 2),
            quantity: parseInt(quantity),
            document_value: toFixed(document_value.floatValue, 2),
            due_date: f_due_date.format("YYYY-MM-DD"),
            payment_date: (payment_date !== "") ? f_payment_date.format("YYYY-MM-DD") : null,
            payment_value: payment_value.floatValue,
            note: note,
            canceled: canceled,
            cancel_reason: cancel_reason,
            system: false,
            advance_payment: advance_payment,
            advance_payment_type: advance_payment_type,
            advance_payment_value: advance_payment_value.floatValue
          })
        });

        let responseJson = await response.json();
        setTextButton("Salvar")

        if (responseJson.success) {
          callback();
          return
        }

        let { message } = responseJson.details;
        if (message === 'error_authorization') {
          dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
          localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
          return
        }

        setTextAlert("Erro ao efetuar operação.")

      } catch (error) {
        console.error(error);
      }
      return
    }

    for (const key in array_twices) {

      let responseValid = await fetch(DEFAULT_URL + 'revenues', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client_id,
          renter_id: renter_id,
          revenue_type: revenue_type,
          payment_type: payment_type,
          document_date: Moment(document_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
          unit_value: toFixed(array_twices[key].valor.floatValue, 2),
          quantity: parseInt(quantity),
          document_value: toFixed(array_twices[key].valor.floatValue, 2),
          due_date: Moment(array_twices[key].data, 'DD/MM/YYYY').format("YYYY-MM-DD"),
          payment_date: (payment_date !== "") ? f_payment_date.format("YYYY-MM-DD") : null,
          payment_value: payment_value.floatValue,
          note: note,
          canceled: canceled,
          cancel_reason: cancel_reason,
          system: false,
          advance_payment: advance_payment,
          advance_payment_type: advance_payment_type,
          advance_payment_value: advance_payment_value.floatValue
        })
      });

      let responseJsonValid = await responseValid.json();
      if (responseJsonValid.success) {
        if (parseInt(key) === (array_twices.length - 1)) {
          callback()
        }
      }

    }
  }

  async function getPayments() {

    if (!financialPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para financeiro")
      return
    }

    var monthNow = monthSelected.number
    var yearNow = yearSelected.number

    var currentMonth = Moment().format("MM")
    var currentYear = Moment().format("YYYY")

    if (currentYear === yearNow) {
      if (parseInt(monthNow) < parseInt(currentMonth)) {
        setTextErrorButtons("Não é possível gerar faturamento de anos anteriores")
      }
    }

    if (parseInt(currentYear) > parseInt(yearNow)) {
      setTextErrorButtons("Não é possível gerar faturamento de meses anteriores")
      return
    }

    setTextErrorButtons("")
    setShowRevenues(false)
    setShowRemittances(false)
    setShowPayments(true)
    setContracts([])

    try {

      // let responseValid = await fetch(DEFAULT_URL + 'revenues/valid-revenues', {
      //     method: 'POST',
      //     headers: {
      //         Accept: 'application/json',
      //         'Content-Type': 'application/json',
      //         'Authorization': 'Bearer ' + user.token
      //     },
      //     body: JSON.stringify({
      //         client_id: client_id,
      //         month: monthNow,
      //         year: yearNow,
      //     })
      // });

      // let responseJsonValid = await responseValid.json();
      // if (responseJsonValid.success) {

      // if (responseJsonValid.data.length > 0) {
      //     setShowPayments(false)
      //     setShowRemittances(false)
      //     setTextErrorButtons(`Faturamento gerado por: ${responseJsonValid.data[0].user.name} em ${Moment(responseJsonValid.data[0].date).format("DD/MM/YYYY")}`)
      //     return;
      // }

      //}

      let response = await fetch(DEFAULT_URL + 'revenues/details', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();

      if (responseJson.success) {

        var contracts = [];
        var totalGeral = 0;

        responseJson.data.map(item => {

          var yearSelected = yearNow;
          let dateInstall = item.installation_date.split("/")
          let lastMonth = parseInt(monthNow) - 1;

          if (parseInt(monthNow) <= parseInt(dateInstall[1]) && (yearNow === dateInstall[2] || parseInt(yearNow) < parseInt(dateInstall[2]))) {
            //console.log('mes de instalacao igual ao mes corrente')
          }
          else {

            if (lastMonth === 0) {
              lastMonth = 12;
              yearSelected = parseInt(yearSelected) - 1;
            }

            item.relative_value = item.contract.monthly_payment;
            let lastDay = new Date(parseInt(yearSelected), parseInt(lastMonth), 0);
            let daysUse = lastDay.getUTCDate();

            if (!item.active) {

              let dateUninstall = item.uninstallation_date.split("/")

              if (parseInt(lastMonth) === parseInt(dateUninstall[1]) && parseInt(yearSelected) === parseInt(dateUninstall[2])) {
                daysUse = parseInt(dateUninstall[0]);
                let value = (daysUse * item.contract.monthly_payment) / lastDay.getUTCDate();
                item.relative_value = value.toFixed(2)
              }
              else {
                item.relative_value = 0;
                daysUse = 0.00;
              }
            }

            else if (parseInt(lastMonth) === parseInt(dateInstall[1]) && parseInt(yearSelected) === parseInt(dateInstall[2])) {
              lastDay = new Date(parseInt(yearSelected), parseInt(lastMonth), 0);
              daysUse = lastDay.getUTCDate() - parseInt(dateInstall[0]);
              let value = (daysUse * item.contract.monthly_payment) / lastDay.getUTCDate();
              item.relative_value = value.toFixed(2)
            }

            item.daysUse = daysUse;

            // if (item.contract.id == 152) {
            //   console.log(dateInstall, monthNow, lastMonth, yearNow, parseInt(dateInstall[2]), parseInt(lastMonth) === parseInt(dateInstall[1]));
            // }

            if (typeof contracts[item.contract.renter.id] === 'undefined') {
              contracts[item.contract.renter.id] = [item]
            }
            else {
              contracts[item.contract.renter.id].push(item)
            }

          }
        })

        var auxContracts = []
        contracts.map(cli => {

          var totalContract = 0;
          var arrDetails = [];
          cli.map(c => {

            //if (c.contract.renter.id === 35) {
            //console.log("Placa: " + c.identifier, " Valor: " + c.relative_value, " Data: " + c.installation_date, "Dias de uso: " + c.daysUse);
            //}

            arrDetails.push({ identifier: c.identifier, relative_value: c.relative_value, daysUse: c.daysUse });

            if (c.contract.bill_type === "MENSAL") {
              totalContract = totalContract + parseFloat(c.relative_value)
            }
            if (c.contract.bill_type === "ANUAL") {
              // console.log(c.contract, c.contract.expiration_month, parseInt(monthNow));
              if (c.contract.expiration_month === parseInt(monthNow)) {
                totalContract = totalContract + parseFloat(c.contract.yearly_payment)
              }
            }

          })

          totalGeral = totalGeral + parseFloat(totalContract)

          var exp_day = (`${cli[0].contract.expiration_day}`.length === 1) ? "0" + cli[0].contract.expiration_day : cli[0].contract.expiration_day
          if (totalContract > 0) {
            auxContracts.push({
              renter_id: cli[0].contract.renter.id,
              name: cli[0].contract.renter.name,
              payment_type: cli[0].contract.payment_type,
              due_date: yearNow + "-" + monthNow + "-" + exp_day,
              length: cli.length,
              total: totalContract,
              discot: cli[0].contract.advance_payment,
              advance_payment: cli[0].contract.advance_payment,
              advance_payment_type: cli[0].contract.advance_payment_type,
              advance_payment_value: cli[0].contract.advance_payment_value,
              uploading: "NOT",
              arrDetails: arrDetails
            })
          }
        })
        setTotalGeral(totalGeral)
        setContracts(auxContracts)
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function generateRevenues() {

    setTextErrorButtons("")

    var auxContracts = contracts;
    setContracts([])
    var newContracts = [];

    let response = await fetch(DEFAULT_URL + 'revenues/log-revenues', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      },
      body: JSON.stringify({
        user_id: user.id,
        client_id: client_id,
        date: Moment(document_date, 'DD/MM/YYYY').format("YYYY-MM-DD")
      })
    });

    let responseJson = await response.json();
    if (!responseJson.success) {
      return;
    }

    for (const key in auxContracts) {

      let responseValid = await fetch(DEFAULT_URL + 'revenues', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client_id,
          renter_id: auxContracts[key].renter_id,
          revenue_type: "MENSALIDADE",
          payment_type: auxContracts[key].payment_type,
          document_date: Moment(document_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
          unit_value: toFixed(parseFloat(auxContracts[key].total.toFixed(2)), 2),
          quantity: 1,
          document_value: toFixed(parseFloat(auxContracts[key].total.toFixed(2)), 2),
          due_date: auxContracts[key].due_date,
          payment_date: null,
          payment_value: 0,
          note: "",
          canceled: false,
          cancel_reason: "",
          system: true,
          advance_payment: auxContracts[key].advance_payment,
          advance_payment_type: auxContracts[key].advance_payment_type,
          advance_payment_value: auxContracts[key].advance_payment_value,
        })
      });

      let responseJsonValid = await responseValid.json();
      if (responseJsonValid.success) {

        var revenue_id = responseJsonValid.data.newItem.id;
        for (const k in auxContracts[key].arrDetails) {

          let responseDetail = await fetch(DEFAULT_URL + 'revenues_details', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + user.token
            },
            body: JSON.stringify({
              revenue_id: revenue_id,
              identifier: auxContracts[key].arrDetails[k].identifier,
              value: auxContracts[key].arrDetails[k].relative_value,
              days_use: auxContracts[key].arrDetails[k].daysUse,
            })
          });

          let responseJsonDetail = await responseDetail.json();
          if (responseJsonDetail.success) {
          }

        }

        auxContracts[key].uploading = "OK"
        newContracts = [...newContracts, auxContracts[key]]
        setContracts(newContracts)

        if (parseInt(key) === (auxContracts.length - 1)) {
          callback()
        }
      }

    }
  }

  const [runGenerateFile, setRunGenerateFile] = useState(false);

  async function generateApi({ bank_name, financial_information_id }) {

    setTextErrorButtons("")
    setShowRevenues(false)
    setShowRemittances(true)
    setShowPayments(false)

    if (!runGenerateFile) {
      setRunGenerateFile(true);

      try {
        let responseValid = await fetch(DEFAULT_URL + 'revenues/remittances-api', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
          body: JSON.stringify({
            financial_information_id,
            revenues: remittancesSelected
          })
        });

        let responseJson = await responseValid.json();
        if (responseJson.success) {
          setShowPayments(false)
          setShowRemittances(false)
          setTextErrorButtons(`Gerando a remessa por API (${bank_name})...`)
        }
        else {
          let { message } = responseJson.details;
          if (message === 'error_authorization') {
            dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
            localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
            return
          }
        }
      } catch (error) {
        console.error(error);
      }


      setRunGenerateFile(false);
    }
  }

  async function generateFile({ bank_name, financial_information_id }) {

    setTextErrorButtons("")
    setShowRevenues(false)
    setShowRemittances(true)
    setShowPayments(false)

    if (!runGenerateFile) {
      setRunGenerateFile(true);

      try {
        let responseValid = await fetch(DEFAULT_URL + 'revenues/remittances-file', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
          body: JSON.stringify({
            financial_information_id,
            revenues: remittancesSelected
          })
        });

        let responseJson = await responseValid.json();
        if (responseJson.success) {
          setShowPayments(false)
          setShowRemittances(false)
          setTextErrorButtons(`Gerando o arquivo de remessa (${bank_name})...`)
        }
        else {
          let { message } = responseJson.details;
          if (message === 'error_authorization') {
            dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
            localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
            return
          }
        }

      } catch (error) {
        console.error(error);
      }


      setRunGenerateFile(false);
    }
  }

  async function getRemittances() {

    setTextErrorButtons("")
    setShowRevenues(false)
    setShowRemittances(true)
    setShowPayments(false)
    setRemittances([])
    setRemittancesSelected([])

    try {

      let response = await fetch(DEFAULT_URL + 'revenues/remittances', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id
        })
      });

      let responseJson = await response.json();
      if (responseJson.success) {

        if (responseJson.data.length === 0) {
          setShowPayments(false)
          setShowRemittances(false)
          setTextErrorButtons(`Todas suas contas a receber já possuem remessas.`)
          return;
        }

        const { revenues, financialInformations } = responseJson.data;
        setRemittances(revenues);
        setRemittancesSelected([]);
        setFinancialInformations(financialInformations);
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  function isAllRemittancesSelected() {
    const all = [...remittances];
    var selecteds = [...remittancesSelected];
    if (all.length === selecteds.length) {
      return true;
    }
    return false;
  }

  function toggleAllRemittances() {
    if (isAllRemittancesSelected()) {
      setRemittancesSelected([]);
    }
    else {
      const all = [...remittances];
      const selecteds = all.map(item => item.id);
      setRemittancesSelected(selecteds);
    }
  }

  function toggleRemittances(id) {
    const selecteds = [...remittancesSelected];
    const index = selecteds.findIndex(x => x === id);
    if (index === -1) {
      selecteds.push(id);
    }
    else {
      selecteds.splice(index, 1);
    }
    setRemittancesSelected(selecteds);
  }

  function isSelectedRemittance(id) {
    const selecteds = [...remittancesSelected];
    const index = selecteds.findIndex(x => x === id);
    if (index === -1) {
      return false;
    }
    return true;
  }

  return (
    <Container>

      <Title>{textDesc.toUpperCase()}: CONTA A RECEBER</Title>
      <Row />

      {(id === 0 && !objSelected.clone) && <>
        <ViewButtons>
          {client_id === 2 && <Button style={{ marginRight: 20 }} onClick={() => getPayments()}>FATURAMENTO MENSAL</Button>}
          <Button onClick={() => getRemittances()}>BUSCAR REMESSAS</Button>
          {
            (showRemittances && (
              <ButtonToggle className={`${isAllRemittancesSelected() ? "active" : ""}`} onClick={() => toggleAllRemittances()}>SELECIONAR TODAS</ButtonToggle>
            ))
          }
        </ViewButtons>
        <Row />
      </>}

      {textErrorButtons !== "" &&
        <label style={{ color: 'red' }}>
          {textErrorButtons}
        </label>
      }

      {showRevenues && <>

        <AutoComplete
          label={"Locatário"}
          finder={"renters"}
          item={{ id: renter_id, name: renter_name }}
          setSelected={(item) => {
            setRenterId(item.id)
            setRenterName(item.search)
          }}
        />
        <Row />

        <HorizontalField>

          <FieldHorizontal>
            <label>Tipo:</label>

            <CheckBox>
              {arrTypeRevenues.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => setRevenueType(e.name)}
                      className={(revenue_type === e.name) ? 'selected' : ''}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>


          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Tipo Pagamento:</label>

            <CheckBox>
              {arrTypePayments.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => setPaymentType(e.name)}
                      className={(payment_type === e.name) ? 'selected' : ''}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>

          </FieldHorizontal>

        </HorizontalField>
        <Row />

        <HorizontalField>

          <FieldHorizontal>
            <label>Data do documento:</label>

            <InputMask
              mask="99/99/9999"
              type="text"
              style={{ background: '#ccc' }}
              disabled={true}
              value={document_date}
              onChange={event => setDocumentDate(event.target.value)} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Valor unitário:</label>

            <NumberFormat
              value={unit_value.formattedValue}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              disabled={(id === 0) ? false : true}
              style={{ background: (id === 0) ? '#f8f8f8' : '#ccc' }}
              allowedDecimalSeparators={["."]}
              onValueChange={(values) => {
                setUnitValue(values)
              }} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Quantidade:</label>

            <input
              type="number"
              min="1"
              value={quantity}
              disabled={(id === 0) ? false : true}
              style={{ background: (id === 0) ? '#f8f8f8' : '#ccc' }}
              onChange={event => setQuantity(event.target.value)} />

          </FieldHorizontal>

        </HorizontalField>

        <HorizontalField>

          <FieldHorizontal>

            <label>Data de vencimento: {due_date_valid ? "" : <small style={{ color: "#f00", fontSize: "10px" }}>Preencha com um dia útil</small>}</label>

            <InputMask
              mask="99/99/9999"
              type="text"
              placeholder="Data de vencimento"
              value={due_date}
              style={{ border: due_date_valid ? '0' : '1px solid #f00' }}
              // disabled={(objSelected.remittance_number === null) ? false : true}
              onChange={event => {
                setDueDate(event.target.value);
                setDueDateValid(event.target.value.indexOf("_") !== -1 ? false : ehDiaUtil(Moment(event.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")));
              }} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>

            <label>Valor do documento:</label>

            <NumberFormat
              value={document_value.floatValue}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              disabled={true}
              style={{ background: '#ccc' }}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
            />

          </FieldHorizontal>

          <Row2 />

        </HorizontalField>

        <Row />

        <HorizontalField>
          <FieldHorizontal>
            <Select>
              <label>Desconto:</label>
              <Switch disabled={(twice === "À VISTA" || objSelected.remittance_number === null) ? false : true} onChange={() => handlerAdvancePayemnt()} checked={advance_payment} />
            </Select>
          </FieldHorizontal>

          {
            (advance_payment && (
              <>
                <Row2 />
                <FieldHorizontal>
                  <label>Tipo de desconto:</label>
                  <CheckBox>
                    <Check onClick={() => setAdvancePaymentType("PER")} className={(advance_payment_type === "PER") ? 'selected' : ''}>PERCENTUAL</Check>
                    <Check onClick={() => setAdvancePaymentType("FIX")} className={(advance_payment_type === "FIX") ? 'selected' : ''}>VALOR FIXO</Check>
                  </CheckBox>
                </FieldHorizontal>
              </>
            ))
          }

          {
            (advance_payment && (
              <>
                <Row2 />
                <FieldHorizontal>
                  <label>Valor do desconto:</label>
                  <NumberFormat
                    value={advance_payment_value.formattedValue}
                    thousandSeparator={"."}
                    prefix={advance_payment_type === "PER" ? "% " : "R$ "}
                    decimalScale={2}
                    placeholder={advance_payment_type === "PER" ? "%" : "R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => setAdvancePaymentValue(values)}
                  />
                </FieldHorizontal>
              </>
            ))
          }
        </HorizontalField>

        <Row />

        {(Object.keys(objSelected).length === 0) &&
          <>
            <HorizontalField>

              <FieldHorizontal>
                <label>Condição:</label>
                <CheckBox>

                  <Check
                    onClick={() => { setTwice("À VISTA") }}
                    className={(twice === "À VISTA") ? 'selected' : ''}>
                    À VISTA
                  </Check>

                  <Check
                    onClick={() => { setTwice("A PRAZO") }}
                    className={(twice === "A PRAZO") ? 'selected' : ''}>
                    A PRAZO
                  </Check>

                </CheckBox>

              </FieldHorizontal>

              {(twice === "A PRAZO") &&
                <>
                  <Row2 />

                  <CheckBox>

                    <div style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', fontSize: 16, fontWeight: 'bold', left: 15, top: 0 }}>x</span>
                      <input
                        type="number"
                        min="2"
                        value={twice_quantity}
                        style={{ background: '#f8f8f8', marginTop: 25, borderRadius: 5, width: 40, height: 40, textAlign: 'center' }}
                        onChange={event => setTwiceQuantity(event.target.value)} />
                    </div>

                    <div style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', fontSize: 15, fontWeight: 'bold', left: 35, top: 1 }}>%</span>
                      <input
                        type="number"
                        step="0.01"
                        min="0.00"
                        value={twice_fees}
                        style={{ background: '#f8f8f8', marginTop: 25, marginLeft: 15, borderRadius: 5, width: 60, height: 40, textAlign: 'center' }}
                        onChange={event => setTwiceFees(event.target.value)} />
                    </div>

                    {array_twices.map(i => {

                      return (
                        <Ticket>
                          <TicketNumber>
                            <strong> {i.parcela}ª</strong>
                          </TicketNumber>

                          <TicketInfo>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              style={{
                                borderWidth: 0,
                                marginTop: 10,
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                outline: "none"
                              }}
                              placeholder="__/__/____"
                              value={i.data}
                              onChange={event => {
                                const aux = [];
                                array_twices.map(tw => {
                                  if (tw.parcela == i.parcela)
                                    tw.data = event.target.value;
                                  aux.push(tw);
                                });
                                setArrayTwices(aux);
                              }} />
                            <span>
                              <NumberFormat
                                value={i.valor.formattedValue}
                                thousandSeparator={"."}
                                prefix={'R$ '}
                                decimalScale={2}
                                placeholder={"R$"}
                                decimalSeparator={","}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                style={{ width: '100%', fontSize: 12, textAlign: 'center', background: '#f9f9f9', fontWeight: 'bold' }}
                                allowedDecimalSeparators={["."]} />
                            </span>

                          </TicketInfo>
                        </Ticket>
                      )

                    })}
                  </CheckBox>
                </>
              }

            </HorizontalField>
            <Row />
          </>
        }


        <Field>

          <label>Descrição:</label>

          <textarea
            type="text"
            value={note}
            onChange={event => setNote(event.target.value)} />

        </Field>

        <Row />

        <HorizontalField>

          <FieldHorizontal>

            <label>Data de pagamento:</label>

            <InputMask
              mask="99/99/9999"
              type="text"
              placeholder="Data de pagamento"
              value={payment_date}
              onChange={event => setPaymentDate(event.target.value)} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>

            <label>Valor pago:</label>

            <NumberFormat
              value={payment_value.formattedValue}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
              onValueChange={(values) => {
                setPaymentValue(values)
              }} />

          </FieldHorizontal>

        </HorizontalField>

        <Row />

        <Select>
          <label>Cancelado:</label>
          <Switch onChange={() => handlerSwitch()} checked={canceled} />
        </Select>

        {canceled &&
          <Field>

            <label>Motivo do cancelamento:</label>

            <textarea
              type="text"
              value={cancel_reason}
              onChange={event => setCancelReason(event.target.value)} />

          </Field>}

        <ViewSubmit>

          {textAlert !== "" &&
            <Alert>
              {textAlert}
            </Alert>}
          {textAlert === "" && <div />}

          <Button onClick={() => saveData()}>
            {textButton.toUpperCase()}
          </Button>

        </ViewSubmit>

      </>}

      {
        showPayments && <>

          <div style={{ backgroundColor: '#222', padding: 10, color: '#fff' }}>{stringdate}</div>

          <InfoHeader>

            <label>LOCATÁRIO</label>
            <span>ATIVOS</span>
            <span>DESCONTO</span>
            <strong>VALOR</strong>
            <strong>BANCO</strong>

          </InfoHeader>

          {contracts.map((cli, key) => {

            return (<InfoPayment>

              <label>{cli.name}</label>
              <span>{cli.length}</span>

              {
                (cli.advance_payment && cli.advance_payment_type === "PER" && (
                  <span>{cli.advance_payment_value}%</span>
                ))
              }

              {
                (cli.advance_payment && cli.advance_payment_type === "FIX" && (
                  <span>R${cli.advance_payment_value}</span>
                ))
              }

              {
                (!cli.advance_payment && (
                  <span>--</span>
                ))
              }

              <NumberFormat
                value={cli.total}
                thousandSeparator={"."}
                prefix={'R$ '}
                decimalScale={2}
                suffix={(cli.discot) ? "*" : ""}
                style={{ textAlign: 'right', width: '10%' }}
                disabled={true}
                placeholder={"R$"}
                decimalSeparator={","}
                fixedDecimalScale={true}
                allowNegative={false}
                allowedDecimalSeparators={["."]}
              />

              <span>
                {cli.uploading === "NOT" &&
                  <FontAwesomeIcon
                    icon={(cli.payment_type === "BOLETO") ? faToggleOn : faToggleOff}
                    style={{ color: (cli.payment_type === "BOLETO") ? 'green' : 'black', width: 25, height: 20, position: 'absolute', right: 25 }}
                    onClick={() => {

                    }}
                  />}

                {cli.uploading === "OK" &&
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: 'green', width: 25, height: 20, position: 'absolute', right: 25 }}
                    onClick={() => {

                    }}
                  />}

              </span>

            </InfoPayment>)

          })}

          <InfoPayment>

            <label style={{ fontWeight: 'bold' }}>Faturamento</label>
            <span></span>
            <span></span>
            <NumberFormat
              value={totalGeral}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              style={{ textAlign: 'right', width: '20%', fontWeight: 'bold' }}
              disabled={true}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
            />
            <span>

            </span>
          </InfoPayment>

          <Button onClick={() => generateRevenues()}>GERAR RECEITAS</Button>

        </>
      }

      {
        showRemittances && <>

          <InfoHeader>
            <label>PAGADOR</label>
            <span>VENCIMENTO</span>
            <span>DESCONTO</span>
            <strong>VALOR</strong>
          </InfoHeader>

          {remittances.map(cli => {
            return (<InfoPayment>
              <label>
                <CheckBox>
                  <Check onClick={() => toggleRemittances(cli.id)} style={{ border: isSelectedRemittance(cli.id) ? "2px solid #e82653" : "" }}>
                    {cli.renter.name}
                  </Check>
                </CheckBox>
              </label>
              <span style={{ fontSize: 14 }}>{Moment(cli.due_date).format("DD/MM/YYYY")}</span>

              {
                (cli.advance_payment && cli.advance_payment_type === "PER" && (
                  <span>{cli.advance_payment_value}%</span>
                ))
              }

              {
                (cli.advance_payment && cli.advance_payment_type === "FIX" && (
                  <span>R${cli.advance_payment_value}</span>
                ))
              }

              {
                (!cli.advance_payment && (
                  <span>--</span>
                ))
              }

              <NumberFormat
                value={cli.document_value}
                thousandSeparator={"."}
                prefix={'R$ '}
                decimalScale={2}
                style={{ textAlign: 'right', fontWeight: 'bold', width: '10%' }}
                disabled={true}
                placeholder={"R$"}
                decimalSeparator={","}
                fixedDecimalScale={true}
                allowNegative={false}
                allowedDecimalSeparators={["."]}
              />

            </InfoPayment>)
          })}

          {
            financialInformations.map((financialInformation, financialInformationIndex) => {
              const bank_name = String(financialInformation.bank_name).toUpperCase();
              const financial_information_id = financialInformation.id;
              return (
                <ButtonInter
                  onClick={() => generateApi({ bank_name, financial_information_id })}
                  disabled={remittancesSelected.length === 0}
                  key={`button-fi-${financialInformationIndex}`}
                >
                  {runGenerateFile ? "AGUARDE..." : bank_name}
                </ButtonInter>
              )
              // if (financialInformation.bank_code === "077") {
              //   return (
              //     <ButtonInter
              //       onClick={() => generateApi({ bank_name, financial_information_id })}
              //       disabled={remittancesSelected.length === 0}
              //       key={`button-fi-${financialInformationIndex}`}
              //     >
              //       {runGenerateFile ? "AGUARDE..." : bank_name}
              //     </ButtonInter>
              //   )
              // }
              // return (
              //   <ButtonSicoob
              //     onClick={() => generateFile({ bank_name, financial_information_id })}
              //     disabled={remittancesSelected.length === 0}
              //     key={`button-fi-${financialInformationIndex}`}
              //   >
              //     {runGenerateFile ? "AGUARDE..." : bank_name}
              //   </ButtonSicoob>
              // )
            })
          }

        </>
      }
    </Container >
  )

}

export default FieldsRevenues;
