import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName, registerPermission } from '../../../helper';
import {
  Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import { FieldHorizontal } from '../FieldsExpense/style';
import {
  Container
} from './style';




const FieldsChip: React.FC = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const clients = useSelector(state => state.clients)
  const objSelected = useSelector(state => state.objSelected)

  const [id, setId] = useState(0)
  const [client_id, setClientId] = useState(0)
  const [client_name, setClientName] = useState("")

  const [number, setNumber] = useState("")
  const [iccid, setIccid] = useState("")
  const [cost, setCost] = useState({ formattedValue: "6,50", value: 6.5, floatValue: 6.5 })
  const [provider, setProvider] = useState("SMARTSIM")
  const [note, setNote] = useState("")
  const [active, setActive] = useState(true)

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")

  function handlerSwitch() {
    setActive(!active)
  }

  useEffect(() => {

    setTextAlert("")

    console.log(objSelected)

    if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
      setId(objSelected.id)
      setClientId(objSelected.client_id)
      setClientName(getClientName(clients, objSelected.client_id))

      setNumber(objSelected.number)
      setIccid(objSelected.iccid)
      setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
      setProvider(objSelected.provider)
      setNote(objSelected.note)
      setActive(objSelected.active)
      setTextDesc("Atualizar")
    }
    else {
      setId(0)
      setClientId(0)
      setClientName("")
      setNumber("")
      setIccid("")
      setCost({ formattedValue: "6,50", value: 6.5, floatValue: 6.5 })
      setProvider("SMARTSIM")
      setNote("")
      setActive(true)
      setTextDesc("Adicionar")
    }

  }, [objSelected])

  async function saveData() {

    if (!registerPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para cadastros")
      return
    }

    if (number.trim() === "") {
      setTextAlert("Informe o campo Número")
      return
    }

    if (number.length !== 19) {
      setTextAlert("Número inválido")
      return
    }

    if (iccid.trim() === "") {
      setTextAlert("Informe o campo ICCID")
      return
    }

    if (iccid.length < 18) {
      setTextAlert("Informe o campo ICCID")
      return
    }

    if (provider === "") {
      setTextAlert("Informe o campo Fornecedor")
      return
    }

    if (client_id === 0) {
      setTextAlert("Informe o campo Cliente")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'chips' : `chips/${id}/update`
    // console.log(number.length)
    // console.log(number)

    try {

      let response = await fetch(DEFAULT_URL + url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: (client_id === 0) ? null : client_id,
          number: number,
          iccid: iccid,
          cost: cost.floatValue,
          provider: provider,
          note: note,
          active: active
        })
      });

      let responseJson = await response.json();
      // console.log(responseJson)

      setTextButton("Salvar")

      if (responseJson.success) {
        dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

      if (message === 'contains_number') {
        setTextAlert("Número já existente")
        dispatch({ type: "SET_SEARCH", data: number })
        return
      }

      if (message === 'contains_iccid') {
        setTextAlert("ICCID já existente")
        dispatch({ type: "SET_SEARCH", data: iccid })
      }

    } catch (error) {
      console.error(error);
    }

  }

  return (
    <Container>

      <Title>{textDesc}</Title>

      <AutoComplete
        label={"Cliente"}
        finder={"clients"}
        item={{ id: client_id, name: client_name }}
        setSelected={(item) => {
          setClientId(item.id)
          setClientName(item.search)
        }}
      />

      <Field>
        <label>Número:</label>

        <InputMask
          mask="+99 (99) 99999-9999"
          type="text"
          placeholder="Número"
          value={number}
          onChange={event => setNumber(event.target.value)} />

      </Field>

      <Field>
        <label>ICCID:</label>
        <input
          type="text"
          placeholder="ICCID"
          minLength={18}
          maxLength={20}
          value={iccid}
          onChange={event => setIccid(event.target.value)} />
      </Field>

      <Field>
        <label>Custo:</label>
        <NumberFormat
          value={cost.formattedValue}
          thousandSeparator={"."}
          prefix={'R$ '}
          decimalScale={2}
          placeholder={"R$"}
          decimalSeparator={","}
          fixedDecimalScale={true}
          allowNegative={false}
          allowedDecimalSeparators={["."]}
          onValueChange={(values) => {
            setCost(values)
          }} />
      </Field>

      <FieldHorizontal>
        <label>Tipo Pagamento:</label>

        <CheckBox>
          {["ALLCOM", "SMARTSIM"].map((e, i) => {

            return (
              <Check
                key={e.id}
                onClick={() => setProvider(e)}
                className={(e === provider) ? 'selected' : ''}>
                {e}
              </Check>)

          })}
        </CheckBox>

      </FieldHorizontal>

      <Field>
        <label>Observação:</label>
        <textarea
          type="text"
          placeholder="Adicione uma observação"
          value={note}
          onChange={event => setNote(event.target.value)}
        />
      </Field>

      <Select>
        <label>Status:</label>
        <Switch onChange={() => handlerSwitch()} checked={active} />
      </Select>

      <ViewSubmit>

        {textAlert !== "" &&
          <Alert>
            {textAlert}
          </Alert>}
        {textAlert === "" && <div />}

        <Button onClick={() => saveData()}>
          {textButton}
        </Button>

      </ViewSubmit>


    </Container>
  )

}

export default FieldsChip;
