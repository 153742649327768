import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Login from '../../components/Login'
import MenuBar from '../../components/MenuBar'
import ModalScreen from '../../components/ModalScreen'
import Storage from '../../components/Storage'
import WebSocket from "../../components/WebSocket"
import Panel from '../Panel'
import { Container, Dark, Wrapper } from './style'


const Main: React.FC = () => {

    const user = useSelector(state => state.user)
    const dark = useSelector(state => state.dark)
    const modal = useSelector(state => state.modal)

    const dispatch = useDispatch()

    useEffect(() => {
      console.log(window.location.pathname)
      if (localStorage.length > 0) {
        //console.log(localStorage)
        const localUser = JSON.parse(localStorage.getItem("@cloctLog"))

        if (localUser) {
          const { con, by } = localUser
          if (con && by) {
            dispatch({ type: "SET_USER", data: { id: con, token: by } })
          }
        }
      }
    }, [])

    return (
        <Container>

            {
              (window.location.pathname === "/" && (
                <>
                  {dark && <Dark />}
                    {modal.display &&

                        <ModalScreen
                            onClose={() => dispatch({ type: "SET_MODAL", data: { display: false, view: "" } })}
                            layout={`${modal.view}`}
                        />

                    }
                    <Wrapper>
                        {user.id === 0 && <Login />}
                        {user.id !== 0 && <MenuBar />}
                        {user.id !== 0 && <Storage />}
                        <WebSocket />
                    </Wrapper>
                </>
              ))
            }

            {
              (window.location.pathname === "/panel" && (
                <>
                  {<Panel />}
                </>
              ))
            }

        </Container >
    )
}

export default Main;
