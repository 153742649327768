import React, { memo, useEffect, useState } from "react";
import "./index.css";

const DEFAULT_ZINDEX = 5000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 1;

const MarkerCluster = ({ count, size, onClick }) => {
  const [eventHover, setEventHover] = useState(false);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [sizeBox] = useState("30px");
  const [sizeBorder] = useState("40px");
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(eventHover ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) + (eventHover ? DEFAULT_ZINDEX : 0));
  }, [eventHover, scale]);

  return (
    <div className="marker-event-cluster-all"
      onClick={onClick}
      onMouseEnter={_ => setEventHover(true)}
      onMouseLeave={_ => setEventHover(false)}
      style={{
        width: sizeBorder,
        height: sizeBorder,
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div
        className="marker-event-cluster"
        style={{ width: sizeBox, height: sizeBox }}
      >
        <div className="icon"></div>
        <div className="count">{count}</div>
      </div>
    </div>
  );
};

export default memo(MarkerCluster);
