
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import {
  Container,
  ExcelDiv,
  Info,
  Itens,
  RowExcel,
  TitleExcel
} from './style';

const FinancialPayer: React.FC = () => {

  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)
  const arrTypeExpenses = useSelector(state => state.typeExpenses)

  const [typeExpenseSelected, setTypeExpenseSelected] = useState(null)
  const [arrayExpenses, setArrayExpenses] = useState([])

  const [totalPayed, setTotalPayed] = useState(0)
  const [totalToPay, setTotalToPay] = useState(0)

  useEffect(() => {

    Moment.locale('pt-br');

    if (arrTypeExpenses.length === 0)
      findTypeExpenses()

  }, [])

  useEffect(() => {
    if (typeExpenseSelected !== null)
      findExpenseByType(typeExpenseSelected);
  }, [typeExpenseSelected])

  async function findTypeExpenses() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_expenses', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_EXPENSES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function findExpenseByType(type_expense) {

    try {

      let response = await fetch(DEFAULT_URL + '/expenses/type_expense', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          type_expense_id: type_expense.id
        })
      });

      let responseJson = await response.json();
      if (responseJson.success) {


        var aux = [];
        var totalPayed = 0;
        var totalToPay = 0;

        responseJson.data.map(item => {

          if (!item.canceled) {

            var monthLy = monthLy = parseInt(item.due_date.split("-")[0] + item.due_date.split("-")[1])

            if (item.payment_date) {
              totalPayed = totalPayed + parseFloat(item.payment_value);
            } else {
              totalToPay = totalToPay + parseFloat(item.document_value);
            }

            if (typeof aux[monthLy] === 'undefined') {
              aux[monthLy] = [item]
            }
            else {
              aux[monthLy].push(item)
            }

          }

        })

        setTotalPayed(totalPayed);
        setTotalToPay(totalToPay);
        setArrayExpenses(aux.reverse());

        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>

      <Info>

        {typeExpenseSelected === null && arrTypeExpenses.filter(ar => ar.active).map(item => {
          return (
            <span onClick={() => setTypeExpenseSelected(item)}>
              {item.name}
            </span>
          )
        })}

        {typeExpenseSelected !== null && arrayExpenses.length === 0 && <p>
          Carregando...
        </p>}

        {typeExpenseSelected !== null && arrayExpenses.length > 0 &&
          <div style={{ width: "100%" }}>

            <div style={{ fontWeight: "bold", marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between" }}>
              <p>{typeExpenseSelected.name} - <span onClick={() => setTypeExpenseSelected(null)}> VOLTAR </span> </p> <p> <span style={{ color: 'red' }}>{formatNumber(totalToPay)}</span> - <span style={{ color: '#2a7bcb' }}>{formatNumber(totalPayed)}</span> - TOTAL: {formatNumber(totalToPay + totalPayed)}</p>
            </div>

            <ExcelDiv>

              {arrayExpenses.map(date => {

                var monthPay = 0;
                date.map(item => {
                  monthPay = monthPay + parseFloat(item.payment_value);
                })

                return (
                  <div style={{ marginBottom: 40 }}>
                    <TitleExcel>
                      <strong style={{ color: "#fff" }}>Data: {date[0].due_date.split("-")[1] + "/" + date[0].due_date.split("-")[0]} </strong>
                      <strong style={{ color: "#fff" }}>{formatNumber(monthPay)} </strong>
                    </TitleExcel>
                    <Itens>
                      <p className='reciver'>RECEBEDOR</p>
                      <p className='date'>DATA DO PAGAMENTO</p>
                      <p className='payment'>VALOR PAGO</p>
                      <p>DESCRIÇÃO</p>
                    </Itens>
                    {date.map(item => {
                      return (
                        <RowExcel>
                          <p className='reciver'>{item.provider.name}</p>
                          <p className='date'>{Moment((item.payment_date) ? item.payment_date : item.due_date).format("DD/MM/YYYY")}</p>
                          <p className='payment'>{formatNumber((item.payment_date) ? item.payment_value : item.document_value)}</p>
                          <p>{item.note}</p>
                        </RowExcel>
                      )
                    })}

                  </div>
                )

              })}

            </ExcelDiv>

          </div>}

      </Info>

    </Container>
  )

}

export default FinancialPayer;
