import React from 'react';
import { useDispatch } from 'react-redux';
import ScheduleModal from '../Maintenances/Timeline/ScheduleModal';
import {
    Container
} from './style';



const ModalScreen: React.FC = ({ onClose, layout, callback }) => {

    const dispatch = useDispatch()

    function closeModal() {
        dispatch({ type: "SET_OBJ_SELECTED", data: {} });
        dispatch({ type: "SET_DARK", data: false })
        onClose();
    }

    return (
        <Container>

            <span onClick={() => closeModal()}>X</span>

            {layout === "NOVA MANUTENÇÃO" && <ScheduleModal callback={() => {
                callback()
                closeModal()
            }} />}


        </Container>
    )

}

export default ModalScreen;