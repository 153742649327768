import React from 'react'
import "react-loading-skeleton/dist/skeleton.css"
import { Provider } from 'react-redux'
import Main from './pages/Main'
import store from './store'
import GlobalStyle from './styles/GlobalStyles'

function App() {
  return (
    <>
      <Provider store={store}>
        <Main />
        <GlobalStyle />
      </Provider>
    </>
  );
}

export default App;
