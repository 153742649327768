import { faFileCode, faSyncAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RequestLogsCommands
} from '../../../actions/Logs';
import { DEFAULT_NTRACKER_URL, DEFAULT_URL, DEFAULT_USER_STRUCT, isPasswordCommandValid } from '../../../helper';
import {
    Container, Details, DisplayCommand, DisplayPassword, FileCmd, Filezilla, Grid, Info, NBoard, Password, Row, SubMenuButton, XMLInfo
} from './style';




var password = ""

const CommandLock: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [arrayItens, setArrayItens] = useState([])

    const [date, setDate] = useState("")
    const [xmlDiv, setXmlDiv] = useState(null)
    const [ftpFiles, setFtpFiles] = useState([])

    const [serialSelected, setSerialSelected] = useState(false)
    const [textPassword, setTextPassword] = useState("")

    const [update, setUpdate] = useState(true)

    useEffect(() => {
        Moment.locale('pt-br');
        setDate(Moment(new Date()).add(1, 'days').format('YYYY-MM-DD 23:59:59'))
        find()
        ftpList()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'vehicles', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                //console.log(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function ftpList() {

        setUpdate(true)

        try {

            let response = await fetch(DEFAULT_NTRACKER_URL + 'tracker/command/xml/list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlYmM4MjI3NTZhYWUzMDAxMmQ0YThmNSIsImlzTG9naW4iOnRydWUsImlhdCI6MTYzOTM5NzQxNCwiZXhwIjozMTU3MzY3ODM5ODE0fQ.aPzrSBhUKxXr1uCV7HjZSUGyov9jUtI5zT0CXVGleBE',
                    'pin': '0000'
                }
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setUpdate(false)

            if (responseJson.success) {
                setFtpFiles(responseJson.data.commands);
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    function handlerClickRow(item) {

        password = ""
        setTextPassword(password)

        if (item.type == "LIMPA-FOSSA") {
            setSerialSelected({ serial: item.niple.tracker.serial, board: item.identifier })
            openFile(item.identifier, item.niple.tracker.serial)
        }
        else {
            setSerialSelected({ serial: item.tracker.serial, board: item.identifier })
            openFile(item.identifier, item.tracker.serial)
        }

    }

    function handlePassword(number) {

        var aux = `${password}${number}`

        if (isPasswordCommandValid(aux) == "MAX_LIMIT") {
            password = ""
            setTextPassword(password)
            console.log("MAX_LIMIT")
            return
        }

        if (isPasswordCommandValid(aux) == "ERRO") {
            password = ""
            setTextPassword(password)
            console.log("ERRO")
            return
        }

        if (isPasswordCommandValid(aux) == "SUCCESS") {
            password = ""
            setTextPassword(password)
            console.log("SUCCESS")

            sendCommandNtracker()

            return
        }

        setTextPassword(aux)
        password = aux
    }

    async function sendCommandNtracker() {

        var xml =
            "<?xml version='1.0' encoding='iso-8859-1'?>" +
            "<COMMANDS>" +
            "<COMMAND>" +
            "<PROTOCOL>166</PROTOCOL>" +
            "<SERIAL>" + serialSelected.serial + "</SERIAL>" +
            "<ID_COMMAND>" + serialSelected.serial + "</ID_COMMAND>" +
            "<TYPE>51</TYPE>" +
            "<ATTEMPTS>10</ATTEMPTS>" +
            "<COMMAND_TIMEOUT>" + date + "</COMMAND_TIMEOUT>" +
            "<PARAMETERS>" +
            "<PARAMETER>" +
            "<ID>SET_OUTPUT</ID>" +
            "<VALUE>1</VALUE>" +
            "</PARAMETER>" +
            "<PARAMETER>" +
            "<ID>SET OUTPUT 1</ID>" +
            "<VALUE>1</VALUE>" +
            "</PARAMETER>" +
            "<PARAMETER>" +
            "<ID>SET OUTPUT 2</ID>" +
            "<VALUE>0</VALUE>" +
            "</PARAMETER>" +
            "<PARAMETER>" +
            "<ID>SET OUTPUT 3</ID>" +
            "<VALUE>0</VALUE>" +
            "</PARAMETER>" +
            "<PARAMETER>" +
            "<ID>SET OUTPUT 4</ID>" +
            "<VALUE>0</VALUE>" +
            "</PARAMETER>" +
            "</PARAMETERS>" +
            "</COMMAND>" +
            "</COMMANDS>"

        console.log(xml);

        try {

            let response = await fetch(DEFAULT_NTRACKER_URL + 'tracker/command/xml/send/' + serialSelected.serial, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/xml',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlYmM4MjI3NTZhYWUzMDAxMmQ0YThmNSIsImlzTG9naW4iOnRydWUsImlhdCI6MTYzOTM5NzQxNCwiZXhwIjozMTU3MzY3ODM5ODE0fQ.aPzrSBhUKxXr1uCV7HjZSUGyov9jUtI5zT0CXVGleBE',
                    'pin': '0000'
                },
                body: xml
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {

                setFtpFiles(
                    [...ftpFiles, serialSelected.serial + "_TEST.cmd"]
                )

                RequestLogsCommands(user.token, {
                    user_id: user.id,
                    note: `COMANDO DE BLOQUEIO ENVIADO: ${serialSelected.board}`
                })

                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function deleteCommand(name) {

        let serial = name.split("_")[0]

        try {

            let response = await fetch(DEFAULT_NTRACKER_URL + 'tracker/command/xml/remove/' + serial, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/xml',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlYmM4MjI3NTZhYWUzMDAxMmQ0YThmNSIsImlzTG9naW4iOnRydWUsImlhdCI6MTYzOTM5NzQxNCwiZXhwIjozMTU3MzY3ODM5ODE0fQ.aPzrSBhUKxXr1uCV7HjZSUGyov9jUtI5zT0CXVGleBE',
                    'pin': '0000'
                }
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                RequestLogsCommands(user.token, {
                    user_id: user.id,
                    note: `COMANDO DE BLOQUEIO DELETADO: ${serial}`
                })
            }


            ftpList();

        } catch (error) {
            console.error(error);
        }


    }

    function openFile(board, serial) {

        setXmlDiv(

            <>

                <FileCmd>
                    <FontAwesomeIcon icon={faFileCode} style={{ color: '#fff', width: 30, height: 40 }} />
                    <span>{board}</span>
                </FileCmd>

                <Row />

                <DisplayCommand>
                    <p>&lt;?xml version='1.0' encoding='iso-8859-1'?&gt;</p>
                    <p>&lt;COMMANDS&gt;</p>

                    <p style={{ marginLeft: 10 }}>&lt;COMMAND&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;PROTOCOL&gt;166&lt;/PROTOCOL&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;SERIAL&gt;{serial}&lt;/SERIAL&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;ID_COMMAND&gt;{serial}&lt;/ID_COMMAND&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;TYPE&gt;51&lt;/TYPE&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;ATTEMPTS&gt;254&lt;/ATTEMPTS&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;COMMAND_TIMEOUT&gt;{date}&lt;/COMMAND_TIMEOUT&gt;</p>
                    <p style={{ marginLeft: 20 }}>&lt;PARAMETERS&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;PARAMETER&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;ID&gt;SET_OUTPUT&lt;/ID&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;VALUE&gt;1&lt;/VALUE&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;/PARAMETER&gt;</p>

                    <p style={{ marginLeft: 30 }}>&lt;PARAMETER&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;ID&gt;SET OUTPUT 1&lt;/ID&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;VALUE&gt;1&lt;/VALUE&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;/PARAMETER&gt;</p>

                    <p style={{ marginLeft: 30 }}>&lt;PARAMETER&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;ID&gt;SET OUTPUT 2&lt;/ID&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;VALUE&gt;0&lt;/VALUE&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;/PARAMETER&gt;</p>

                    <p style={{ marginLeft: 30 }}>&lt;PARAMETER&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;ID&gt;SET OUTPUT 3&lt;/ID&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;VALUE&gt;0&lt;/VALUE&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;/PARAMETER&gt;</p>

                    <p style={{ marginLeft: 30 }}>&lt;PARAMETER&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;ID&gt;SET OUTPUT 4&lt;/ID&gt;</p>
                    <p style={{ marginLeft: 40 }}>&lt;VALUE&gt;0&lt;/VALUE&gt;</p>
                    <p style={{ marginLeft: 30 }}>&lt;/PARAMETER&gt;</p>

                    <p style={{ marginLeft: 20 }}>&lt;/PARAMETERS&gt;</p>
                    <p style={{ marginLeft: 10 }}>&lt;/COMMAND&gt;</p>
                    <p>&lt;/COMMANDS&gt;</p>
                </DisplayCommand>

            </>)

    }

    return (
        <Container>

            <Info>

                <Grid>

                    <div style={{ background: '#eeeeee' }}>
                        <Details>
                            <NBoard style={{ fontWeight: 'bold' }}>Placa</NBoard>
                        </Details>
                    </div>

                    {arrayItens.map((item, i) => {

                        if (item.active) {

                            if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase())) {
                                return (
                                    <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} onClick={() => handlerClickRow(item)}>
                                        <Details>
                                            <NBoard>{item.identifier}</NBoard>
                                        </Details>
                                    </div>
                                )
                            }

                        }
                    })}

                </Grid>

                <XMLInfo>
                    {xmlDiv && <Password>

                        <DisplayPassword>
                            {textPassword}
                        </DisplayPassword>

                        <div>
                            {[1, 2, 3, 4, 5].sort(() => Math.random() - 0.5).map(number => {
                                return (
                                    <span style={{ opacity: textPassword.includes(number) ? 0.5 : 1 }} key={number} onClick={() => handlePassword(number)}>{number}</span>
                                )
                            })}
                        </div>

                        <div>
                            {[6, 7, 8, 9, 0].sort(() => Math.random() - 0.5).map(number => {
                                return (
                                    <span style={{ opacity: textPassword.includes(number) ? 0.5 : 1 }} key={number} onClick={() => handlePassword(number)}>{number}</span>
                                )
                            })}
                        </div>
                    </Password>}

                    {xmlDiv}
                </XMLInfo>

                <Filezilla>

                    <div style={{ background: '#eeeeee', marginBottom: 20 }}>
                        <Details>

                            <FontAwesomeIcon icon={faSyncAlt} onClick={() => ftpList()} style={{ position: 'absolute', left: 20, top: 10, color: '#000', width: 20, height: 20 }} />
                            <NBoard style={{ fontWeight: 'bold' }}>NTracker Commands</NBoard>
                        </Details>
                    </div>

                    {update &&
                        <div style={{
                            marginLeft: 20,
                            fontSize: 20
                        }}>Atualizando...</div>
                    }

                    {!update && ftpFiles.length === 0 &&
                        <div style={{
                            marginLeft: 20,
                            fontSize: 20
                        }}>Nenhum comando encontrado</div>
                    }

                    {!update && ftpFiles.map((file, i) => {
                        return (
                            <SubMenuButton>
                                <FontAwesomeIcon icon={faFileCode} style={{ color: '#000', width: 20, height: 20 }} />
                                <span>{file}</span>
                                <FontAwesomeIcon icon={faTrashAlt} onDoubleClick={() => deleteCommand(file)} style={{ position: 'absolute', cursor: 'pointer', right: 20, color: '#000', width: 20, height: 20 }} />
                            </SubMenuButton>
                        )
                    })}

                </Filezilla>

            </Info>

        </Container>
    )

}

export default CommandLock;
