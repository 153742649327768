import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Alert, Check, CheckBox,
    Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Button, Container, FieldHorizontal, HorizontalField,
    Row,
    Row2
} from './style';



const FieldsInvestments = ({ client_id, callback }) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)

    const [id, setId] = useState(0)

    const [type_investments_id, setTypeInvestmentsId] = useState(0)
    const [arrayTypeInvestments, setArrayTypeInvestments] = useState([])

    const [type, setType] = useState("APORTE")
    const [date, setDate] = useState("")
    const [value, setValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
    const [active, setActive] = useState(true)


    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")
    const [textErrorButtons, setTextErrorButtons] = useState("")

    const [myRef, setMyRef] = useState()

    const [stringdate, setStringDate] = useState("")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        Moment.locale('pt-br');
        setTextAlert("")
        findTypeIvestments()

    }, [])

    async function findTypeIvestments() {

        try {

            let response = await fetch(DEFAULT_URL + 'type_investments/' + client_id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            if (responseJson.success) {
                setArrayTypeInvestments(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function saveData() {

        if (type_investments_id === 0) {
            setTextAlert("Informe um Tipo de Investimento")
            return
        }

        if (value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (date === "") {
            setTextAlert("Informe o campo Data")
            return
        }

        const f_due_date = Moment(date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        try {

            let response = await fetch(DEFAULT_URL + 'expenses', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    provider_id: 5,
                    financial_payer_id: 1,
                    type_expense_id: 34,
                    payment_type: "PIX",
                    document_number: 1,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: value.floatValue,
                    payment_value: value.floatValue,
                    payment_date: f_due_date.format("YYYY-MM-DD"),
                    note: "INVESTIMENTO",
                    system: false,
                    cancel_reason: "",
                    canceled: false
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {

                let response = await fetch(DEFAULT_URL + 'investments', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.token
                    },
                    body: JSON.stringify({
                        client_id: client_id,
                        type_investments_id: type_investments_id,
                        type: type,
                        date: f_due_date.format("YYYY-MM-DD"),
                        value: value.floatValue,
                        active: active
                    })
                });

                let responseJson = await response.json();
                console.log(responseJson)

                setTextButton("Salvar")

                if (responseJson.success) {
                    callback();
                    return
                }

            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc.toUpperCase()}</Title>
            <Row />

            {textErrorButtons != "" &&
                <label style={{ color: 'red' }}>
                    {textErrorButtons}
                </label>
            }

            <>

                <HorizontalField>

                    <FieldHorizontal>
                        <label>Tipo:</label>
                        <CheckBox
                            ref={el => setMyRef(el)}>
                            {arrayTypeInvestments.map((e, i) => {

                                if (type_investments_id === e.id) {
                                    if (i > 6) myRef.scrollTo(i * 90, 0)
                                }

                                const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                                return (
                                    <Check
                                        key={e.id}
                                        onClick={() => setTypeInvestmentsId(e.id)}
                                        className={(type_investments_id === e.id) ? 'selected' : ''}>
                                        {name}
                                    </Check>)

                            })}
                        </CheckBox>
                    </FieldHorizontal>

                </HorizontalField>
                <Row />


                <HorizontalField>

                    <FieldHorizontal>
                        <label>Valor aportado:</label>

                        <NumberFormat
                            value={value.formattedValue}
                            thousandSeparator={"."}
                            prefix={'R$ '}
                            decimalScale={2}
                            placeholder={"R$"}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowedDecimalSeparators={["."]}
                            onValueChange={(values) => {
                                setValue(values)
                            }} />

                    </FieldHorizontal>

                    <Row2 />

                    <FieldHorizontal>
                        <label>Data do aporte:</label>

                        <InputMask
                            mask="99/99/9999"
                            type="text"
                            placeholder="Data do aporte"
                            value={date}
                            onChange={event => setDate(event.target.value)} />

                    </FieldHorizontal>

                </HorizontalField>

                <Row />

                <Select>
                    <label>Status:</label>
                    <Switch onChange={() => handlerSwitch()} checked={active} />
                </Select>

                <ViewSubmit>

                    {textAlert !== "" &&
                        <Alert>
                            {textAlert}
                        </Alert>}
                    {textAlert === "" && <div />}

                    <Button onClick={() => saveData()}>
                        {textButton}
                    </Button>

                </ViewSubmit>
            </>

        </Container>
    )

}

export default FieldsInvestments;