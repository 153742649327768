import React, { useState } from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import HistoryItem from "./HistoryItem";

const HistoryGroupItem = ({ isBoardSelected, isBoardSuspicionSelected, selectBoard, selectBoardSuspicion, item, hist }) => {
  const [open, setOpen] = useState(hist.open ?? true);

  useState(() => {
    hist.open = open;
  }, []);

  return (
    <li className="history-group">
      <div 
        className="history-group-click"
        onClick={() => {
          hist.open = !open;
          setOpen(prevState => !prevState);
        }}
      >
        <div className="history-flag">
          <div style={{ backgroundColor: hist.color }} />
        </div>
        <div className="history-count">{hist.count}</div> - {hist.name}
        <div className="history-group-caret">
          {
            ((open) && (
              <FontAwesomeIcon icon={faCaretUp} />
            ))
          }
          {
            ((!open) && (
              <FontAwesomeIcon icon={faCaretDown} />
            ))
          }
        </div>
      </div>
      <ul className={`${open ? "active" : ""}`}>
        {hist.items.map((histItem) => <HistoryItem isBoardSelected={isBoardSelected} isBoardSuspicionSelected={isBoardSuspicionSelected} selectBoard={selectBoard} selectBoardSuspicion={selectBoardSuspicion} item={item} hist={histItem} hideBorder={true} key={`item-history-${Buffer.from(String(item.board)).toString("hex")}-${Buffer.from(String(histItem.name)).toString("hex")}`} />)}
      </ul>
    </li>
  );
};

export default HistoryGroupItem;
