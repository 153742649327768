import api from "../..";

export default async function requestUpdateObservation(id, body, token) {

  const reponse = await api.post(
    `vehicle_observation/${id}/update`,
    body,
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  )
    .then(res => {

      return {
        data: res.data.data,
        status: res.status,
        error: {}
      };

    })
    .catch(e => {

      return {
        data: false,
        status: 500,
        error: e.response?.data
      };

    });

  return reponse;
}
