import { Text } from '@react-pdf/renderer';
import React from 'react';
import PDFStyle from '../../../styles/PDFStyle';


const Footer: React.FC = ({ page, pages }) => {

    return (

        <div style={PDFStyle.footer}>
            <div>
                <Text style={{ fontSize: 9 }}>Página: {page + 1}/{pages}</Text>
                <Text style={{ fontSize: 9 }}>Three Pixels</Text>
            </div>
        </div>
    )
}

export default Footer;