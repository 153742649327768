import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active, Container,
    Details,
    Identifiler, Payment, Renter
} from './style';

const GridSaas: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'saas', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                var tVal = 0;
                responseJson.data.map(item => {
                    if (item.active) {
                        const payment = (item.contract) ? item.contract.monthly_payment : 0;
                        tVal = tVal + parseFloat(payment);
                    }
                });
                setTotalValue(tVal);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        var tVal = 0;
        arrayItens.map((item, i) => {

            const social_reason = (item.contract) ? item.contract.renter.name : "";

            if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase()) || social_reason.includes(search)) {
                t++
                const payment = (item.contract) ? item.contract.monthly_payment : 0;
                tVal = tVal + parseFloat(payment);
            }
        })
        setTotal(t);
        setTotalValue(tVal);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total} - {formatNumber(totalValue)}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    <Identifiler style={{ fontWeight: 'bold' }}>Identificador</Identifiler>
                    <Renter style={{ fontWeight: 'bold' }}>Locatário</Renter>
                    <Payment style={{ fontWeight: 'bold' }}>Mensalidade</Payment>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                const payment = (item.contract) ? item.contract.monthly_payment : 0;
                const social_reason = (item.contract) ? item.contract.renter.name : "";

                if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase()) || social_reason.includes(search)) {

                    return (
                        <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                <Identifiler>{item.identifier}</Identifiler>
                                <Renter>{social_reason.substr(0, 30)}</Renter>
                                <Payment>
                                    <NumberFormat
                                        value={payment}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        prefix={"R$ "}
                                        disabled={true}
                                        style={{ textAlign: 'center', fontSize: 11, width: '100%' }}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                    /></Payment>
                                <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridSaas;