import styled, { css } from 'styled-components'


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--primary);
    padding: 0px 0px 15px 0px;
`;

export const Row = styled.div`
    height: 2px;
    border-radius: 2px;
    align-self: center;
    background-color: #fff;
`;

export const Legend = styled.div`
    margin: 0 0 0 35px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 15px;
    margin: 0 0 10px 35px;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media(max-width: 725px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

`;

export const VerticalRow = styled.div`
    width: 4px;
    height: calc(100vh - 250px);
    background: var(--white);

    @media(max-width: 725px) {
        width: 100%;
        height: 4px;
    }

`;

export const Grid = styled.div`
    width: 50%;
    height: calc(100vh - 150px);
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media(max-width: 725px) {
        width: 100%;
    }

`;

export const PDFInfo = styled.div`
    width: 45%;
    height: calc(100vh - 150px);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    background: #333;
    border-radius: 5px;
    @media(max-width: 725px) {
        width: 100%;
    }
`;

export const FileCmd = styled.div`
    height: 75px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 15px;
    position: relative;

    > span {
        border-radius: 2px;
        background-color: var(--white);
        margin: 5px 0 0 0;
        user-select: none;
        padding: 2px 5px 2px 5px;
        font-size: 12px;
        text-align: center;
    }
`;

export const DisplayCommand = styled.div`
    width: 100%;
    color: var(--white);
    margin: 10px 0 0 0;
    font-size: 12px;
    letter-spacing: 1.5px;

    > p {
        margin-bottom: 3px;
    }
`;

export const Password = styled.div`
    position: absolute;
    right: 15px;
    top: 10px;
    height: 80px;
    display: flex;
    flex-direction: column;
    z-index: 1;

    > div {

        display: flex;
        flex-direction: row;

        > span {
            cursor: pointer;
            font-weight: bold;
            margin: 3px;
            height: 20px;
            width: 25px;
            text-align: center;
            padding: 2px 0 0 0;
            background-color: #fff;
            font-size: 13px;
            color: #000;
        }
    }
`

export const DisplayPassword = styled.div`
    background-color: #dfedfe;
    height: 30px;
    border-radius: 0px;
    margin: 0 25px 0 25px;
    justify-content: center;
    padding-top: 2px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 3px;
`;

export const Details = styled.div`
    width: 100%;
    height: 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const NBoard = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    overflow-y: hidden;
`;

export const SubMenuButton = styled.div`
    display: flex;
    flex-direction: row;
    height: 35px;
    width: 100%;
    flex-shrink: 0;
    margin: 5px 10px 5px 10px;

    > span {
        font-size: 15px;
        margin-left: 10px;
    }

`;
