import styled, { css } from 'styled-components'


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--primary);
    padding: 0px 0px 15px 0px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media(max-width: 725px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

`;

export const VerticalRow = styled.div`
    width: 4px;
    height: calc(100vh - 200px);
    background: var(--white);

    @media(max-width: 725px) {
        width: 100%;
        height: 4px;
    }

`;

export const Grid = styled.div`
    width: 60%;
    height: calc(100vh - 200px);
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media(max-width: 725px) {
        width: 100%;
    }

    > label {
        font-size: 10px;
        text-align: left;
        background: #eee;
        padding: 5px;
        width: 60px;
    }

`;

export const Fields = styled.div`
    width: 35%; 
    height: calc(100vh - 200px);
    overflow-x: hidden;

    @media(max-width: 725px) {
        width: 100%;
    }

`;

export const Details = styled.div`
    width: 100%;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 12px;
`;

export const Name = styled.div`
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: left; 
    overflow-y: hidden;
    white-space: nowrap;
`;

export const Length = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
`;

export const Cost = styled.div`
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
`;

export const Active = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
`;