import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 180px);
    /* background: #f7f9fc; */

    .active {
        height: 290px;
    }

`;

export const Filter = styled.div`
    
    transition: all 0.5s;
    height:40px;
    overflow: hidden;
    background-color: #f7f9fc;

    > .header {
   
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        align-items: center;

        > .filter {
            display: flex;
            align-items: center;
            font-size: 13px;
        }

        > .button {
            
            font-weight: bold;
            height: 40px;
            padding: 0 15px;
            color: #222;
            display: flex;
            justify-content: center;
            align-items: center;
            /* border-radius: 10px; */
            cursor: pointer;
        }

    }

    > .itens {
        padding: 10px 20px;
    }

    
`;

export const Totals = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    background: #fff;
    /* background: #f7f9fc; */
    align-items: center;
    padding: 0 20px;
    color: #ddd;

    > div {
        position: relative;
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        border:3px solid #fff;
        height: 97px;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        cursor: pointer;

        > div > .total {
            font-size: 35px;
            margin-left: 5px;
        }

        > .name {
            margin-top: 5px;
            font-size: 13px;
        }
    }

    > span {
        width: 2px;
        margin: 0 25px;
        background-color: #f7f9fc;
        height: 60%;
        border-radius: 10px;
    }
`;

export const Cards = styled.div`
    padding: 10px 25px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

export const LazYLoadDiv = styled.div`

  animation: Animation 2s ease infinite;
  background: linear-gradient(45deg, #fff, #ddd, #fff);
  background-size: 100% 200%;
  margin:0 10px;
  border-radius: 10px;

  @keyframes Animation {
      0%{background-position:100% 0%}
      50%{background-position:0% 100%}
      100%{background-position:100% 0%}
  }

`;

export const CardView = styled.div`
    width: 250px; 
    height: 380px;
    margin: 10px 10px;
    background-color: #fff;
    box-shadow: 3px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;

    > .time {
        margin: 10px;
        height: 20px;
        width: 80px;
        justify-content: center;
        align-items: center;
        color: #fff;
        display: flex;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        font-size: 10px;
    }

    > .row {

        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 7px 10px;

        > .board {
            font-weight: bold;
            font-size: 20px;
            color: #000;
        }

        > .emp {
            font-weight: bold;
            font-size: 12px;
            color: #333;
            height: 15px;
            overflow: hidden;
        }

        > .city {
            font-weight: bold;
            font-size: 12px;
            color: #333;
        }
    }

    .column {

        margin: 7px 10px;

        > .info {
            font-size: 12px;
            color: #666;
        }

        > .date {
            font-weight: bold;
            font-size: 13px;
            color: #333;
        }

    }

    .last-maintenance {
        background-color: #dedede;
        height: 60%;
        margin: 0px 0 0 0;
        padding: 5px 5px;
    }

`;  