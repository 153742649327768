import styled from 'styled-components';

export const Container = styled.div`
    background: var(--primary);
`;

export const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
`;

export const Dark = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 98;
    opacity: 0.5;
    background-color: #000;
`;