import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, HorizontalField, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';


const FieldsChassiMaintenances: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [chassi_id, setChassiId] = useState(0)
    const [chassi_serial, setChassiSerial] = useState("")
    const [provider_id, setProviderId] = useState(0)
    const [provider_name, setProviderName] = useState("")

    const [note, setNote] = useState("")
    const [entry_date, setEntryDate] = useState("")
    const [departue_date, setDepartueDate] = useState("")

    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0, floatValue: 0.0 })
    const [status, setStatus] = useState("ENVIADO")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    useEffect(() => {

        Moment.locale('pt-br');
        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)

            if (objSelected.chassi) {
                setChassiId(objSelected.chassi.id)
                setChassiSerial(objSelected.chassi.serial)
            }


            if (objSelected.provider) {
                setProviderId(objSelected.provider.id)
                setProviderName(objSelected.provider.name)
            }


            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            setEntryDate(Moment(objSelected.entry_date).format("DD/MM/YYYY"))
            setDepartueDate((objSelected.departue_date) ? Moment(objSelected.departue_date).format("DD/MM/YYYY") : "")
            setStatus(objSelected.status)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setChassiId(0)
            setChassiSerial("")
            setProviderId(0)
            setProviderName("")
            setNote("")
            setEntryDate(Moment().format("DD/MM/YYYY"))
            setDepartueDate("")
            setCost({ formattedValue: "0,00", value: 0, floatValue: 0 })
            setStatus("ENVIADO")
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {


        if (chassi_id === 0) {
            setTextAlert("Informe o campo Chassi")
            return
        }

        if (provider_id === 0) {
            setTextAlert("Informe o campo Fornencedor")
            return
        }

        if (entry_date.trim() === "") {
            setTextAlert("Informe o campo Data do envio")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'chassis_maintenances' : `chassis_maintenances/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    chassi_id: chassi_id,
                    provider_id: provider_id,
                    entry_date: Moment(entry_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    departue_date: departue_date === "" ? null : Moment(departue_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    note: note,
                    cost: cost.floatValue,
                    status: status
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Chassi"}
                finder={"chassis"}
                item={{ id: chassi_id, name: chassi_serial }}
                setSelected={(item) => {
                    setChassiId(item.id)
                    setChassiSerial(item.search)
                }}
            />

            <AutoComplete
                label={"Fornecedor"}
                finder={"providers"}
                item={{ id: provider_id, name: provider_name }}
                setSelected={(item) => {
                    setProviderId(item.id)
                    setProviderName(item.search)
                }}
            />

            <Field>
                <label>Data de envio:</label>
                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data de envio"
                    value={entry_date}
                    onChange={event => setEntryDate(event.target.value)} />
            </Field>

            <Field>
                <label>Note:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
                {/* <input
                    type="text"
                    placeholder="Custo"
                    value={cost}
                    onChange={event => setCost(event.target.value)}
                /> */}
            </Field>

            <Field>
                <label>Data de recebimento:</label>
                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data de recebimento"
                    value={departue_date}
                    onChange={event => setDepartueDate(event.target.value)} />
            </Field>

            <HorizontalField>

                <CheckBox>
                    <Check onClick={() => { setStatus("ENVIADO") }} style={{ border: (status === "ENVIADO") ? "2px solid #e82653" : "" }}>
                        ENVIADO
                    </Check>
                    <Check onClick={() => { setStatus("RECEBIDO") }} style={{ border: (status === "RECEBIDO") ? "2px solid #e82653" : "" }}>
                        RECEBIDO
                    </Check>
                </CheckBox>

            </HorizontalField>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsChassiMaintenances;