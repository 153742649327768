import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, financialPermission } from '../../../helper';
import {
    Alert, Check, CheckBox,
    Field,
    Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Button, Container, FieldHorizontal, HorizontalField,
    Row,
    Row2
} from './style';


const FieldsFinancialRequest = ({ client_id, callback }) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)

    const [provider_id, setProviderId] = useState(0)
    const [provider_name, setProviderName] = useState("")

    const [requested_by_id, setRequestedById] = useState(0)
    const [requested_by_name, setRequestedByName] = useState("")

    const [type_expense_id, setTypeExpenseId] = useState(0)
    const [arrayTypeExpenses, setArrayTypeExpenses] = useState([])

    const [payment_type, setPaymentType] = useState("PIX")
    const [due_date, setDueDate] = useState("")
    const [document_value, setDocumentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })

    const [note, setNote] = useState("")
    const [status, setStatus] = useState("EM ABERTO")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")
    const [textErrorButtons, setTextErrorButtons] = useState("")

    const [myRef, setMyRef] = useState()

    useEffect(() => {

        Moment.locale('pt-br');
        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {

            setId(objSelected.id)
            setDueDate(Moment(objSelected.due_date).format("DD/MM/YYYY"))
            setTextDesc("Atualizar")
            setProviderId(objSelected.provider.id)
            setProviderName(objSelected.provider.name)
            setTypeExpenseId(objSelected.type_expense_id)
            setPaymentType(objSelected.payment_type)
            setDocumentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
            setNote(objSelected.note)

            if (objSelected.requested_by) {
                setRequestedById(objSelected.requested.id)
                setRequestedByName(objSelected.requested.name)
            }

        }

        findTypeExpenses()

    }, [objSelected])

    async function findTypeExpenses() {

        try {

            let response = await fetch(DEFAULT_URL + 'type_expenses', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            if (responseJson.success) {
                setArrayTypeExpenses(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function saveData() {

        if (provider_id === 0) {
            setTextAlert("Informe um Fornecedor")
            return
        }

        if (type_expense_id === 0) {
            setTextAlert("Informe um Tipo de Despesa")
            return
        }

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'financial_approvals' : `financial_approvals/${id}/update`

        let renter_id = null;
        if (client_id === 3 && provider_id === 5) {
            renter_id = 56
        }
        if (client_id === 2 && provider_id === 8) {
            renter_id = 30;
        }

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    requested_by: user.id,
                    approved_by: null,
                    provider_id: provider_id,
                    type_expense_id: type_expense_id,
                    payment_type: payment_type,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: document_value.floatValue,
                    note: note,
                    renter_id: renter_id,
                    status: "EM ABERTO"
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function approve() {

        console.log("resolve")

        if (provider_id === 0) {
            setTextAlert("Informe um Fornecedor")
            return
        }

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        if (!await createExpense()) {
            return setTextAlert("Erro ao criar despesa")
        }

        const url = `financial_approvals/${id}/update`

        let renter_id = null;
        // OCTOPUS SOLICITA PARA PIXEL
        if (client_id === 3 && provider_id === 5) {
            renter_id = 30;
        }

        // PIXEL SOLICITA PARA OCTPUS
        if (client_id === 2 && provider_id === 8) {
            renter_id = 56;
        }

        if (renter_id != null) {
            if (!await createRevenue(renter_id)) {
                return setTextAlert("Erro ao criar receita")
            }
        }

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    requested_by: objSelected.requested.id,
                    approved_by: user.id,
                    provider_id: provider_id,
                    type_expense_id: type_expense_id,
                    payment_type: payment_type,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: document_value.floatValue,
                    note: note,
                    renter_id: renter_id,
                    status: "APROVADA"
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function reject() {

        if (provider_id === 0) {
            setTextAlert("Informe um Fornecedor")
            return
        }

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = `financial_approvals/${id}/update`

        let renter_id = null;
        if (client_id === 3 && provider_id === 5) {
            renter_id = 56
        }
        if (client_id === 2 && provider_id === 8) {
            renter_id = 30;
        }

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    requested_by: objSelected.requested.id,
                    approved_by: user.id,
                    provider_id: provider_id,
                    type_expense_id: type_expense_id,
                    payment_type: payment_type,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: document_value.floatValue,
                    note: note,
                    renter_id: renter_id,
                    status: "REJEITADA"
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function createExpense() {

        if (provider_id === 0) {
            setTextAlert("Informe um Fornecedor")
            return
        }

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = 'expenses'

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    provider_id: provider_id,
                    financial_payer_id: 1,
                    type_expense_id: type_expense_id,
                    payment_type: payment_type,
                    document_number: 1,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    document_value: document_value.floatValue,
                    payment_value: 0,
                    payment_date: null,
                    note: note,
                    system: false,
                    cancel_reason: "",
                    canceled: false
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                return true
            }

            return false

        } catch (error) {
            console.error(error);
        }

    }

    async function createRevenue(renter_id) {

        if (document_value.formattedValue === "") {
            setTextAlert("Informe o campo Valor do documento")
            return
        }

        if (due_date === "") {
            setTextAlert("Informe o campo Data de vencimento")
            return
        }

        const f_due_date = Moment(due_date, 'DD/MM/YYYY');
        if (!f_due_date.isValid()) {
            setTextAlert("Data de vencimento inválida")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = 'revenues'

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id === 2 ? 3 : 2,
                    renter_id: renter_id,
                    revenue_type: "SERVIÇOS",
                    payment_type: payment_type,
                    document_date: Moment().format("YYYY-MM-DD"),
                    unit_value: document_value.floatValue,
                    quantity: 1,
                    document_value: document_value.floatValue,
                    due_date: f_due_date.format("YYYY-MM-DD"),
                    payment_date: null,
                    payment_value: 0,
                    note: note,
                    canceled: false,
                    cancel_reason: "",
                    system: false,
                    advance_payment: false,
                    advance_payment_type: "PER",
                    advance_payment_value: null
                })
            });

            let responseJson = await response.json();
            if (responseJson.success)
                return true


            return false

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc.toUpperCase()}</Title>
            <Row />

            {textErrorButtons !== "" &&
                <label style={{ color: 'red' }}>
                    {textErrorButtons}
                </label>}

            <>

                <AutoComplete
                    label={"Fornecedor"}
                    finder={"providers"}
                    item={{ id: provider_id, name: provider_name }}
                    setSelected={(item) => {
                        setProviderId(item.id)
                        setProviderName(item.search)
                    }}
                />
                <Row />

                <HorizontalField>

                    <FieldHorizontal>
                        <label>Tipo:</label>
                        <CheckBox
                            ref={el => setMyRef(el)}>
                            {arrayTypeExpenses.map((e, i) => {

                                if (type_expense_id === e.id) {
                                    if (i > 6) myRef.scrollTo(i * 90, 0)
                                }

                                if (e.active) {
                                    const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                                    return (
                                        <Check
                                            key={e.id}
                                            onClick={() => setTypeExpenseId(e.id)}
                                            style={{ border: (type_expense_id === e.id) ? "2px solid #e82653" : "" }}>
                                            {name}
                                        </Check>)
                                }
                            })}
                        </CheckBox>
                    </FieldHorizontal>

                    <Row2 />

                    <FieldHorizontal>
                        <label>Tipo Pagamento:</label>
                        <CheckBox>

                            <Check onClick={() => { setPaymentType("AGENDAMENTO") }} style={{ border: (payment_type === "AGENDAMENTO") ? "2px solid #e82653" : "" }}>
                                AGENDAMENTO
                            </Check>

                            <Check onClick={() => { setPaymentType("BOLETO") }} style={{ border: (payment_type === "BOLETO") ? "2px solid #e82653" : "" }}>
                                BOLETO
                            </Check>

                            <Check onClick={() => { setPaymentType("CHEQUE") }} style={{ border: (payment_type === "CHEQUE") ? "2px solid #e82653" : "" }}>
                                CHEQUE
                            </Check>

                            <Check onClick={() => { setPaymentType("CARTÃO") }} style={{ border: (payment_type === "CARTÃO") ? "2px solid #e82653" : "" }}>
                                CARTÃO
                            </Check>

                            <Check onClick={() => { setPaymentType("DINHEIRO") }} style={{ border: (payment_type === "DINHEIRO") ? "2px solid #e82653" : "" }}>
                                DINHEIRO
                            </Check>

                            <Check onClick={() => { setPaymentType("PIX") }} style={{ border: (payment_type === "PIX") ? "2px solid #e82653" : "" }}>
                                PIX
                            </Check>

                            <Check onClick={() => { setPaymentType("TAFIRA") }} style={{ border: (payment_type === "TAFIRA") ? "2px solid #e82653" : "" }}>
                                TAFIRA
                            </Check>

                        </CheckBox>

                    </FieldHorizontal>

                </HorizontalField>
                <Row />


                <HorizontalField>

                    <FieldHorizontal>
                        <label>Valor do documento:</label>

                        <NumberFormat
                            value={document_value.formattedValue}
                            thousandSeparator={"."}
                            prefix={'R$ '}
                            decimalScale={2}
                            placeholder={"R$"}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowedDecimalSeparators={["."]}
                            onValueChange={(values) => {
                                setDocumentValue(values)
                            }} />

                    </FieldHorizontal>


                    <Row2 />

                    <FieldHorizontal>
                        <label>Data de vencimento:</label>

                        <InputMask
                            mask="99/99/9999"
                            type="text"
                            placeholder="Data de vencimento"
                            value={due_date}
                            onChange={event => setDueDate(event.target.value)} />

                    </FieldHorizontal>

                </HorizontalField>

                <Row />

                <Field>
                    <label>Descrição:</label>

                    <textarea
                        type="text"
                        value={note}
                        onChange={event => setNote(event.target.value)} />

                </Field>

                {financialPermission(user.profile) && id != 0 ?

                    <>
                        <HorizontalField>
                            <Field>
                                <label>Solitada por:</label>

                                <input
                                    type="text"
                                    value={requested_by_name}
                                    disabled />

                            </Field>
                            <Row2 />
                            <Field>
                                <label>Data:</label>

                                <input
                                    type="text"
                                    value={Moment(objSelected.createdAt).format("DD/MM/YYYY")}
                                    disabled />

                            </Field>
                        </HorizontalField>

                        {textAlert !== "" &&
                            <Alert>
                                {textAlert}
                            </Alert>}
                        {textAlert === "" && <div />}

                        <Button onClick={() => reject()} style={{ background: "red", marginTop: 20 }}>
                            NEGAR
                        </Button>

                        <Button onClick={() => approve()} style={{ marginTop: 20 }}>
                            APROVAR
                        </Button>

                    </>
                    :
                    <ViewSubmit>

                        {textAlert !== "" &&
                            <Alert>
                                {textAlert}
                            </Alert>}
                        {textAlert === "" && <div />}

                        <Button onClick={() => saveData()}>
                            {textButton}
                        </Button>

                    </ViewSubmit>


                }


            </>

        </Container>
    )

}

export default FieldsFinancialRequest;