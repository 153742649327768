import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
  Container,
  Details,
  SocialReason
} from './style';


const GridTypeSaas = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const search = useSelector(state => state.search)
  const objSelected = useSelector(state => state.objSelected)

  const [total, setTotal] = useState(0)
  const [arrayItens, setArrayItens] = useState([])

  useEffect(() => {
    find()
  }, []);

  async function find() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_saas', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      console.log(responseJson)

      if (responseJson.success) {
        setArrayItens(responseJson.data);
        setTotal(responseJson.data.length);
        dispatch({ type: "SET_TYPE_REVENUES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  useEffect(() => {
    if (typeof objSelected.insert != 'undefined') {
      find()
      dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    }
  }, [objSelected])

  useEffect(() => {
    var t = 0;
    arrayItens.map((item, i) => {
      if (search === "" || item.name.toUpperCase().includes(search.toUpperCase())) {
        t++;
      }
    })
    setTotal(t);
  }, [search])

  function handlerClickRow(item) {

    if (item === objSelected) {
      dispatch({ type: "SET_OBJ_SELECTED", data: {} });
      return
    }

    dispatch({ type: "SET_OBJ_SELECTED", data: item });
  }

  return (
    <Container>

      <label>Total: {total}</label>

      <div style={{ background: '#eeeeee' }}>
        <Details>
          <SocialReason style={{ fontWeight: 'bold' }}>Nome</SocialReason>
        </Details>
      </div>

      {arrayItens.map((item, i) => {

        if (search === "" || item.name.toUpperCase().includes(search.toUpperCase())) {
          return (
            <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
              <Details>
                <SocialReason>{item.name.substr(0, 35)}</SocialReason>
              </Details>
            </div>
          )
        }
      })}

    </Container>
  )

}

export default GridTypeSaas;
