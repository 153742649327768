import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import Modal from '../../Modal';
import {
    Container, Content,
    Row, Totals
} from './style';

const StockTechnical = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)

    const [modal, setModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("");

    const scrollView = useRef();

    const [socios, setSocios] = useState([])
    const [socioSelected, setSocioSelected] = useState(false)

    const [arrNiples, setArrNiples] = useState([])
    const [arrChassis, setArrChassis] = useState([])
    const [arrTrackers, setArrTrackers] = useState([])
    const [arrHboards, setArrHboards] = useState([])
    const [totalAll, setTotalAll] = useState(0)

    useEffect(() => {
        Moment.locale('pt-br');
        findSocios();
    }, [])

    async function findSocios() {

        try {

            let response = await fetch(DEFAULT_URL + 'users', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var s = [];
                responseJson.data.map(item => {
                    if (item.active) {
                        s.push(item)
                    }
                });

                setSocios(s);
                setSocioSelected(s[0].id)
                findNiples(s[0].id)
                findTrackers(s[0].id)
                findChassis(s[0].id)
                findHboards(s[0].id)
                return;
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }


    }

    async function findNiples(user_id) {

        setArrNiples([]);
        setSocioSelected(user_id);

        try {

            let response = await fetch(DEFAULT_URL + 'niples/technical', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user_id,
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrNiples(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findTrackers(user_id) {

        setArrTrackers([])

        try {

            let response = await fetch(DEFAULT_URL + 'trackers/technical', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user_id
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrTrackers(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function findChassis(user_id) {

        setArrChassis([])

        try {

            let response = await fetch(DEFAULT_URL + 'chassis/technical', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user_id
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrChassis(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function findHboards(user_id) {

        setArrHboards([])

        try {

            let response = await fetch(DEFAULT_URL + 'h_boards/technical', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user_id
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setArrHboards(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container
            ref={scrollView}
        >

            {modal &&
                <Modal
                    onClose={() => setModal(false)}
                    layout={modalTitle}
                    client_id={client.id}
                    callback={() => {

                    }}
                />}

            <Row />

            <Content>

                {socios.map(item => {

                    return (
                        <div
                            style={{ background: (item.id == socioSelected) ? "#aaa" : "#f1f1f1" }}
                            onClick={() => {
                                findNiples(item.id)
                                findTrackers(item.id)
                                findChassis(item.id)
                                findHboards(item.id)
                            }}
                        >
                            {item.name}
                        </div>
                    )

                })}

            </Content>

            <Row />

            <Totals>

                <div className='totals'>CABEÇOTES: {arrNiples.length}</div>

                <div>

                    {arrNiples.map((item, i) => {

                        var ad = (item.adjunct_id) ? item.adjunct.name : "Sem conjunto";
                        var tracker = (item.tracker_id) ? item.tracker.serial : "Sem maxtrack";

                        return (
                            <div>
                                <p>{item.id_serial}</p>
                                <div>{item.status}</div>
                                <div>Rast.: {tracker}</div>
                                <div>{ad}</div>
                                <div>{item.note}</div>
                            </div>
                        )

                    })}

                </div>

            </Totals>

            <Totals>

                <div className='totals'>ESCOTILHAS: {arrHboards.length}</div>

                <div>

                    {arrHboards.map((item, i) => {

                        return (
                            <div>
                                <p>{item.serial}</p>
                                <div>{item.lot_number}</div>
                            </div>
                        )

                    })}

                </div>

            </Totals>

            <Totals>

                <div className='totals'>CHASSIS: {arrChassis.length}</div>

                <div>

                    {arrChassis.map((item, i) => {

                        return (
                            <div>
                                <p>{item.serial}</p>
                                <div>{item.note}</div>
                            </div>
                        )

                    })}


                </div>

            </Totals>

            <Totals>

                <div className='totals'>RASTREADORES: {arrTrackers.length}</div>

                <div>

                    {arrTrackers.map((item, i) => {

                        var chip = (item.chip_id) ? item.chip.number : "";

                        return (
                            <div>
                                <p>{item.serial}</p>
                                <div>{chip}</div>
                                <div>{item.note}</div>
                            </div>
                        )

                    })}


                </div>

            </Totals>

        </Container>
    )

}

export default StockTechnical;