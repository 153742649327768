import React from 'react';
import { useSelector } from 'react-redux';
import FieldsAdjuncts from '../Fields/FieldsAdjuncts';
import FieldsBatteryBank from '../Fields/FieldsBatteryBank';
import FieldsChassi from '../Fields/FieldsChassi';
import FieldsChassiMaintenances from '../Fields/FieldsChassiMaintenances';
import FieldsChip from '../Fields/FieldsChip';
import FieldsHBoard from '../Fields/FieldsHBoard';
import FieldsMaintenance from '../Fields/FieldsMaintenance';
import FieldsMaintenanceService from '../Fields/FieldsMaintenanceService';
import FieldsMotor from '../Fields/FieldsMotor';
import FieldsNBoard from '../Fields/FieldsNBoard';
import FieldsNiple from '../Fields/FieldsNiple';
import FieldsRfid from '../Fields/FieldsRfid';
import FieldsSensorBoard from '../Fields/FieldsSensorBoard';
import FieldsTracker from '../Fields/FieldsTracker';
import GridAdjuncts from '../Grids/GridAdjuncts';
import GridBatteryBank from '../Grids/GridBatteryBank';
import GridChassi from '../Grids/GridChassi';
import GridChassiMaintenances from '../Grids/GridChassiMaintenances';
import GridChip from '../Grids/GridChip';
import GridFixedExpenses from '../Grids/GridFixedExpenses';
import GridHBoard from '../Grids/GridHBoard';
import GridMaintenance from '../Grids/GridMaintenance';
import GridMaintenanceService from '../Grids/GridMaintenanceService';
import GridMotor from '../Grids/GridMotor';
import GridNBoard from '../Grids/GridNBoard';
import GridNiple from '../Grids/GridNiple';
import GridRfid from '../Grids/GridRfid';
import GridSensorBoard from '../Grids/GridSensorBoard';
import GridTracker from '../Grids/GridTracker';
import {
    CircleActive,
    CircleInactive, Container, Fields, Grid, Info, Legend, TitleInfo
} from './style';

const Equipment = () => {

    const page = useSelector(state => state.page);

    return (
        <Container>

            <TitleInfo>{page.data}</TitleInfo>
            <Legend>
                <span><CircleActive /> <label>Ativos</label></span>
                <span><CircleInactive /> <label>Inativos</label></span>
            </Legend>

            <Info>

                <Grid>

                    {page.data === "Banco de baterias" && <GridBatteryBank />}
                    {page.data === "Chip" && <GridChip />}
                    {page.data === "Chassi" && <GridChassi />}
                    {page.data === "Manutenções de Chassi" && <GridChassiMaintenances />}
                    {page.data === "NFC" && <GridRfid />}
                    {page.data === "H.Board" && <GridHBoard />}
                    {page.data === "N.Board" && <GridNBoard />}
                    {page.data === "Rastreador" && <GridTracker />}
                    {page.data === "Cabeçote" && <GridNiple />}
                    {page.data === "Manutenções" && <GridMaintenance />}
                    {page.data === "Motores" && <GridMotor />}
                    {page.data === "Complementos" && <GridAdjuncts />}
                    {page.data === "Placa de sensores" && <GridSensorBoard />}
                    {page.data === "Serviços de manut." && <GridMaintenanceService />}
                    {page.data === "Despesas Fixas" && <GridFixedExpenses />}

                </Grid>

                <Fields>

                    {page.data === "Banco de baterias" && <FieldsBatteryBank />}
                    {page.data === "Chip" && <FieldsChip />}
                    {page.data === "Chassi" && <FieldsChassi />}
                    {page.data === "Manutenções de Chassi" && <FieldsChassiMaintenances />}
                    {page.data === "NFC" && <FieldsRfid />}
                    {page.data === "H.Board" && <FieldsHBoard />}
                    {page.data === "N.Board" && <FieldsNBoard />}
                    {page.data === "Rastreador" && <FieldsTracker />}
                    {page.data === "Cabeçote" && <FieldsNiple />}
                    {page.data === "Manutenções" && <FieldsMaintenance />}
                    {page.data === "Motores" && <FieldsMotor />}
                    {page.data === "Complementos" && <FieldsAdjuncts />}
                    {page.data === "Placa de sensores" && <FieldsSensorBoard />}
                    {page.data === "Serviços de manut." && <FieldsMaintenanceService />}

                </Fields>

            </Info>

        </Container>
    )

}

export default Equipment;
