import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    z-index: 2;
`;

export const Arrow = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
`;

export const ItemSelected = styled.div`
    height: 40px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: bold;
    color: var(--black2);
    background-color: var(--white);
    border: 1px solid #ddd;
    border-radius: 5px;
`;

export const List = styled.div`
    background-color: #eee;
    max-height: 150px;
    z-index: 1;
    overflow: scroll;
    padding: 2px;
`;

export const Item = styled.div`
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-left: 10px;
    font-weight: bold;
    border-radius: 5px;

    :hover {
        background-color: #fff;
    }
`;

