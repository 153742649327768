import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';

const ReportChip: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [responseAll, setResponseAll] = useState([])

    const [totalChips, setTotalChips] = useState(0)
    const [totalNiples, setTotalNiples] = useState([])
    const [totalVehicles, setTotalVehicles] = useState([])
    const [totalUnused, setTotalUnused] = useState([])
    const [totalInactives, setTotalInactives] = useState([])

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostUseNiple, setTotalCostUseNiple] = useState(0)
    const [totalCostUseVehicle, setTotalCostUseVehicle] = useState(0)
    const [totalCostUnused, setTotalCostUnused] = useState(0)


    useEffect(() => {
        findDetails()
    }, [])

    useEffect(() => {

        var totalCost = 0;
        var totalCostUseNiple = 0;
        var totalCostUseVehicle = 0;
        var totalCostUnused = 0;

        var totalChips = 0;
        var totalNiples = [];
        var totalVehicles = [];
        var totalUnused = [];
        var totalInactive = [];

        responseAll.map(chip => {

            console.log(chip)

            if (search == "" || chip?.serial?.includes(search) || chip.number.includes(search) || chip.iccid.includes(search)) {

                if (chip.active) {

                    totalChips += 1;
                    totalCost = parseFloat(totalCost) + parseFloat(chip.cost);
                    if (chip.serial) {
                        if (chip.allow_niple) {
                            totalCostUseNiple = parseFloat(totalCostUseNiple) + parseFloat(chip.cost);
                            totalNiples.push(chip);
                        }
                        else {
                            totalCostUseVehicle = parseFloat(totalCostUseVehicle) + parseFloat(chip.cost);
                            totalVehicles.push(chip);
                        }
                    }
                    else {
                        totalCostUnused = parseFloat(totalCostUnused) + parseFloat(chip.cost);
                        totalUnused.push(chip);
                    }
                }
                else {
                    totalInactive.push(chip);
                }

            }
        })

        setTotalCost(totalCost)
        setTotalCostUseVehicle(totalCostUseVehicle)
        setTotalCostUseNiple(totalCostUseNiple)
        setTotalCostUnused(totalCostUnused)

        setTotalChips(totalChips)
        setTotalNiples(totalNiples)
        setTotalVehicles(totalVehicles)
        setTotalUnused(totalUnused)
        setTotalInactives(totalInactive)


    }, [search, responseAll])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'chips/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setResponseAll(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            {/* <Legend>
                <span><CircleActive /> <label>Ativos</label></span>
                <span><CircleInactive /> <label>Inativos</label></span>
            </Legend> */}

            <Info>

                <Totals><strong>Chips ativos</strong> <label>Total: {totalChips}</label> <span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Rastreador Niple</strong> <label>Total: {totalNiples.length}</label> <span>R$ {totalCostUseNiple.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Rastreador Veículo</strong> <label>Total: {totalVehicles.length}</label> <span>R$ {totalCostUseVehicle.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnused.length}</label> <span style={{ color: 'red' }}>R$ {totalCostUnused.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativos</strong> <label>Total: {totalInactives.length}</label> <span>R$ 0,00</span></Totals>

                <List>

                    <strong>Chips: Rastreador Niple</strong>

                    <div>

                        <div style={{ background: '#eee' }}>
                            <strong>Rastreador</strong>
                            <strong>Número</strong>
                            <strong>ICCID</strong>
                            <label>Custo</label>
                        </div>

                        {totalNiples.map((chip, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chip.serial}</span>
                                    <span>{chip.number}</span>
                                    <span>{chip.iccid}</span>
                                    <label>R$ {parseFloat(chip.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Chips: Rastreador Veículo</strong>
                    <div>

                        <div style={{ background: '#eee' }}>
                            <strong>Rastreador</strong>
                            <strong>Número</strong>
                            <strong>ICCID</strong>
                            <label>Custo</label>
                        </div>

                        {totalVehicles.map((chip, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chip.serial}</span>
                                    <span>{chip.number}</span>
                                    <span>{chip.iccid}</span>
                                    <label>R$ {parseFloat(chip.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Chips: Disponíveis</strong>
                    <div>

                        <div style={{ background: '#eee' }}>
                            <strong>Número</strong>
                            <strong>ICCID</strong>
                            <label>Custo</label>
                        </div>

                        {totalUnused.map((chip, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chip.number}</span>
                                    <span>{chip.iccid}</span>
                                    <label>R$ {parseFloat(chip.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>

                    <strong>Chips: Inativos</strong>

                    <div>

                        <div style={{ background: '#eee' }}>
                            <strong>Número</strong>
                            <strong>ICCID</strong>
                            <strong>Observação</strong>
                            {/* <label>Custo</label> */}
                        </div>

                        {totalInactives.map((chip, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{chip.number}</span>
                                    <span>{chip.iccid}</span>
                                    {/* <label>R$ {parseFloat(chip.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label> */}
                                    <span>{chip.note}</span>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>



        </Container>
    )

}

export default ReportChip;