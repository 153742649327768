import styled from 'styled-components'

export const Container = styled.div`
    
    display:flex;
    flex-direction: column;
    width: min(501px, 100%);
    height: 100vh; 

    > form {

        position: absolute;
        height: auto;
        width: min(501px, 97%);
        border-radius: 5px;
        background: var(--secondary);
        top: calc(100vh / 2 - (min(300px, 100%) / 2));
        padding: 30px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

    @media(max-width: 501px) {
        margin-left: 5px;
        padding: 10px;
    }
    }
`;

export const Auth = styled.div`
    
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary);
    height: 40px;
    margin-bottom: 30px;

    @media(max-width: 301px) {
        height: 25px;
        justify-content: center;
    }

    >strong {
        font-size: 25px;
        font-weight: bold;
        color: var(--black);
        @media(max-width: 301px) {
            font-size: 15px;
        }
    }

    >span {
        @media(max-width: 301px) {
            display: none;
        }
    }
`;

export const InfoMessage = styled.div`
    margin-bottom: 10px;
    margin-left: 5px;
    color: var(--like);
`;

export const TextInput = styled.input`
    height: 40px;
    width: 100%;
    background: var(--primary);
    color: var(--black);
    border: 1px solid #eeeeee;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    border-radius: 3px;
    margin-bottom: 30px;

    :focus {
        border: 2px solid var(--details)
    }
`;

export const ButtonSend = styled.button`
    background: var(--details);
    width: 100%;
    height: 40px;
    color: var(--secondary);
    border-radius: 5px;
    font-size: 18px;
    justify-content: center;
`;
