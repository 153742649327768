import styled from 'styled-components'


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const Details = styled.div`
    width: 100%;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const Serial = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    overflow-y: hidden;
`;

export const LotNumber = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    overflow-y: hidden;
`;

export const Note = styled.div`
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    overflow-y: hidden;
`;

export const Active = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
`;