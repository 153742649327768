import { Font, Image, Text } from '@react-pdf/renderer';
import Moment from 'moment/min/moment-with-locales';
import React from 'react';
import PDFStyle from '../../../styles/PDFStyle';
import font from '../../../assets/fonts/ARIAL.TTF';
import logo from '../../../assets/images/logo.png';


Font.register({
    family: 'Arial',
    format: 'truetype',
    src: font
});

const Header: React.FC = ({ title, user }) => {

    return (

        <div style={PDFStyle.header}>

            <div style={PDFStyle.infoHeader}>
                <Text style={{ fontSize: 16, marginTop: 0, marginBottom: 15, fontWeight: "bold" }}>C L O C T</Text>
                <Text style={{ fontSize: 11, marginBottom: 8 }}>Relatório: {title}</Text>
                <Text style={{ fontSize: 11, marginBottom: 8 }}>Emitido por: {user}</Text>
                <Text style={{ fontSize: 11, marginBottom: 8 }}>Data da emissão: {Moment().format("DD/MM/YYYY HH:MM:SS")}</Text>
            </div>

            <Image style={{ position: 'absolute', right: 5, top: -20, width: 110, height: 110 }} source={logo} />

        </div>
    )
}

export default Header;