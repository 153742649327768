import React, { useState } from 'react'

import {
    Container,
    ItemSelected,
    List,
    Item,
    Arrow
} from './style';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const DropDown = ({ width, item, array, onChange }) => {

    const [visible, setVisible] = useState(false)

    return (
        <Container style={{ width: width }}>

            <ItemSelected onClick={() => setVisible(!visible)}>{item}</ItemSelected>
            <Arrow>
                <FontAwesomeIcon onClick={() => setVisible(!visible)} icon={(!visible) ? faArrowDown : faArrowUp} />
            </Arrow>

            {visible && <List>
                {array.map(item => {
                    return (<Item onClick={() => { onChange(item); setVisible(!visible) }}>{item.name}</Item>)
                })}
            </List>}

        </Container>
    )

}

export default DropDown;