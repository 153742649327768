import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
  Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import { FieldHorizontal } from '../FieldsRevenues/style';
import {
  Container
} from './style';


const FieldsSaas: React.FC = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const objSelected = useSelector(state => state.objSelected)

  const arrTypeSaas = useSelector(state => state.typeSaas)

  const [id, setId] = useState(0)
  const [contract_id, setContractId] = useState(0)
  const [contract_name, setContractName] = useState("")
  const [type_saas_id, setTypeSaasId] = useState(0)
  const [identifier, setIdentifier] = useState("")
  const [installation_date, setInstallationDate] = useState("")
  const [uninstallation_date, setUninstallationDate] = useState("")
  const [cost, setCost] = useState(0)
  const [note, setNote] = useState("")
  const [active, setActive] = useState(true)

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")

  function handlerSwitch() {
    setActive(!active)
  }

  useEffect(() => {

    setTextAlert("")

    if (arrTypeSaas.length === 0) {
      findTypeSaas()
    }

    if (Object.keys(objSelected).length > 0) {
      setId(objSelected.id)

      if (objSelected.contract) {
        setContractId(objSelected.contract.id)
        setContractName(objSelected.contract.name)
      }
      else {
        setContractId(0)
        setContractName("")
      }

      setTypeSaasId(objSelected.type_saas_id)
      setIdentifier(objSelected.identifier)
      setInstallationDate(objSelected.installation_date)
      setUninstallationDate(objSelected.uninstallation_date)
      setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })

      setNote(objSelected.note)
      setActive(objSelected.active)
      setTextDesc("Atualizar")
    }
    else {
      setId(0)
      setContractId(0)
      setContractName("")
      setTypeSaasId(0)
      setIdentifier("")
      setInstallationDate("")
      setUninstallationDate("")
      setCost({ formattedValue: "0,00", value: 0, floatValue: 0 })
      setNote("")
      setActive(true)
      setTextDesc("Adicionar")
    }

  }, [objSelected])

  async function findTypeSaas() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_saas', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_SAAS", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function saveData() {

    if (!registerPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para cadastros")
      return
    }

    if (contract_id === 0) {
      setTextAlert("Selecione um Contrato")
      return;
    }

    if (type_saas_id === 0) {
      setTextAlert("Selecione um Tipo de Saas")
      return;
    }

    if (identifier.trim() === "") {
      setTextAlert("Informe o campo Identificação")
      return
    }

    if (installation_date.trim() === "") {
      setTextAlert("Informe o campo Data da Instalação")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'saas' : `saas/${id}/update`

    try {

      let response = await fetch(DEFAULT_URL + url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          contract_id: contract_id,
          type_saas_id: type_saas_id,
          identifier: identifier,
          installation_date: installation_date,
          uninstallation_date: uninstallation_date,
          cost: cost.floatValue,
          note: note,
          active: active
        })
      });

      let responseJson = await response.json();
      // console.log(responseJson)

      setTextButton("Salvar")

      if (responseJson.success) {
        dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

      setTextAlert("Erro ao efetuar operação.")

    } catch (error) {
      console.error(error);
    }

  }

  return (
    <Container>

      <Title>{textDesc}</Title>

      <AutoComplete
        label={"Contrato"}
        finder={"contracts"}
        item={{ id: contract_id, name: contract_name }}
        setSelected={(item) => {
          setContractId(item.id)
          setContractName(item.search)
        }}
      />

      <FieldHorizontal>
        <label>Tipo de Saas.:</label>
        <CheckBox>
          {arrTypeSaas.map((e, i) => {

            return (
              <Check
                key={e.id}
                onClick={() => setTypeSaasId(e.id)}
                className={(type_saas_id === e.id) ? 'selected' : ''}>
                {e.name}
              </Check>)

          })}
        </CheckBox>
      </FieldHorizontal>

      <Field>
        <label>Identificador:</label>
        <input
          type="text"
          placeholder="Identificador"
          value={identifier}
          onChange={event => setIdentifier(event.target.value)} />
      </Field>

      <Field>
        <label>Custo:</label>
        <NumberFormat
          value={cost.formattedValue}
          thousandSeparator={"."}
          prefix={'R$ '}
          decimalScale={2}
          placeholder={"R$"}
          decimalSeparator={","}
          fixedDecimalScale={true}
          allowNegative={false}
          allowedDecimalSeparators={["."]}
          onValueChange={(values) => {
            setCost(values)
          }} />
      </Field>

      <Field>
        <label>Data Instalação:</label>
        <InputMask
          mask="99/99/9999"
          type="text"
          placeholder="Data Instalação"
          value={installation_date}
          onChange={event => setInstallationDate(event.target.value)} />
      </Field>

      <Field>
        <label>Data Desinstalação:</label>
        <InputMask
          mask="99/99/9999"
          type="text"
          placeholder="Data Desinstalação"
          value={uninstallation_date}
          onChange={event => setUninstallationDate(event.target.value)} />

      </Field>

      <Field>
        <label>Observação:</label>
        <textarea
          type="text"
          placeholder="Adicione uma observação"
          value={note}
          onChange={event => setNote(event.target.value)}
        />
      </Field>


      <Select>
        <label>Status:</label>
        <Switch onChange={() => handlerSwitch()} checked={active} />
      </Select>

      <ViewSubmit>

        {textAlert !== "" &&
          <Alert>
            {textAlert}
          </Alert>}
        {textAlert === "" && <div />}

        <Button onClick={() => saveData()}>
          {textButton}
        </Button>

      </ViewSubmit>


    </Container>
  )

}

export default FieldsSaas;
