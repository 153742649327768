import { faBroom, faCalendarTimes, faSearch, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_USER_STRUCT } from '../../helper';
import {
    Alerts,
    Container, ProfileInfo, Search
} from './style';



var timeout = null;

const SearchBar: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const page = useSelector(state => state.page)
    const toSchedule = useSelector(state => state.toSchedule)
    const bugs = useSelector(state => state.bugs)

    const [thisSearch, setThisSearch] = useState("")
    const [icon, setIcon] = useState(faSearch)

    function clearCrud() {
        dispatch({ type: "SET_PAGE", data: { name: "DASHBOARD", data: "" } })
        dispatch({ type: "SET_SEARCH", data: "" })
        dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    }

    useEffect(() => {
        setThisSearch(search)
        if (search === "") {
            setIcon(faSearch)
        }
    }, [search])


    function handlerChange(text) {

        setThisSearch(text)
        setIcon(faBroom)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {

            if (page.name != "DASHBOARD") {
                dispatch({ type: "SET_SEARCH", data: text })
                dispatch({ type: "SET_OBJ_SELECTED", data: {} })
            }
            return

        }, 100);

    }

    function handlerLogout() {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
    }

    return (
        <Container>

            <Search>

                <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "SET_SEARCH", data: "" })}>
                    <FontAwesomeIcon icon={icon} style={{ width: 20, height: 20 }} />
                </span>

                <input
                    type="text"
                    placeholder="Buscar"
                    value={thisSearch}
                    onChange={event => handlerChange(event.target.value)}
                />

                {page.name != "DASHBOARD" && <div>
                    <span onClick={() => clearCrud()}>x</span>
                    <label>{page.data}</label>
                </div>}

            </Search>

            <Alerts onClick={() => {
                dispatch({ type: "SET_SEARCH", data: "" })
                dispatch({ type: "SET_OBJ_SELECTED", data: {} })
                dispatch({ type: "SET_PAGE", data: { name: "MAINTENANCE", data: "Timeline" } })
            }}>
                {toSchedule.length > 0 && <div className='notf'>{toSchedule.length}</div>}
                <FontAwesomeIcon icon={faCalendarTimes} style={{ width: 20, height: 20 }} />
            </Alerts>

            {/* <Alerts>
                {bugs.length > 0 && <div className='notf'>{bugs.length}</div>}
                <FontAwesomeIcon icon={faBug} style={{ width: 20, height: 20 }} />
            </Alerts> */}


            {user.name &&
                <ProfileInfo>
                    <strong>{user.name.substr(0, 15)}</strong>
                    <span style={{ cursor: 'pointer' }} onClick={() => handlerLogout()}>
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ color: '#222', width: 25, height: 25 }} />
                    </span>
                </ProfileInfo>
            }



        </Container>
    )

}

export default SearchBar;