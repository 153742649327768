import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field,
    Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Container
} from './style';


const YIELD_TYPES = [
    "CDB",
    "CDI",
    "LCA",
    "LCI",
    "FUNDO DE INVESTIMENTOS",
    "PREVIDENCIA"
];

const FieldsTypeInvestments = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)
    const client = useSelector(state => state.client)

    const [id, setId] = useState(0)
    const [name, setName] = useState("")
    const [yieldType, setYieldType] = useState("")
    const [percent, setPercent] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })


    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)
            setName(objSelected.name)
            setYieldType(objSelected.yield)
            setPercent({ formattedValue: objSelected.percent, value: objSelected.percent, floatValue: objSelected.percent })
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setName("")
            setYieldType("CDB")
            setPercent({ formattedValue: "", value: 0, floatValue: 0 });
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (name.trim() === "") {
            setTextAlert("Informe o campo Nome")
            return
        }

        if (percent.formattedValue === "") {
            setTextAlert("Informe o percentual")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'type_investments' : `type_investments/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client.id,
                    name: name,
                    yield: yieldType,
                    percent: percent.value
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_name') {
                setTextAlert("Nome já existente")
                dispatch({ type: "SET_SEARCH", data: name })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Nome:</label>

                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />

            </Field>

            <Field>

                <label>Tipo:</label>
                <CheckBox>
                    {YIELD_TYPES.map((name, i) => {
                        return (
                            <Check
                                key={name}
                                onClick={() => setYieldType(name)}
                                className={(yieldType === name) ? 'selected' : ''}>
                                {name}
                            </Check>)

                    })}

                </CheckBox>

            </Field>

            <Field>
                <label>Percentual de rendimento:</label>
                <NumberFormat
                    value={percent.formattedValue}
                    thousandSeparator={"."}
                    prefix={"% "}
                    decimalScale={2}
                    placeholder={"%"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => setPercent(values)}
                />
            </Field>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsTypeInvestments;