import styled, { css } from 'styled-components'

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
`;

export const Row = styled.div`
    height: 2px;
    margin: 0px 30px 10px 30px;
    background: #fff;
`;

export const Row1 = styled.div`
    height: 1px;
    margin: 4px 0px 6px 0px;
    background: #f1f1f1;
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-family: 'GothamProBold';
    margin: 0 0 10px 35px;
    letter-spacing: 1px;

    > span {
        font-family: 'GothamProBold';
    }

    .red {
        color: red;
    }

    .green {
        color: var(--details);
    }
`;

export const GraphsInfo = styled.div`
    
    margin: 0px 20px 30px 20px;
    padding: 10px 20px 0 0;
    display: flex;
    flex-direction: row;
    background-color: #EEE;
    border-radius: 10px;

    > .graph {
        width: 55vw;
    }

    > .totals {
        width: 35vw;
        padding: 10px 20px;
        max-height: 400px;
        overflow: scroll;

        > .total-12 {
            color: #888;
            font-weight: bold;
            font-size: 13px;
            height: 20px;
        }

        > .total-res-12 {
            color: #000;
            font-size: 18px;
            height: 40px;
        }

        > .info-res {
            display: flex;
            flex-direction: row;
            height: 30px;
            border: 1px solid #fff;
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 0;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            > p {
                font-weight: bold;
                color: #555;
                font-size: 10px;
                width: 30%;
            }

            > span {
                font-size: 10px;
                font-weight: bold;
                text-align: right;
                width: 30%;
            }

            > div > .span_percent {
                font-size: 9px;
            }

            > .green {
                color: var(--details);
            }

            >.red {
                color: red;
            }
        }
    }
`;

export const GraphsHalf = styled.div`
    height: 100px;
    margin: 10px 0 10px 0px;
    align-items: center;
    display: flex;
    flex-direction: column;
    > p {
        color: #555;
        text-align: center;
    }

    > span {
        font-size: 60px;
        text-align: center;
        font-weight: bold;
        width: 100%;
    }

`;

export const LastInstallInfo = styled.div`
    margin: -20px 0px 20px 0px;
    padding: 0 20px;
    border-radius: 10px;
    
    justify-content: space-evenly;

    > div {

        font-size: 25px;
        font-weight: bold;
        padding: 10px;
        /* background-color: #ffffff; */
        background-color: #f1f1f1;
        border-radius: 10px;
        margin: 10px 0 10px 0;
    }
`;

export const Legend1 = styled.div`
    margin: 0 0 0 35px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {
        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 12px;
        justify-content: space-between;

        > label {
            font-family: 'GothamProRegular';
            margin-top: 0px;
            margin-left: 5px;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
`;

export const Total = styled.div`
    width: 100%;
    height: auto;
    padding: 0 30px;

    > div {
        background: var(--primary);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow: scroll;
    }
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 0px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--details);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const FinancialInfos = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FinancialCards = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
`;

export const FinancialGraph = styled.div`
    padding-top: 40px;
`;

const cardView = css`

    background: #fff;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5px 15px 0px 15px;
    display: flex;
    flex-direction: column;
    margin: 5px 20px 20px 5px;
    min-width: 190px;

    > strong {
        font-family: 'GothamProBold';
        font-size: 11px;
        padding: 5px 0 5px 0;
        text-align: center;
        color: #333;
        border-bottom: 1px solid #333;
    }

`;

export const FundoCardView = styled.div`
    ${cardView}
    > span {
        font-size: 14px;
        height: 20px;
        display: flex;
        padding-top: 7px;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'GothamProRegular';
        outline: 0;
    }
`;

export const BottomMenu = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: var(--black);
    width: 100%;
    border-top: 2px solid var(--outline);
    display: flex;
    justify-content: space-between;
    padding: 8px min(46px, max(10vw, 10px));

    @media(min-width: 725px) {
        display: none;
    }

`;

const iconCss = css`
    flex-shrink: 0;
    width: 31px;
    height: 31px;
    cursor: pointer;
    color: var(--gray);

    &:hover,&.active {
        color: var(--details);
    }
`;

export const Register = styled.div`
    ${iconCss}
`;
export const Commands = styled.div`
    ${iconCss}
`;
export const Reports = styled.div`
    ${iconCss}
`;


