import { faPlusSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, HorizontalField, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Container
} from './style';


const FieldsAdjuncts: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [name, setName] = useState("")
    const [arrayComponents, setArrayComponents] = useState([])
    const [active, setActive] = useState(true)

    const [nameAdd, setNameAdd] = useState("")
    const [costAdd, setCostAdd] = useState(0)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {

            setId(objSelected.id)
            setName(objSelected.name)
            setActive(objSelected.active)
            setTextDesc("Atualizar")
            if (objSelected.id)
                getComponents(objSelected.id)

        }
        else {
            setId(0)
            setName("")
            setArrayComponents([])
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    function addComponent() {

        if (nameAdd.trim() === "") {
            setTextAlert("Informe o nome do componente.")
            return
        }

        if (arrayComponents.length === 0) {
            setArrayComponents([{ name: nameAdd, cost: costAdd }])
        } else {
            setArrayComponents([...arrayComponents, { name: nameAdd, cost: costAdd }])
        }

        setNameAdd("")
        setCostAdd(0)
    }

    function removeComponent(item) {
        var aux = arrayComponents;
        const index = aux.indexOf(item);
        if (index > -1) aux.splice(index, 1);
        setArrayComponents([...aux])
    }

    async function getComponents(id) {

        try {

            let response = await fetch(DEFAULT_URL + `components/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayComponents(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (name.trim() === "") {
            setTextAlert("Informe o campo Nome")
            return
        }

        if (active) {
            if (arrayComponents.length === 0) {
                setTextAlert("Informe pelo menos um Componente")
                return
            }
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'adjuncts' : `adjuncts/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    name: name,
                    components: arrayComponents,
                    active: active
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                setArrayComponents([])
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Nome:</label>

                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />

            </Field>

            <Title>Componentes</Title>

            {arrayComponents.map(item => {

                return (
                    <HorizontalField>

                        <Field style={{ marginRight: 15 }}>
                            <label>Nome:</label>

                            <input
                                type="text"
                                readOnly
                                style={{ background: "#f1f1f1" }}
                                value={item.name} />

                        </Field>

                        <Field style={{ marginRight: 15 }}>
                            <label>Custo:</label>

                            <input
                                type="text"
                                readOnly
                                style={{ background: "#f1f1f1" }}
                                value={"R$ " + parseFloat(item.cost).toFixed(2)} />

                        </Field>

                        <FontAwesomeIcon onClick={() => removeComponent(item)} icon={faTrashAlt} style={{ width: 20, height: 20, marginTop: 30, cursor: 'pointer' }} />

                    </HorizontalField>
                )

            })}

            <HorizontalField>

                <Field style={{ marginRight: 15 }}>
                    <label>Nome:</label>

                    <input
                        type="text"
                        placeholder="Nome"
                        value={nameAdd}
                        onChange={event => setNameAdd(event.target.value)} />

                </Field>

                <Field style={{ marginRight: 15 }}>
                    <label>Custo:</label>

                    <input
                        type="text"
                        placeholder="Custo"
                        value={costAdd}
                        onChange={event => setCostAdd(event.target.value)} />

                </Field>

                <FontAwesomeIcon onClick={() => addComponent()} icon={faPlusSquare} style={{ width: 20, height: 20, marginTop: 30, cursor: 'pointer' }} />

            </HorizontalField>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsAdjuncts;