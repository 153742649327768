
import React from 'react';
import { useSelector } from 'react-redux';
import FieldsSuspicions from './Fields';
import GridSuspicions from './Grid';
import {
  CircleActive,
  CircleInactive, Container, Fields, Grid, Info, Legend, TitleInfo
} from './style';


const MaintenanceSuspicions: React.FC = () => {

  const page = useSelector(state => state.page);

  return (
    <Container>

      <TitleInfo>{page.data}</TitleInfo>
      <Legend>
        <span><CircleActive /> <label>Ativos</label></span>
        <span><CircleInactive /> <label>Inativos</label></span>
      </Legend>

      <Info>

        <Grid>

          <GridSuspicions />

        </Grid>

        <Fields>

          <FieldsSuspicions />

        </Fields>

      </Info>

    </Container>
  )

}

export default MaintenanceSuspicions;