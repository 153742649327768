export function getItem(parsed, item, defaultValue = undefined) {
  try {
    let itemAux = item.split(".");
    let dataAux = parsed;
    itemAux.map(it => dataAux = dataAux[it]);
    if (typeof dataAux !== "undefined" && dataAux !== null && dataAux !== "") return JSON.parse(JSON.stringify({ value: dataAux })).value;
    return defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

export function sortReason(reasons) {
  return JSON.parse(JSON.stringify(reasons)).sort(function (a, b) {
    const aDate = JSON.parse(JSON.stringify({ date: a.date })).date;
    const bDate = JSON.parse(JSON.stringify({ date: b.date })).date;
    const aPriority = a.priority === "CRI" ? 1 : 2;
    const bPriority = b.priority === "CRI" ? 1 : 2;
    if(bDate.localeCompare(aDate) === 0) return (aPriority < bPriority) ? -1 : (aPriority > bPriority) ? 1 : 0;
    else return bDate.localeCompare(aDate);
  });
}
