import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';


const ReportMaintenanceAll: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [responseAll, setResponseAll] = useState([])

    const [totalItem, setTotalItem] = useState(0)
    const [totalRotine, setTotalRotine] = useState([])
    const [totalErros, setTotalErros] = useState([])

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostRotine, setTotalCostRotine] = useState(0)
    const [totalCostErros, setTotalCostErros] = useState(0)

    const [maintenances, setMaintenances] = useState([])
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        findDetails()
    }, [])


    useEffect(() => {

        var totalItem = 0;
        var totalRotine = [];
        var totalErros = [];

        var totalCost = 0;
        var totalCostRotine = 0;
        var totalCostErros = 0;

        var aux = [];
        responseAll.map(item => {

            if (search == "" || item.vehicle.contract.renter.name.toLowerCase().includes(search.toLowerCase())) {

                totalItem += 1;
                totalCost = totalCost + parseFloat(item.cost);

                if (item.routine) {
                    totalRotine.push(item);
                    totalCostRotine = totalCostRotine + parseFloat(item.cost);
                }
                else {
                    totalErros.push(item);
                    totalCostErros = totalCostErros + parseFloat(item.cost);
                }

                if (typeof aux[item.vehicle.contract.renter.id] === 'undefined') {
                    aux[item.vehicle.contract.renter.id] = [item]
                }
                else {
                    aux[item.vehicle.contract.renter.id].push(item)
                }
            }
        })

        setMaintenances(aux)

        setTotalItem(totalItem)
        setTotalRotine(totalRotine)
        setTotalErros(totalErros)

        setTotalCost(totalCost)
        setTotalCostRotine(totalCostRotine)
        setTotalCostErros(totalCostErros)


    }, [search, responseAll])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenances/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();



            if (responseJson.success) {

                var dataAux = responseJson.data.sort(function (a, b) {
                    return new Moment(b.date, 'DD/MM/YYYY') - Moment(a.date, 'DD/MM/YYYY');
                });

                setResponseAll(dataAux)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>Manutenções</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Manutenções: Rotina</strong> <label>Total: {totalRotine.length}</label><span>R$ {totalCostRotine.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Manutenções: Não programadas</strong> <label>Total: {totalErros.length}</label><span style={{ color: 'red' }}>R$ {totalCostErros.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>

                <Totals><span></span></Totals>

                {maintenances.map((item, name) => {

                    var totalGeral = 0;

                    return (

                        <List>
                            <strong>{item[0].vehicle.contract.renter.name} </strong>

                            <div>

                                <div style={{ background: "#eee" }}>
                                    <strong>Veículo</strong>
                                    <strong>Data</strong>
                                    <strong>Serviço</strong>
                                    <strong>Detalhes</strong>
                                    <strong>Rotina</strong>
                                    <label>Custo</label>
                                </div>

                                {item.map((it, i) => {

                                    totalGeral = totalGeral + parseFloat(it.cost);

                                    return (
                                        <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                            <span>{it.vehicle.identifier}</span>
                                            <span>{it.date}</span>
                                            <span>{it.service_rendered}</span>
                                            <span>{it.note}</span>
                                            <span>{it.routine ? "ON" : "OFF"}</span>
                                            <label style={{ color: 'red' }}>R$ {it.cost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                        </div>
                                    )
                                })}

                                <div style={{ background: "#ccc" }}>
                                    <strong>Total</strong>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <label style={{ color: 'red' }}>R$ {parseFloat(totalGeral).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>

                            </div>

                        </List>
                    )
                })}


            </Info>

        </Container>
    )

}

export default ReportMaintenanceAll;