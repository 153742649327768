import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: #f7f9fc; 
    padding: 10px 0;
`;


export const HeaderBar = styled.div`
    width: 100%;
    height: 40px;
    padding: 0 25px;
    
    align-items: center;
    display: flex;
    background: #fff;
    /* background: #f7f9fc; */

    > div {
        width: 80px;
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #D1D1D1;
        font-weight: bold;
        font-size: 15px;
        border:3px solid #fff;
        height: 37px;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        cursor: pointer;
    }
`;
