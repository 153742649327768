import React from 'react';
import { useDispatch } from 'react-redux';
import FieldsCreditCard from '../Fields/FieldsCreditCard';
import FieldsExpense from '../Fields/FieldsExpense';
import FieldsFinancialRequest from '../Fields/FieldsFinancialRequest';
import FieldsInvestments from '../Fields/FieldsInvestments';
import FieldsRevenues from '../Fields/FieldsRevenues';
import FieldsSubcomponent from '../Fields/FieldsSubcomponent';
import CreditCard from '../Financial/CreditCard';
import GraphExpenses from '../GraphExpenses';
import GraphExpensesGroup from '../GraphExpensesGroup';
import GraphRevenues from '../GraphRevenues';
import GraphRevenuesGroup from '../GraphRevenuesGroup';
import InfoMaintenance from '../InfoMaintenance';
import NewMaintenance from '../Maintenances/Timeline/NewMaintenance';
import PDFAnalizeExpenses from '../PDF/PDFAnalizeExpenses';
import PDFAnalizeExpensesGroup from '../PDF/PDFAnalizeExpensesGroup';
import PDFAnalizeRevenue from '../PDF/PDFAnalizeRevenue';
import PDFAnalizeRevenuesGroup from '../PDF/PDFAnalizeRevenuesGroup';
import PDFExpenses from '../PDF/PDFExpenese';
import PDFExpensesGroup from '../PDF/PDFExpensesGroup';
import PDFExtract from '../PDF/PDFExtract';
import PDFInvolice from '../PDF/PDFInvolice';
import PDFRevenues from '../PDF/PDFRevenues';
import PDFRevenuesGroup from '../PDF/PDFRevenuesGroup';
import PDFTicket from '../PDF/PDFTicket';
import PDFRenterTicket from '../PDF/PDFRenterTicket';
import {
    Container
} from './style';



const Modal: React.FC = ({ onClose, layout, client_id, callback, monthSelected, yearSelected, ...otherProps }) => {

    const dispatch = useDispatch()

    function closeModal() {
        dispatch({ type: "SET_OBJ_SELECTED", data: {} });
        dispatch({ type: "SET_DARK", data: false })
        onClose();
    }

    return (
        <Container>

            <span onClick={() => closeModal()}>X</span>

            {layout === "CONTAS: PAGAR" && <FieldsExpense
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }}
                monthSelected={monthSelected}
                yearSelected={yearSelected}
            />}

            {layout === "CONTAS: RECEBER" && <FieldsRevenues
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }}
                monthSelected={monthSelected}
                yearSelected={yearSelected}
            />}

            {layout === "CONTAS: PDF_RECEBER" && <PDFRevenues />}
            {layout === "CONTAS: GRAPH_RECEBER" && <GraphRevenues />}

            {layout === "CONTAS: PDF_PAGAR" && <PDFExpenses />}
            {layout === "CONTAS: GRAPH_PAGAR" && <GraphExpenses />}

            {layout === "CONTAS: EXPENSES_CARD" && <CreditCard
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }}
            />}

            {layout === "CONTAS: CREDIT_CARD" && <FieldsCreditCard
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }}
            />}

            {layout === "CONTAS: PDF_EXTRACK" && <PDFExtract />}

            {layout === "CONTAS: PDF_RECEITAS_GRUPO" && <PDFRevenuesGroup />}
            {layout === "CONTAS: GRAPH_RECEBER_GRUPO" && <GraphRevenuesGroup />}

            {layout === "CONTAS: PDF_DESPESAS_GRUPO" && <PDFExpensesGroup />}
            {layout === "CONTAS: GRAPH_DESPESAS_GRUPO" && <GraphExpensesGroup />}

            {layout === "CONTAS: PDF_TICKET" && <PDFTicket />}
            {layout === "CONTAS: PDF_INVOICE" && <PDFInvolice />}

            {layout === "RECEITAS: BRUTO - LIQUIDO" && <PDFAnalizeRevenue />}
            {layout === "DESPESAS: BRUTO - LIQUIDO" && <PDFAnalizeExpenses />}

            {layout.includes("GRUPO DE RECEITA") && <PDFAnalizeRevenuesGroup />}
            {layout.includes("GRUPO DE DESPESA") && <PDFAnalizeExpensesGroup />}

            {layout === "ADICIONAR SUBCOMPONENTE" && <FieldsSubcomponent callback={() => {
                callback()
                closeModal()
            }} />}

            {layout === "MANUTENÇÃO" && <InfoMaintenance callback={() => {
                callback()
                closeModal()
            }} />}

            {layout === "NOVA MANUTENÇÃO" && <NewMaintenance callback={() => {
                callback()
                closeModal()
            }} />}

            {layout === "INVESTIMENTOS: APORTE" && <FieldsInvestments
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }} />}

            {layout === "INVESTIMENTOS: RENDIMENTO" && <FieldsInvestments
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }} />}

            {layout === "INVESTIMENTOS: RETIRADA" && <FieldsInvestments
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }} />}

            {layout === "SOLICITAR DESPESA" && <FieldsFinancialRequest
                client_id={client_id}
                callback={() => {
                    callback()
                    closeModal()
                }} />}

            {layout === "IMPRIMIR ETIQUETAS" && <PDFRenterTicket {...otherProps} />}

        </Container>
    )

}

export default Modal;
