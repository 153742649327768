import styled, { css } from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--primary);
    padding: 0px 0px 15px 0px;
`;

export const Legend = styled.div`
    margin: 0 0 0 25px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 25px;
    letter-spacing: 1px;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media(max-width: 1280px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

`;

export const VerticalRow = styled.div`
    width: 0px;
    height: calc(100vh - 200px);
    background: var(--newGray);

    @media(max-width: 1280px) {
        width: 100%;
        height: 4px;
    }
`;

export const Grid = styled.div`
    width: 60%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    border: 1px solid #ddd;

    @media(max-width: 1280px) {
        width: 100%;
        height: calc(50vh);
    }

    .selected {
        border: 1px solid rgba(232, 38, 83, 0.3);
        border-left: none;
        border-right: none;
        background-color: rgba(232, 38, 83, 0.1) !important;
    }

`;

export const Fields = styled.div`
    width: 37%; 
    height: calc(100vh - 200px);
    overflow-x: hidden;

    @media(max-width: 1280px) {
        width: 100%;
        height: calc(50vh);
    }

`;

