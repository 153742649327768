import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    setRemittanceApi: ["remittanceApi"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  remittanceApi: false
};

const setRemittanceApi = (state = INITIAL_STATE, action) => {
    const { remittanceApi } = action;
  return {
    ...state,
    remittanceApi
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_REMITTANCE_API]: setRemittanceApi
});