import { faBoxes, faCashRegister, faClipboardList, faCogs, faFileInvoiceDollar, faListAlt, faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react/cjs/react.development';
import teste from '../../assets/images/teste.png';
import loaderlotie from '../../assets/lotties/loader.json';
import { creditCardPermission, financialPermission } from '../../helper';
import {
  Commands, Container, Logo,
  MenuButton, PDFs, Register, Reports, SubMenuButton, TopSide
} from './style';


const MENU_REGISTER = [
  "Acessórios",
  "Contratos",
  "Despesas Fixas",
  "Fornecedores",
  "Locatários",
  "Software (Saas)",
  "Tipo de Despesa",
  "Tipo de Investimento",
  "Tipo de Pagamento",
  "Tipo de Receita",
  "Tipo de Saas",
  "Veículos"
]

const MENU_PDF = [
  "Patrocínio publicitário",
  "Termo publicitário"
]

const MENU_COMMANDS = [
  "Baixar pânico",
  "Bloqueio",
  "Desbloqueio",
  "Posicionar desligado",
  "Recalibrar",
  "Reset",
  "Teste"
]

export const MENU_STOCK = [
  "Conjuntos",
  "Subcomponentes",
  "Técnicos"
]

export const MENU_EQUIPMENTS = [
  "Banco de baterias",
  "Cabeçote",
  "Chassi",
  "Chip",
  "H.Board",
  "Manutenções",
  "Motores",
  "N.Board",
  "NFC",
  "Placa de sensores",
  "Rastreador",
  "Serviços de manut."
]

export const MENU_FINANCIAL = [
  "Aprovações",
  "Balancete de sócio",
  "Cartão de crédito",
  "Contas",
  "Investimentos",
  "Pagamentos",
  "Recebimentos"
]

export const MENU_MAINTENANCES = [
  "Histórico",
  "Problemas",
  "Soluções",
  "Subproblemas",
  "Suspeitas",
  "Timeline"
]

export const MENU_REPORTS = [
  "Banco de baterias",
  "Chassi",
  "Chip",
  "H.Board",
  "Manutenções Geral",
  "Manutenções",
  "Motores",
  "N.Board",
  "Niple",
  "Placa de sensores",
  "Rastreador"
]

const MenuBar = () => {

  const dispatch = useDispatch()

  const client = useSelector(state => state.client)
  const loader = useSelector(state => state.loader)
  const user = useSelector(state => state.user)
  const financialApproves = useSelector(state => state.financialApproves)
  const page = useSelector(state => state.page);

  const [showRegister, setShowRegister] = useState(false)
  const [showPdfs, setShowPdfs] = useState(false)
  const [showCommands, setShowCommands] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const [showEquipments, setShowEquipments] = useState(false)
  const [showFinancial, setShowFinancial] = useState(false)
  const [showMaintenances, setShowMaintenances] = useState(false)
  const [showReports, setShowReports] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderlotie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  function resetAll() {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "DASHBOARD", data: "" } })
  }

  function handlerMenu(menu) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "CRUD", data: menu } })
  }

  function pdfs(pdf) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "PDF", data: pdf } })
  }

  function reports(report) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "REPORT", data: report } })
  }

  function commands(command) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "COMMAND", data: command } })
  }

  function financial(financial) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "FINANCIAL", data: financial } })
  }

  function stock(stock) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "STOCK", data: stock } })
  }

  function equipment(equipment) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "EQUIPMENT", data: equipment } })
  }

  function maintenance(maintenance) {
    dispatch({ type: "SET_SEARCH", data: "" })
    dispatch({ type: "SET_OBJ_SELECTED", data: {} })
    dispatch({ type: "SET_PAGE", data: { name: "MAINTENANCE", data: maintenance } })
  }

  return (
    <Container>

      <TopSide>

        <Logo>
          <img src={teste} width={25} height={25} onClick={() => { resetAll() }} alt="Logo CLOCT" />
          <div
            style={{ backgroundColor: (client.id === 2) ? '#ff1212' : '#2c83d6' }}
            onClick={() => {
              if (client.id === 2)
                dispatch({ type: "SET_CLIENT", data: { id: 3, name: "OCTOPUS" } })
              else
                dispatch({ type: "SET_CLIENT", data: { id: 2, name: "THREE PIXELS" } })
            }}
          >{client.name}</div>
        </Logo>

        {loader && <Lottie
          options={defaultOptions}
          height={40}
          width={40}
        />}

        <>
          <MenuButton onClick={() => setShowRegister(!showRegister)}>
            <Register>
              <FontAwesomeIcon icon={faCashRegister} style={{ width: 25, height: 25 }} />
            </Register>
            <span>Cadastro</span>
          </MenuButton>

          {showRegister && MENU_REGISTER.map(menu => {
            if (menu === "Tipo de Investimento" && !financialPermission(user.profile))
              return null

            if (menu === "Despesas Fixas" && !financialPermission(user.profile))
              return null

            return (
              <SubMenuButton onClick={() => handlerMenu(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })

          }
        </>

        <>
          <MenuButton onClick={() => setShowPdfs(!showPdfs)}>
            <PDFs>
              <FontAwesomeIcon icon={faTerminal} style={{ width: 25, height: 25 }} />
            </PDFs>
            <span>PDFs</span>
          </MenuButton>
          {showPdfs && MENU_PDF.map(menu => {
            return (
              <SubMenuButton onClick={() => pdfs(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

        <>
          <MenuButton onClick={() => setShowCommands(!showCommands)}>
            <Commands>
              <FontAwesomeIcon icon={faTerminal} style={{ width: 25, height: 25 }} />
            </Commands>
            <span>Comandos</span>
          </MenuButton>
          {showCommands && MENU_COMMANDS.map(menu => {
            return (
              <SubMenuButton onClick={() => commands(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

        <>
          <MenuButton onClick={() => setShowStock(!showStock)}>
            <Commands>
              <FontAwesomeIcon icon={faBoxes} style={{ width: 25, height: 25 }} />
            </Commands>
            <span>Estoque</span>
          </MenuButton>

          {showStock && MENU_STOCK.map(menu => {
            return (
              <SubMenuButton onClick={() => stock(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

        <>
          <MenuButton onClick={() => setShowEquipments(!showEquipments)}>
            <Commands>
              <FontAwesomeIcon icon={faListAlt} style={{ width: 25, height: 25 }} />
            </Commands>
            <span>Equipamentos</span>
          </MenuButton>

          {showEquipments && MENU_EQUIPMENTS.map(menu => {
            return (
              <SubMenuButton onClick={() => equipment(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

        <>
          <MenuButton onClick={() => setShowFinancial(!showFinancial)}>
            <Commands>
              <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ width: 25, height: 25 }} />
            </Commands>
            <span>Financeiro</span>
          </MenuButton>

          {showFinancial && <>

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Aprovações')}>
              <div className={(page.data === "Aprovações") ? 'selected' : ''}>Aprovações ({financialApproves.length})</div>
            </SubMenuButton>}

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Balancete de sócio')}>
              <div className={(page.data === "Balancete de sócio") ? 'selected' : ''}>Balancete de sócio</div>
            </SubMenuButton>}

            {(creditCardPermission(user.profile) || financialPermission(user.profile)) && <SubMenuButton onClick={() => financial('Cartão de crédito')}>
              <div className={(page.data === "Cartão de crédito") ? 'selected' : ''}>Cartão de crédito</div>
            </SubMenuButton>}

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Contas')}>
              <div className={(page.data === "Contas") ? 'selected' : ''}>Contas</div>
            </SubMenuButton>}

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Carteira de investimentos')}>
              <div className={(page.data === "Carteira de investimentos") ? 'selected' : ''}>Investimentos</div>
            </SubMenuButton>}

            {creditCardPermission(user.profile) && <SubMenuButton onClick={() => financial('Requisições')}>
              <div className={(page.data === "Requisições") ? 'selected' : ''}>Requisições</div>
            </SubMenuButton>}

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Pagamentos')}>
              <div className={(page.data === "Pagamentos") ? 'selected' : ''}>Pagamentos</div>
            </SubMenuButton>}

            {financialPermission(user.profile) && <SubMenuButton onClick={() => financial('Recebimentos')}>
              <div className={(page.data === "Recebimentos") ? 'selected' : ''}>Recebimentos</div>
            </SubMenuButton>}

          </>}
        </>

        <>
          <MenuButton onClick={() => setShowMaintenances(!showMaintenances)}>
            <Commands>
              <FontAwesomeIcon icon={faCogs} style={{ width: 25, height: 25 }} />
            </Commands>
            <span>Manutenções</span>
          </MenuButton>

          {showMaintenances && MENU_MAINTENANCES.map(menu => {
            return (
              <SubMenuButton onClick={() => maintenance(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

        <>
          <MenuButton onClick={() => setShowReports(!showReports)}>
            <Reports>
              <FontAwesomeIcon icon={faClipboardList} style={{ width: 25, height: 25 }} />
            </Reports>
            <span>Relatórios</span>
          </MenuButton>

          {showReports && MENU_REPORTS.map(menu => {
            return (
              <SubMenuButton onClick={() => reports(menu)}>
                <div className={(page.data === menu) ? 'selected' : ''}>{menu}</div>
              </SubMenuButton>
            )
          })
          }
        </>

      </TopSide>

    </Container>
  )

}

export default MenuBar;
