import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container, Cost, Info, List, LotNumber, Niple, Note, Totals
} from './style';



const ReportMotors: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    // const report = useSelector(state => state.report)

    const [totalItem, setTotalItem] = useState(0)
    const [totalLinked, setTotalLinked] = useState([])
    const [totalUnlinked, setTotalUnlinked] = useState([])
    const [totalInactives, setTotalInactives] = useState([])
    const [totalLots, setTotalLots] = useState([])
    const [lotNumber, setLotNumber] = useState(0)

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostLinked, setTotalCostLinked] = useState(0)
    const [totalCostUnlinked, setTotalCostUnlinked] = useState(0)
    const [totalCostInactive, setTotalCostInactive] = useState(0)

    useEffect(() => {
        findDetails()
    }, [])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'sensor-boards/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalItem = 0;
                var totalLinked = [];
                var totalUnlinked = [];
                var totalInactive = [];
                var totalLots = [];
                var maxLot = 0;

                var totalCost = 0;
                var totalCostLinked = 0;
                var totalCostUnlinked = 0;
                var totalCostInactive = 0;

                responseJson.data.map(item => {

                    const lot = item.lot_number.split(".")[2]
                    if (parseInt(lot) > maxLot) {
                        maxLot = lot;
                    }

                    if (!totalLots[lot]) {
                        totalLots[lot] = [item]
                    } else {
                        totalLots[lot].push(item)
                    }

                    if (item.active) {
                        totalItem += 1;
                        totalCost = parseFloat(totalCost) + parseFloat(item.cost);

                        if (item.n_board) {
                            totalLinked.push(item);
                            totalCostLinked = parseFloat(totalCostLinked) + parseFloat(item.cost);
                        }
                        else {
                            totalUnlinked.push(item);
                            totalCostUnlinked = parseFloat(totalCostUnlinked) + parseFloat(item.cost);
                        }
                    }
                    else {
                        totalInactive.push(item);
                        totalCostInactive = parseFloat(totalCostInactive) + parseFloat(item.cost);
                    }

                })

                var filtered = totalLots.filter(function (el) {
                    return el != null;
                });

                setTotalItem(totalItem)
                setTotalLinked(totalLinked)
                setTotalUnlinked(totalUnlinked)
                setTotalInactives(totalInactive)
                setTotalLots(filtered)
                setLotNumber(maxLot)

                setTotalCost(totalCost)
                setTotalCostLinked(totalCostLinked)
                setTotalCostUnlinked(totalCostUnlinked)
                setTotalCostInactive(totalCostInactive)

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>Placa de sensor</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Lotes</strong> <label>Total: {lotNumber}</label><span></span></Totals>
                {totalLots.map((lot, key) => {
                    var cost = 0;
                    lot.map(l => {
                        cost = parseFloat(cost) + parseFloat(l.cost);
                    })
                    return (
                        <Totals><strong>Lote: {key + 1}</strong> <label>Total: {lot.length}</label><span>R$ {cost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                    )
                })}
                <Totals><span></span></Totals>
                <Totals><strong>Em Niple</strong> <label>Total: {totalLinked.length}</label><span>R$ {totalCostLinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnlinked.length}</label><span style={{ color: 'red' }}>R$ {totalCostUnlinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativos</strong> <label>Total: {totalInactives.length}</label><span>R$ {totalCostInactive.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>

                <List>

                    <strong>Placa de sensor: Niple</strong>

                    <div>

                        <div style={{ background: "#eee", fontWeight: 'bold' }}>
                            <Niple>Niple</Niple>
                            <LotNumber>Lote</LotNumber>
                            <Note>Observação</Note>
                            <Cost>Custo</Cost>
                        </div>

                        {totalLinked.map((item, i) => {

                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <Niple>{item.n_board}</Niple>
                                    <LotNumber>{item.lot_number}</LotNumber>
                                    {/* <Note>{item.note}</Note> */}
                                    <Note>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</Note>
                                    <Cost>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Cost>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Placa de sensor: Disponíveis</strong>
                    <div>

                        <div style={{ background: "#eee", fontWeight: 'bold' }}>
                            <Niple>Niple</Niple>
                            <LotNumber>Lote</LotNumber>
                            <Note>Observação</Note>
                            <Cost>Custo</Cost>
                        </div>

                        {totalUnlinked.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <Niple> </Niple>
                                    <LotNumber>{item.lot_number}</LotNumber>
                                    {/* <Note>{item.note}</Note> */}
                                    <Note>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</Note>
                                    <Cost>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Cost>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Placa de sensor: Inativos</strong>
                    <div>

                        <div style={{ background: "#eee", fontWeight: 'bold' }}>
                            <Niple>Niple</Niple>
                            <LotNumber>Lote</LotNumber>
                            <Note>Observação</Note>
                            <Cost>Custo</Cost>
                        </div>

                        {totalInactives.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <Niple> </Niple>
                                    <LotNumber>{item.lot_number}</LotNumber>
                                    {/* <Note>{item.note}</Note> */}
                                    <Note>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</Note>
                                    <Cost>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Cost>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>

        </Container>
    )

}

export default ReportMotors;