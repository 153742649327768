import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';

const FieldsVehicle = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [contract_id, setContractId] = useState(0)
    const [contract_name, setContractName] = useState("")
    const [niple_id, setNipleId] = useState(0)
    const [niple_serial, setNipleSerial] = useState("")
    const [tracker_id, setTrackerId] = useState(0)
    const [tracker_serial, setTrackerSerial] = useState("")
    const [type, setType] = useState("LIMPA-FOSSA")
    const [identifier, setIdentifier] = useState("")
    const [installation_date, setInstallationDate] = useState("")
    const [uninstallation_date, setUninstallationDate] = useState("")
    const [note, setNote] = useState("")
    const [active, setActive] = useState(true)
    const [accessories, setAccessories] = useState([]);
    const [accessoriesVehicle, setAccessoriesVehicle] = useState([]);
    const [running, setRunning] = useState(false);
    const [runningVehicle, setRunningVehicle] = useState(false);

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        getAccessories();

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)

            if (objSelected.contract) {
                setContractId(objSelected.contract.id)
                setContractName(objSelected.contract.name)
            }
            else {
                setContractId(0)
                setContractName("")
            }

            if (objSelected.niple) {
                setNipleId(objSelected.niple.id)
                setNipleSerial(objSelected.niple.tracker?.n_board?.serial)
            }
            else {
                setNipleId(0)
                setNipleSerial("")
            }

            if (objSelected.tracker) {
                setTrackerId(objSelected.tracker.id)
                setTrackerSerial(objSelected.tracker.serial)
            } else {
                setTrackerId(0)
                setTrackerSerial("")
            }

            setType(objSelected.type)
            setIdentifier(objSelected.identifier)
            setInstallationDate(objSelected.installation_date)
            setUninstallationDate(objSelected.uninstallation_date)
            setNote(objSelected.note)
            setActive(objSelected.active)
            setTextDesc("Atualizar")
            getAccessoriesByVehicleId(objSelected.id);
        }
        else {
            setId(0)
            setContractId(0)
            setContractName("")
            setNipleId(0)
            setNipleSerial("")
            setTrackerId(0)
            setTrackerSerial("")
            setType("LIMPA-FOSSA")
            setIdentifier("")
            setInstallationDate("")
            setUninstallationDate("")
            setNote("")
            setActive(true)
            setTextDesc("Adicionar")
            setAccessoriesVehicle([]);
        }

    }, [objSelected])

    async function getAccessories() {
      try {
        if (running) return;
        setRunning(true);

          let response = await fetch(DEFAULT_URL + `accessories`, {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + user.token
              }
          });

          let responseJson = await response.json();
          console.log(responseJson)

          setRunning(false);

          if (responseJson.success) {
              setAccessories(responseJson.data)
              return
          }

          let { message } = responseJson.details;
          if (message === 'error_authorization') {
              dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
              localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
              return
          }

      } catch (error) {
          setRunning(false);
          console.error(error);
      }
    }

    async function getAccessoriesByVehicleId(vehicle_id) {
      try {
          if (runningVehicle) return;
          setRunningVehicle(true);

          let response = await fetch(DEFAULT_URL + `accessories/vehicle`, {
              method: 'POST',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + user.token
              },
              body: JSON.stringify({
                vehicle_id: vehicle_id
              })
          });

          let responseJson = await response.json();
          console.log(responseJson)
          setRunningVehicle(false);

          if (responseJson.success) {
              setAccessoriesVehicle(responseJson.data)
              return
          }

          let { message } = responseJson.details;
          if (message === 'error_authorization') {
              dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
              localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
              return
          }

      } catch (error) {
          setRunningVehicle(false);
          console.error(error);
      }

  }

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (contract_id === 0) {
            setTextAlert("Selecione um Contrato")
            return;
        }

        if (identifier.trim() === "") {
            setTextAlert("Informe o campo Identificação")
            return
        }

        if (installation_date.trim() === "") {
            setTextAlert("Informe o campo Data da Instalação")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'vehicles' : `vehicles/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    accessories: accessoriesVehicle.map(accessoryVehicle => accessoryVehicle.id),
                    contract_id: contract_id,
                    niple_id: (niple_id === 0) ? null : niple_id,
                    tracker_id: (tracker_id === 0) ? null : tracker_id,
                    type: type,
                    identifier: identifier,
                    installation_date: installation_date,
                    uninstallation_date: uninstallation_date,
                    note: note,
                    active: active
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    function toggleComponent(component) {
      const accessoriesCopy = accessoriesVehicle.slice();
      const index = accessoriesCopy.findIndex(componentCopy => componentCopy.id === component.id);
      if (index === -1) {
        accessoriesCopy.push(component);
        setAccessoriesVehicle(accessoriesCopy);
        return;
      }
      accessoriesCopy.splice(index, 1);
      setAccessoriesVehicle(accessoriesCopy);
    }

    function haveComponent(component) {
      const accessoriesCopy = accessoriesVehicle.slice();
      const index = accessoriesCopy.findIndex(componentCopy => componentCopy.id === component.id);
      if (index === -1) return false;
      return true;
    }

    return (
        <Container>
            {
              ((running || runningVehicle) && (
                <Title>Aguarde...</Title>
              ))
            }

            {
              ((!running && !runningVehicle) && (
                <>
                  <Title>{textDesc}</Title>
                  <AutoComplete
                      label={"Contrato"}
                      finder={"contracts"}
                      item={{ id: contract_id, name: contract_name }}
                      setSelected={(item) => {
                          setContractId(item.id)
                          setContractName(item.search)
                      }}
                  />

                  <Field>
                      <label>Tipo:</label>
                      <CheckBox>
                          <Check onClick={() => setType("LIMPA-FOSSA")} className={(type === "LIMPA-FOSSA") ? 'selected' : ''}>LIMPA-FOSSA</Check>
                          <Check onClick={() => setType("CARRO")} className={(type === "CARRO") ? 'selected' : ''} >CARRO</Check>
                      </CheckBox>
                  </Field>

                  <Field>
                    <label>Acessórios:</label>
                    <CheckBox>
                        {
                          accessories.map(accessory => (
                            <Check key={`item-tag-${accessory.tag}`} onClick={() => toggleComponent(accessory)} className={(haveComponent(accessory)) ? 'selected' : ''}>{accessory.tag}</Check>
                          ))
                        }
                    </CheckBox>
                  </Field>

                  {type === "LIMPA-FOSSA" &&

                      <AutoComplete
                          label={"Niple"}
                          finder={"niples"}
                          item={{ id: niple_id, name: niple_serial }}
                          setSelected={(item) => {
                              setNipleId(item.id)
                              setNipleSerial(item.search)
                          }}
                      />

                  }

                  {type === "CARRO" &&

                      <AutoComplete
                          label={"Rastreador"}
                          finder={"trackers_v"}
                          item={{ id: tracker_id, name: tracker_serial }}
                          setSelected={(item) => {
                              setTrackerId(item.id)
                              setTrackerSerial(item.search)
                          }}
                      />
                  }

                  <Field>
                      <label>Identificador:</label>
                      <input
                          type="text"
                          placeholder="Identificador"
                          value={identifier}
                          onChange={event => setIdentifier(event.target.value)} />
                  </Field>

                  <Field>
                      <label>Data Instalação:</label>
                      <InputMask
                          mask="99/99/9999"
                          type="text"
                          placeholder="Data Instalação"
                          value={installation_date}
                          onChange={event => setInstallationDate(event.target.value)} />
                  </Field>

                  <Field>
                      <label>Data Desinstalação:</label>
                      <InputMask
                          mask="99/99/9999"
                          type="text"
                          placeholder="Data Desinstalação"
                          value={uninstallation_date}
                          onChange={event => setUninstallationDate(event.target.value)} />

                  </Field>

                  <Field>
                      <label>Observação:</label>
                      <textarea
                          type="text"
                          placeholder="Adicione uma observação"
                          value={note}
                          onChange={event => setNote(event.target.value)}
                      />
                  </Field>

                  <Select>
                      <label>Status:</label>
                      <Switch onChange={() => handlerSwitch()} checked={active} />
                  </Select>

                  <ViewSubmit>

                      {textAlert !== "" &&
                          <Alert>
                              {textAlert}
                          </Alert>}
                      {textAlert === "" && <div />}

                      <Button onClick={() => saveData()}>
                          {textButton}
                      </Button>

                  </ViewSubmit>
                </>
              ))
            }


        </Container>
    )

}

export default FieldsVehicle;
