import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import Moment from 'moment/min/moment-with-locales';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PDFStyle from '../../../styles/PDFStyle';
import FooterFixed from '../PDFLayout/FooterFixed';
import Header from '../PDFLayout/Header';

const TITLE_STYLE = {
  fontFamily: "Helvetica-Bold",
  width: "100%",
  fontSize: "13px",
  lineHight: 1.5,
  textAlign: "center",
  fontWeight: "bold",
  margin: "10px 0",
  color: "#000000"
};

const TEXT_STYLE = {
  fontFamily: "Helvetica",
  width: "100%",
  fontSize: "12px",
  lineHight: 1.5,
  textAlign: "justify",
  fontWeight: "normal",
  margin: "15px 0",
  color: "#000000"
}

const TEXT_BOLD = {
  fontFamily: "Helvetica-Bold",
  fontSize: "12px",
  lineHight: 1.5,
  fontWeight: "bold",
  margin: "0",
  padding: "0",
  color: "#000000",
  textTransform: "uppercase"
}

const TEXT_WRITE = {
  fontFamily: "Helvetica",
  width: "100%",
  fontSize: "12px",
  lineHight: 1.5,
  textAlign: "center",
  fontWeight: "normal",
  margin: "0",
  color: "#000000"
}

const TEXT_CLAUSE_STYLE = {
  ...TEXT_STYLE,
  margin: "15px 0 5px 0",
  paddingLeft: "15px"
};

const TEXT_SUB_CLAUSE_STYLE = {
  ...TEXT_CLAUSE_STYLE,
  margin: "0 0 5px 0",
  paddingLeft: "30px"
};

const MONTH = [
  "janeiro",
  "fevereito",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro"
];

const PDFsSponsoring: React.FC = ({ renter, client, fidelity, payment }) => {

    const user = useSelector(state => state.user)
    const pages = 1;

    const [date, setDate] = useState(new Date());

    function bold(value) {
      return <Text style={TEXT_BOLD}>{value}</Text>;
    }

    return (
        <PDFViewer style={{ width: "100%", height: "100%" }}>
            <Document
                title='CONTRATO DE PATROCÍNIO PUBLICITÁRIO/"SPONSORING"'
                author="THREE PIXELS SISTEMAS"
                creator="CLOCT"
                producer="CLOCT">

                <Page size="A4" style={{...PDFStyle.page, lineHeight: 1.5 }}>

                  <div>

                    <Text style={TITLE_STYLE}>CONTRATO DE PATROCÍNIO PUBLICITÁRIO/“SPONSORING”</Text>

                    <Text style={TEXT_STYLE}>PARTES:</Text>
                    <Text style={TEXT_STYLE}>{bold(renter.name)}, com sede em {renter.address}, inscrita no {renter.identifier.type} sob o nº {renter.identifier.value}, neste ato representada por {bold(renter.responsible.name)}, portador do {renter.responsible.type} nº {renter.responsible.value}, doravante denominada simplesmente {bold('"Patrocinadora"')};</Text>
                    <Text style={TEXT_STYLE}>e</Text>
                    <Text style={TEXT_STYLE}>{bold(client.name)}, com sede em {client.address}, inscrito no {client.identifier.type} sob o nº {client.identifier.value}, neste ato representado por {bold(client.responsible.name)}, portador do {client.responsible.type} nº {client.responsible.value}, doravante denominado simplesmente {bold('"Patrocinado"')}.</Text>

                    <Text style={TEXT_STYLE}>OBJETO:</Text>
                    <Text style={TEXT_STYLE}>O presente contrato tem por objeto estabelecer as condições e obrigações relacionadas ao patrocínio publicitário a ser realizado pela {bold("Patrocinadora")} em favor do {bold("Patrocinado")}, por meio do aplicativo {bold('“UPPI Vá de Taxi”')}, doravante denominado {bold("PATROCINADO")}.</Text>
                    <Text style={TEXT_STYLE}>O presente objeto constitui responsabilidade de meio, de forma que não vincula as partes a resultados almejados.</Text>

                    <Text style={TEXT_STYLE}>CLÁUSULAS:</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>1. DO OBJETO DO PATROCÍNIO:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. A {bold("Patrocinadora")} concorda em patrocinar o {bold("Patrocinado")}, fornecendo recursos financeiros para a realização de veiculação de propaganda/“sponsoring”, por meio do aplicativo {bold('“UPPI Vá de Taxi”')}, conforme acordado entre as partes.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>2. DOS DIREITOS E OBRIGAÇÕES DAS PARTES:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. A {bold("Patrocinadora")} terá direito a publicidade, contendo imagem, nome, endereço e contato, que ficará visivel sempre que um usuário solicitar uma corrida;</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>b. O {bold("Patrocinado")} se compromete a cumprir com todas as obrigações previstas neste contrato e a utilizar os recursos fornecidos pela {bold("Patrocinadora")} exclusivamente para os fins acordados entre as partes.</Text>

                    <Text style={{...TEXT_CLAUSE_STYLE, margin: "20px 0 5px 0" }}>3. DO PRAZO:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. O presente contrato terá vigência a partir da data de assinatura pelas partes e vigorará pelo prazo pré - fixado de {fidelity}, salvo disposição em contrário acordada por escrito entre as partes.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>4. DA REMUNERAÇÃO:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. Em contrapartida ao patrocínio fornecido, a {bold("Patrocinadora")} pagará ao {bold("Patrocinado")} a quantia {payment}, conforme acordado entre as partes.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>5. DA RESCISÃO:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. Este contrato poderá ser rescindido por qualquer das partes, mediante notificação por escrito com antecedência mínima de 30 dias, sem prejuízo das obrigações já assumidas até a data da rescisão.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>6. DA CONFIDENCIALIDADE:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. As partes concordam em manter em sigilo todas as informações confidenciais obtidas em virtude deste contrato, comprometendo-se a não divulgar tais informações a terceiros sem o consentimento prévio por escrito da outra parte.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>7. EXCLUSIVIDADE:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. Durante a vigência deste contrato, o {bold("Patrocinado")} terá exclusividade como único {bold("Patrocinado")} em aplicativos de mobilidade urbana, sendo vedado ao {bold("Patrocinador")} firmar qualquer tipo de acordo de patrocínio com outras empresas, marcas ou aplicativos que possam conflitar ou competir diretamente com os interesses do {bold("Patrocinado")}, salvo autorização expressa e por escrito do {bold("Patrocinado")}.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>8. RESPONSABILIDADE CIVIL/CRIMINAL</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. O {bold("Patrocinador")} isenta o {bold("Patrocinado")} de qualquer responsabilidade civil, criminal ou administrativa decorrente de propaganda veiculada em conexão com este contrato que seja considerada ofensiva, enganosa, difamatória, caluniosa, ou que viole qualquer direito de terceiros.</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>b. O {bold("Patrocinador")} concorda em indenizar e manter o {bold("Patrocinado")} isento de qualquer reclamação, ação judicial, custo, despesa ou responsabilidade decorrente de tal propaganda.</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>c. O {bold("Patrocinador")} concorda em assumir total responsabilidade pela veracidade, legalidade e adequação de toda a propaganda veiculada em conexão com este contrato.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>9. OBRIGAÇÕES TRABALHISTAS</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. O {bold("Patrocinador")} declara estar ciente e concorda que, em relação à prestação dos serviços objeto deste contrato, o {bold("Patrocinado")} atuará de forma independente e autônoma, não mantendo relação de emprego ou subordinação com o {bold("Patrocinado")}.</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>b. O {bold("Patrocinador")} isenta o {bold("Patrocinado")} de qualquer obrigação trabalhista, previdenciária, fiscal ou de natureza similar, assumindo integralmente tais responsabilidades perante seus próprios empregados, prepostos e terceirizados.</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>c. O {bold("Patrocinador")} concorda em indenizar e manter o {bold("Patrocinado")} isento de qualquer reclamação, demanda, ação judicial, custo, despesa ou responsabilidade decorrente de alegação de relação de emprego entre o {bold("Patrocinado")} e qualquer pessoa vinculada ao {bold("Patrocinador")}.</Text>

                    <Text style={TEXT_CLAUSE_STYLE}>10. DO FORO:</Text>
                    <Text style={TEXT_SUB_CLAUSE_STYLE}>a. As partes elegem o foro da comarca de Chapecó, Estado de Santa Catarina Para dirimir quaisquer controvérsias decorrentes deste contrato, com renúncia a qualquer outro, por mais privilegiado que seja.</Text>

                    <Text style={TEXT_STYLE}>E, por estarem assim justas e contratadas, as partes assinam o presente contrato em duas vias de igual teor e forma, na presença de duas testemunhas, para que produza os seus efeitos legais.</Text>
                    <Text style={TEXT_STYLE}>Chapecó, {date.getDay()} de {MONTH[date.getMonth()]} de {date.getFullYear()}.</Text>

                    <Text style={{...TEXT_WRITE, margin: "30px 0 0 0"}}>________________________________</Text>
                    <Text style={TEXT_WRITE}>{bold(renter.name)}</Text>
                    <Text style={TEXT_WRITE}>{renter.identifier.type} nº {renter.identifier.value}</Text>
                    <Text style={TEXT_WRITE}>{renter.responsible.name}</Text>
                    <Text style={TEXT_WRITE}>{renter.responsible.type} nº {renter.responsible.value}</Text>

                    <Text style={{...TEXT_WRITE, margin: "50px 0 0 0"}}>________________________________</Text>
                    <Text style={TEXT_WRITE}>{bold(client.name)}</Text>
                    <Text style={TEXT_WRITE}>{client.identifier.type} nº {client.identifier.value}</Text>
                    <Text style={TEXT_WRITE}>{client.responsible.name}</Text>
                    <Text style={TEXT_WRITE}>{client.responsible.type} nº {client.responsible.value}</Text>

                  </div>
              </Page>

            </Document>

        </PDFViewer>
    )
}

export default PDFsSponsoring;
