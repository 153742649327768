import styled, { css } from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--secondary);
    padding: 0px 0 15px 0px;
`;

export const Legend = styled.div`
    margin: 0 0 0 25px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 25px;
    letter-spacing: 1px;
    font-family: 'GothamProBold';
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;

    @media(max-width: 1280px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    > span {
      background-color: #eee;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 10px;
      cursor: pointer;
    }

`;

export const ExcelDiv = styled.div`
  display: flex;
  overflow: scroll;
  height: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

export const TitleExcel = styled.div`
  height: 45px;
  background-color: #555;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

export const Itens = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eee;
  height: 45px;
  align-items: center;
  padding: 0 20px;
  font-weight: bold;

  .reciver {
    width:250px;
    overflow: scroll;
  }

  .date {
    width:200px;
    overflow: scroll;
  }

  .payment {
    width:200px;
    overflow: scroll;
  }

`;

export const RowExcel = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #eee;

  :last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  > p {
    width: calc(100% - 850px);
    overflow: scroll;
    font-weight: 500;
    font-size: 13px;
  }

  .reciver {
    width: 250px;
  }

  .date {
    width:200px;
  }

  .payment {
    width:200px;
  }

`;
