import { faFileCode, faSyncAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValid } from "@fnando/cnpj";
import { porExtenso } from "numero-por-extenso";

import { DEFAULT_URL } from '../../../../helper';

import AutoComplete from '../../../AutoComplete';

import {
    Container, Details, Grid, Info, NBoard, PDFInfo
} from './style';
import {
  Field, CheckBox, Check
} from '../../../../styles/CrudLayout';

import PDFSponsoringTerm from '../../PDFSponsoringTerm';

const DEFAULT_CLIENT = {
  address: "ENDEREÇO",
  addressDetailed: {
    address: "RUA",
    cep: "CEP",
    city: {
      name: "CIDADE",
      state: {
        initials: "ES",
        name: "ESTADO"
      }
    },
    district: "BAIRRO",
    streetNumber: "NÚMERO"
  },
  email: "email",
  identifier: {
    value: "CNPJ",
    type: "CNPJ"
  },
  ie: "IE",
  name: "NOME",
  phone: "CELULAR",
  responsible: {
    value: "CPF",
    name: "RESPONSÁVEL",
    type: "CPF"
  },
  site: "site"
}

const DEFAULT_RENTER = {
  address: "ENDEREÇO",
  addressDetailed: {
    address: "RUA",
    cep: "CEP",
    city: {
      name: "CIDADE",
      state: {
        initials: "ES",
        name: "ESTADO"
      }
    },
    district: "BAIRRO",
    streetNumber: "NÚMERO"
  },
  email: "email",
  identifier: {
    value: "CNPJ",
    type: "CNPJ"
  },
  ie: "IE",
  name: "NOME",
  number: "00000",
  phone: "CELULAR",
  responsible: {
    cpf: "CPF",
    name: "RESPONSÁVEL",
    type: "CPF"
  },
  site: "site"
}

const addZero = (num) => {
  let numAux = `${num}`;
  while(numAux.length < 5){
    numAux = `0${numAux}`;
  }
  return numAux;
}

const PDFsSponsoringTerm: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [arrayItens, setArrayItens] = useState([])

    const [date, setDate] = useState("")
    const [xmlDiv, setXmlDiv] = useState(null)
    const [ftpFiles, setFtpFiles] = useState([])

    const [serialSelected, setSerialSelected] = useState(false)
    const [textPassword, setTextPassword] = useState("")

    const [update, setUpdate] = useState(true)

    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")

    const [contract_id, setContractId] = useState(0)
    const [contract_name, setContractName] = useState("")

    const [renter_id, setRenterId] = useState(0)
    const [renter_name, setRenterName] = useState("")

    const [fidelity, setFidelity] = useState("6 (seis meses)");
    const [payment, setPayment] = useState("");

    const [client, setClient] = useState(DEFAULT_CLIENT);
    const [renter, setRenter] = useState(DEFAULT_RENTER);

    useEffect(() => {
        Moment.locale('pt-br');
        setDate(Moment(new Date()).add(1, 'days').format('YYYY-MM-DD 23:59:59'))
    }, []);

    async function findById(url, id) {
      try {
        let response = await fetch(DEFAULT_URL + url + "/" + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            }
        });

        let responseJson = await response.json();
        if (responseJson.success) return responseJson.data;
      } catch (error) {
          console.error(error);
      }
      return null;
  }

    return (
        <Container>

            <Info>

                <Grid>
                    <div style={{ background: '#eeeeee' }}>
                        <Details>
                            <NBoard style={{ fontWeight: 'bold' }}>Parâmetros</NBoard>
                        </Details>
                    </div>

                    <div style={{ padding: "15px" }}>
                      <AutoComplete
                        label={"Empresa Patrocinada"}
                        finder={"renters"}
                        item={{ id: client_id, name: client_name }}
                        setSelected={async (item) => {
                          if (item && item.id && item.search) {
                            setClientId(item.id);
                            setClientName(item.search);
                            const renter_data = await findById("renters", item.id);

                            const responsibleValue = renter_data.responsible_cpf ? (String(renter_data.responsible_cpf).trim() !== "" ? String(renter_data.responsible_cpf).trim() : renter_data.identifier) : renter_data.identifier;
                            const responsibleName = renter_data.responsible_name ? (String(renter_data.responsible_name).trim() !== "" ? String(renter_data.responsible_name).trim().toUpperCase() : String(`${renter_data.name}`).toUpperCase()) : String(`${renter_data.name}`).toUpperCase();
                            setClient(prev => ({
                              ...prev,
                              address: String(`${renter_data.address}, ${renter_data.district}, ${renter_data.city.name} - ${renter_data.city.state.initials}`).toUpperCase(),
                              addressDetailed: {
                                address: String(renter_data.address).toUpperCase(),
                                streetNumber: String(renter_data.street_number).toUpperCase(),
                                cep: renter_data.cep,
                                city: renter_data.city,
                                district: String(renter_data.district).toUpperCase()
                              },
                              email: String(renter_data.email).toLocaleLowerCase(),
                              identifier: {
                                value: renter_data.identifier,
                                type: isValid(renter_data.identifier) ? "CNPJ" : "CPF"
                              },
                              ie: renter_data.ie,
                              name: String(`${renter_data.name}`).toUpperCase(),
                              number: String(`${addZero(renter_data.id)}`),
                              phone: renter.number,
                              responsible: {
                                value: responsibleValue,
                                name: responsibleName,
                                type: isValid(responsibleValue) ? "CNPJ" : "CPF"
                              },
                              site: String(renter_data.site).toLocaleLowerCase()
                            }));
                          }
                          else {
                            setClientId(0);
                            setClientName("");
                            setClient(DEFAULT_CLIENT);
                          }
                        }}
                      />

                    <AutoComplete
                      label={"Contrato"}
                      finder={"contracts"}
                      item={{ id: contract_id, name: contract_name }}
                      setSelected={async (item) => {
                          if (item && item.id && item.search) {
                            setContractId(item.id)
                            setContractName(item.search)
                            const contract_data = await findById("contracts", item.id);
                            const { renter, assessment, fidelity, monthly_payment, yearly_payment, payment_type, bill_type, type, installation, uninstallation, expiration_day, expiration_month } = contract_data;

                            setFidelity(`${fidelity} (${porExtenso(fidelity)} meses)`);
                            setPayment(`${bill_type === "MENSAL" ? `mensal de R$ ${monthly_payment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} (${porExtenso(monthly_payment, "monetario")})` : `anual de R$ ${yearly_payment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} (${porExtenso(yearly_payment, "monetario")})`}`);

                            const count = 1;

                            const responsibleValue = renter.responsible_cpf ? (String(renter.responsible_cpf).trim() !== "" ? String(renter.responsible_cpf).trim() : renter.identifier) : renter.identifier;
                            const responsibleName = renter.responsible_name ? (String(renter.responsible_name).trim() !== "" ? String(renter.responsible_name).trim().toUpperCase() : String(`${renter.name}`).toUpperCase()) : String(`${renter.name}`).toUpperCase();
                            setRenter(prev => ({
                              ...prev,
                              address: String(`${renter.address}, ${renter.district}, ${renter.city.name} - ${renter.city.state.initials}`).toUpperCase(),
                              addressDetailed: {
                                address: String(renter.address).toUpperCase(),
                                streetNumber: String(renter.street_number).toUpperCase(),
                                cep: renter.cep,
                                city: renter.city,
                                district: String(renter.district).toUpperCase()
                              },
                              email: String(renter.email).toLocaleLowerCase(),
                              identifier: {
                                value: renter.identifier,
                                type: isValid(renter.identifier) ? "CNPJ" : "CPF"
                              },
                              ie: renter.ie,
                              name: String(`${renter.name}`).toUpperCase(),
                              number: String(`${addZero(renter.id)}`),
                              contractNumber: String(`${contract_data.id}`),
                              phone: renter.number,
                              responsible: {
                                value: responsibleValue,
                                name: responsibleName,
                                type: isValid(responsibleValue) ? "CNPJ" : "CPF"
                              },
                              site: String(renter.site).toLocaleLowerCase(),
                              chargeType: `COMODATO (${type === "C" ? "X" : " "}) ou VENDA (${type === "V" ? "X" : " "}) ou PRÓPRIOS (${type === "P" ? "X" : " "})`,
                              loyaltyPeriod: `${fidelity} (${porExtenso(fidelity)} meses)`,
                              paymentTerms: `(${payment_type === "CHEQUE" ? "X" : " "}) Cheque (${payment_type === "BOLETO" ? "X" : " "}) Boleto (${payment_type === "CARTAO" ? "X" : " "}) Cartão (${payment_type === "PIX" ? "X" : " "}) PIX`,
                              textMonthYearType: bill_type === "MENSAL" ? "mensalidade" : "anuidade",
                              textMonthYearTypeHh:  bill_type === "MENSAL" ? "MENSALIDADE" : "ANUIDADE",
                              textMonthYearTypePl:  bill_type === "MENSAL" ? "mensalidades" : "anuidades",
                              textMonthYearTypeSl:  bill_type === "MENSAL" ? "PAGAMENTO MENSAL" : "PAGAMENTO ANUAL",
                              textMonthYearTypeSs:  bill_type === "MENSAL" ? "mês" : "ano",
                              textAllInstall: `R$ ${installation.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              textAllUninstall: `R$ ${uninstallation.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              textAllContract: `R$ ${((bill_type === "MENSAL" ? monthly_payment : yearly_payment)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              valueAllInstall: `R$ ${(installation * count).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              valueAllUninstall: `R$ ${(uninstallation * count).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              valueAllContract: `R$ ${((bill_type === "MENSAL" ? (monthly_payment * count) : (yearly_payment * count))).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
                              paymentType: payment_type,
                              billType: bill_type,
                              expirationDay:  bill_type === "MENSAL" ? expiration_day : expiration_month,
                              assessment: assessment ? `R$ ${((bill_type === "MENSAL" ? monthly_payment : (yearly_payment / 12))).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` : `R$ 0,00`
                            }));
                          }
                          else {
                            setContractId(0);
                            setContractName("");
                            setRenterId(0);
                            setRenterName("");
                            setRenter(DEFAULT_RENTER);
                          }
                      }}
                    />

                  </div>

                </Grid>

                <PDFInfo>
                  {
                    ((client_id !== 0 && contract_id !== 0) ? (
                      <PDFSponsoringTerm
                        client={client}
                        renter={renter}
                        fidelity={fidelity}
                        payment={payment}
                      />
                    ) : (
                      <p style={{ color: "#ffffff" }}>Selecione a empresa e o contrato.</p>
                    ))
                  }
                </PDFInfo>
            </Info>

        </Container>
    )

}

export default PDFsSponsoringTerm;
