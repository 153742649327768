
import React from 'react';
import { useSelector } from 'react-redux';
import FieldsSubProblemsNiple from './Fields';
import GridSubProblemsNiple from './Grid';
import {
  CircleActive,
  CircleInactive, Container, Fields, Grid, Info, Legend, TitleInfo
} from './style';


const MaintenanceSubProblemsNiple: React.FC = () => {

  const page = useSelector(state => state.page);

  return (
    <Container>

      <TitleInfo>{page.data}</TitleInfo>
      <Legend>
        <span><CircleActive /> <label>Ativos</label></span>
        <span><CircleInactive /> <label>Inativos</label></span>
      </Legend>

      <Info>

        <Grid>

          <GridSubProblemsNiple />

        </Grid>

        <Fields>

          <FieldsSubProblemsNiple />

        </Fields>

      </Info>

    </Container>
  )

}

export default MaintenanceSubProblemsNiple;