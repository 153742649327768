import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';

const ReportNBoard: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)

    const [responseAll, setResponseAll] = useState([])

    const [totalItem, setTotalItem] = useState(0)
    const [totalLinked, setTotalLinked] = useState([])
    const [totalUnlinked, setTotalUnlinked] = useState([])
    const [totalInactives, setTotalInactives] = useState([])
    const [totalLots, setTotalLots] = useState([])
    const [lotNumber, setLotNumber] = useState(0)

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostLinked, setTotalCostLinked] = useState(0)
    const [totalCostUnlinked, setTotalCostUnlinked] = useState(0)
    const [totalCostInactive, setTotalCostInactive] = useState(0)

    useEffect(() => {
        findDetails()
    }, [])

    useEffect(() => {

        var totalItem = 0;
        var totalLinked = [];
        var totalUnlinked = [];
        var totalInactive = [];
        var totalLots = [];
        var maxLot = 0;

        var totalCost = 0;
        var totalCostLinked = 0;
        var totalCostUnlinked = 0;
        var totalCostInactive = 0;

        responseAll.map(item => {

            if (search == "" || item.lot_number.includes(search) || item.serial.includes(search) || item.t_serial?.includes(search)) {

                const lot = item.lot_number.split(".")[2]
                if (parseInt(lot) > maxLot) {
                    maxLot = lot;
                }

                if (!totalLots[lot]) {
                    totalLots[lot] = [item]
                } else {
                    totalLots[lot].push(item)
                }

                if (item.active) {
                    totalItem += 1;
                    totalCost = parseFloat(totalCost) + parseFloat(item.cost);

                    if (item.t_serial) {
                        totalLinked.push(item);
                        totalCostLinked = parseFloat(totalCostLinked) + parseFloat(item.cost);
                    }
                    else {
                        totalUnlinked.push(item);
                        totalCostUnlinked = parseFloat(totalCostUnlinked) + parseFloat(item.cost);
                    }
                }
                else {
                    totalInactive.push(item);
                    totalCostInactive = parseFloat(totalCostInactive) + parseFloat(item.cost);
                }
            }

        })

        var filtered = totalLots.filter(function (el) {
            return el != null;
        });

        setTotalItem(totalItem)
        setTotalLinked(totalLinked)
        setTotalUnlinked(totalUnlinked)
        setTotalInactives(totalInactive)
        setTotalLots(filtered)
        setLotNumber(maxLot)

        setTotalCost(totalCost)
        setTotalCostLinked(totalCostLinked)
        setTotalCostUnlinked(totalCostUnlinked)
        setTotalCostInactive(totalCostInactive)

    }, [search, responseAll])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'n_boards/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setResponseAll(responseJson.data)
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>N.Boards Ativas</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>NBoards em Rastreadores</strong> <label>Total: {totalLinked.length}</label><span>R$ {totalCostLinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnlinked.length}</label><span style={{ color: 'red' }}>R$ {totalCostUnlinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativas</strong> <label>Total: {totalInactives.length}</label><span style={{ color: 'red' }}>R$ {totalCostInactive.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><span></span></Totals>
                <Totals><strong>Lotes</strong> <label>Total: {lotNumber}</label><span></span></Totals>
                {totalLots.map((lot, key) => {
                    var cost = 0;
                    lot.map(l => {
                        cost = parseFloat(cost) + parseFloat(l.cost);
                    })
                    return (
                        <Totals><strong>Lote: {key + 1}</strong> <label>Total: {lot.length}</label><span>R$ {cost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                    )
                })}


                <List>

                    <strong>NBoards: Rastreador</strong>

                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong style={{ width: '10%' }}>N Board</strong>
                            <strong style={{ width: '10%' }}>Rastreador</strong>
                            <strong style={{ width: '5%' }}>Versão</strong>
                            <strong>Lote</strong>
                            <strong style={{ width: '35%' }}>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalLinked.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span style={{ width: '10%' }}>{item.serial}</span>
                                    <span style={{ width: '10%' }}>{item.t_serial}</span>
                                    <span style={{ width: '5%' }}>{item.firmware_version}</span>
                                    <span>{item.lot_number}</span>
                                    <span style={{ width: '35%' }}>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</span>
                                    {/* <span>{item.note}</span> */}
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>NBoards: Disponíveis</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>N Board</strong>
                            <strong style={{ width: '5%' }}>Versão</strong>
                            <strong>Lote</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalUnlinked.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.serial}</span>
                                    <span style={{ width: '5%' }}>{item.firmware_version}</span>
                                    <span>{item.lot_number}</span>
                                    {/* <span>{item.note}</span> */}
                                    <span>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</span>
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>NBoards: Inativas</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong style={{ width: '10%' }}>H Board</strong>
                            <strong style={{ width: '5%' }}>Versão</strong>
                            <strong>Lote</strong>
                            <strong style={{ width: '45%' }}>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalInactives.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span style={{ width: '10%' }}>{item.serial}</span>
                                    <span style={{ width: '5%' }}>{item.firmware_version}</span>
                                    <span>{item.lot_number}</span>
                                    <span style={{ width: '45%' }}>{(item.note) ? item.note.split("\n").map(i => { return (<><span>{i}</span><br></br></>) }) : ""}</span>
                                    {/* <span>{item.note}</span> */}
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>



        </Container>
    )

}

export default ReportNBoard;