import { Document, Image, Page, PDFViewer, Text, Font } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import PDFStyle from '../../../styles/PDFStyle';
import logo from '../../../assets/images/logo.png';
import "./style.css";

import font from '../../../assets/fonts/ARIAL-BOLD.TTF';

Font.register({
  family: 'ArialBold',
  format: 'truetype',
  src: font
});

const PDFRenterTicket: React.FC = ({ onClose, layout, client_id, callback, selectedItens, jumpItens }) => {

  const [data, setData] = useState([]);

  var pages = 0;
  var totalItens = 0
  var arrayPages = []
  var cntArray = 0
  var row = 0

  useEffect(() => {
    setData([].concat([], Array.from({ length: jumpItens ?? 0 }), selectedItens ? selectedItens.sort((a, b) => a.name.localeCompare(b.name))  : []));
  }, []);

  data.map((item, i) => {

    totalItens++;
    row++;

    if (row < 30) {
      if (typeof arrayPages[cntArray] === 'undefined') {
        arrayPages[cntArray] = [item]
      } else {
        arrayPages[cntArray].push(item)
      }
    } else {
      arrayPages[cntArray].push(item)
      cntArray++;
      row = 0;
    }

  })

  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document
        title="ETIQUETAS"
        author="THREE PIXELS SISTEMAS"
        creator="CLOCT"
        producer="CLOCT">

        {arrayPages.map((itemPage, page) => {

          return (

            <Page wrap={true} orientation="portrait" size={{ width: "617.9527559055118px", height: "788.0314960629922px" }} style={{ ...PDFStyle.page, padding: "36.85039370078741px 14.173228346456694px", margin: "0" }}>
              <div style={{ margin: "0", padding: "0", width: "100%", height: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", flex: 1, alignItems: "flex-start", justifyContent: "flex-start", justifyItems: "flex-start", textAlign: "center" }}>
                {
                  itemPage.map((item, i) => {
                   if (item) {
                    const emails = item.email.split(";").map(email => email.trim()).filter(email => email !== "");
                    const name = String(item.name).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
                    const phone = String(item.number).trim().replaceAll("_", "").replaceAll("-", "");
                    return (
                      <div style={{ display: "flex", flex: `0 0 ${100/3}%`, height: "71.52755905511812px", overflow: "hidden", margin: "0", boxSizing: "border-box", padding: "0", alignItems: "center", justifyContent: "flex-start" }}>
                        <div style={{ backgroundColor: "#fff", border: "1px solid #fff", borderRadius: "10px", display: "flex", flex: "1", width: "192.75590551181105px", height: "100%", boxSizing: "border-box", padding: "0", flexDirection: "row" }}>
                          <div style={{ display: "flex", width: "40px", height: "100%", alignItems: "center", justifyContent: "center", padding: "0" }}>
                            <Text style={{ width: "100%", fontSize: 30, textAlign: "center", fontFamily: "ArialBold" }}>{name[0]}</Text>
                          </div>
                          <div style={{ display: "flex", flex: 1, height: "100%", alignItems: "center", justifyContent: "center", padding: "0 2px" }}>
                            <Text style={{ width: "100%", fontSize: 9, textAlign: "left", fontFamily: "ArialBold" }}>{item.name}</Text>
                            <Text style={{ width: "100%", fontSize: 8, textAlign: "left" }}>{item.identificador}</Text>
                            {emails.map(email => <Text style={{ width: "100%", fontSize: 8, textAlign: "left" }}>{email}</Text>)}
                            <Text style={{ width: "100%", fontSize: 8, textAlign: "left" }}>{phone}</Text>
                          </div>
                        </div>
                      </div>
                    )
                   }
                   return (
                    <div style={{ display: "flex", flex: `0 0 ${100/3}%`, height: "71.52755905511812px", overflow: "hidden", margin: "0", boxSizing: "border-box", padding: "0", alignItems: "center", justifyContent: "flex-start" }}><div style={{ backgroundColor: "#fff", border: "1px solid #fff", borderRadius: "10px", display: "flex", flex: "1", width: "192.75590551181105px", height: "100%", boxSizing: "border-box", padding: "0", flexDirection: "row" }}></div></div>
                   );
                  })
                }
              </div>
            </Page>)

        })}

      </Document>

    </PDFViewer >
  )
}

export default PDFRenterTicket;
