
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';
import requestCreateObservation from '../../../../api/service/MaintenanceObservation/Create';
import requestUpdateObservation from '../../../../api/service/MaintenanceObservation/Update';
import loaderlotie from '../../../../assets/lotties/loader.json';
import { Alert, Button, Check, CheckBox, Field, Select, ViewSubmit } from '../../../../styles/CrudLayout';
import AutoComplete from '../../../AutoComplete';
import { FieldHorizontal } from '../../../Fields/FieldsRevenues/style';
import { Body, Container, Steps } from './style';


const selectedColor = "#000";
const DF_USER = { id: 0, name: "" }
const textDefaultNo = "Não possuí ou não vinculado";
const ok = "#039422";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderlotie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const NewObservation = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const suspicions = useSelector(state => state.suspicions)
  const objSelected = useSelector(state => state.objSelected)

  const [textAlert, setTextAlert] = useState("")
  const [load, setLoad] = useState(false)

  const [id, setId] = useState(0)
  const [vehicle_id, setVehicleId] = useState(0)
  const [vehicle_identifier, setVehicleIdentifier] = useState("")
  const [entry_date, setEntryDate] = useState("")
  const [suspicionsArr, setSuspicionsArr] = useState([]);
  const [output_date, setOutputDate] = useState(null)
  const [note, setNote] = useState("")
  const [finished, setFinished] = useState(false)

  useEffect(() => {

    setTextAlert("")

    if (Object.keys(objSelected).length > 0) {

      console.log(objSelected)
      setId(objSelected.id);
      setVehicleId(objSelected.vehicle.id)
      setVehicleIdentifier(objSelected.vehicle.identifier)

      if (objSelected.suspicions)
        setSuspicionsArr(objSelected.suspicions.split(","))

      setEntryDate(Moment(objSelected.entry_date).format("DD/MM/YYYY"))
      if (objSelected.output_date)
        setOutputDate(Moment(objSelected.entry_date).format("DD/MM/YYYY"))

      setNote(objSelected.note)
      setFinished(objSelected.finished)
    }

  }, [objSelected])

  function handleSuspicions(item) {

    const clone = Object.assign([], suspicionsArr);
    var index = clone.findIndex(s => s == item.id);
    if (index > -1) {
      clone.splice(index, 1);
    } else {
      clone.push(item.id);
    }

    setSuspicionsArr(clone);
  }

  const saveObservation = async () => {

    if (vehicle_id === 0)
      return setTextAlert("Informe o Veículo")

    if (entry_date === "")
      return setTextAlert("Informe a Data da ocorrência")

    if (suspicionsArr.length === 0)
      return setTextAlert("Informe ao menos uma suspeita")

    const body = {
      user_id: user.id,
      vehicle_id: vehicle_id,
      entry_date: Moment(entry_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      suspicions: suspicionsArr.toString(),
      output_date: finished ? Moment().format("YYYY-MM-DD") : output_date,
      finished: finished,
      note: note
    }

    if (id !== 0) {

      setLoad(true);
      const responseSuspicions = await requestUpdateObservation(id, body, user.token);
      setLoad(false);
      if (responseSuspicions.status === 200) {
        dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
        dispatch({ type: "SET_MODAL", data: { display: false, view: "" } })
        dispatch({ type: "SET_DARK", data: false })
      }
      return;
    }

    setLoad(true);
    const responseSuspicions = await requestCreateObservation(body, user.token);
    setLoad(false);
    if (responseSuspicions.status === 201) {
      dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
      dispatch({ type: "SET_MODAL", data: { display: false, view: "" } })
      dispatch({ type: "SET_DARK", data: false })
    }

  }


  return (
    <Container>

      <Body>

        {load && <div
          style={{ position: "absolute", zIndex: 1, background: "rgba(0,0,0,0.5)", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}
        >
          <Lottie
            options={defaultOptions}
            height={150}
            width={250}
          />
        </div>}


        <Steps>

          <div>
            <AutoComplete
              label={"Veículo"}
              finder={"vehicles/niple"}
              item={{ id: vehicle_id, name: vehicle_identifier }}
              setSelected={(item) => {
                setVehicleId(item.id)
                setVehicleIdentifier(item.search)
              }}
            />


            <Field>
              <label>Data da ocorrência:</label>

              <ReactInputMask
                mask="99/99/9999"
                type="text"
                placeholder="Data da ocorrência"
                value={entry_date}
                onChange={event => setEntryDate(event.target.value)} />

            </Field>


            <FieldHorizontal>
              <label>Suspeitas:</label>
              <CheckBox>
                {suspicions.map(e => {
                  if (e.active) {
                    const name = (e.name.length > 20) ? e.name.substr(0, 19) + ".." : e.name
                    return (
                      <Check
                        key={e.id}
                        onClick={() => handleSuspicions(e)}
                        style={{ border: (suspicionsArr.find(s => s == e.id) ? "2px solid #e82653" : "") }}>
                        {name}
                      </Check>)
                  }
                })}
              </CheckBox>
            </FieldHorizontal>

            {id !== 0 &&
              <Select>
                <label>Concluido:</label>
                <ReactSwitch onChange={() => setFinished(!finished)} checked={finished} />
              </Select>}


            <Field>
              <label>Observação:</label>
              <textarea
                type="text"
                placeholder="Adicione uma observação"
                value={note}
                onChange={event => setNote(event.target.value)}
              />


              <ViewSubmit>

                {textAlert !== "" &&
                  <Alert>
                    {textAlert}
                  </Alert>}
                {textAlert === "" && <div />}


                {id !== 0 && <Button onClick={() => {

                  const obj = {
                    maintenance: [{
                      id: 0,
                      client_id: 2,
                    }],
                    vehicle: {
                      id: vehicle_id,
                      identifier: vehicle_identifier
                    },
                    auxSuspicions: {
                      suspicions: suspicionsArr.toString(),
                      date: Moment(entry_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                      id_observation: id,
                      entry_date: Moment(entry_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                      note: note
                    }
                  }

                  dispatch({ type: "SET_OBJ_SELECTED", data: obj });
                  dispatch({ type: "SET_MODAL", data: { display: true, view: "NOVA MANUTENÇÃO" } })
                  dispatch({ type: "SET_DARK", data: true })

                }} style={{ backgroundColor: "#a19817", width: 150 }}>
                  MANUTENÇÃO
                </Button>}


                <Button onClick={() => saveObservation()}>
                  SALVAR
                </Button>

              </ViewSubmit>

            </Field>


          </div>

        </Steps>


      </Body>

    </Container>
  )

}

export default NewObservation;