import Moment from "moment/min/moment-with-locales";  
import React from "react"; 

const HistoryItem = ({ isBoardSelected, isBoardSuspicionSelected, selectBoard, selectBoardSuspicion, item, hist, hideBorder }) => {
  const selected = isBoardSelected(item.board) && isBoardSuspicionSelected(hist.type);
  return (
    <li className="current-card-suspicion">
      <div className="current-card-toggle">
        <button className={`${selected ? "active" : ""}`} type="button" onClick={() => {
          if (!isBoardSelected(item.board)) selectBoard(item.board);
          selectBoardSuspicion(hist.type);
        }} />
      </div>
      <div className={hist.priority}>
        {
          ((!hideBorder) && (
            <div className="history-flag">
              <div style={{ backgroundColor: hist.color }} />
            </div>
          ))
        }
        <div className="history-count">{hist.count}</div> - {hist.name}
      </div>
      {((hist.date) && (<small>{Moment(new Date(hist.date)).fromNow()} ({Moment(new Date(hist.date)).format("DD/MM/YYYY HH:mm:ss")})</small>))}
    </li>
  );
};

export default HistoryItem;
