import React, { useState } from 'react';
import { ButtonSend, Container, InfoMessage, TextInput, Title } from './style';

import { useDispatch } from 'react-redux';

import { faHdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DEFAULT_CLIENT_STRUCT, DEFAULT_URL } from '../../helper';

const Login: React.FC = () => {

    const dispatch = useDispatch()

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [loginText, setLoginText] = useState("ENTRAR")
    const [infoMessage, setInfoMessage] = useState("")

    async function handleLogin() {

        if (login.trim() === "") {
            setInfoMessage("Preencha o campo Usuário!");
            return
        }

        if (login.length !== 11) {
            setInfoMessage(`${login} não é válido!`);
            return
        }

        if (password.trim() === "") {
            setInfoMessage("Preencha o campo Senha!");
            return
        }

        if (password.length <= 4) {
            setInfoMessage(`Senha inválida!`);
            return
        }

        setLoginText("Aguarde..")

        try {

            let response = await fetch(DEFAULT_URL + 'users/authenticate', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    login: login,
                    password: password
                }),
            });

            let responseJson = await response.json();

            setLoginText("ENTRAR")

            if (responseJson.success) {

                var respondeUser = responseJson.data.user
                respondeUser.token = responseJson.data.token

                dispatch({ type: "SET_CLIENT", data: DEFAULT_CLIENT_STRUCT })
                dispatch({ type: "SET_USER", data: respondeUser })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: responseJson.data.user.id, by: responseJson.data.token }));
                return
            }

            let { message } = responseJson.details;
            if (message === 'login_not_found') {
                setInfoMessage(`Usuário não encontrado!`);
                return
            }

            if (message === 'password_not_found') {
                setInfoMessage(`Desculpe, senha incorreta!`);
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <form onSubmit={(e) => {
                e.preventDefault()
                handleLogin()
            }}>

                <Title>
                    <strong>C L O C T</strong>
                    <span>
                        <FontAwesomeIcon icon={faHdd} color="#000" size="2x" />
                    </span>
                </Title>

                {infoMessage !== "" &&
                    <InfoMessage>
                        {infoMessage}
                    </InfoMessage>
                }

                <TextInput
                    placeholder="Usuário"
                    aria-label="Usuário"
                    value={login}
                    onChange={event => setLogin(event.target.value)}
                />

                <TextInput
                    type="password"
                    placeholder="Senha"
                    aria-label="Senha"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                />

                <ButtonSend
                    onClick={() => handleLogin()}>
                    {loginText}
                </ButtonSend>

            </form>

        </Container>
    )

}

export default Login;