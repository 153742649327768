import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active, Container,
    Details,
    Identifiler, Niple, Payment, Renter, Tracker
} from './style';


const GridVehicle: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'vehicles', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                var tVal = 0;
                responseJson.data.map(item => {
                    if (item.active) {
                        const payment = (item.contract) ? item.contract.monthly_payment : 0;
                        tVal = tVal + parseFloat(payment);
                    }
                });
                setTotalValue(tVal);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        var tVal = 0;

        arrayItens.map((item, i) => {
            const tracker_n = (item.niple) ? (item.niple.tracker ? item.niple.tracker.serial : "") : "";
            const trakcer_v = (item.tracker) ? item.tracker.serial : "";
            const niple = (item.niple) ? (item.niple.tracker ? (item.niple.tracker.n_board ? item.niple.tracker.n_board.serial : "") : "") : "";
            const social_reason = (item.contract) ? (item.contract.renter ? (item.contract.renter.name) : "") : "";

            if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase()) || tracker_n.includes(search) || trakcer_v.includes(search) || niple.includes(search) || social_reason.toUpperCase().includes(search.toUpperCase())) {
                t++
                const payment = (item.contract) ? item.contract.monthly_payment : 0;
                tVal = tVal + parseFloat(payment);
            }
        })
        setTotal(t);
        setTotalValue(tVal);

    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total} - {formatNumber(totalValue)}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    <Identifiler style={{ fontWeight: 'bold' }}>Identificador</Identifiler>
                    <Tracker style={{ fontWeight: 'bold' }}>Rastreador</Tracker>
                    <Niple style={{ fontWeight: 'bold' }}>Niple</Niple>
                    <Renter style={{ fontWeight: 'bold' }}>Locatário</Renter>
                    <Payment style={{ fontWeight: 'bold' }}>Mensalidade</Payment>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                const tracker_n = (item.niple) ? (item.niple.tracker ? item.niple.tracker.serial : "") : "";
                const trakcer_v = (item.tracker) ? item.tracker.serial : "";
                const niple = (item.niple) ? (item.niple.tracker ? (item.niple.tracker.n_board ? item.niple.tracker.n_board.serial : "") : "") : "";
                const payment = (item.contract) ? item.contract.monthly_payment : 0;
                const social_reason = (item.contract) ? (item.contract.renter ? (item.contract.renter.name) : "") : "";

                if (search === "" || item.identifier.toUpperCase().includes(search.toUpperCase()) || tracker_n.includes(search) || trakcer_v.includes(search) || niple.includes(search) || social_reason.toUpperCase().includes(search.toUpperCase())) {

                    return (
                        <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                <Identifiler>{item.identifier}</Identifiler>
                                <Tracker>{tracker_n + trakcer_v}</Tracker>
                                <Niple>{niple}</Niple>
                                <Renter>{social_reason.substr(0, 30)}</Renter>
                                <Payment>{formatNumber(payment)}</Payment>
                                <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridVehicle;
