
import { faArrowDown, faArrowUp, faCheckCircle, faFileImage, faFileInvoiceDollar, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import requestCreateMaintenance from '../../../../api/service/Maintenance/Create';
import requestUpdateMaintenance from "../../../../api/service/Maintenance/Update";
import requestUpdateObservation from '../../../../api/service/MaintenanceObservation/Update';
import requestCreateMaintenanceSchedule from '../../../../api/service/MaintenanceSchedule/Create';
import requestUpdateMaintenanceSchedule from '../../../../api/service/MaintenanceSchedule/Update';
import requestCreateMaintenanceSuspicion from '../../../../api/service/MaintenanceSuspicion/Create';
import requestUpdateMaintenanceSuspicion from '../../../../api/service/MaintenanceSuspicion/Update';
import requestLastWorkers from '../../../../api/service/Niples/FindLastWorkers';
import requestPictures from '../../../../api/service/Pictures/FindByMaintenance';
import requestDetailsByVehicle from '../../../../api/service/Vehicle/FindDetails';
import loaderlotie from '../../../../assets/lotties/loader.json';
import { formatHourToSeconds, formatNumber, formatNumberS, somaHora } from '../../../../helper';
import { Alert, Button, Check, CheckBox, Field, ViewSubmit } from '../../../../styles/CrudLayout';
import AutoComplete from '../../../AutoComplete';
import { FieldHorizontal } from '../../../Fields/FieldsRevenues/style';
import { Body, Container, Pictures, Steps, SuspicionsView } from './style';

const selectedColor = "#000";
const DF_USER = { id: 0, name: "" }
const textDefaultNo = "Não possuí ou não vinculado";
const ok = "#039422";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderlotie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const NewMaintenance = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const suspicions = useSelector(state => state.suspicions)
  const subproblems = useSelector(state => state.subproblems)
  const solutions = useSelector(state => state.solutions)
  const objSelected = useSelector(state => state.objSelected)
  const clients = useSelector(state => state.clients)

  const [menuSide, setMenuSide] = useState("")

  const [textAlert, setTextAlert] = useState("")
  const [load, setLoad] = useState(false)

  const [id, setId] = useState(0)
  const [vehicle_id, setVehicleId] = useState(0)
  const [vehicle_identifier, setVehicleIdentifier] = useState("")
  const [client_id, setClientId] = useState(0)
  const [client_name, setClientName] = useState("")
  const [maintenance_suspicions_id, setMaintenanceSuspicionsId] = useState(0)
  const [maintenance_schedules_id, setMaintenanceScheduleId] = useState(0)
  const [maintenance_subproblem_id, setMaintenanceSubProblemId] = useState(0)
  const [maintenance_solution_id, setMaintenanceSolutionId] = useState(0)

  const [vehicle_ntracker_id, setVehicleNTrackerId] = useState(0);

  const [travel_vehicle_id, setTravelVehicleId] = useState(0)
  const [travel_vehicle_name, setTravelVehicleName] = useState("")

  const [note, setNote] = useState("")
  const [output_date, setOutputDate] = useState(null)
  const [stat_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const [cost, setCost] = useState(null)
  const [km, setKm] = useState(null)
  const [km_cost, setKmCost] = useState(null)
  const [techinical_time, setTechinicalTime] = useState(null)
  const [lost_itens, setLostItens] = useState(null)
  const [status, setStatus] = useState("")

  const [user_main_suspicion, setUserMainSuspicion] = useState(DF_USER)
  const [user_mounter, setUserMounter] = useState(DF_USER)
  const [user_checker, setUserChecker] = useState(DF_USER)
  const [date_suspicion, setDateSuspicion] = useState("")
  const [suspicionsArr, setSuspicionsArr] = useState([]);
  const [chassi_suspicion, setChassiSuspucion] = useState("")
  const [tracker_suspicion, setTrackerSuspicion] = useState("")
  const [nboard_suspicion, setNboardSuspicion] = useState("")
  const [hboard_suspicion, setHboardSuspicion] = useState("")
  const [battery_bank_suspicion, setBatteryBankSuspicion] = useState("")
  const [motor_suspicion, setMotorSuspicion] = useState("")
  const [sensor_board_suspicion, setSensorBoardSuspicion] = useState("")
  const [note_suspicion, setNoteSuspicion] = useState("")
  const [created_at_suspicion, setCreateAtSuspicion] = useState("")

  const [user_main_schedule, setUserMainSchedule] = useState(DF_USER)
  const [technical_id_schedule, setTechnicalIdSchedule] = useState(0);
  const [technical_name_schedule, setTechnicalNameSchedule] = useState("")
  const [position_schedule, setPositionSchedule] = useState("")
  const [date_schedule, setDateSchedule] = useState("")
  const [note_schedule, setNoteSchedule] = useState("")
  const [created_at_schedule, setCreatedAtSchedule] = useState("")

  const [user_main_subproblem, setUserMainSubProblem] = useState(DF_USER)
  const [subproblemsArr, setSubproblemsArr] = useState([]);
  const [note_subproblems, setNoteSubproblems] = useState("")

  const [user_main_solution, setUserMainSolution] = useState(DF_USER)
  const [solutionsArr, setSolutuionsArr] = useState([]);
  const [note_solutions, setNoteSolutions] = useState("")

  const [pictures, setPictures] = useState([]);
  const [hoursT, setHoursT] = useState("")
  const [hAgend, setHagend] = useState("")

  const [isOld, setIsOld] = useState(false);

  useEffect(() => {

    setTextAlert("")

    if (Object.keys(objSelected).length > 0) {

      setIsOld(objSelected.isOld);

      setId(objSelected.maintenance[0].id);
      setVehicleId(objSelected.vehicle.id)
      setVehicleIdentifier(objSelected.vehicle.identifier)
      setClientId(objSelected.maintenance[0].client_id)
      setClientName(clients.find(c => c.id == objSelected.maintenance[0].client_id).name)
      setNote(objSelected.maintenance[0].note)

      if (objSelected.maintenance[0].output_date)
        setOutputDate(Moment(objSelected.maintenance[0].output_date).format("DD/MM/YYYY HH:mm"))

      setStartDate(Moment(objSelected.maintenance[0].start_date).format("DD/MM/YYYY HH:mm:ss"))
      setEndDate(Moment(objSelected.maintenance[0].end_date).format("DD/MM/YYYY HH:mm:ss"))

      if (objSelected.maintenance[0].cost === null)
        setCost(0)
      else
        setCost(objSelected.maintenance[0].cost)

      if (objSelected.maintenance[0].km === null)
        setKm(0)
      else
        setKm(objSelected.maintenance[0].km)

      if (objSelected.maintenance[0].km_cost === null)
        setKmCost(0)
      else
        setKmCost(objSelected.maintenance[0].km_cost)

      setTechinicalTime(objSelected.maintenance[0].technical_time)
      setLostItens(objSelected.maintenance[0].lost_itens)
      setStatus(objSelected.maintenance[0].status)

      if (objSelected.maintenance[0].maintenance_suspicions_id) {
        const suspicion = objSelected.maintenance[0].maintenance_suspicions;
        setMaintenanceSuspicionsId(suspicion.id)
        setSuspicionsArr(suspicion.suspicions.split(","))
        setUserMainSuspicion(suspicion.user)
        setUserMounter(suspicion.mounter)
        setUserChecker(suspicion.checker)
        setDateSuspicion(Moment(suspicion.date).format("DD/MM/YYYY"))
        setNoteSuspicion(suspicion.note)
        setChassiSuspucion(suspicion.chassi)
        setTrackerSuspicion(suspicion.tracker)
        setNboardSuspicion(suspicion.n_board)
        setHboardSuspicion(suspicion.h_board)
        setMotorSuspicion(suspicion.motor)
        setSensorBoardSuspicion(suspicion.sensor_board)
        setBatteryBankSuspicion(suspicion.battery_bank)
        setCreateAtSuspicion(suspicion.createdAt)
      }

      if (objSelected.maintenance[0].maintenance_schedules_id) {
        const schedule = objSelected.maintenance[0].maintenance_schedules;
        setMaintenanceScheduleId(schedule.id)
        setUserMainSchedule(schedule.user)

        if (schedule.technical_id) {
          setTechnicalIdSchedule(schedule.technical.id);
          setTechnicalNameSchedule(schedule.technical.name);
        }

        setPositionSchedule(schedule.position);

        setDateSchedule(Moment(schedule.date).format("DD/MM/YYYY HH:mm"))
        setNoteSchedule(schedule.note)
        setCreatedAtSchedule(schedule.createdAt)

        let diffT = Moment(`${objSelected.maintenance[0].maintenance_suspicions.date} 00:00:00`).from(Moment(schedule.date).utc());
        setHagend(diffT);

      }

      if (objSelected.maintenance[0].maintenance_subproblems_id) {
        const subproblem = objSelected.maintenance[0].maintenance_subproblems;
        setMaintenanceSubProblemId(subproblem.id);
        setUserMainSubProblem(subproblem.user);
        setSubproblemsArr(subproblem.subproblems.split(","))
        setNoteSubproblems(subproblem.note)

        setIsOld(true);
      }

      if (objSelected.maintenance[0].maintenance_solutions_id) {

        const solution = objSelected.maintenance[0].maintenance_solutions;
        setMaintenanceSolutionId(solution.id)
        setUserMainSolution(solution.user)
        setSolutuionsArr(solution.solutions.split(","))
        setNoteSolutions(solution.note)

        let diffT = Moment(objSelected.maintenance[0].end_date).diff(Moment(objSelected.maintenance[0].start_date));
        diffT = new Date(diffT);
        let hrsT = diffT.getUTCHours().toString().padStart(2, "0") + ":" + diffT.getUTCMinutes().toString().padStart(2, "0") + ":" + diffT.getSeconds().toString().padStart(2, "0");
        if (diffT.getUTCDate() > 1) {
          const h = 24 * (diffT.getUTCDate() - 1);
          hrsT = somaHora(hrsT, `${h}:00:00`);
        }

        setHoursT(hrsT);
        setIsOld(true);

      }

      if (objSelected.maintenance[0].travel_vehicle_id) {
        setTravelVehicleId(objSelected.maintenance[0].travel_vehicle_id);
        setTravelVehicleName(objSelected.maintenance[0].travel_vehicle.identifier)
      }

      if (objSelected.auxSuspicions) {
        setMenuSide("SUSPEITA")
        setSuspicionsArr(objSelected.auxSuspicions.suspicions.split(","))
        setDateSuspicion(Moment(objSelected.auxSuspicions.date).format("DD/MM/YYYY"))
      }

      getPictures();


    }

  }, [objSelected])

  function handleMenuSide(menu) {

    if (maintenance_suspicions_id === 0 && menu === "AGENDAMENTO")
      return

    if (maintenance_schedules_id === 0 && menu === "PROLEMA")
      return

    if (maintenance_subproblem_id === 0 && menu === "SOLUCAO")
      return

    setMenuSide(menu);
  };

  function resetAllSuspicions() {

    console.log("reset");

    setUserChecker(DF_USER)
    setUserMounter(DF_USER)
    setChassiSuspucion("");
    setTrackerSuspicion("");
    setNboardSuspicion("");
    setHboardSuspicion("");
    setBatteryBankSuspicion("");
    setMotorSuspicion("");
    setSensorBoardSuspicion("");
  }

  function handleSuspicions(item) {

    const clone = Object.assign([], suspicionsArr);
    var index = clone.findIndex(s => s == item.id);
    if (index > -1) {
      clone.splice(index, 1);
    } else {
      clone.push(item.id);
    }

    setSuspicionsArr(clone);
  }

  const getPictures = async () => {

    const body = { limit: 1, page: 1, search: objSelected.vehicle.identifier, tags: [`maintenance_sheets_${objSelected.maintenance[0].id}_`] };
    const responseVehicleNtracker = await requestPictures(body, user.auth);
    if (responseVehicleNtracker.status == 200) {
      setVehicleNTrackerId(responseVehicleNtracker.data.docs[0].id);
      setPictures(responseVehicleNtracker.data.docs[0].pictures);
    }

  }

  const findDetailsByVehicle = async (vehicle_id) => {

    const response = await requestDetailsByVehicle({ vehicle_id: vehicle_id }, user.token);
    if (response.status === 200) {
      setChassiSuspucion(response.data[0].c_serial)
      setTrackerSuspicion(response.data[0].t_serial)
      setNboardSuspicion(response.data[0].n_serial)
      setHboardSuspicion(response.data[0].h_serial)
      setMotorSuspicion(response.data[0].m_lot)
      setSensorBoardSuspicion(response.data[0].s_lot)
      setBatteryBankSuspicion(response.data[0].b_lot)
      findWorkers(response.data[0].n_id);
    }

  }

  const findWorkers = async (n_id) => {

    const response = await requestLastWorkers(n_id, user.token);
    if (response.status === 200) {
      setUserChecker(response.data[0].checked_by)
      setUserMounter(response.data[0].mounted_by)
    }

  }

  const saveSuspicion = async () => {

    if (client_id === 0)
      return setTextAlert("Informe o Prestador do Serviço")

    if (vehicle_id === 0)
      return setTextAlert("Informe o Veículo")

    if (date_suspicion === "")
      return setTextAlert("Informe a Data da ocorrência")

    if (suspicionsArr.length === 0)
      return setTextAlert("Informe ao menos uma suspeita")

    const bodySuspicions = {
      user_id: user.id,
      mounter_id: user_mounter.id,
      checked_id: user_checker.id,
      date: Moment(date_suspicion, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      suspicions: suspicionsArr.toString(),
      chassi: chassi_suspicion,
      tracker: tracker_suspicion,
      n_board: nboard_suspicion,
      h_board: hboard_suspicion,
      battery_bank: battery_bank_suspicion,
      motor: motor_suspicion,
      sensor_board: sensor_board_suspicion,
      note: note_suspicion
    }

    if (maintenance_suspicions_id !== 0) {

      if (vehicle_id != objSelected.maintenance[0].vehicle_id) {
        return setTextAlert("Não é permitido alterar o veículo")
      }

      setLoad(true);
      const responseSuspicions = await requestUpdateMaintenanceSuspicion(maintenance_suspicions_id, bodySuspicions, user.token);
      setLoad(false);
      if (responseSuspicions.status === 200) {
        setUserMainSuspicion({ id: user.id, name: user.name })
        setMenuSide("")
        dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
      }
      return;
    }

    setLoad(true);

    if (objSelected.auxSuspicions) {

      var body = {
        user_id: user.id,
        vehicle_id: vehicle_id,
        entry_date: objSelected.auxSuspicions.entry_date,
        output_date: Moment().format("YYYY-MM-DD"),
        note: objSelected.auxSuspicions.note,
        suspicions: suspicionsArr.toString(),
        finished: true
      }

      await requestUpdateObservation(objSelected.auxSuspicions.id_observation, body, user.token)

    }


    const responseSuspicions = await requestCreateMaintenanceSuspicion(bodySuspicions, user.token);
    if (responseSuspicions.status === 201) {

      setMaintenanceSuspicionsId(responseSuspicions.data.newItem.id)
      setUserMainSuspicion({ id: user.id, name: user.name })

      const bodyMaintenance = {
        client_id: client_id,
        vehicle_id: vehicle_id,
        maintenance_suspicions_id: responseSuspicions.data.newItem.id,
        status: "AGENDAR"
      };

      const responseMaintenance = await requestCreateMaintenance(bodyMaintenance, user.token)
      setLoad(false);
      if (responseMaintenance.status === 201) {
        setId(responseMaintenance.data.newItem.id)
        setMenuSide("")
        dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
      }

    }

  }

  const saveSchedule = async () => {

    if (travel_vehicle_id === 0)
      return setTextAlert("Informe o Véiculo")

    if (technical_id_schedule === 0)
      return setTextAlert("Informe o Técnico")

    if (output_date === null)
      return setTextAlert("Informe a Data de saída dos técnicos")

    if (date_suspicion === null)
      return setTextAlert("Informe a Data do agendamento")

    const bodySchedule = {
      technical_id: technical_id_schedule,
      user_id: user.id,
      date: Moment(date_schedule, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm:ss"),
      position: (position_schedule === "") ? null : position_schedule,
      note: note_schedule
    };

    if (maintenance_schedules_id !== 0) {

      setLoad(true);
      const responseSuspicions = await requestUpdateMaintenanceSchedule(maintenance_schedules_id, bodySchedule, user.token);

      if (responseSuspicions.status === 200) {
        setUserMainSchedule({ id: user.id, name: user.name })

        const bodyMaintenance = {
          travel_vehicle_id: travel_vehicle_id,
          output_date: Moment(output_date, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm:ss")
        };

        const responseMaintenance = await requestUpdateMaintenance(id, bodyMaintenance, user.token)
        setLoad(false);
        if (responseMaintenance.status === 200) {
          setMenuSide("")
          dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
        }

      }

      return;
    }




    const responseSchedules = await requestCreateMaintenanceSchedule(bodySchedule, user.token);
    if (responseSchedules.status === 201) {

      setMaintenanceScheduleId(responseSchedules.data.newItem.id)
      setUserMainSchedule({ id: user.id, name: user.name })

      const bodyMaintenance = {
        travel_vehicle_id: travel_vehicle_id,
        maintenance_schedules_id: responseSchedules.data.newItem.id,
        output_date: Moment(output_date, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm:ss"),
        status: "AGENDADO"
      };

      const responseMaintenance = await requestUpdateMaintenance(id, bodyMaintenance, user.token)
      if (responseMaintenance.status === 200) {
        setMenuSide("")
        dispatch({ type: "SET_UPDATE_TIMELINE", data: true })
      }

    }


  }

  return (
    <Container>

      <Body>

        {load && <div
          style={{ position: "absolute", zIndex: 1, background: "rgba(0,0,0,0.5)", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}
        >
          <Lottie
            options={defaultOptions}
            height={150}
            width={250}
          />
        </div>}

        <Steps>

          <div className={(menuSide === "SUSPEITA") ? "active" : ""}>

            {menuSide !== "SUSPEITA" ?
              <div className='row' onClick={() => handleMenuSide("SUSPEITA")}>
                <div className='title' style={{ color: (maintenance_suspicions_id === 0) ? "#222" : ok }}>
                  <FontAwesomeIcon icon={(maintenance_suspicions_id === 0) ? faArrowDown : faCheckCircle} />
                  <p>SUSPEITA</p>
                </div>
                <div className='user_main'>
                  <span>Informada por</span>
                  <p>{user_main_suspicion.id === 0 ? user.name : user_main_suspicion.name}</p>
                </div>
              </div>
              :
              <SuspicionsView>

                <div className='title' onClick={() => handleMenuSide("")}>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <p>SUSPEITA</p>
                </div>

                <div className='crud'>

                  <div className='maintenance'>

                    <AutoComplete
                      label={"Prestador do serviço"}
                      finder={"clients"}
                      item={{ id: client_id, name: client_name }}
                      setSelected={(item) => {
                        setClientId(item.id)
                        setClientName(item.search)
                      }}
                    />

                    <AutoComplete
                      label={"Veículo"}
                      finder={"vehicles/niple"}
                      item={{ id: vehicle_id, name: vehicle_identifier }}
                      setSelected={(item) => {
                        setVehicleId(item.id)
                        setVehicleIdentifier(item.search)
                        findDetailsByVehicle(item.id)
                      }}
                    />

                    <Field>
                      <label>Data da ocorrência:</label>

                      <InputMask
                        mask="99/99/9999"
                        type="text"
                        placeholder="Data da ocorrência"
                        value={date_suspicion}
                        onChange={event => setDateSuspicion(event.target.value)} />

                    </Field>

                    <FieldHorizontal>
                      <label>Suspeitas:</label>
                      <CheckBox>
                        {suspicions.map(e => {
                          if (e.active) {
                            return (
                              <Check
                                key={e.id}
                                onClick={() => handleSuspicions(e)}
                                style={{ border: (suspicionsArr.find(s => s == e.id) ? "2px solid #e82653" : "") }}>
                                {e.name}
                              </Check>)
                          }
                        })}
                      </CheckBox>
                    </FieldHorizontal>

                    <Field>
                      <label>Observação:</label>
                      <textarea
                        type="text"
                        placeholder="Adicione uma observação"
                        value={note_suspicion}
                        onChange={event => setNoteSuspicion(event.target.value)}
                      />
                    </Field>

                    <ViewSubmit>

                      {textAlert !== "" &&
                        <Alert>
                          {textAlert}
                        </Alert>}
                      {textAlert === "" && <div />}

                      {!isOld && <Button onClick={() => saveSuspicion()}>
                        SALVAR
                      </Button>}

                    </ViewSubmit>

                  </div>

                  <div className='equipment'>

                    <div>
                      <label>NBoard</label>
                      <span>{nboard_suspicion === null ? textDefaultNo : nboard_suspicion}</span>
                    </div>

                    <div>
                      <label>Montado por</label>
                      <span>{user_mounter.name}</span>
                    </div>

                    <div>
                      <label>Aprovado por</label>
                      <span>{user_checker.name}</span>
                    </div>

                    <div>
                      <label>Rastreador</label>
                      <span>{tracker_suspicion === null ? textDefaultNo : tracker_suspicion}</span>
                    </div>

                    <div>
                      <label>Chassi</label>
                      <span>{chassi_suspicion === null ? textDefaultNo : chassi_suspicion}</span>
                    </div>

                    <div>
                      <label>HBoard</label>
                      <span>{hboard_suspicion === null ? textDefaultNo : hboard_suspicion}</span>
                    </div>

                    <div>
                      <label>Baterias</label>
                      <span>{battery_bank_suspicion === null ? textDefaultNo : battery_bank_suspicion}</span>
                    </div>

                    <div>
                      <label>Motor</label>
                      <span>{motor_suspicion === null ? textDefaultNo : motor_suspicion}</span>
                    </div>

                    <div>
                      <label>Sensores</label>
                      <span>{sensor_board_suspicion === null ? textDefaultNo : sensor_board_suspicion}</span>
                    </div>

                  </div>

                </div>
              </SuspicionsView>
            }

          </div>

          <div className={(menuSide === "AGENDAMENTO") ? "active" : ""} style={{ opacity: (maintenance_suspicions_id === 0) ? 0.4 : 1 }}>

            {menuSide !== "AGENDAMENTO" ?
              <div className='row' onClick={() => handleMenuSide("AGENDAMENTO")}>
                <div className='title' style={{ color: (maintenance_schedules_id === 0) ? "#222" : ok }}>
                  <FontAwesomeIcon icon={(maintenance_schedules_id === 0) ? faArrowDown : faCheckCircle} />
                  <p>AGENDAMENTO</p>
                </div>
                <div className='user_main'>
                  <span>{user_main_schedule.id === 0 ? "Aguardando" : "Relizado por"}</span>
                  <p>{user_main_schedule.id === 0 ? user.name : user_main_schedule.name}</p>
                </div>
              </div>
              :
              <SuspicionsView>

                <div className='title' onClick={() => handleMenuSide("")}>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <p>AGENDAMENTO</p>
                </div>

                <div className='crud'>

                  <div className='maintenance'>

                    <AutoComplete
                      label={"Veículo para deslocamento"}
                      finder={"vehicles/find_all_pixel"}
                      item={{ id: travel_vehicle_id, name: travel_vehicle_name }}
                      setSelected={(item) => {
                        setTravelVehicleId(item.id)
                        setTravelVehicleName(item.search)
                      }}
                    />

                    <AutoComplete
                      label={"Técnico responsável"}
                      finder={"users"}
                      item={{ id: technical_id_schedule, name: technical_name_schedule }}
                      setSelected={(item) => {
                        setTechnicalIdSchedule(item.id)
                        setTechnicalNameSchedule(item.search)
                      }}
                    />

                    <Field>
                      <label>Data de saída do veículo para manutenção:</label>
                      <InputMask
                        mask="99/99/9999 99:99"
                        type="text"
                        placeholder="Data de saída"
                        value={output_date}
                        onChange={event => setOutputDate(event.target.value)} />
                    </Field>

                    <Field>
                      <label>Data marcada com o cliente:</label>
                      <InputMask
                        mask="99/99/9999 99:99"
                        type="text"
                        placeholder="Data do agendamento"
                        value={date_schedule}
                        onChange={event => setDateSchedule(event.target.value)} />

                    </Field>

                    <Field>
                      <label>Posição:</label>
                      <input
                        type="text"
                        placeholder="latitude,longitude"
                        value={position_schedule}
                        onChange={event => setPositionSchedule(event.target.value)} />

                    </Field>

                    <Field>
                      <label>Nota para os técnicos:</label>
                      <textarea
                        type="text"
                        placeholder="Adicione uma nota para os técnicos"
                        value={note_schedule}
                        onChange={event => setNoteSchedule(event.target.value)}
                      />
                    </Field>

                    <ViewSubmit>

                      {textAlert !== "" &&
                        <Alert>
                          {textAlert}
                        </Alert>}
                      {textAlert === "" && <div />}

                      {!isOld && <Button onClick={() => saveSchedule()}>
                        SALVAR
                      </Button>}

                    </ViewSubmit>

                  </div>

                  <div className='equipment'>

                    <div>
                      <label>Veículo</label>
                      <span>{vehicle_identifier}</span>
                    </div>

                    {suspicionsArr.map((e) => {

                      const it = suspicions.find(s => s.id == e).itens.split(",");
                      return (
                        <div style={{ height: 25 * it.length }}>
                          {it.map((ite, i) => {
                            return (
                              <>
                                {i === 0 && <label>{suspicions.find(s => s.id == e).name}:</label>}
                                <span>{i + 1}. {ite}</span>
                              </>
                            )
                          })}
                        </div>
                      )

                    })}

                  </div>

                </div>

              </SuspicionsView>
            }
          </div>

          <div className={(menuSide === "PROLEMA") ? "active" : ""} style={{ opacity: (maintenance_schedules_id === 0) ? 0.4 : 1 }}>

            {menuSide !== "PROLEMA" ?
              <div className='row' onClick={() => handleMenuSide("PROLEMA")}>
                <div className='title' style={{ color: (maintenance_subproblem_id === 0) ? "#222" : ok }}>
                  <FontAwesomeIcon icon={(maintenance_subproblem_id === 0) ? faArrowDown : faCheckCircle} />
                  <p>PROLEMA</p>
                </div>
                <div className='user_main'>
                  <span>Detectado por</span>
                  <p>{user_main_subproblem.name}</p>
                </div>
              </div>
              :
              <SuspicionsView>

                <div className='title' onClick={() => handleMenuSide("")}>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <p>PROBLEMA</p>
                </div>

                <div className='crud'>

                  <div className='maintenance' style={{ width: "100%" }}>

                    <FieldHorizontal>
                      <label>Problemas:</label>
                      <CheckBox>
                        {subproblems.map(e => {
                          if (e.active) {
                            if (subproblemsArr.find(s => s == e.id))
                              return (
                                <Check
                                  key={e.id}
                                  style={{ border: "2px solid #e82653" }}>
                                  {e.name}
                                </Check>)
                          }
                        })}
                      </CheckBox>
                    </FieldHorizontal>

                    <Field>
                      <label>Observação:</label>
                      <textarea
                        type="text"
                        placeholder="Nenhuma observação"
                        value={note_subproblems}
                        onChange={event => setNoteSuspicion(event.target.value)}
                      />
                    </Field>

                  </div>

                </div>

              </SuspicionsView>
            }

          </div>

          <div className={(menuSide === "SOLUCAO") ? "active" : ""} style={{ opacity: (maintenance_subproblem_id === 0) ? 0.4 : 1 }}>
            {menuSide !== "SOLUCAO" ?
              <div className='row' onClick={() => handleMenuSide("SOLUCAO")}>
                <div className='title' style={{ color: (maintenance_solution_id === 0) ? "#222" : ok }}>
                  <FontAwesomeIcon icon={(maintenance_solution_id === 0) ? faArrowDown : faCheckCircle} />
                  <p>SOLUÇÃO</p>
                </div>
                <div className='user_main'>
                  <span>Realizada por</span>
                  <p>{user_main_solution.name}</p>
                </div>
              </div>
              :
              <SuspicionsView>

                <div className='title' onClick={() => handleMenuSide("")}>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <p>PROBLEMA</p>
                </div>

                <div className='crud'>

                  <div className='maintenance' style={{ width: "100%" }}>

                    <FieldHorizontal>
                      <label>Soluções:</label>
                      <CheckBox>
                        {solutions.map(e => {
                          if (e.active) {
                            if (solutionsArr.find(s => s == e.id))
                              return (
                                <Check
                                  key={e.id}
                                  style={{ border: "2px solid #e82653" }}>
                                  {e.name}
                                </Check>)
                          }
                        })}
                      </CheckBox>
                    </FieldHorizontal>

                    <Field>
                      <label>Observação:</label>
                      <textarea
                        type="text"
                        placeholder="Nenhuma observação"
                        value={note_solutions}
                        onChange={event => setNoteSuspicion(event.target.value)}
                      />
                    </Field>

                  </div>

                </div>

              </SuspicionsView>
            }
          </div>

          {id !== 0 &&
            <div className={(menuSide === "DOCUMENTACAO") ? "active" : ""}>
              {menuSide !== "DOCUMENTACAO" ?
                <div className='row' onClick={() => handleMenuSide("DOCUMENTACAO")}>
                  <div className='title'>
                    <FontAwesomeIcon icon={faFileImage} />
                    <p>DOCUMENTAÇÃO</p>
                  </div>
                </div>
                :
                <SuspicionsView>

                  <div className='title' onClick={() => handleMenuSide("")}>
                    <FontAwesomeIcon icon={faArrowUp} />
                    <p>DOCUMENTAÇÃO</p>
                  </div>

                  <div className='crud'>

                    <div className='maintenance' style={{ width: "100%" }}>

                      <p>1. INÍCIO DE MANUTENÇÃO</p>
                      <Pictures>
                        {pictures.map((picture) => {

                          if (picture.tag == `maintenance_sheets_${id}_start`) {
                            if (picture?.type.includes("video")) {
                              return (<div key={`video_${picture.key}`}>
                                <video controls>
                                  <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                </video>
                              </div>);
                            }

                            return (
                              <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                              }} >
                                <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                              </div>
                            );

                          }
                        })}
                      </Pictures>

                      <p>2. ABERTURA DO CABEÇOTE</p>
                      <Pictures>
                        {pictures.map((picture) => {

                          if (picture.tag == `maintenance_sheets_${id}_open_niple`) {
                            if (picture?.type.includes("video")) {
                              return (<div key={`video_${picture.key}`}>
                                <video controls>
                                  <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                </video>
                              </div>);
                            }

                            return (
                              <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                              }} >
                                <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                              </div>
                            );

                          }
                        })}
                      </Pictures>

                      <p>3. IDENTIFICAÇÃO DO PROBLEMA</p>
                      <Pictures>
                        {pictures.map((picture) => {

                          if (picture.tag == `maintenance_sheets_${id}_problem`) {
                            if (picture?.type.includes("video")) {
                              return (<div key={`video_${picture.key}`}>
                                <video controls>
                                  <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                </video>
                              </div>);
                            }

                            return (
                              <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                              }} >
                                <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                              </div>
                            );

                          }
                        })}
                      </Pictures>

                      <p>4. SOLUÇÃO</p>
                      <Pictures>
                        {pictures.map((picture) => {

                          if (picture.tag == `maintenance_sheets_${id}_end`) {
                            if (picture?.type.includes("video")) {
                              return (<div key={`video_${picture.key}`}>
                                <video controls>
                                  <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                </video>
                              </div>);
                            }

                            return (
                              <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                              }} >
                                <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                              </div>
                            );

                          }
                        })}
                      </Pictures>

                      <p>5. COMPROVANTE DE SERVIÇO</p>
                      <Pictures>
                        {pictures.map((picture) => {

                          if (picture.tag == `maintenance_sheets_${id}_signature`) {
                            if (picture?.type.includes("video")) {
                              return (<div key={`video_${picture.key}`}>
                                <video controls>
                                  <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                </video>
                              </div>);
                            }

                            return (
                              <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                              }} >
                                <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                              </div>
                            );

                          }
                        })}
                      </Pictures>

                    </div>

                  </div>

                </SuspicionsView>
              }
            </div>}

          {id !== 0 &&
            <div className={(menuSide === "CUSTOS") ? "active" : ""}>
              {menuSide !== "CUSTOS" ?
                <div className='row' onClick={() => handleMenuSide("CUSTOS")}>
                  <div className='title'>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    <p>TABELA DE CUSTOS</p>
                  </div>
                </div>
                :
                <SuspicionsView>

                  <div className='title' onClick={() => handleMenuSide("")}>
                    <FontAwesomeIcon icon={faArrowUp} />
                    <p>TABELA DE CUSTOS</p>
                  </div>

                  <div className='crud' style={{ justifyContent: "center" }}>

                    <div className='costs'>


                      <div className='table-header'>
                        <span>Discriminação</span>
                        <span>Unidade</span>
                        <span>Quantidade</span>
                        <span className='money'>Unitário</span>
                        <span className='money'>Total</span>
                      </div>


                      <div className='table-body'>
                        <span>Serviço técnico</span>
                        <span>hora</span>
                        <span>{hoursT}</span>
                        <span className='money'>{formatNumber(techinical_time)}</span>
                        <span className='money'>{formatNumber((formatHourToSeconds(hoursT) * techinical_time) / (60 * 60))}</span>
                      </div>

                      <div className='table-body'>
                        <span>Deslocamento</span>
                        <span>km</span>
                        <span>{formatNumberS(km)}</span>
                        <span className='money'>{formatNumber(km_cost)}</span>
                        <span className='money'>{formatNumber(km * km_cost)}</span>
                      </div>


                      <div className='table-footer'>
                        <span>TOTAL</span>
                        <span></span>
                        <span></span>
                        <span className='money'></span>
                        <span className='money'>{formatNumber(cost)}</span>
                      </div>

                    </div>

                  </div>

                </SuspicionsView>
              }
            </div>}

          {id !== 0 &&
            <div className={(menuSide === "FICHA") ? "active" : ""}>
              {menuSide !== "FICHA" ?
                <div className='row' onClick={() => handleMenuSide("FICHA")}>
                  <div className='title'>
                    <FontAwesomeIcon icon={faFilePdf} />
                    <p>FICHA</p>
                  </div>
                </div>
                :
                <SuspicionsView>

                  <div className='title' onClick={() => handleMenuSide("")}>
                    <FontAwesomeIcon icon={faArrowUp} />
                    <p>FICHA</p>
                  </div>

                  <div className='maintenance' style={{ width: "100%" }}>

                    {maintenance_suspicions_id &&
                      <>
                        <div className='info'>
                          <p>1. Suspeita criada por {user_main_suspicion.name} em {Moment(created_at_suspicion).format("DD/MM/YYYY HH:mm:ss")}, o monitor informou as suspeitas:</p>
                          {suspicions.map(e => {
                            if (e.active) {
                              if (suspicionsArr.find((s, i) => s == e.id))
                                return (<p> - {e.name}</p>)
                            }
                          })}
                          <p>A peça NIPLE de serial {nboard_suspicion} foi montada por {user_mounter.name} e conferida por {user_checker.name}.</p>
                          <p>{note_suspicion !== "" && <span>O monitor relatou: {'"' + note_suspicion + '"'}</span>}</p>

                        </div>
                      </>}

                    {maintenance_schedules_id &&
                      <>
                        <div className='info'>
                          <p>2. Agendamento foi criado por {user_main_schedule.name} em {Moment(created_at_suspicion).format("DD/MM/YYYY HH:mm:ss")}, {hAgend} da solicitação de agendamento, com: </p>
                          <p>Saída prevista para: {output_date} com o veículo {travel_vehicle_name}</p>
                          <p>A manutenção estava marcada para {date_schedule}, e foi iniciada as {stat_date} </p>
                          <p>{note_schedule !== "" && <span>O agendador relatou: {'"' + note_schedule + '"'}</span>}</p>
                        </div>
                      </>
                    }

                    {maintenance_subproblem_id &&
                      <>
                        <div className='info'>
                          <p>3. No local, o técnico {user_main_subproblem.name} identificou os seguintes problemas:</p>
                          {subproblems.map(e => {
                            if (e.active) {
                              if (subproblemsArr.find((s, i) => s == e.id))
                                return (<p> - {e.name}</p>)
                            }
                          })}
                          <p>{note_subproblems !== "" && <span>O técnico relatou: {'"' + note_subproblems + '"'}</span>}</p>
                        </div>
                      </>
                    }

                    {maintenance_solution_id &&
                      <>
                        <div className='info'>
                          <p>4. Para manter o equipamento em devido funcionamento, o técnico procedeu com as soluções:</p>
                          {solutions.map(e => {
                            if (e.active) {
                              if (solutionsArr.find((s, i) => s == e.id))
                                return (<p> - {e.name}</p>)
                            }
                          })}
                          <p>{note_subproblems !== "" && <span>O técnico relatou: {'"' + note_subproblems + '"'}</span>}</p>
                        </div>
                      </>
                    }

                    {end_date &&
                      <>
                        <div className='info'>
                          <p>5. A manutenção teve uma duração de {hoursT}, sendo finalizada as {end_date}, gerando um custo de serviço de {formatNumber((formatHourToSeconds(hoursT) * techinical_time) / (60 * 60))}</p>
                          <p>Para a manutenção ser realizada foi realizado um deslocament de {formatNumberS(km)} km, Gerando um custo de {formatNumber(km_cost)}</p>
                          <p>O custo total da manutenção foi estipulado em: {formatNumber(cost)}</p>
                        </div>
                      </>
                    }

                  </div>

                </SuspicionsView>
              }
            </div>}

        </Steps>


      </Body>

    </Container >
  )

}

export default NewMaintenance;
