import React, { memo, useEffect, useState } from "react";
import Moment from "moment/min/moment-with-locales";
import "./index.css";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Marker = ({ onClick, position, selected }) => { 
  const [eventHover, setEventHover] = useState(selected);
  const [popupShow, setPopupShow] = useState(selected);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(eventHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (eventHover ? DEFAULT_ZINDEX : 0));
  }, [eventHover, popupShow, scale]);

  useEffect(() => {
    setEventHover(selected);
    setPopupShow(selected);
  }, [selected]);

  const transform = "rotate(0deg)";

  const popup = (
    <div
      id={`event-marker-popup-${position.index}`}
      className="marker-event-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">{position.board}</h3>
      <div className="mep-content">
        {position.name}
        <p>{Moment(new Date(position.date)).fromNow()} ({Moment(new Date(position.date)).format("DD/MM/YYYY HH:mm:ss")})</p>
      </div>
    </div>
  );

  return (
    <div
      className="marker-event-all"
      onClick={_ => onClick()}
      onMouseEnter={_ => setEventHover(true)}
      onMouseLeave={_ => setEventHover(false)}
      style={{
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-event" style={{ background: `${position.color}` }}>
        <div className="icon" style={{
          transform,
          MozTransform: transform,
          WebkitTransform: transform }}>
          {/* {eventDetails && eventDetails.icon()} */}
        </div>
        {popupShow && popup}
      </div>
    </div>
  );
};

export default memo(Marker);
