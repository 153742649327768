export const DEFAULT_URL = process.env.REACT_APP_APP_API_URL
export const DEFAULT_USER_STRUCT = { id: 0 }
export const DEFAULT_CLIENT_STRUCT = { id: 2, name: "THREE PIXELS" }
export const DEFAULT_LIMIT_ITENS_REPORTS = process.env.REACT_APP_DEFAULT_LIMIT_ITENS_REPORTS
export const DEFAULT_NTRACKER_URL = process.env.REACT_APP_APP_NTRACKER_URL

const REGISTER_ID_PERMISSION = 1;
const COMMANDS_ID_PERMISSION = 2;
const FINANCIAL_ID_PERMISSION = 3;
const REPORTS_ID_PERMISSION = 4;
const OFFICIER_ID_PERMISSION = 5;
const CREDIT_CARD_PERMISSION = 6;
const COMMAND_PASSWORD = "4792";

export const months = [
    { value: 1, number: "01", name: "Janeiro", label: "Janeiro", abbr: "Jan." },
    { value: 2, number: "02", name: "Fevereiro", label: "Fevereiro", abbr: "Fev." },
    { value: 3, number: "03", name: "Março", label: "Março", abbr: "Mar." },
    { value: 4, number: "04", name: "Abril", label: "Abril", abbr: "Abr." },
    { value: 5, number: "05", name: "Maio", label: "Maio", abbr: "Mai." },
    { value: 6, number: "06", name: "Junho", label: "Junho", abbr: "Jun." },
    { value: 7, number: "07", name: "Julho", label: "Julho", abbr: "Jul." },
    { value: 8, number: "08", name: "Agosto", label: "Agosto", abbr: "Ago." },
    { value: 9, number: "09", name: "Setembro", label: "Setembro", abbr: "Set." },
    { value: 10, number: "10", name: "Outubro", label: "Outubro", abbr: "Out." },
    { value: 11, number: "11", name: "Novembro", label: "Novembro", abbr: "Nov." },
    { value: 12, number: "12", name: "Dezembro", label: "Dezembro", abbr: "Dez." },
];

export const years = [
    { value: 1, index: 1, number: "2018", label: "2018" },
    { value: 2, index: 2, number: "2019", label: "2019" },
    { value: 3, index: 3, number: "2020", label: "2020" },
    { value: 4, index: 4, number: "2021", label: "2021" },
    { value: 5, index: 5, number: "2022", label: "2022" },
    { value: 6, index: 6, number: "2023", label: "2023" },
    { value: 7, index: 7, number: "2024", label: "2024" },
    { value: 8, index: 8, number: "2025", label: "2025" },
    { value: 9, index: 9, number: "2026", label: "2026" },
    { value: 10, index: 10, number: "2027", label: "2027" }
];

export const DROPDOWN_MONTH = [
    { name: "JANEIRO", number: '01' },
    { name: "FEVEREIRO", number: '02' },
    { name: "MARÇO", number: '03' },
    { name: "ABRIL", number: '04' },
    { name: "MAIO", number: '05' },
    { name: "JUNHO", number: '06' },
    { name: "JULHO", number: '07' },
    { name: "AGOSTO", number: '08' },
    { name: "SETEMBRO", number: '09' },
    { name: "OUTUBRO", number: '10' },
    { name: "NOVEMBRO", number: '11' },
    { name: "DEZEMBRO", number: '12' },
]

export const DROPDOWN_YEAR = [
    { name: "2021" },
    { name: "2022" },
    { name: "2023" },
    { name: "2024" },
    { name: "2025" },
    { name: "2026" },
    { name: "2027" }
]



export function somaHora(hrA, hrB) {
    if (hrA.length !== 8 || hrB.length !== 8) return "00:00:00";

    let temp = 0;
    let nova_h = "";
    let novo_m = "";
    let novo_s = "";

    const hora1 = parseInt(hrA.substring(0, 2)) * 1;
    const hora2 = parseInt(hrB.substring(0, 2)) * 1;
    const minu1 = parseInt(hrA.substring(3, 5)) * 1;
    const minu2 = parseInt(hrB.substring(3, 5)) * 1;
    const seg1 = parseInt(hrA.substring(6, 8)) * 1;
    const seg2 = parseInt(hrB.substring(6, 8)) * 1;

    temp = seg1 + seg2;
    while (temp > 59) {
        novo_m++;
        temp = temp - 60;
    }
    novo_s = temp.toString().length === 2 ? `${temp}` : `0${temp}`;

    temp = minu1 + minu2 + novo_m;
    while (temp > 59) {
        nova_h++;
        temp = temp - 60;
    }
    novo_m = temp.toString().length === 2 ? `${temp}` : `0${temp}`;

    temp = hora1 + hora2 + nova_h;
    nova_h = temp.toString().length === 2 ? `${temp}` : `0${temp}`;

    return nova_h + ":" + novo_m + ":" + novo_s;
}

export function formatHourToSeconds(hour) {
    const a = hour.split(":");
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}

export function formatNumber(value) {
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
}

export function formatNumberS(value) {
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
}

export function getClientName(clients, client_id) {
    return clients.find(p => p.id === client_id).name;
}

export function registerPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === REGISTER_ID_PERMISSION)) ? true : false;
}

export function commandPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === COMMANDS_ID_PERMISSION)) ? true : false;
}

export function financialPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === FINANCIAL_ID_PERMISSION)) ? true : false;
}

export function creditCardPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === CREDIT_CARD_PERMISSION)) ? true : false;
}

export function reportsPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === REPORTS_ID_PERMISSION)) ? true : false;
}

export function officierPermission(profile) {
    if (typeof profile == 'undefined') return false;
    return (profile.find(p => p.profile_id === OFFICIER_ID_PERMISSION)) ? true : false;
}

export function isPasswordCommandValid(password) {

    if (password.length > 4) return "MAX_LIMIT"
    if (password.length === 4) {
        if (password === COMMAND_PASSWORD) return "SUCCESS"
        return "ERRO"
    }

    return ""

}