import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ehDiaUtil from '@lfreneda/eh-dia-util';
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, financialPermission } from '../../../helper';
import {
  Alert, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import { Ticket, TicketInfo, TicketNumber } from '../FieldsRevenues/style';
import {
  Button, Container, FieldHorizontal, HorizontalField, InfoHeader,
  InfoPayment, Row,
  Row2,
  ViewButtons
} from './style';



const FieldsExpense = ({ client_id, callback, monthSelected, yearSelected }) => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const objSelected = useSelector(state => state.objSelected)
  const client = useSelector(state => state.client)

  const arrTypeExpenses = useSelector(state => state.typeExpenses)
  const arrTypePayments = useSelector(state => state.typePayments)

  const [showExpenses, setShowExpenses] = useState(true)
  const [showFixedExpenses, setShowFixedExpenses] = useState(false)

  const [fixedExpenses, setFixedExpenses] = useState([])
  const [totalFixedExpenses, setTotalFixedExpenses] = useState(0)

  const [id, setId] = useState(0)

  const [provider_id, setProviderId] = useState(0)
  const [provider_name, setProviderName] = useState("")

  const [type_expense_id, setTypeExpenseId] = useState(0)

  const [financial_payer_id, setFinancialPayerId] = useState(0)
  const [arrayFinancialPayer, setArrayFinancialPayer] = useState([])

  const [payment_type, setPaymentType] = useState("BOLETO")
  const [document_number, setDocumentNumber] = useState("")
  const [due_date, setDueDate] = useState("")
  const [document_value, setDocumentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [payment_value, setPaymentValue] = useState({ formattedValue: "", value: 0, floatValue: 0 })
  const [payment_date, setPaymentDate] = useState("")
  const [note, setNote] = useState("")
  const [canceled, setCanceled] = useState(false)
  const [cancel_reason, setCancelReason] = useState("")

  const [twice, setTwice] = useState("À VISTA")
  const [twice_quantity, setTwiceQuantity] = useState(2)
  const [twice_fees, setTwiceFees] = useState(0)
  const [array_twices, setArrayTwices] = useState([])

  const [textDesc, setTextDesc] = useState("Adicionar")
  const [textAlert, setTextAlert] = useState("")
  const [textButton, setTextButton] = useState("Salvar")
  const [textErrorButtons, setTextErrorButtons] = useState("")

  const [myRef, setMyRef] = useState()

  const [stringdate, setStringDate] = useState("")

  function handlerSwitch() {
    setCanceled(!canceled)
  }

  useEffect(() => {

    Moment.locale('pt-br');
    setTextAlert("")

    setStringDate(`${monthSelected.name} de ${yearSelected.number}`)

    if (arrTypeExpenses.length === 0) {
      findTypeExpenses()
    }

    if (arrTypePayments.length === 0) {
      findTypePayments()
    }

    if (Object.keys(objSelected).length > 0) {

      if (!objSelected.clone) {
        setId(objSelected.id)
        setDueDate(Moment(objSelected.due_date).format("DD/MM/YYYY"))
        setTextDesc("Atualizar")

        if (objSelected.payment_date) {
          setPaymentDate(Moment(objSelected.payment_date).format("DD/MM/YYYY"))
          setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
        }
      }

      if (objSelected.clone) {
        setDueDate(Moment(objSelected.due_date).add(1, 'M').format("DD/MM/YYYY"))
        if (objSelected.payment_date) {
          //setPaymentDate(Moment(objSelected.payment_date).add(1, 'M').format("DD/MM/YYYY"))
          //setPaymentValue({ formattedValue: objSelected.payment_value, value: objSelected.payment_value, floatValue: objSelected.payment_value })
        }
      }

      if (!objSelected.payment_date && objSelected.payoff) {
        setPaymentDate(Moment().format("DD/MM/YYYY"))
        setPaymentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })
      }

      setDocumentNumber(objSelected.document_number)
      setProviderId(objSelected.provider.id)
      setProviderName(objSelected.provider.name)
      setTypeExpenseId(objSelected.type_expense_id)
      setFinancialPayerId(objSelected.financial_payer_id)
      setPaymentType(objSelected.payment_type)
      setDocumentValue({ formattedValue: objSelected.document_value, value: objSelected.document_value, floatValue: objSelected.document_value })

      setNote(objSelected.note)
      setCanceled(objSelected.canceled)
      setCancelReason(objSelected.cancel_reason)

    }

    findFinancialPayer()

  }, [objSelected])

  useEffect(() => {

    var juros = (twice_fees === 0 || twice_fees === "") ? 0 : twice_quantity * document_value.floatValue * (twice_fees / 100);
    var total = document_value.floatValue + juros;
    var parcelas = total / twice_quantity;

    var i = 0;
    var aux = [];

    while (i < twice_quantity) {

      var dt = (due_date !== "") ? Moment(due_date, 'DD/MM/YYYY').add(i, 'months').format('DD/MM/YYYY') : ""
      if (dt !== "" && due_date.indexOf("_") === -1 && !ehDiaUtil(Moment(dt, 'DD/MM/YYYY').format("YYYY-MM-DD"))) {
        do {
          dt = Moment(dt, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
          console.log(dt);
        } while (!ehDiaUtil(Moment(dt, 'DD/MM/YYYY').format("YYYY-MM-DD")));
      }

      aux.push({
        parcela: i + 1,
        data: dt,
        valor: { formattedValue: parcelas, value: parcelas, floatValue: parcelas }
      })

      i++;
    }

    setArrayTwices(aux)

  }, [twice_quantity, document_value, due_date, twice_fees])

  async function findTypeExpenses() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_expenses', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_EXPENSES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function findTypePayments() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_payments', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_PAYMENTS", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function findFinancialPayer() {

    try {

      let response = await fetch(DEFAULT_URL + 'financial_payer/actives', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
        })
      });

      let responseJson = await response.json();
      // console.log(responseJson)

      if (responseJson.success) {
        setArrayFinancialPayer(responseJson.data);
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function saveData() {

    if (!financialPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para financeiro")
      return
    }

    if (provider_id === 0) {
      setTextAlert("Informe um Fornecedor")
      return
    }

    if (financial_payer_id === 0) {
      setTextAlert("Informe um Pagador")
      return
    }

    if (document_number === "") {
      setTextAlert("Informe o campo Número da nota")
      return
    }

    if (document_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor do documento")
      return
    }

    if (due_date === "") {
      setTextAlert("Informe o campo Data de vencimento")
      return
    }

    const f_due_date = Moment(due_date, 'DD/MM/YYYY');
    if (!f_due_date.isValid()) {
      setTextAlert("Data de vencimento inválida")
      return;
    }

    if (payment_value.formattedValue !== "" && payment_date === "") {
      setTextAlert("Informe o campo Data de pagamento")
      return
    }

    if (payment_date !== "" && payment_value.formattedValue === "") {
      setTextAlert("Informe o campo Valor de pagamento")
      return
    }

    var f_payment_date = ""
    if (payment_date !== "") {
      f_payment_date = Moment(payment_date, 'DD/MM/YYYY')
      if (!f_payment_date.isValid()) {
        setTextAlert("Data de pagamento inválida")
        return
      }
    }

    if (canceled && cancel_reason === "") {
      setTextAlert("Informe o motivo do cancelamento")
      return
    }

    setTextAlert("")
    setTextButton("Aguarde..")

    const url = (id === 0) ? 'expenses' : `expenses/${id}/update`

    if (twice === "À VISTA") {

      try {

        let response = await fetch(DEFAULT_URL + url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
          body: JSON.stringify({
            client_id: client_id,
            provider_id: provider_id,
            financial_payer_id: financial_payer_id,
            type_expense_id: type_expense_id,
            payment_type: payment_type,
            document_number: document_number,
            due_date: f_due_date.format("YYYY-MM-DD"),
            document_value: document_value.floatValue,
            payment_value: payment_value.floatValue,
            payment_date: (payment_date != "") ? f_payment_date.format("YYYY-MM-DD") : null,
            note: note,
            system: false,
            cancel_reason: cancel_reason,
            canceled: canceled
          })
        });

        let responseJson = await response.json();
        console.log(responseJson)

        setTextButton("Salvar")

        if (responseJson.success) {
          callback();
          return
        }

        let { message } = responseJson.details;
        if (message === 'error_authorization') {
          dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
          localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
          return
        }

        setTextAlert("Erro ao efetuar operação.")

      } catch (error) {
        console.error(error);
      }

    }

    for (const key in array_twices) {

      let responseValid = await fetch(DEFAULT_URL + url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client_id,
          provider_id: provider_id,
          financial_payer_id: financial_payer_id,
          type_expense_id: type_expense_id,
          payment_type: payment_type,
          document_number: document_number,
          document_value: array_twices[key].valor.floatValue,
          due_date: Moment(array_twices[key].data, 'DD/MM/YYYY').format("YYYY-MM-DD"),
          payment_date: (payment_date !== "") ? f_payment_date.format("YYYY-MM-DD") : null,
          payment_value: payment_value.floatValue,
          note: note,
          system: false,
          canceled: canceled,
          cancel_reason: cancel_reason,
        })
      });

      let responseJsonValid = await responseValid.json();
      if (responseJsonValid.success) {
        if (parseInt(key) === (array_twices.length - 1)) {
          callback()
        }
      }

    }


  }

  async function getFixedExpenses() {

    if (!financialPermission(user.profile)) {
      setTextAlert("Usuário sem permissão para financeiro")
      return
    }

    setTextErrorButtons("")
    setShowExpenses(false)
    setShowFixedExpenses(true)
    setFixedExpenses([])

    let monthNow = monthSelected.number
    let yearNow = yearSelected.number

    try {

      let response = await fetch(DEFAULT_URL + 'fixed_expenses/' + client_id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();

      if (responseJson.success) {

        var fixedExpenses = [];
        var totalFixedExpenses = 0;

        responseJson.data.map(item => {

          if (item.active) {

            totalFixedExpenses = totalFixedExpenses + parseFloat(item.document_value)
            var exp_day = (`${item.expiration_day}`.length == 1) ? "0" + item.expiration_day : item.expiration_day

            fixedExpenses.push({
              client_id: client_id,
              financial_payer_id: 1,
              provider_id: item.provider.id,
              provider_name: item.provider.name,
              type_expense_id: item.type_expense.id,
              type_expense_name: item.type_expense.name,
              payment_type: item.payment_type,
              document_number: 1,
              due_date: `${yearNow}-${monthNow}-${exp_day}`,
              document_value: item.document_value,
              payment_value: 0,
              payment_date: null,
              note: "",
              cancel_reason: "",
              canceled: false,
              uploading: "NOT"
            })
          }
        })

        setTotalFixedExpenses(totalFixedExpenses)
        setFixedExpenses(fixedExpenses)
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  async function generateExpenses() {

    setTextErrorButtons("")

    var auxExpenses = fixedExpenses;
    setFixedExpenses([])
    var newExpenses = [];

    for (const key in auxExpenses) {

      console.log(auxExpenses[key])

      let responseValid = await fetch(DEFAULT_URL + 'expenses', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client_id,
          provider_id: auxExpenses[key].provider_id,
          type_expense_id: auxExpenses[key].type_expense_id,
          payment_type: auxExpenses[key].payment_type,
          document_number: auxExpenses[key].document_number,
          due_date: auxExpenses[key].due_date,
          document_value: auxExpenses[key].document_value,
          payment_value: auxExpenses[key].payment_value,
          payment_date: auxExpenses[key].payment_date,
          note: auxExpenses[key].note,
          system: true,
          cancel_reason: auxExpenses[key].cancel_reason,
          canceled: auxExpenses[key].canceled,
          financial_payer_id: 1
        })
      });

      let responseJsonValid = await responseValid.json();
      if (responseJsonValid.success) {
        auxExpenses[key].uploading = "OK"
        newExpenses = [...newExpenses, auxExpenses[key]]
        setFixedExpenses(newExpenses)

        if (parseInt(key) == (auxExpenses.length - 1)) {
          callback()
        }
      }

    }
  }

  return (
    <Container>

      <Title>{textDesc.toUpperCase()}: CONTA A PAGAR</Title>
      <Row />

      <ViewButtons>
        <Button onClick={() => getFixedExpenses()}>GERAR DESPESAS FIXAS</Button>
      </ViewButtons>

      {textErrorButtons !== "" &&
        <label style={{ color: 'red' }}>
          {textErrorButtons}
        </label>
      }

      <Row />


      {showExpenses && <>
        <AutoComplete
          label={"Fornecedor"}
          finder={"providers"}
          item={{ id: provider_id, name: provider_name }}
          setSelected={(item) => {
            setProviderId(item.id)
            setProviderName(item.search)
          }}
        />
        <Row />

        <HorizontalField>

          <FieldHorizontal>
            <label>Tipo:</label>
            <CheckBox
              ref={el => setMyRef(el)}>
              {arrTypeExpenses.map((e, i) => {

                if (type_expense_id === e.id) {
                  if (i > 6) myRef.scrollTo(i * 90, 0)
                }

                if (e.active) {
                  const name = (e.name.length > 20) ? e.name.substr(0, 19) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => setTypeExpenseId(e.id)}
                      className={(type_expense_id === e.id) ? 'selected' : ''}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>
          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Tipo Pagamento:</label>

            <CheckBox>
              {arrTypePayments.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => setPaymentType(e.name)}
                      className={(payment_type === e.name) ? 'selected' : ''}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>

          </FieldHorizontal>

        </HorizontalField>
        <Row />

        <FieldHorizontal>
          <label>Pagador:</label>
          <CheckBox
            ref={el => setMyRef(el)}>
            {arrayFinancialPayer.map((e, i) => {
              if (e.active) {
                return (
                  <Check
                    key={e.id}
                    onClick={() => setFinancialPayerId(e.id)}
                    className={(financial_payer_id === e.id) ? 'selected' : ''}>
                    {e.name}
                  </Check>)
              }
            })}
          </CheckBox>
        </FieldHorizontal>

        <HorizontalField>

          <FieldHorizontal>
            <label>Número da nota:</label>

            <input
              type="text"
              placeholder="Número da nota"
              value={document_number}
              onChange={event => setDocumentNumber(event.target.value)} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Valor do documento:</label>

            <NumberFormat
              value={document_value.formattedValue}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
              onValueChange={(values) => {
                setDocumentValue(values)
              }} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Data de vencimento:</label>

            <InputMask
              mask="99/99/9999"
              type="text"
              placeholder="Data de vencimento"
              value={due_date}
              onChange={event => setDueDate(event.target.value)} />

          </FieldHorizontal>

        </HorizontalField>

        {(Object.keys(objSelected).length === 0) &&
          <>
            <HorizontalField>

              <FieldHorizontal>
                <label>Condição:</label>
                <CheckBox>

                  <Check
                    onClick={() => { setTwice("À VISTA") }}
                    className={(twice === "À VISTA") ? 'selected' : ''}>
                    À VISTA
                  </Check>

                  <Check
                    onClick={() => { setTwice("A PRAZO") }}
                    className={(twice === "A PRAZO") ? 'selected' : ''}>
                    A PRAZO
                  </Check>

                </CheckBox>

              </FieldHorizontal>

              {(twice === "A PRAZO") &&
                <>
                  <Row2 />

                  <CheckBox>

                    <div style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', fontSize: 16, fontWeight: 'bold', left: 15, top: 0 }}>x</span>
                      <input
                        type="number"
                        min="2"
                        value={twice_quantity}
                        style={{ background: '#f8f8f8', marginTop: 25, borderRadius: 5, width: 40, height: 40, textAlign: 'center' }}
                        onChange={event => setTwiceQuantity(event.target.value)} />
                    </div>

                    <div style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', fontSize: 15, fontWeight: 'bold', left: 35, top: 1 }}>%</span>
                      <input
                        type="number"
                        step="0.01"
                        min="0.00"
                        value={twice_fees}
                        style={{ background: '#f8f8f8', marginTop: 25, marginLeft: 15, borderRadius: 5, width: 60, height: 40, textAlign: 'center' }}
                        onChange={event => setTwiceFees(event.target.value)} />
                    </div>

                    {array_twices.map(i => {

                      return (
                        <Ticket>
                          <TicketNumber>
                            <strong> {i.parcela}ª</strong>
                          </TicketNumber>

                          <TicketInfo>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              style={{
                                borderWidth: 0,
                                marginTop: 10,
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                outline: "none"
                              }}
                              placeholder="__/__/____"
                              value={i.data}
                              onChange={event => {
                                const aux = [];
                                array_twices.map(tw => {
                                  if (tw.parcela == i.parcela)
                                    tw.data = event.target.value;
                                  aux.push(tw);
                                });
                                setArrayTwices(aux);
                              }} />
                            <span>
                              <NumberFormat
                                value={i.valor.formattedValue}
                                thousandSeparator={"."}
                                prefix={'R$ '}
                                decimalScale={2}
                                placeholder={"R$"}
                                decimalSeparator={","}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                style={{ width: '100%', fontSize: 12, textAlign: 'center', background: '#f9f9f9', fontWeight: 'bold' }}
                                allowedDecimalSeparators={["."]} />
                            </span>

                          </TicketInfo>
                        </Ticket>
                      )

                    })}
                  </CheckBox>
                </>
              }

            </HorizontalField>
            <Row />
          </>
        }

        <Field>
          <label>Descrição:</label>

          <textarea
            type="text"
            value={note}
            onChange={event => setNote(event.target.value)} />

        </Field>

        <Row />

        <HorizontalField>

          <FieldHorizontal>
            <label>Data de pagamento:</label>

            <InputMask
              mask="99/99/9999"
              type="text"
              placeholder="Data de pagamento"
              value={payment_date}
              onChange={event => setPaymentDate(event.target.value)} />

          </FieldHorizontal>

          <Row2 />

          <FieldHorizontal>
            <label>Valor pago:</label>

            <NumberFormat
              value={payment_value.formattedValue}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
              onValueChange={(values) => {
                setPaymentValue(values)
              }} />

          </FieldHorizontal>

        </HorizontalField>

        <Row />

        <Select>
          <label>Cancelado:</label>
          <Switch onChange={() => handlerSwitch()} checked={canceled} />
        </Select>

        {canceled &&
          <Field>
            <label>Motivo do cancelamento:</label>

            <textarea
              type="text"
              value={cancel_reason}
              onChange={event => setCancelReason(event.target.value)} />

          </Field>}

        <ViewSubmit>

          {textAlert !== "" &&
            <Alert>
              {textAlert}
            </Alert>}
          {textAlert === "" && <div />}

          <Button onClick={() => saveData()}>
            {textButton}
          </Button>

        </ViewSubmit>
      </>
      }

      {
        showFixedExpenses && <>

          <div style={{ backgroundColor: '#222', padding: 10, color: '#fff' }}>{stringdate}</div>

          <InfoHeader>

            <label>FORNECEDOR</label>
            <span>TIPO DESPESA</span>
            <strong>VALOR</strong>
            <strong>VENCIMENTO</strong>

          </InfoHeader>

          {fixedExpenses.map((cli, key) => {

            return (<InfoPayment>

              <label>{cli.provider_name}</label>
              <span>{cli.type_expense_name}</span>

              <NumberFormat
                value={cli.document_value}
                thousandSeparator={"."}
                prefix={'R$ '}
                decimalScale={2}
                suffix={(cli.discot) ? "*" : ""}
                style={{ textAlign: 'right', width: '15%' }}
                disabled={true}
                placeholder={"R$"}
                decimalSeparator={","}
                fixedDecimalScale={true}
                allowNegative={false}
                allowedDecimalSeparators={["."]}
              />

              <strong>

                {cli.uploading === "OK" ?
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: 'green', width: 25, height: 20, position: 'absolute', right: 25 }}
                    onClick={() => {

                    }}
                  /> : Moment(cli.due_date).format("DD/MM/YYYY")}
              </strong>

            </InfoPayment>)

          })}


          <InfoPayment>

            <label style={{ fontWeight: 'bold' }}>Despesas</label>
            <span></span>
            <NumberFormat
              value={totalFixedExpenses}
              thousandSeparator={"."}
              prefix={'R$ '}
              decimalScale={2}
              style={{ textAlign: 'right', width: '15%', fontWeight: 'bold' }}
              disabled={true}
              placeholder={"R$"}
              decimalSeparator={","}
              fixedDecimalScale={true}
              allowNegative={false}
              allowedDecimalSeparators={["."]}
            />
            <strong>

            </strong>
          </InfoPayment>

          <Button onClick={() => generateExpenses()}>GERAR DESPESAS</Button>

        </>
      }

    </Container>
  )

}

export default FieldsExpense;
