import React from 'react';
import { useSelector } from 'react-redux';
import TimelineMaintenances from '../TimelineMaintenances';
import ReportBatteryBank from './ReportBatteryBank';
import ReportChassi from './ReportChassi';
import ReportChip from './ReportChip';
import ReportHBoard from './ReportHBoard';
import ReportMaintenance from './ReportMaintenance';
import ReportMaintenanceAll from './ReportMaintenanceAll';
import ReportMotors from './ReportMotors';
import ReportNBoard from './ReportNBoard';
import ReportNiple from './ReportNiple';
import ReportSensorBoard from './ReportSensorBoard';
import ReportTracker from './ReportTracker';
import {
    Container, Info, TitleInfo
} from './style';


const Report: React.FC = () => {

    const page = useSelector(state => state.page)

    return (
        <Container>

            <TitleInfo>Relatório: {page.data}</TitleInfo>

            <Info>

                {page.data === "Banco de baterias" && <ReportBatteryBank />}
                {page.data === "Chip" && <ReportChip />}
                {page.data === "Chassi" && <ReportChassi />}
                {page.data === "H.Board" && <ReportHBoard />}
                {page.data === "N.Board" && <ReportNBoard />}
                {page.data === "Rastreador" && <ReportTracker />}
                {page.data === "Niple" && <ReportNiple />}
                {page.data === "Manutenções Geral" && <ReportMaintenanceAll />}
                {page.data === "Manutenções" && <ReportMaintenance />}
                {page.data === "Motores" && <ReportMotors />}
                {page.data === "Placa de sensores" && <ReportSensorBoard />}
                {page.data === "Timeline" && <TimelineMaintenances />}

            </Info>

        </Container>
    )

}

export default Report;