import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_LIMIT_ITENS_REPORTS } from '../../../helper';
import PDFStyle from '../../../styles/PDFStyle';
import Footer from '../PDFLayout/Footer';
import Header from '../PDFLayout/Header';


const PDFExpensesGroup: React.FC = ({ onClose, layout, client_id, callback }) => {

    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    var color = "#f1f1f1";
    var pages = 0;
    var totalItens = 0
    var total = 0
    var arrayPages = []
    var cntArray = 0
    var row = 0
    var totalAll = [];
    var cntTotalAll = 0;

    objSelected.map((item, i) => {

        item.map(it => {
            if (!it.canceled) {
                if (typeof totalAll[cntTotalAll] === 'undefined') {
                    totalAll[cntTotalAll] = { name: it.type_expense.name, value: parseFloat(it.document_value) };
                } else {
                    totalAll[cntTotalAll].value = totalAll[cntTotalAll].value + parseFloat(it.document_value);
                }
            }
        })

        if (typeof arrayPages[cntArray] === 'undefined') {
            arrayPages[cntArray] = [totalAll[cntTotalAll]]
        } else {
            arrayPages[cntArray].push(totalAll[cntTotalAll])
        }

        cntTotalAll++;
    })

    row = cntTotalAll;

    objSelected.map((item, i) => {

        item.map(it => {

            if (!it.canceled) {

                totalItens++;
                row++;
                total = total + parseFloat(it.document_value);

                if (row < DEFAULT_LIMIT_ITENS_REPORTS) {
                    if (typeof arrayPages[cntArray] === 'undefined') {
                        arrayPages[cntArray] = [it]
                    } else {
                        arrayPages[cntArray].push(it)
                    }
                } else {
                    arrayPages[cntArray].push(it)
                    cntArray++;
                    row = 0;
                }

            }
        })
    })

    // console.log(arrayPages)

    pages = cntArray + 1;

    return (
        <PDFViewer style={{ width: "100%", height: "100%" }}>
            <Document title="DESPESAS POR GRUPO" author="THREE PIXELS SISTEMAS" creator="CLOCT" producer="CLOCT">

                {arrayPages.map((itemPage, page) => {

                    return (

                        <Page size="A4" style={PDFStyle.page}>

                            <Header title={"Despesas por grupo"} user={user.name} />

                            <div style={PDFStyle.body}>

                                {/* <div style={{ ...PDFStyle.row, borderBottom: "1px solid #444", backgroundColor: "#fff", color: "#222" }}>
                                    <Text style={{ width: "25%", paddingLeft: 5, fontWeight: "bold", ...PDFStyle.title }}>TIPO DE DESPESA</Text>
                                    <Text style={{ width: "60%", textAlign: 'left', fontWeight: "bold", ...PDFStyle.title }}>FORNECEDOR</Text>
                                    <Text style={{ width: "15%", textAlign: "right", fontWeight: "bold", paddingRight: 5, ...PDFStyle.title }}>VALOR</Text>
                                </div> */}

                                {itemPage.map((item, i) => {

                                    color = (i % 2 === 0) ? "#ddd" : "#fff"

                                    if (i < cntTotalAll && page == 0) {
                                        return (
                                            <div style={{ ...PDFStyle.row, backgroundColor: color }}>
                                                <Text style={{ width: "35%", paddingLeft: 5, ...PDFStyle.desc }}>{item.name}</Text>
                                                <Text style={{ width: "50%", textAlign: 'left', ...PDFStyle.desc }}></Text>
                                                <Text style={{ width: "15%", textAlign: 'right', ...PDFStyle.desc, paddingRight: 5 }}>R$ {item.value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                            </div>
                                        )
                                    }

                                    var name = (item.provider.name.length < 55) ? item.provider.name : item.provider.name.substr(0, 55) + ".."

                                    return (
                                        <div style={{ ...PDFStyle.row, backgroundColor: color, borderTop: (i == cntTotalAll && page == 0) ? "1px solid #222" : "" }}>
                                            <Text style={{ width: "35%", paddingLeft: 5, ...PDFStyle.desc }}>{item.type_expense.name}</Text>
                                            <Text style={{ width: "50%", textAlign: 'left', ...PDFStyle.desc }}>{name}</Text>
                                            <Text style={{ width: "15%", textAlign: "right", ...PDFStyle.desc, paddingRight: 5 }}>R$ {item.document_value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                        </div>
                                    )
                                })}

                                {page === (arrayPages.length - 1) &&
                                    <div style={{ ...PDFStyle.row, borderTop: "1px solid #444", backgroundColor: "#fff", color: "#222" }}>
                                        <Text style={{ width: "60%", paddingLeft: 5, ...PDFStyle.desc }}>TOTAL: {totalItens}</Text>
                                        <Text style={{ width: "25%", textAlign: 'left', ...PDFStyle.desc }}></Text>
                                        <Text style={{ width: "15%", textAlign: "right", ...PDFStyle.desc, paddingRight: 5 }}>R$ {total.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                                    </div>
                                }

                            </div>

                            <Footer page={page} pages={pages} />

                        </Page>)

                })}

            </Document>

        </PDFViewer>
    )
}

export default PDFExpensesGroup;