import styled, { createGlobalStyle } from 'styled-components';

export const ContainerGlobal = createGlobalStyle`
  body {
    background: #E5E3DF !important;
  }
`;

export const ContentHeader = styled.div`
  padding: 5px 0;
  margin: 0;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #000;
  z-index: 999;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;

  > .diagnostic,
  > .status,
  > .report,
  > .voicer {
    padding: 5px;
    margin-left: 5px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    > div {
      margin-right: 5px;
      width: 15px;
      min-width: 15px;
      max-width: 15px;
      height: 15px;
      min-height: 15px;
      max-height: 15px;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }
  .voicer {
    padding-right: 15px;
    border-right: 1px solid #fff;
  }
  .diagnostic {
    padding-left: 15px;
    border-left: 1px solid #fff;
  }
  .report.report-selected {
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    &.selected,
    &:hover {
      border: 1px solid #ffffff;
    }
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  justify-content: flex-start;

  .current-card-view-selected {
    position: fixed;
    right: 25vw;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999 !important;
    width: 500px;
    height: 400px;
    box-shadow: 1px 1px 10px rgba(0,0,0,.5);
  }

  .shake {
    animation: shake 2s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  h1,
  h2 {
    display: flex;
    flex: 1;
  }
`;

export const Content = styled.div`
  padding: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;

export const ContentCards = styled.div`
  padding: 0;
  width: 75%;
  height: calc(100% - 105px);
  z-index: 1;
  position: relative;
  overflow: auto;
`;

export const ContentMap = styled.div`
  padding: 0;
  width: 25vw;
  height: calc(100% - 105px);
  z-index: 1;
  position: fixed;
  top: 105px;
  right: 0;
  display: flex;
  /* background-color: #E5E3DF; */
`;

export const LinearDetail = styled.div`
  background: linear-gradient(90deg, #E5E3DF, rgba(255, 255, 255, 0.0));
  height: 100vh;
  width: 5vw;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
`;

export const Cards = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
`;

export const CardView = styled.div`
  padding: 5px;
  width: 350px;
  height: 400px;
  margin: 0 0 5px 0;

  .remove-card {
    padding: 5px;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 4px);
    height: 38px;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
    z-index: 999;
    border-radius: 12px;
    font-size: 13px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
  .remove-card > p,
  .remove-card > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .remove-card > p {
    flex: 1;
  }
  .remove-card > div > button {
    margin: 0 2px !important;
  }

  .current-card-suspicion {
    position: relative;
    background-color: #eee;
  }

  .current-card-toggle {
    padding: 0 !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 5px !important;
    margin: auto !important;
    width: 15px !important;
    min-width: 15px !important;
    max-width: 15px !important;
    height: 15px !important;
    min-height: 15px !important;
    max-height: 15px !important;
    z-index: 1 !important;
    overflow: hidden !important;
  }

  .current-card-toggle > button {
    padding: 0;
    background-color: #eee;
    border-radius: 50%;
    color: #d2d2d2;
    width: 15px;
    height: 15px;
    border: 2px solid #ffffff;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    margin: 0 !important;
  }
  .current-card-toggle > button.active,
  .current-card-toggle > button:hover {
    background-color: #039422;
  }

  .current-card-main-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .current-card-main-toggle.remove {
    right: 50px;
  }
  .remove-card button,
  .current-card-main-toggle > button {
    padding: 0;
    background-color: #eee;
    border-radius: 50%;
    color: #d2d2d2;
    width: 30px;
    height: 30px;
    border: 1px solid #eee;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    margin: 0 !important;
  }
  .remove-card button.active,
  .remove-card button:hover,
  .current-card-main-toggle > button.active,
  .current-card-main-toggle > button:hover {
    background-color: #000000;
  }


  &.current-card-view {
    border-radius: 15px;
    box-shadow: inset 1px 1px 15px rgba(0,0,0,.2);
  }

  > div {
    padding: 7px;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 15px;
    position: relative;
    /* border: 5px solid #d1d1d1; */
    border-left: 8px solid #fff;
    box-shadow: 1px 1px 10px rgba(0,0,0,.2);
    overflow-x: hidden;
    overflow-y: auto;
  }

  > div > .time {
    margin: 0;
    height: 30px;
    width: 150px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: flex;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
  }

  > div > .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 2px auto;

    > .board {
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }

    > .emp {
      font-weight: bold;
      font-size: 15px;
      color: #333;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
      white-space: nowrap;
    }

    > .city {
      font-weight: bold;
      font-size: 15px;
      color: #333;
    }

  }

  > div .column {
    width: 100%;
    margin: 7px auto;

    > .info {
      font-size: 13px;
      color: #666;
    }

    > .date {
      font-weight: bold;
      font-size: 14px;
      color: #333;
      &.date-suspicions {
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      > small {
        color: #000;
        font-size: 11px;
        display: block;
      }
    }
  }

  > div > .last-maintenance {
    /* background-color: #eee; */
    //height: 60%;
    padding: 20px 0px 0 0px;
    /* border-top: 3px solid #222; */
  }

  .history {
    padding: 0;
    /* background: #eee;
    border: 1px solid #dee2e6; */
    list-style: none;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .history ul,
  .history ul ul {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
  }
  .history ul li,
  .history ul ul li {
    padding: 0;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .history-group-click {
    cursor: pointer;
  }
  .history .history-group .active {
    margin-top: 2px;
  }
  .history ul li:not(:last-child) {
    margin-bottom: 2px;
  }
  .history ul li > div,
  .history ul ul li > div {
    line-height: 1;
    text-align: left;
    color: #000;
    font-weight: bold;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    background-color: #eee;
  }

  .history ul li > div {
    padding: 3px;
    font-size: 15px;
  }
  .history ul li > div.CRI {
    color: #cc0404;
  }

  .history-flag,
  .history-flag > div {
    border-radius: 50%;
    overflow: hidden;
  }
  .history-flag {
    display: flex;
    margin-right: 5px;
    width: 15px;
    min-width: 15px;
    max-width: 15px;
    height: 15px;
    min-height: 15px;
    max-height: 15px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
  }
  .history-flag > div {
    display: flex;
    margin: auto;
    width: 13px;
    min-width: 13px;
    max-width: 13px;
    height: 13px;
    min-height: 13px;
    max-height: 13px;
    border: 2px solid #ffffff;
  }

  .history-count {
    margin-right: 2px;
    display: inline-block;
  }

  .history-group-caret {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 14px;
    color: #000;
  }

  .history ul ul {
    display: none;
  }
  .history ul ul.active {
    display: flex;
  }

  .history ul ul li  {
    padding: 3px 3px 3px 25px;
    background-color: #eee;
  }
  .history ul ul li > div,
  .history ul ul li > small {
    padding: 0;
    font-size: 12px;
  }
  .history ul ul li > small {
    /* margin-bottom: 2px; */
  }

  .history ul li > small,
  .history ul ul li > small {
    color: #000;
    font-size: 12px;
    font-weight: normal;
  }
  .history ul li > small {
    padding-left: 5px;
  }
`;


export const IconCountBug = styled.div`
  height: 60px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 50px;
  top: 50px;
`;

export const IconCountWarning = styled.div`
  height: 60px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 50px;
  top: 120px;
`;


