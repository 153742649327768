import styled, { css } from 'styled-components';


export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    padding: 20px 15px;
    height: 100%;
    width: 200px;
    overflow-y: auto;
    background: #444;

    @media(max-width:1280px) {
        width: auto;
    }

`;

export const TopSide = styled.div`
    display: flex;
    flex-direction: column;
   // align-items: center;
   // @media(min-width: 1280px) {
        align-items: flex-start;
   // }
`;

export const Logo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    cursor: pointer;
    padding-bottom: 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #fff;

    > div {
        border-radius: 5px;
        font-weight: bold;
        font-size: 11px;
        color: #fff;
        height: 25px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
`;

export const MenuButton = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    outline: 0;
    height: 40px;
    width: 100%;
    cursor: pointer;

    > span {
        display: none;
        color:#ffffff;
        user-select: none;

        @media(min-width: 1280px) {
            display: inline;
            color: #fff;
            font-weight: bold;
            font-size: 16px;
        }
    }
`;

export const SubMenuButton = styled.div`

    height: 30px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 5px;

    div {

        color: #fff;
        height: 30px;
        width: 100%;
        font-size: 12px;
        user-select: none;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .selected {
        color:#999;
    }

`;


const iconCss = css`
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: var(--white);
    padding-top: 3px;
    &.active {
        color: var(--details);
    }
    @media(min-width: 1280px) {
        display:none;
    }
`;

export const Register = styled.div`
     ${iconCss}
`;

export const PDFs = styled.div`
     ${iconCss}
`;

export const Commands = styled.div`
     ${iconCss}
`;

export const Reports = styled.div`
     ${iconCss}
`;
