import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as MailActions } from "./../../store/reducers/mail";
import { Creators as RemittanceApiActions } from "./../../store/reducers/remittanceApi";
import { Creators as RemittanceFileActions } from "./../../store/reducers/remittanceFile";

import Ws from "./Ws";

const WebSocket = ({
  props: {
    currentUser
  },
  funcs: {
    addMail,
    setRemittanceApi,
    setRemittanceFile
  }
}) => {

  const [ws, setWs] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    initWebsocket();
  }, []); /* eslint-disable-line */

  const initWebsocket = () => {
    const ws = new Ws({
      callbacks: [
        {
          key: "connect",
          action: (e) => {
            console.log("Connect!");
          }
        },
        {
          key: "disconnect",
          action: () => {
            console.log("Disconnect!");
          }
        },
        {
          key: "mailWs",
          action: (e) => addMail(e)
        },
        {
          key: "remittancesApiWs",
          action: (e) => setRemittanceApi(e)
        },
        {
          key: "remittancesFileWs",
          action: (e) => setRemittanceFile(e)
        },
        {
          key: "returnsFileWs",
          action: (e) => {
            dispatch({ type: "SET_RETURN_FILE", data: e })
          }
        }
      ],
      url: process.env.REACT_APP_APP_WS_URL
    });
    if (currentUser) {
      ws.connect(currentUser.token, 0);
    }
    setWs(ws);
  };

  useEffect(() => {
    if (ws) {
      if (currentUser) {
        return ws.connect(currentUser.token, 0);
      }
      return ws.disconnect();
    }
  }, [currentUser]); /* eslint-disable-line */

  return (
    null
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.user.id === 0 ? false : state.user,
    remittanceFile: state.remittanceFile
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(
    Object.assign(
      {},
      MailActions,
      RemittanceApiActions,
      RemittanceFileActions
    ),
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket);
