import { faChartPie, faCheck, faCommentDots, faCreditCard, faExclamationTriangle, faEye, faFilePdf, faFileUpload, faListOl, faPlusCircle, faSpinner, faTimesCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { bindActionCreators } from "redux";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, financialPermission, formatNumber, months, years } from '../../../helper';
import { Creators as MailActions } from "../../../store/reducers/mail";
import { Creators as WppActions } from "../../../store/reducers/wpp";
import Modal from '../../Modal';
import {
  Bank,
  BankSign,
  Bills, BillsInfo, Container, Date, Header, InfoHeader, InfoReturns,
  InfoRow,
  InfoRowExp, Infos, ProgressDraggable,
  ProgressDraggableBar,
  ProgressDraggableText, RightButtonClick, Row,
  Row1,
  Row2,
  RowWhite,
  Total
} from './style';


const coloralert = "#bd5959";
const badpayerColor = "#807c0a"
const realBalanceColor = "#036c15"
const redimentColor = "#2a7bcb"

const FinancialBills = ({ props: { mails, wpps }, funcs: { addMail, addWpp } }) => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)
  const objSelected = useSelector(state => state.objSelected)
  const returnFile = useSelector(state => state.returnFile)
  const search = useSelector(state => state.search)

  const arrTypeRevenues = useSelector(state => state.typeRevenues)

  var myRef = React.createRef()

  const [loadExpenses, setLoadExpenses] = useState(false);
  const [loadRevenues, setLoadRevenues] = useState(false);

  const [monthSelected, setMonthSelected] = useState(false)
  const [yearSelected, setYearSelected] = useState(false)

  const [showInfoRevenuesGroup, setShowInfoRevenuesGroup] = useState(false)
  const [showInfoExpenseGroup, setShowInfoExpenseGroup] = useState(false)
  const [showInfoDays, setShowInfoDay] = useState(false)

  const [showRightClick, setShowRightClick] = useState(false)
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const [modal, setModal] = useState(false)
  const [bill_type, setBillType] = useState("PAGAR")

  const [draggable, setDraggable] = useState(false)
  const [iconDraggable, setIconDraggable] = useState(faFileUpload)
  const [progressDraggable, setProgressDraggable] = useState(false)

  const [totalToPayExpenses, setTotalToPayExpenses] = useState(0)
  const [arrayExpenses, setArrayExpenses] = useState([])
  const [investment, setInvestment] = useState(0)
  const [rescue, setRescue] = useState(0)
  const [totalExpensesGroup, setTotalExpensesGroup] = useState(0)
  const [arrayExpensesGroup, setArrayExpensesGroup] = useState([])

  const [totalToReciveRevenues, setTotalToReviceRevenues] = useState(0)
  const [totalBadPayer, setTotalBadPayer] = useState({ total: 0, value: 0 });
  const [arrayRevenues, setArrayRevenues] = useState([])
  const [totalDiscounts, setTotalDiscounts] = useState(0)
  const [totalDiscountsGived, setTotalDiscountsGived] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [totalFeesRecived, setTotalFeesRecived] = useState(0);
  const [totalRevenuesGroup, setTotalRevenuesGroup] = useState(0)
  const [arrayRevenuesGroup, setArrayRevenuesGroup] = useState([])

  const [totalBills, setTotalBiils] = useState(0)
  const [arrayBills, setArrayBills] = useState([])
  const [arrayBillsDay, setArrayBillsDay] = useState([])

  const [revenuesExpired, setRevenuesExpired] = useState({ total: 0, value: 0 });
  const [expensesExpired, setExpensesExpired] = useState({ total: 0, value: 0 });

  const [daysToPay, setDaysToPay] = useState([])
  const [daysToRecive, setDaysToRecive] = useState([]);
  const [financialInformations, setFinancialInformations] = useState([]);
  const [financialInformationSelected, setFinancialInformationSelected] = useState(null);

  const LIMIT_CHECK = (client.id === 2) ? 30000 : 0;

  useEffect(() => {

    Moment.locale('pt-br');

    const month = months.find(m => m.number === Moment().format("MM"));
    const year = years.find(y => y.number === Moment().format("YYYY"));

    setMonthSelected(month)
    setYearSelected(year)

    findExpenses(month, year)
    findRevenues(month, year)

    if (arrTypeRevenues.length === 0) {
      findTypeRevenues()
    }

    return () => {
      dispatch({ type: "SET_RETURN_FILE", data: [] })
    }

  }, [])

  useEffect(() => {

    if (monthSelected && yearSelected) {
      findExpenses(monthSelected, yearSelected)
      findRevenues(monthSelected, yearSelected)
    }

  }, [client])

  useEffect(() => {

    var aux = [];
    var auxDays = [];
    var totalBills = 0;

    var auxToRecibe = [];
    var auxToPay = [];

    var revenuesExpired = 0;
    var expiredR = 0;

    var investment = 0;
    var rescue = 0;
    var expensesExpired = 0;
    var expiredE = 0;
    var discounts = 0;
    var discountsGived = 0;
    var feesToRecive = 0;
    var feesRecived = 0;

    if (arrayRevenues.length > 0) {
      const financialInformationsAux = [];
      arrayRevenues.map(item => {

        item.type = "revenues"
        aux.push(item)

        if (item.financial_information) {
          const fIndex = financialInformationsAux.findIndex(x => x.id === item.financial_information.id);
          if (fIndex !== -1) {
            financialInformationsAux[fIndex].count += 1;
            financialInformationsAux[fIndex].label = `${financialInformationsAux[fIndex].name} (${financialInformationsAux[fIndex].count})`
          }
          else {
            financialInformationsAux.push({
              id: item.financial_information.id,
              name: item.financial_information.bank_name,
              label: `${item.financial_information.bank_name} (1)`,
              count: 1,
              value: item.financial_information.id
            });
          }
        }

        if (!item.canceled) {

          if (item.payment_date) {
            totalBills = totalBills + parseFloat(item.payment_value)
            const d = parseInt(item.payment_date.split("-")[2])
            if (typeof auxDays[d] === 'undefined') auxDays[d] = [item]
            else auxDays[d].push(item)

            if (item.revenue_type === "RESGATE")
              rescue = rescue + parseFloat(item.payment_value);

            if (!Moment(item.due_date).isAfter(Moment(item.payment_date).subtract(1, 'days'))) {
              const feesAfter = parseFloat(item.payment_value) - parseFloat(item.document_value);
              if (feesAfter > 0)
                feesRecived = feesRecived + feesAfter;
              else
                discountsGived = discountsGived + feesAfter;
            }

          }

          if (!item.payment_date) {

            if (!Moment(item.due_date).isAfter(Moment().subtract(1, "days"))) {
              revenuesExpired = revenuesExpired + parseFloat(item.document_value);
              expiredR++;

              if (!item.renter.bad_payer) {
                let twopercent = parseFloat(item.document_value) * 2 / 100;
                let feesDays = Moment().diff(Moment(item.due_date), 'days');
                if (feesDays > 10)
                  feesDays = 10;

                const liveValue = (((1.98 / 100) / 30) * parseFloat(item.document_value));
                let valueFees = parseFloat(twopercent) + (parseFloat(feesDays) * parseFloat(liveValue));
                feesToRecive = feesToRecive + valueFees;
              }

            } else {
              if (item.advance_payment) {
                if (item.advance_payment_type === "PER") {
                  var percentValue = (parseFloat(item.document_value) * parseFloat(item.advance_payment_value)) / 100;
                  discounts = discounts + parseFloat(percentValue);
                }
              }
            }

            const d = parseInt(item.due_date.split("-")[2]);
            if (typeof auxToRecibe[d] === 'undefined') auxToRecibe[d] = [item]
            else auxToRecibe[d].push(item)

          }
        }
      })
      setFinancialInformations(financialInformationsAux);
    }

    if (arrayExpenses.length > 0) {

      arrayExpenses.map(item => {

        item.type = "expenses"
        aux.push(item)

        if (!item.canceled) {

          if (item.payment_date) {

            if (item.type_expense.name === "INVESTIMENTO")
              investment = investment + parseFloat(item.payment_value);

            // if (item.payment_type === "AGENDAMENTO") {
            // totalBills = totalBills + parseFloat(item.payment_value);
            // }

            if (item.financial_payer.extract) {
              totalBills = totalBills - parseFloat(item.payment_value)
              const d = parseInt(item.payment_date.split("-")[2])
              if (typeof auxDays[d] === 'undefined') auxDays[d] = [item]
              else auxDays[d].push(item)
            }

          }

          if (!item.payment_date) {

            if (!Moment(item.due_date).isAfter(Moment().subtract(1, "days"))) {
              expensesExpired = expensesExpired + parseFloat(item.document_value);
              expiredE++;
            }

            const d = parseInt(item.due_date.split("-")[2])
            if (typeof auxToPay[d] === 'undefined') auxToPay[d] = [item]
            else auxToPay[d].push(item)
          }
        }

      })

    }

    setDaysToRecive(auxToRecibe);
    setRevenuesExpired({ total: expiredR, value: revenuesExpired });

    setDaysToPay(auxToPay);
    setExpensesExpired({ total: expiredE, value: expensesExpired });
    setInvestment(investment);
    setRescue(rescue);
    setTotalDiscounts(discounts);
    setTotalDiscountsGived(discountsGived);
    setTotalFees(feesToRecive);
    setTotalFeesRecived(feesRecived);

    if (aux.length > 0) aux.sort((a, b) => {
      if (a.payment_date && b.payment_date)
        return a.payment_date.localeCompare(b.payment_date);
      else
        return a.due_date.localeCompare(b.due_date);
    })

    setTotalBiils(totalBills)

    if (aux.length === 0) setArrayBills([])
    else setArrayBills(aux)
    if (auxDays.length === 0) setArrayBillsDay([])
    else setArrayBillsDay(auxDays)

    dispatch({ type: "SET_LOADER", data: false })

  }, [arrayExpenses, arrayRevenues])

  useEffect(() => {
    if (!modal && !showRightClick) {
      dispatch({ type: "SET_OBJ_SELECTED", data: {} });
    }
  }, [showRightClick])

  useEffect(() => {

    if (returnFile.length === 0) return;

    if (returnFile.success) {
      setProgressDraggable(false)
      return;
    }

    dispatch({ type: "SET_RETURN_FILE", data: [] })

  }, [returnFile])

  async function findTypeRevenues() {

    try {

      let response = await fetch(DEFAULT_URL + 'type_revenues', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_TYPE_REVENUES", data: responseJson.data })
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  function handlerClickRow(item, type) {
    item.clone = false;
    dispatch({ type: "SET_OBJ_SELECTED", data: item });
    setBillType(type)
    openModal()
  }

  function rightClick(e, item, type) {
    e.preventDefault();
    setMouseX(e.nativeEvent.x - 180)
    setMouseY((e.nativeEvent.y + myRef.current.scrollTop) - 100)
    setShowRightClick(true)
    dispatch({ type: "SET_OBJ_SELECTED", data: item });
    setBillType(type)
  }

  function handlerRightClick(item) {

    var obj = objSelected

    if (item === "BOLETO") {
      return handlerPDF("PDF_TICKET");
    }

    if (item === "FATURA") {
      return handlerPDF("PDF_INVOICE");
    }

    if (item === "EMAIL") {
      return handlerEMAIL("EMAIL_TICKET");
    }

    if (item === "WPP") {
      return handlerWPP("WPP_TICKET");
    }

    if (item === "CLONAR") {
      obj.clone = true;
    }

    if (item === "QUITAR") {
      obj.payoff = true;
    }

    dispatch({ type: "SET_OBJ_SELECTED", data: obj });
    openModal()
  }

  async function findExpenses(month, year) {

    setLoadExpenses(true)
    setArrayExpenses([])

    try {

      let response = await fetch(DEFAULT_URL + 'expenses/date', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          month: month.number,
          year: year.number,
        })
      });

      let responseJson = await response.json();

      setLoadExpenses(false)

      if (responseJson.success) {
        setArrayExpenses(responseJson.data);

        var totalToPay = 0;
        var totalExpenses = 0;
        var arrayGroup = [];
        responseJson.data.map(item => {

          if (!item.canceled) {

            if (typeof arrayGroup[item.type_expense_id] === 'undefined')
              arrayGroup[item.type_expense_id] = [item]
            else
              arrayGroup[item.type_expense_id].push(item)

            if (item.payment_date) {

              if (item.financial_payer.extract)
                totalExpenses = totalExpenses + parseFloat(item.payment_value)
              //totalToPay = totalToPay + parseFloat(item.payment_value)
            } else {
              // confere se vai pro extrato
              if (item.financial_payer.extract) {
                totalExpenses = totalExpenses + parseFloat(item.document_value)
                totalToPay = totalToPay + parseFloat(item.document_value)
              }
            }
          }

        })

        setArrayExpensesGroup(arrayGroup)
        setTotalToPayExpenses(totalToPay)
        setTotalExpensesGroup(totalExpenses)

        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function findRevenues(month, year) {

    setLoadRevenues(true)
    setArrayRevenues([])

    try {

      let response = await fetch(DEFAULT_URL + 'revenues/date', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          month: month.number,
          year: year.number,
        })
      });

      let responseJson = await response.json();

      setLoadRevenues(false)
      if (responseJson.success) {
        setArrayRevenues(responseJson.data);

        var totalToReciver = 0;
        var totalRevenues = 0;
        var totalBadPayer = 0;
        var badp = 0;
        var arrayGroup = [];

        responseJson.data.map(item => {

          if (!item.canceled) {

            var finder = arrTypeRevenues.find(type => type.name === item.revenue_type);
            if (finder) {
              if (typeof arrayGroup[finder.id] === 'undefined') arrayGroup[finder.id] = [item]
              else arrayGroup[finder.id].push(item)
            }

            if (item.payment_date) {
              totalRevenues = totalRevenues + parseFloat(item.payment_value)
            }
            else {

              if (item.renter.bad_payer) {
                totalBadPayer = totalBadPayer + parseFloat(item.document_value);
                badp++;
              } else {
                totalToReciver = totalToReciver + parseFloat(item.document_value)
              }

              totalRevenues = totalRevenues + parseFloat(item.document_value)
            }

          }
        })

        setArrayRevenuesGroup(arrayGroup)
        setTotalToReviceRevenues(totalToReciver)
        setTotalBadPayer({ total: badp, value: totalBadPayer });
        setTotalRevenuesGroup(totalRevenues)

        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }

  function openModal() {

    if (!financialPermission(user.profile)) {
      return
    }

    setModal(true)
    dispatch({ type: "SET_DARK", data: true })
  }

  async function handlerEMAIL(type) {
    if (type === "EMAIL_TICKET") {
      fetch(`${DEFAULT_URL}revenues/ticket/${objSelected.id}/mail`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then((e) => addMail({
          ticket: objSelected.id,
          success: -1
        }))
        .catch(e => addMail({
          ticket: objSelected.id,
          success: false
        }));
    }
  }

  async function handlerWPP(type) {
    if (type === "WPP_TICKET") {
      addWpp({ ticket: objSelected.id, success: -1 });
      fetch(`${DEFAULT_URL}revenues/ticket/${objSelected.id}/wpp`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(async (e) => {
          const response = await e.json();
          if (response.success) {
            addWpp({ ticket: objSelected.id, success: true });
            return;
          }
          addWpp({ ticket: objSelected.id, success: false });
        })
        .catch(e => addWpp({ ticket: objSelected.id, success: false }));
    }
  }

  function handlerPDF(type) {

    if (type === "PDF_RECEBER" || type === "GRAPH_RECEBER") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayRevenues });
    }

    if (type === "PDF_PAGAR" || type === "GRAPH_PAGAR") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayExpenses });
    }

    if (type === "PDF_EXTRACK") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayBills });
    }

    if (type === "PDF_RECEITAS_GRUPO" || type === "GRAPH_RECEBER_GRUPO") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayRevenuesGroup });
    }

    if (type === "PDF_DESPESAS_GRUPO" || type === "GRAPH_DESPESAS_GRUPO") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayExpensesGroup });
    }

    if (type === "EXPENSES_CARD") {
      dispatch({ type: "SET_OBJ_SELECTED", data: arrayExpenses.filter(i => i.payment_type === "CARTÃO" && i.payment_date === null && i.canceled === false) });
    }

    setBillType(type)
    openModal()

  }

  function clearAllReturns() {
    findRevenues(monthSelected, yearSelected)
    setDraggable(false)
    setProgressDraggable(false)
    setIconDraggable(faFileUpload)
    dispatch({ type: "SET_RETURN_FILE", data: [] })

  }

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDraggable(true)
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDraggable(false)
    setIconDraggable(faFileUpload)
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFile = (file) => {
    const name = file.name.split(".");
    if (name[name.length - 1] === 'ret') {
      return true;
    }
    return false;
  }

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        //setIconDraggable(faClipboardCheck);
        var reader = new FileReader()
        reader.onload = function (event) {
          var formdata = new FormData();
          formdata.append("file", files[i], files[i].name);

          axios.post(
            `${DEFAULT_URL}revenues/returns-file-upload`,
            formdata,
            {
              headers: { "Authorization": "Bearer " + user.token },
              onUploadProgress: (e) => {
                let progress = Math.round(
                  (e.loaded * 100) / e.total
                );
                setProgressDraggable(progress);
              }
            }
          )
            .then((response) => {
              // setIconDraggable(faCheck)
              // setTimeout(() => {
              //     setDraggable(false)
              //     setProgressDraggable(false)
              //     setIconDraggable(faFileUpload)
              // }, 1000)
            })
            .catch((e) => {
              setIconDraggable(faTimesCircle)
              setTimeout(() => {
                setDraggable(false)
                setProgressDraggable(false)
                setIconDraggable(faFileUpload)
              }, 1000)
            })

        }
        reader.readAsText(files[i]);
      } else {
        setIconDraggable(faTimesCircle)
        setTimeout(() => {
          setDraggable(false)
          setProgressDraggable(false)
          setIconDraggable(faFileUpload)
        }, 1000)
      }
    }
  }

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIconDraggable(faEye)
    var files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  var totalDayAll = 0;

  return (
    <Container
      ref={myRef}
      onClick={(e) => {
        setShowRightClick(false);
        e.preventDefault();
      }}
    >

      {modal &&
        <Modal
          onClose={() => setModal(false)}
          layout={`CONTAS: ${bill_type}`}
          client_id={client.id}
          callback={() => {

            if (bill_type === "PAGAR" || bill_type === "EXPENSES_CARD") {
              findExpenses(monthSelected, yearSelected)
            }
            if (bill_type === "RECEBER") {
              findRevenues(monthSelected, yearSelected)
            }
          }}
          monthSelected={monthSelected}
          yearSelected={yearSelected}
        />}

      <Header>
        <Bank>
          {
            (financialInformations && financialInformations.map(financialInformation => (
              <div><BankSign className={`${financialInformation.name.toLowerCase()}`} /> {financialInformation.count}</div>
            )))
          }
        </Bank>

        <Date>
          <Select
            onChange={(newOption) => {
              setMonthSelected(newOption)
              findExpenses(newOption, yearSelected)
              findRevenues(newOption, yearSelected)
            }}
            value={monthSelected}
            options={months}
            isMulti={false}
            isSearchable={false}
          />
          <div style={{ width: 10 }} />
          <Select
            value={yearSelected}
            onChange={(newOption) => {
              setYearSelected(newOption)
              findExpenses(monthSelected, newOption)
              findRevenues(monthSelected, newOption)
            }}
            options={years}
            isMulti={false}
            isSearchable={false}
          />

        </Date>

      </Header>

      {showRightClick &&
        <RightButtonClick style={{ top: `${mouseY}px`, left: `${mouseX}px` }}>

          <div onClick={() => handlerRightClick("QUITAR")}>QUITAR</div>
          <Row1 />
          <div onClick={() => handlerRightClick("CLONAR")}>CLONAR</div>
          {
            objSelected.system &&
            <>
              <Row1 />
              <div onClick={() => handlerRightClick("FATURA")}>FATURA</div>
            </>
          }
          {
            objSelected.payment_type === "BOLETO" && objSelected.remittance_number !== null ? (
              <>
                <Row1 />
                <div onClick={() => handlerRightClick("BOLETO")}>BOLETO</div>
                <Row1 />
                <div onClick={() => handlerRightClick("EMAIL")}>E-MAIL</div>
                <Row1 />
                <div onClick={() => handlerRightClick("WPP")}>WHATSAPP</div>
                {/* {
                                  ((objSelected.renter && objSelected.renter.number) && (
                                    <>
                                      <Row1 />
                                      <div onClick={() => handlerRightClick("WPP")}>WHATSAPP</div>
                                    </>
                                  ))
                                } */}
              </>
            ) : null
          }

        </RightButtonClick>
      }

      <BillsInfo>

        <Bills>

          <label>A RECEBER
            <FontAwesomeIcon icon={faFilePdf} onClick={() => {
              handlerPDF("PDF_RECEBER")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 25, cursor: 'pointer' }} />
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
              setBillType("RECEBER")
              openModal()
            }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />

          </label>

          <Row2 />

          <InfoHeader>
            <label>Cliente</label>
            <span>Vencimento</span>
            <strong>Valor</strong>
            <span style={{ width: "10%" }}>Banco</span>
          </InfoHeader>

          <Infos
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
            style={{ border: (!draggable) ? "" : "2px dotted #222" }}>

            {draggable &&
              <div style={{ background: '#fff', width: '100%', height: '100%' }}>
                {
                  returnFile.length === 0 ? (
                    <FontAwesomeIcon icon={iconDraggable} style={{ width: 40, height: 40, position: 'absolute', right: "45%", top: "40%", cursor: 'pointer' }} />
                  ) : null
                }

                {
                  returnFile.length !== 0 ? (
                    <FontAwesomeIcon icon={faWindowClose} onClick={() => clearAllReturns()} style={{ width: 20, height: 20, position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} />
                  ) : null
                }

                {
                  progressDraggable !== false ? (
                    <ProgressDraggable>
                      <ProgressDraggableBar width={`${progressDraggable}%`} />
                      <ProgressDraggableText>{progressDraggable}%</ProgressDraggableText>
                    </ProgressDraggable>
                  ) : null
                }

                {
                  returnFile.length !== 0 ? (
                    <InfoReturns>
                      <div>
                        <p style={{ width: "55%" }}>Quitações: {returnFile.returns.liquidation.length}</p>
                        <p style={{ width: "20%", textAlign: "end" }}>Data pagamento</p>
                        <p style={{ width: "25%", textAlign: "end" }}>Valor pago</p>
                      </div>
                      {returnFile?.returns?.liquidation.map(item =>
                        <div>
                          <p style={{ width: "55%" }}>{item.renter.substr(0, 25)}</p>
                          <p style={{ width: "20%", textAlign: "end" }}>{Moment(item.payment_date).format("DD/MM/YYYY")}</p>
                          <p style={{ width: "25%", textAlign: "end" }}>R$ {item.payment_value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        </div>
                      )}
                    </InfoReturns>
                  ) : null
                }

              </div>
            }

            {loadRevenues ? [1, 2, 3, 4].map(item => {
              return (
                <Skeleton key={`skeleton_r_${item}`} style={{ height: 35, marginBottom: 5 }} />
              );
            })
              :
              !draggable && daysToRecive.map((arrDay, key) => {

                var totalDay = 0;

                return (
                  <>
                    {(financialInformationSelected ? arrDay.filter(arD => (arD.financial_information && arD.financial_information.id === financialInformationSelected.value)) : arrDay).map((item) => {

                      if ((search === "" || item.renter.name.toLowerCase().includes(search.toLowerCase())) && (!item.payment_date && !item.canceled)) {

                        totalDay = totalDay + item.document_value;

                        return (
                          <InfoRow className={item === objSelected ? "active" : ""} key={`arr_revenues${item.id}`} onContextMenu={(e) => rightClick(e, item, "RECEBER")} onDoubleClick={() => handlerClickRow(item, "RECEBER")} style={{ color: (item.renter.bad_payer) ? coloralert : "" }}>
                            {
                              mails.find(x => x.ticket === item.id) ? (
                                mails.find(x => x.ticket === item.id).success === -1 ? (
                                  <FontAwesomeIcon icon={faSpinner} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }
                            {
                              mails.find(x => x.ticket === item.id) ? (
                                mails.find(x => x.ticket === item.id).success === true ? (
                                  <FontAwesomeIcon icon={faCheck} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }
                            {
                              mails.find(x => x.ticket === item.id) ? (
                                mails.find(x => x.ticket === item.id).success === false ? (
                                  <FontAwesomeIcon icon={faExclamationTriangle} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }

                            {
                              wpps.find(x => x.ticket === item.id) ? (
                                wpps.find(x => x.ticket === item.id).success === -1 ? (
                                  <FontAwesomeIcon icon={faSpinner} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }
                            {
                              wpps.find(x => x.ticket === item.id) ? (
                                wpps.find(x => x.ticket === item.id).success === true ? (
                                  <FontAwesomeIcon icon={faCheck} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }
                            {
                              wpps.find(x => x.ticket === item.id) ? (
                                wpps.find(x => x.ticket === item.id).success === false ? (
                                  <FontAwesomeIcon icon={faExclamationTriangle} style={{ width: 17, height: 17, paddingRight: 2 }} />
                                ) : null
                              ) : ""
                            }

                            {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                            <label>{item.renter.name}</label>
                            <span
                              style={{
                                color: (!Moment(item.due_date).isAfter(Moment().subtract(1, "days"))) ? badpayerColor : "",
                              }}
                            >{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                            <strong>{formatNumber(item.document_value)}</strong>
                            <span style={{ width: "10%" }}>
                              {item.financial_information && (
                                <BankSign className={`${item.financial_information.bank_name.toLowerCase()}`} />
                              )}
                              {!item.financial_information && "-"}
                            </span>
                          </InfoRow>
                        )
                      }
                    })}

                    <InfoRow style={{ color: "#000", backgroundColor: "#f1f1f1", cursor: "not-allowed", fontWeight: "bold", borderBottomColor: "#000" }}>
                      <label>{key}/{monthSelected.number}</label>
                      <span></span>
                      <strong style={{ fontWeight: "bold" }}>{formatNumber(totalDay)}</strong>
                    </InfoRow>

                  </>
                )
              })
            }

          </Infos>

          <Total>

            <div>
              TOTAL GERAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber((totalToReciveRevenues + totalBadPayer.value))}</label>
            </div>

            <div style={{ color: coloralert }}>
              PREVISÃO DE DESCONTOS:
              <label style={{ textAlign: 'right', width: '30%' }}>-{formatNumber(totalDiscounts)}</label>
            </div>

            <div style={{ color: coloralert }}>
              MAU PAGADOR: ({totalBadPayer.total})
              <label style={{ textAlign: 'right', width: '30%' }}>-{formatNumber(totalBadPayer.value)}</label>
            </div>

            <div style={{ color: redimentColor }}>
              PREVISÃO DE JUROS:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalFees)}</label>
            </div>

            <div style={{ color: redimentColor }}>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalToReciveRevenues - totalDiscounts)}</label>
            </div>

            <div style={{ color: badpayerColor }}>
              EM ATRASO: ({revenuesExpired.total})
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(revenuesExpired.value)}</label>
            </div>

          </Total>

        </Bills>

        <Row />

        <Bills>
          <label>A PAGAR

            <FontAwesomeIcon icon={faCreditCard} onClick={() => {
              handlerPDF("EXPENSES_CARD")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 52, cursor: 'pointer' }} />
            <FontAwesomeIcon icon={faFilePdf} onClick={() => {
              handlerPDF("PDF_PAGAR")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 25, cursor: 'pointer' }} />
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
              setBillType("PAGAR")
              openModal()
            }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />
          </label>
          <Row2 />
          <InfoHeader>
            <label>Cliente</label>
            <span>Vencimento</span>
            <strong>Valor</strong>
          </InfoHeader>

          <Infos>

            {loadExpenses ? [1, 2, 3, 4].map(item => {
              return (
                <Skeleton key={`skeleton_e_${item}`} style={{ height: 35, marginBottom: 5 }} />
              );
            })
              :
              daysToPay.map((arrDay, key) => {

                var totalDay = 0;
                return (
                  <>
                    {arrDay.map(item => {

                      if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {

                        if (!item.payment_date && !item.canceled) {

                          totalDay = totalDay + item.document_value;

                          return (
                            <InfoRow key={`arrExpenses${item.id}`} style={{ color: 'red' }} onContextMenu={(e) => rightClick(e, item, "PAGAR")} onDoubleClick={() => handlerClickRow(item, "PAGAR")}>
                              {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                              <label>{(!item.financial_payer.extract) ? `- ${item.provider.name}` : item.provider.name} </label>
                              <span style={{ color: (!Moment(item.due_date).isAfter(Moment().subtract(1, "days"))) ? badpayerColor : "" }}>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                              <strong>{formatNumber(item.document_value)}</strong>
                            </InfoRow>
                          )
                        }
                      }
                    })}

                    <InfoRow style={{ color: "#000", backgroundColor: "#f9f9f9", fontWeight: "bold", borderBottomColor: "#000" }}>
                      <label>{key}/{monthSelected.number}</label>
                      <span></span>
                      <strong style={{ fontWeight: "bold" }}>{formatNumber(totalDay)}</strong>
                    </InfoRow>

                  </>
                )
              })
            }


          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalToPayExpenses)}</label>
            </div>

            <div style={{ color: badpayerColor }}>
              EM ATRASO: ({expensesExpired.total}):
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(expensesExpired.value)}</label>
            </div>

          </Total>

        </Bills>

        <Row />

        <Bills style={{ width: '40%' }}>
          <label>EXTRATO
            <FontAwesomeIcon icon={faFilePdf} onClick={() => {
              handlerPDF("PDF_EXTRACK")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 5, cursor: 'pointer' }} />

          </label>
          <Row2 />
          <InfoHeader>
            <label>Cliente</label>
            <span>Vencimento</span>
            <span>Valor Doc.</span>
            <span>Pagamento</span>
            <span>Valor</span>
            <span style={{ width: "10%" }}>Banco</span>
          </InfoHeader>

          <Infos>

            {arrayBillsDay.map((arrDay, key) => {

              return (
                <>
                  {(financialInformationSelected ? arrDay.filter(arD => (arD.financial_information && arD.financial_information.id === financialInformationSelected.value)) : arrDay).map(item => {

                    if (search === "" || item?.renter?.name.toLowerCase().includes(search.toLowerCase()) || item?.provider?.name.toLowerCase().includes(search.toLowerCase())) {

                      if (item.type === "revenues") {

                        totalDayAll = totalDayAll + item.payment_value;

                        var color = undefined;
                        if (item.revenue_type === "QUEBRA DE CAIXA") color = 'blueviolet'
                        if (item.revenue_type === "SALDO ANTERIOR") color = '#AAA'
                        if (item.revenue_type === "RESGATE") color = realBalanceColor

                        return (
                          <InfoRow className={item === objSelected ? "active" : ""} key={`revenues${item.id}`} onContextMenu={(e) => rightClick(e, item, "RECEBER")} onDoubleClick={() => handlerClickRow(item, "RECEBER")} style={{ color: color }}>
                            {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                            <label>{item.renter.name}</label>
                            <span>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                            <strong style={{ width: "20%" }}>{formatNumber(item.document_value)}</strong>
                            <span>{(item.payment_date) ? Moment(item.payment_date).format("DD/MM/YYYY") : ""}</span>
                            <strong style={{ width: "20%" }}>{formatNumber(item.payment_value)}</strong>
                            <span style={{ width: "10%" }}>
                              {item.financial_information && (
                                <BankSign className={`${item.financial_information.bank_name.toLowerCase()}`} />
                              )}
                              {!item.financial_information && "-"}
                            </span>
                          </InfoRow>
                        )
                      }

                      if (item.type === "expenses") {

                        totalDayAll = totalDayAll - item.payment_value;

                        return (
                          <InfoRow key={`expenses${item.id}`} style={{ color: (item.type_expense.name === "INVESTIMENTO") ? redimentColor : '#ff0000', }} onContextMenu={(e) => rightClick(e, item, "PAGAR")} onDoubleClick={() => handlerClickRow(item, "PAGAR")}>
                            <label>{(!item.financial_payer.extract) ? `- ${item.provider.name}` : item.provider.name}</label>
                            {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                            <span>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                            <strong style={{ width: "20%" }}>{formatNumber(item.document_value)}</strong>
                            <span>{(item.payment_date) ? Moment(item.payment_date).format("DD/MM/YYYY") : ""}</span>
                            <strong style={{ width: "20%" }}>{formatNumber(item.payment_value)}</strong>
                            <span style={{ width: "10%" }}>-</span>
                          </InfoRow>
                        )
                      }
                    }

                  })}

                  <InfoRow style={{ color: "#000", backgroundColor: "#f9f9f9", fontWeight: "bold", borderBottomColor: "#000" }}>
                    <label>{key}/{monthSelected.number}</label>
                    <span></span>
                    <strong style={{ fontWeight: "bold" }}>{formatNumber(totalDayAll)}</strong>
                  </InfoRow>
                </>
              )

            })}

          </Infos>

          <Total>
            <div>
              SALDO DO DIA:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalBills)}</label>
            </div>

            <div style={{ color: coloralert }}>
              REGASTE:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(rescue)}</label>
            </div>

            <div style={{ color: (totalBills - rescue > 0) ? redimentColor : coloralert }}>
              SALDO REAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalBills - rescue)}</label>
            </div>

            <div style={{ color: redimentColor }}>
              APLICADO:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(investment)}</label>
            </div>

            <div style={{ color: realBalanceColor }}>
              JUROS RECEBIDOS:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalFeesRecived)}</label>
            </div>

            <div style={{ color: coloralert }}>
              DESCONTO CONCEDIDO:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalDiscountsGived)}</label>
            </div>

          </Total>

        </Bills>

      </BillsInfo>

      <RowWhite />

      <BillsInfo>

        <Bills style={{ width: '33%' }}>

          <label>RECEITAS POR GRUPO
            <FontAwesomeIcon icon={faChartPie} onClick={() => {
              handlerPDF("GRAPH_RECEBER_GRUPO")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 50, cursor: 'pointer' }} />
            <FontAwesomeIcon icon={faFilePdf} onClick={() => {
              handlerPDF("PDF_RECEITAS_GRUPO")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 25, cursor: 'pointer' }} />
            <FontAwesomeIcon icon={faListOl} onClick={() => setShowInfoRevenuesGroup(!showInfoRevenuesGroup)} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />

          </label>

          <Row2 />

          <InfoHeader>
            <label>GRUPO</label>
            <span>Total</span>
            <strong>Valor recebido</strong>
          </InfoHeader>

          <Infos>

            {arrayRevenuesGroup.map(item => {

              var totalGroupValue = 0;
              item.map(i => {

                if (!i.canceled) {
                  if (i.payment_date)
                    totalGroupValue = totalGroupValue + parseFloat(i.payment_value);
                  else
                    totalGroupValue = totalGroupValue + parseFloat(i.document_value);
                }
              })

              return (
                <>
                  <InfoRow style={(showInfoRevenuesGroup) ? { border: '1px solid #ddd', paddingLeft: 5, paddingRight: 5, marginTop: 10 } : null}>
                    <label style={{ fontWeight: (showInfoRevenuesGroup) ? 'bold' : 'normal' }}>{item[0].revenue_type}</label>
                    <span>{item[0].length}</span>
                    <strong>{formatNumber(totalGroupValue)}</strong>
                  </InfoRow>

                  {showInfoRevenuesGroup && item.map(i => {

                    var payment = i.document_value
                    if (i.payment_date)
                      payment = i.payment_value

                    return (<InfoRowExp key={`group_${i.id}`}>
                      {i.note !== "" && <div className="tooltiptext">{i.note}</div>}
                      <label>{i.renter.name} {(i.note !== "") ? <FontAwesomeIcon icon={faCommentDots} style={{ marginLeft: 5, width: 12, height: 12 }} /> : null}</label>
                      <strong>{formatNumber(payment)}</strong>
                    </InfoRowExp>)
                  })}
                </>
              )

            })}

          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalRevenuesGroup)}</label>
            </div>
          </Total>

        </Bills>

        <Row />

        <Bills style={{ width: '33%' }}>
          <label>
            DESEPESAS POR GRUPO
            <FontAwesomeIcon icon={faChartPie} onClick={() => {
              handlerPDF("GRAPH_DESPESAS_GRUPO")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 50, cursor: 'pointer' }} />

            <FontAwesomeIcon icon={faFilePdf} onClick={() => {
              handlerPDF("PDF_DESPESAS_GRUPO")
            }} style={{ width: 17, height: 17, position: 'absolute', right: 25, cursor: 'pointer' }} />

            <FontAwesomeIcon icon={faListOl} onClick={() => setShowInfoExpenseGroup(!showInfoExpenseGroup)} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />

          </label>
          <Row2 />
          <InfoHeader>
            <label>GRUPO</label>
            <span>Total</span>
            <strong>Valor pago</strong>
          </InfoHeader>

          <Infos>

            {arrayExpensesGroup.map(item => {

              var totalGroupValue = 0;
              item.map(i => {
                if (!i.canceled) {

                  if (i.payment_date)
                    totalGroupValue = totalGroupValue + parseFloat(i.payment_value);
                  else
                    totalGroupValue = totalGroupValue + parseFloat(i.document_value);
                }
              })

              return (

                <>
                  <InfoRow style={(showInfoExpenseGroup) ? { border: '1px solid #ddd', paddingLeft: 5, paddingRight: 5, marginTop: 10 } : null}>
                    <label style={{ fontWeight: (showInfoExpenseGroup) ? 'bold' : 'normal' }}>{item[0].type_expense.name}</label>
                    <span>{item.length}</span>
                    <strong>{formatNumber(totalGroupValue)}</strong>
                  </InfoRow>

                  {showInfoExpenseGroup && item.map(i => {

                    var payment = i.document_value
                    if (i.payment_date)
                      payment = i.payment_value

                    return (<InfoRowExp key={`group_${i.id}`} onContextMenu={(e) => rightClick(e, i, "PAGAR")} >
                      {i.note !== "" && <div className="tooltiptext">{i.note}</div>}
                      <label>{i.provider.name} {(i.note !== "") ? <FontAwesomeIcon icon={faCommentDots} style={{ marginLeft: 5, width: 12, height: 12 }} /> : null}</label>
                      <strong>{formatNumber(payment)}</strong>
                    </InfoRowExp>)
                  })}

                </>
              )

            })}

          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalExpensesGroup)}</label>
            </div>
          </Total>

        </Bills>

        <Row />

        <Bills style={{ width: '33%' }}>
          <label>EXTRATO DIÁRIO <FontAwesomeIcon icon={faListOl} onClick={() => setShowInfoDay(!showInfoDays)} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} /></label>
          <Row2 />
          <InfoHeader>
            <label>Data</label>
            <span>Clientes</span>
            <strong>Valor</strong>
          </InfoHeader>

          <Infos>

            {arrayBillsDay.map((item, key) => {

              var total = 0;
              item.map(i => {
                if (i.type === "revenues")
                  total = total + parseFloat(i.payment_value)
                if (i.type === "expenses")
                  total = total - parseFloat(i.payment_value)
              })

              return (
                <>
                  <InfoRow style={(showInfoDays) ? { border: '1px solid #ddd', paddingLeft: 5, paddingRight: 5, marginTop: 10 } : null}>
                    <label style={{ fontWeight: (showInfoDays) ? 'bold' : 'normal' }}>{(item[0].payment_date) ? Moment(item[0].payment_date).format('DD/MM/YYYY') : Moment(item[0].due_date).format('DD/MM/YYYY')}</label>
                    <span>{item.length}</span>
                    <strong>{formatNumber(total)}</strong>
                  </InfoRow>

                  {showInfoDays && item.map(i => {
                    if (i.type === "revenues") {
                      return (<InfoRowExp key={`listday_${i.id}`} >
                        {i.note !== "" && <div className="tooltiptext">{i.note}</div>}
                        <label>{i.renter.name} {(i.note !== "") ? <FontAwesomeIcon icon={faCommentDots} style={{ marginLeft: 5, width: 12, height: 12 }} /> : null}</label>
                        <strong>{formatNumber(i.payment_value)}</strong>
                      </InfoRowExp>)
                    }
                    if (i.type === "expenses") {
                      return (
                        <InfoRowExp key={`listday${i.id}`} style={{ color: 'red' }} >
                          {i.note !== "" && <div className="tooltiptext">{i.note}</div>}
                          <label>{i.provider.name} {(i.note !== "") ? <FontAwesomeIcon icon={faCommentDots} style={{ marginLeft: 5, width: 12, height: 12 }} /> : null}</label>
                          <strong>{formatNumber(i.payment_value)}</strong>
                        </InfoRowExp>
                      )
                    }

                  })}
                </>
              )
            })}

          </Infos>

        </Bills>

      </BillsInfo>

    </Container >
  )
}

const mapStateToProps = state => ({
  props: {
    mails: state.mail.mails,
    wpps: state.wpp.wpps
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(
    Object.assign(
      {},
      MailActions,
      WppActions
    ),
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialBills);
