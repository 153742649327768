import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Alert, Field, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import {
    Button, Container, FieldHorizontal, HorizontalField, Row,
    Row2
} from './style';


const FieldsSubcomponent: React.FC = ({ callback }) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [name, setName] = useState("")
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(100)
    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [ingress, setIngress] = useState(0)
    const [issue, setIssue] = useState(0)
    const [available, setAvailable] = useState(0)
    const [note, setNote] = useState("")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")
    const [textErrorButtons, setTextErrorButtons] = useState("")

    const [activity, setActivity] = useState("")
    const [textButtonActivity, setTextButtonActivity] = useState("")
    const [newIngress, setNewIngress] = useState(0)
    const [newCost, setNewCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [newIssue, setNewIssue] = useState(0)
    const [issueNote, setIssueNote] = useState("")
    const [returnBackNumber, setReturnBackNumber] = useState(0)

    useEffect(() => {

        Moment.locale('pt-br');
        setTextAlert("")

        setTextDesc("Adicionar")

        console.log(objSelected)

        if (Object.keys(objSelected).length > 0) {

            setId(objSelected.id)
            setName(objSelected.name)
            setMin(objSelected.min)
            setMax(objSelected.max)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            setIngress(objSelected.ingress)
            setIssue(objSelected.issue)
            setAvailable(objSelected.available)
            setNote(objSelected.note)
            setTextDesc("Atualizar")

        }

    }, [objSelected])

    async function saveData() {

        if (name === "") {
            setTextAlert("Informe o campo Nome")
            return;
        }

        if (min === "" || min <= 0) {
            setTextAlert("Informe o mínimo de componentes")
            return;
        }

        if (max === "" || max <= 0) {
            setTextAlert("Informe o máximo de componentes")
            return;
        }

        if (parseInt(min) >= parseInt(max)) {
            setTextAlert("O quantidade mínima deve ser menor que a máxima")
            return;
        }

        if (cost.formattedValue === "") {
            setTextAlert("Informe o campo Custo médio")
            return;
        }

        if (ingress === "" || ingress <= 0) {
            setTextAlert("Informe o campo Entrada")
            return;
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'subcomponents' : `subcomponents/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user.id,
                    name: name,
                    min: min,
                    max: max,
                    cost: cost.floatValue,
                    ingress: ingress,
                    issue: issue,
                    available: available,
                    note: note,
                })
            });

            let responseJson = await response.json();
            setTextButton("Salvar")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function handleIngress() {

        if (newIngress === "" || newIngress <= 0) {
            setTextAlert("Informe o campo Entrada")
            return;
        }

        if (newCost.floatValue === 0) {
            setTextAlert("Informe o campo Custo da compra")
            return;
        }

        setTextButtonActivity("Aguarde..")

        try {

            var url = `${id}/add`

            let response = await fetch(DEFAULT_URL + 'subcomponents/' + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user.id,
                    newCost: newCost.floatValue,
                    newIngress: parseInt(newIngress),
                })
            });

            let responseJson = await response.json();
            setTextButtonActivity("CADASTRAR ENTRADA")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function handleIssue() {

        if (newIssue === "" || newIssue <= 0) {
            setTextAlert("Informe o campo Quantidade")
            return;
        }

        if (issueNote === "") {
            setTextAlert("Informe o motivo da baixa")
            return;
        }

        setTextButtonActivity("Aguarde..")

        try {

            var url = `${id}/remove`

            let response = await fetch(DEFAULT_URL + 'subcomponents/' + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user.id,
                    newIssue: parseInt(newIssue),
                    issueNote: issueNote,
                })
            });

            let responseJson = await response.json();
            setTextButtonActivity("CADASTRAR BAIXA")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === "remove_more") {
                setTextAlert("Quantidade superior a disponível")
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    async function handleReturn() {

        if (returnBackNumber === "" || returnBackNumber <= 0) {
            setTextAlert("Informe o campo Quantidade")
            return;
        }

        setTextButtonActivity("Aguarde..")

        try {

            var url = `${id}/return`

            let response = await fetch(DEFAULT_URL + 'subcomponents/' + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    user_id: user.id,
                    returnBackNumber: parseInt(returnBackNumber)
                })
            });

            let responseJson = await response.json();
            setTextButtonActivity("RETORNAR AO ESTOQUE")

            if (responseJson.success) {
                callback();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === "remove_more") {
                setTextAlert("Quantidade superior a baixas")
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Title>{textDesc.toUpperCase()} SUBCOMPONENTE</Title>
            <Row />

            {textErrorButtons != "" &&
                <label style={{ color: 'red' }}>
                    {textErrorButtons}
                </label>
            }

            {activity === "" &&
                <>
                    <HorizontalField>

                        <FieldHorizontal>
                            <label>Nome:</label>

                            <input
                                type="text"
                                value={name}
                                onChange={event => setName(event.target.value)} />

                        </FieldHorizontal>

                        <Row2 />

                        <FieldHorizontal>
                            <label>Quantidade mínima:</label>

                            <input
                                type="number"
                                min="1"
                                value={min}
                                onChange={event => setMin(event.target.value)} />

                        </FieldHorizontal>

                        <Row2 />

                        <FieldHorizontal>

                            <label>Quantidade máxima:</label>

                            <input
                                type="number"
                                min="1"
                                value={max}
                                onChange={event => setMax(event.target.value)} />

                        </FieldHorizontal>

                    </HorizontalField>

                    <HorizontalField>

                        <FieldHorizontal>

                            <label>Entrada:</label>

                            <input
                                type="number"
                                min="1"
                                value={ingress}
                                disabled={(id === 0) ? false : true}
                                style={{ background: (id === 0) ? '#f8f8f8' : '#ccc' }}
                                onChange={event => {
                                    setIngress(event.target.value)
                                    setAvailable(event.target.value)
                                }} />

                        </FieldHorizontal>

                        <Row2 />

                        <FieldHorizontal>
                            <label>Custo médio:</label>

                            <NumberFormat
                                value={cost.formattedValue}
                                thousandSeparator={"."}
                                prefix={'R$ '}
                                decimalScale={2}
                                placeholder={"R$"}
                                decimalSeparator={","}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                disabled={(id === 0) ? false : true}
                                style={{ background: (id === 0) ? '#f8f8f8' : '#ccc' }}
                                allowedDecimalSeparators={["."]}
                                onValueChange={(values) => {
                                    setCost(values)
                                }} />

                        </FieldHorizontal>

                        <Row2 />

                    </HorizontalField>

                    <Row />

                    <Field>

                        <label>Descrição:</label>

                        <textarea
                            type="text"
                            value={note}
                            onChange={event => setNote(event.target.value)} />

                    </Field>

                    <Row />
                </>}

            {(Object.keys(objSelected).length != 0) &&

                <>

                    {activity === "" && <>
                        <Title> AÇÕES DISPONÍVEIS</Title>

                    </>}

                    <HorizontalField style={{ marginTop: 10 }}>

                        {activity === "" && <Button onClick={() => {
                            setTextButtonActivity("CADASTRAR ENTRADA")
                            setActivity("ENTRADA")
                        }}>
                            ENTRADA
                        </Button>}

                        {activity === "" && <Button onClick={() => {
                            setActivity("BAIXA")
                            setTextButtonActivity("CADASTRAR BAIXA")
                        }} style={{ marginLeft: 25, backgroundColor: 'red' }}>
                            BAIXA
                        </Button>}

                        {activity === "" && <Button onClick={() => {
                            setActivity("RETORNAR")
                            setTextButtonActivity("RETORNAR AO ESTOQUE")
                        }} style={{ marginLeft: 25, backgroundColor: '#444' }}>
                            RETORNO
                        </Button>}

                    </HorizontalField>

                    <Row />

                    {activity == "ENTRADA" &&
                        <>

                            <Title> NOVA ENTRADA: {name}</Title>
                            <Row />
                            <HorizontalField>

                                <FieldHorizontal>

                                    <label>Quantidade</label>

                                    <input
                                        type="number"
                                        min="1"
                                        value={newIngress}
                                        onChange={event => {
                                            setNewIngress(event.target.value)
                                        }} />

                                </FieldHorizontal>

                                <Row2 />

                                <FieldHorizontal>

                                    <label>Custo unitário:</label>

                                    <NumberFormat
                                        value={newCost.formattedValue}
                                        thousandSeparator={"."}
                                        prefix={'R$ '}
                                        decimalScale={2}
                                        placeholder={"R$"}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                        onValueChange={(values) => {
                                            setNewCost(values)
                                        }} />

                                </FieldHorizontal>

                            </HorizontalField>

                            <Button onClick={() => handleIngress()}>
                                {textButtonActivity}
                            </Button>

                        </>
                    }

                    {activity == "BAIXA" &&
                        <>
                            <Title> EFETUAR BAIXA DE: {name}</Title>
                            <Row />

                            <FieldHorizontal>

                                <label>Quantidade</label>

                                <input
                                    type="number"
                                    min="1"
                                    value={newIssue}
                                    onChange={event => {
                                        setNewIssue(event.target.value)
                                    }} />

                            </FieldHorizontal>

                            <Row2 />

                            <Field>

                                <label>Motivo da baixa:</label>

                                <textarea
                                    type="text"
                                    value={issueNote}
                                    onChange={event => setIssueNote(event.target.value)} />

                            </Field>

                            <Button onClick={() => handleIssue()} style={{ backgroundColor: 'red' }}>
                                {textButtonActivity}
                            </Button>
                        </>
                    }

                    {activity == "RETORNAR" &&
                        <>
                            <Title> RETONAR PARA ESTOQUE: {name}</Title>
                            <Row />

                            <FieldHorizontal>

                                <label>Quantidade</label>

                                <input
                                    type="number"
                                    min="1"
                                    value={returnBackNumber}
                                    onChange={event => {
                                        setReturnBackNumber(event.target.value)
                                    }} />

                            </FieldHorizontal>

                            <Row2 />

                            <Button onClick={() => handleReturn()} style={{ backgroundColor: '#444' }}>
                                {textButtonActivity}
                            </Button>
                        </>
                    }

                    {textAlert !== "" &&
                        <div style={{ width: 300, marginTop: 10 }}>
                            <Alert>
                                {textAlert}
                            </Alert>

                        </div>
                    }
                    {textAlert === "" && <div />}

                </>

            }

            {activity === "" &&
                <ViewSubmit>

                    {textAlert !== "" &&
                        <Alert>
                            {textAlert}
                        </Alert>}
                    {textAlert === "" && <div />}

                    <Button onClick={() => saveData()}>
                        {textButton.toUpperCase()}
                    </Button>

                </ViewSubmit>}

        </Container>
    )

}

export default FieldsSubcomponent;