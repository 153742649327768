import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addWpp: ["wpp"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  wpps: []
};

const addWpp = (state = INITIAL_STATE, action) => {
  const { wpp } = action;
  wpp.ticket = Number(wpp.ticket);

  let wpps = state.wpps;
  const index = wpps.findIndex(x => x.ticket === wpp.ticket);
  if (index !== -1) {
    wpps[index] = wpp; 
  }
  else {
    wpps.push(wpp);
  }

  return {
    ...state,
    wpps
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_WPP]: addWpp
});
