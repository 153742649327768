
import { faBusinessTime, faCalendarAlt, faCalendarCheck, faHourglassEnd, faHourglassStart, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import requestTimelineNiple from '../../../../api/service/Maintenance/FindTimeline';
import requestOkObservations from '../../../../api/service/MaintenanceObservation/FindOk';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, somaHora } from '../../../../helper';
import { Check, CheckBox } from '../../../../styles/CrudLayout';
import { FieldHorizontal } from '../../../Fields/FieldsRevenues/style';
import { CardView, Cards, Container, Filter, LazYLoadDiv, Totals } from './style';

const selectedColor = "#000";
const iconsize = 15;
const alert = "#ca9501";
const warning = "#ff1212";
const ok = "#039422";

const MaintenanceTimelineNiple = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const suspicions = useSelector(state => state.suspicions)
  const problems = useSelector(state => state.problems)
  const subproblems = useSelector(state => state.subproblems)
  const solutions = useSelector(state => state.solutions)
  const updateTimeline = useSelector(state => state.updateTimeline)
  const search = useSelector(state => state.search)

  const [allData, setAllData] = useState([]);
  const [allDataObs, setAllDataObs] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const [headerSelected, setHeaderSelected] = useState("AGENDAR")
  const [showFilter, setShowFilter] = useState(false);
  const [itemFilter, setItemFilter] = useState({ id: 0, name: "", url: "" })

  const [toDo, setToDo] = useState([])
  const [scheduled, setScheduled] = useState([])
  const [observation, setObservation] = useState([])
  const [recents, setRecentes] = useState([])
  const [longTime, setLongTime] = useState([])
  const [isOk, setIsOk] = useState([])
  const [stoped, setStoped] = useState([])

  useEffect(() => {

    Moment.locale('pt-br');

    if (suspicions.length === 0)
      getSuspicions()
    if (problems.length === 0)
      getProblems()
    if (subproblems.length === 0)
      getSubproblems()
    if (solutions.length === 0)
      getSolutions()

    getAllMaintenances()

  }, [])

  useEffect(() => {
    if (updateTimeline) {
      getAllMaintenances()
    }
  }, [updateTimeline])

  useEffect(() => {
    handleAllData(allData);
  }, [itemFilter, search])

  const getSuspicions = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'suspicions', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SUSPICIONS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  const getProblems = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'problems_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_PROBLEMS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  const getSubproblems = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'sub_problems_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SUBPROBLEMS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  }

  const getSolutions = async () => {

    try {

      let response = await fetch(DEFAULT_URL + 'solutions_niple', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });

      let responseJson = await response.json();
      if (responseJson.success) {
        dispatch({ type: "SET_SOLUTIONS", data: responseJson.data })
      }

    } catch (error) {
      console.error(error);
    }

  };

  const getAllMaintenances = async () => {

    setLoaded(false);

    const responseObservations = await requestOkObservations(user.token);
    if (responseObservations.status === 200) {
      setAllDataObs(responseObservations.data);
      setObservation(responseObservations.data);
    }

    const response = await requestTimelineNiple(user.token);

    if (response.status !== 200) {
      let { message } = response.error.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }
    }

    setAllData(response.data);
    handleAllData(response.data);

    setLoaded(true);
    dispatch({ type: "SET_UPDATE_TIMELINE", data: false })

  }

  const handleAllData = (data) => {

    var toDo = [];
    var scheduled = []
    var recents = [];
    var longTime = []
    var isOk = []
    var stoped = []

    data.map(item => {

      if (search === "" || item.vehicle.identifier.toUpperCase().includes(search.toUpperCase()) || item.vehicle.contract.renter.renter_name.toUpperCase().includes(search.toUpperCase())) {

        if (itemFilter.id === 0 || itemFilter.url === "problems") {

          if (item.maintenance[0].status === "AGENDAR") toDo.push(item);
          else if (item.maintenance[0].status === "AGENDADO") scheduled.push(item);
          else if (item.maintenance[0].status === "PARADO") stoped.push(item);
          else if (item.maintenance[0].status === "CONCLUIDA") {

            var timeMaintenance = item.maintenance[0].end_date;
            console.log(timeMaintenance, Moment(new Date()).subtract(3, 'month').isAfter(Moment(timeMaintenance)));
            if (Moment(new Date()).subtract(3, 'month').isAfter(Moment(timeMaintenance)))
              longTime.push(item);
            else {
              if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance)))
                isOk.push(item);
              else
                recents.push(item);
            }
          }

        }

        else {

          if (item.maintenance[0].maintenance_suspicions_id && itemFilter.url === "suspitions") {
            if (item.maintenance[0].maintenance_suspicions.suspicions.includes(itemFilter.id)) {
              if (item.maintenance[0].status === "AGENDAR") toDo.push(item);
              else if (item.maintenance[0].status === "AGENDADO") scheduled.push(item);
              else if (item.maintenance[0].status === "PARADO") stoped.push(item);
              else if (item.maintenance[0].status === "CONCLUIDA") {

                var timeMaintenance = item.maintenance[0].end_date;
                if (Moment(new Date()).subtract(5, 'month').isAfter(Moment(timeMaintenance)))
                  longTime.push(item);
                else {
                  if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance)))
                    isOk.push(item);
                  else
                    recents.push(item);
                }
              }
            }
          }

          if (item.maintenance[0].maintenance_subproblems_id && itemFilter.url === "subproblems") {

            if (item.maintenance[0].maintenance_subproblems.subproblems.includes(itemFilter.id)) {
              if (item.maintenance[0].status === "AGENDAR") toDo.push(item);
              else if (item.maintenance[0].status === "AGENDADO") scheduled.push(item);
              else if (item.maintenance[0].status === "PARADO") stoped.push(item);
              else if (item.maintenance[0].status === "CONCLUIDA") {

                var timeMaintenance = item.maintenance[0].end_date;
                if (Moment(new Date()).subtract(5, 'month').isAfter(Moment(timeMaintenance)))
                  longTime.push(item);
                else {
                  if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance)))
                    isOk.push(item);
                  else
                    recents.push(item);
                }
              }
            }
          }

          if (item.maintenance[0].maintenance_solutions_id && itemFilter.url === "solutions") {
            if (item.maintenance[0].maintenance_solutions.solutions.includes(itemFilter.id)) {
              if (item.maintenance[0].status === "AGENDAR") toDo.push(item);
              else if (item.maintenance[0].status === "AGENDADO") scheduled.push(item);
              else if (item.maintenance[0].status === "PARADO") stoped.push(item);
              else if (item.maintenance[0].status === "CONCLUIDA") {

                var timeMaintenance = item.maintenance[0].end_date;

                if (Moment(new Date()).subtract(5, 'month').isAfter(Moment(timeMaintenance)))
                  longTime.push(item);
                else {
                  if (Moment(new Date()).subtract(1, 'month').isSameOrAfter(Moment(timeMaintenance)))
                    isOk.push(item);
                  else
                    recents.push(item);
                }
              }
            }
          }

        }
      }



    })

    setToDo(toDo);
    setScheduled(scheduled);
    setRecentes(recents);
    setLongTime(longTime);
    setIsOk(isOk);
    setStoped(stoped);

  }

  const handleFilter = (url, element) => {

    if (itemFilter.name === element.name)
      setItemFilter({ id: 0, name: "", url: "" })
    else
      setItemFilter({ id: element.id, name: element.name, url: url })

    if (url != "problems")
      setShowFilter(false)
  }

  const openModal = (type, view, obj) => {

    if (obj != null) {
      const clone = Object.assign(obj, {});
      clone.view = view;
      clone.isOld = false;
      dispatch({ type: "SET_OBJ_SELECTED", data: clone });
    }

    dispatch({ type: "SET_MODAL", data: { display: true, view: type } })
    dispatch({ type: "SET_DARK", data: true })
  }

  const renderObservations = item => {

    var color = alert;
    var timeMaintenance = item.entry_date;
    if (Moment(new Date()).subtract(1, 'month').isAfter(Moment(timeMaintenance)))
      color = warning;

    let time = Moment(timeMaintenance).fromNow();
    var suspicionsName = [];
    item.suspicions.split(",").map(x => {
      var f = suspicions.find(s => s.id == x);
      if (f)
        suspicionsName.push(f);
    })

    return (
      <CardView onDoubleClick={() => openModal("NOVA MANUTENÇÃO", "observation", item)}>

        <div className='time' style={{ backgroundColor: color }}>
          <p>{time}</p>
        </div>

        <div className='row'>
          <p className='board'>{item.vehicle.identifier}</p>
        </div>

        <div className='row'>
          <p className='emp'>{item.vehicle.contract.renter.renter_name}</p>
        </div>

        <div className='row'>
          <p className='city'>{item.vehicle.contract.renter.city.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Registrada por:</p>
          <p className='date'>{item.user.name} - {Moment(item.createdAt).format("DD/MM/YYYY")}</p>
        </div>

        <div className='column'>
          <p className='info'>Inicio das suspeitas:</p>
          <p className='date'>{Moment(timeMaintenance).format("DD/MM/YYYY")}</p>
        </div>

        <div className='column'>

          <p className='info'>Suspeitas:</p>

          <CheckBox style={{ marginTop: 5 }}>

            {suspicionsName.map(s => {
              return (
                <Check
                  style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                  {s.name}
                </Check>
              )
            })}

          </CheckBox>

        </div>

        <div className='column'>
          <p className='info'>Observação:</p>
          <p className='date'>{item.note}</p>
        </div>

      </CardView>
    )

  }

  const renderToDo = item => {

    var color = alert;
    var timeMaintenance = item.maintenance[0].maintenance_suspicions.date;
    if (Moment(new Date()).subtract(1, 'month').isAfter(Moment(timeMaintenance)))
      color = warning;

    let time = Moment(timeMaintenance).fromNow();
    var suspicionsName = [];
    item.maintenance[0].maintenance_suspicions.suspicions.split(",").map(x => {
      var f = suspicions.find(s => s.id == x);
      if (f)
        suspicionsName.push(f);
    })

    var solutionsLast = [];
    var problemLast = [];
    if (item.maintenance.length > 1) {

      if (item.maintenance[1].maintenance_solutions_id) {
        item.maintenance[1].maintenance_solutions.solutions.split(",").map(x => {
          var f = solutions.find(s => s.id == x);
          if (f)
            solutionsLast.push(f);
        })
      }

      if (item.maintenance[1].maintenance_subproblems_id) {
        item.maintenance[1].maintenance_subproblems.subproblems.split(",").map(x => {
          var f = subproblems.find(s => s.id == x);
          if (f)
            problemLast.push(f);
        })
      }

    }

    return (
      <CardView onDoubleClick={() => openModal("NOVA MANUTENÇÃO", "maintenance", item)}>

        <div className='time' style={{ backgroundColor: color }}>
          <p>{time}</p>
        </div>

        <div className='row'>
          <p className='board'>{item.vehicle.identifier}</p>
        </div>

        <div className='row'>
          <p className='emp'>{item.vehicle.contract.renter.renter_name}</p>
        </div>

        <div className='row'>
          <p className='city'>{item.vehicle.contract.renter.city.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Registrada por:</p>
          <p className='date'>{item.maintenance[0].maintenance_suspicions.user.name} - {Moment(item.maintenance[0].maintenance_suspicions.createdAt).format("DD/MM/YYYY")}</p>
        </div>

        <div className='column'>
          <p className='info'>Inicio das suspeitas:</p>
          <p className='date'>{Moment(timeMaintenance).format("DD/MM/YYYY")}</p>
        </div>

        <div className='column'>
          <p className='info'>Suspeitas:</p>
          <CheckBox style={{ marginTop: 5 }}>
            {suspicionsName.map(s => {
              return (
                <Check
                  style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                  {s.name}
                </Check>
              )
            })}
          </CheckBox>
        </div>

        <div className='column'>
          <p className='info'>Montado por:</p>
          <p className='date'>{item.maintenance[0].maintenance_suspicions.mounter.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Conferido por:</p>
          <p className='date'>{item.maintenance[0].maintenance_suspicions.checker.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Observação:</p>
          <p className='date'>{item.maintenance[0].maintenance_suspicions.note}</p>
        </div>

        {item.maintenance.length > 1 &&

          <div className='last-maintenance'>
            <div className='column'>
              <p className='info'>Última manutenção:</p>
              <p className='date'>{Moment(item.maintenance[1].end_date).format("DD/MM/YYYY")}</p>
            </div>

            <div className='column'>
              <p className='info'>Técnico:</p>
              <p className='date'>{item.maintenance[1].maintenance_solutions.user.name}</p>
            </div>

            <div className='column'>
              <p className='info'>Problema:</p>
              <CheckBox style={{ marginTop: 5 }}>
                {problemLast.map(s => {
                  return (
                    <Check
                      style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                      {s.name}
                    </Check>
                  )
                })}
              </CheckBox>
            </div>

            <div className='column'>
              <p className='info'>Solução:</p>
              <CheckBox style={{ marginTop: 5 }}>
                {solutionsLast.map(s => {
                  return (
                    <Check
                      style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                      {s.name}
                    </Check>
                  )
                })}
              </CheckBox>
            </div>

          </div>}

      </CardView>
    )

  }

  const renderSchedule = item => {

    var color = ok;
    var timeMaintenance = item.maintenance[0].maintenance_schedules.date;

    let time = Moment(timeMaintenance).fromNow();
    var suspicionsName = [];
    item.maintenance[0].maintenance_suspicions.suspicions.split(",").map(x => {
      var f = suspicions.find(s => s.id == x);
      if (f)
        suspicionsName.push(f);
    })

    var solutionsLast = [];
    var problemLast = [];
    if (item.maintenance.length > 1) {

      if (item.maintenance[1].maintenance_solutions_id) {
        item.maintenance[1].maintenance_solutions.solutions.split(",").map(x => {
          var f = solutions.find(s => s.id == x);
          if (f)
            solutionsLast.push(f);
        })
      }

      if (item.maintenance[1].maintenance_subproblems_id) {
        item.maintenance[1].maintenance_subproblems.subproblems.split(",").map(x => {
          var f = subproblems.find(s => s.id == x);
          if (f)
            problemLast.push(f);
        })
      }
    }

    return (
      <CardView onDoubleClick={() => openModal("NOVA MANUTENÇÃO", "maintenance", item)}>

        <div className='time' style={{ backgroundColor: color }}>
          <p>{time}</p>
        </div>

        <div className='row'>
          <p className='board'>{item.vehicle.identifier}</p>
        </div>

        <div className='row'>
          <p className='emp'>{item.vehicle.contract.renter.renter_name}</p>
        </div>

        <div className='row'>
          <p className='city'>{item.vehicle.contract.renter.city.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Atualizado em:</p>
          <p className='date'>{Moment(item.maintenance[0].maintenance_schedules.updatedAt).format("DD/MM/YYYY HH:mm")}</p>
        </div>

        <div className='column'>
          <p className='info'>Agendado por:</p>
          <p className='date'>{item.maintenance[0].maintenance_schedules.user.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Data:</p>
          <p className='date'>{Moment(timeMaintenance).format("DD/MM/YYYY HH:mm")}</p>
        </div>

        <div className='column'>
          <p className='info'>Saída prevista:</p>
          <p className='date'>{Moment(item.maintenance[0].output_date).format("DD/MM/YYYY HH:mm")}</p>
        </div>

        <div className='column'>
          <p className='info'>Veículo disponível:</p>
          <p className='date'>{item.maintenance[0].travel_vehicle.identifier}</p>
        </div>

        <div className='column'>

          <p className='info'>Suspeitas:</p>

          <CheckBox style={{ marginTop: 5 }}>

            {suspicionsName.map(s => {
              return (
                <Check
                  style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                  {s.name}
                </Check>
              )
            })}

          </CheckBox>

        </div>

        {item.maintenance.length > 1 &&

          <div className='last-maintenance'>
            <div className='column'>
              <p className='info'>Última manutenção:</p>
              <p className='date'>{Moment(item.maintenance[1].end_date).format("DD/MM/YYYY")}</p>
            </div>

            <div className='column'>
              <p className='info'>Técnico:</p>
              <p className='date'>{item.maintenance[1].maintenance_solutions.user.name}</p>
            </div>

            <div className='column'>
              <p className='info'>Problema:</p>
              <CheckBox style={{ marginTop: 5 }}>
                {problemLast.map(s => {
                  return (
                    <Check
                      style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                      {s.name}
                    </Check>
                  )
                })}
              </CheckBox>
            </div>

            <div className='column'>
              <p className='info'>Solução:</p>
              <CheckBox style={{ marginTop: 5 }}>
                {solutionsLast.map(s => {
                  return (
                    <Check
                      style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                      {s.name}
                    </Check>
                  )
                })}
              </CheckBox>
            </div>

          </div>}

      </CardView>
    )

  }

  const renderDoned = item => {

    var color = ok;
    var timeMaintenance = item.maintenance[0].end_date;

    let time = Moment(timeMaintenance).fromNow();
    var subproblemsName = [];
    item.maintenance[0].maintenance_subproblems.subproblems.split(",").map(x => {
      var f = subproblems.find(s => s.id == x);
      if (f)
        subproblemsName.push(f);
    })

    var solutionsName = [];
    item.maintenance[0].maintenance_solutions.solutions.split(",").map(x => {
      var f = solutions.find(s => s.id == x);
      if (f)
        solutionsName.push(f);
    })

    let diffT = Moment(timeMaintenance).diff(Moment(item.maintenance[0].start_date));
    diffT = new Date(diffT);
    let hrsT = diffT.getUTCHours().toString().padStart(2, "0") + ":" + diffT.getUTCMinutes().toString().padStart(2, "0") + ":" + diffT.getSeconds().toString().padStart(2, "0");
    if (diffT.getUTCDate() > 1) {
      const h = 24 * (diffT.getUTCDate() - 1);
      hrsT = somaHora(hrsT, `${h}:00:00`);
    }

    return (
      <CardView onDoubleClick={() => openModal("NOVA MANUTENÇÃO", "maintenance", item)}>

        <div className='time' style={{ backgroundColor: color }}>
          <p>{time}</p>
        </div>

        <div className='row'>
          <p className='board'>{item.vehicle.identifier}</p>
        </div>

        <div className='row'>
          <p className='emp'>{item.vehicle.contract.renter.renter_name}</p>
        </div>

        <div className='row'>
          <p className='city'>{item.vehicle.contract.renter.city.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Realizada em:</p>
          <p className='date'>{Moment(item.maintenance[0].end_date).format("DD/MM/YYYY HH:mm")}</p>
        </div>

        <div className='column'>
          <p className='info'>Técnico:</p>
          <p className='date'>{item.maintenance[0].maintenance_solutions.user.name}</p>
        </div>

        <div className='column'>
          <p className='info'>Tempo de duração:</p>
          <p className='date'>{hrsT}</p>
        </div>

        <div className='column'>
          <p className='info'>Problemas:</p>
          <CheckBox style={{ marginTop: 5 }}>
            {subproblemsName.map(p => {
              return (
                <Check
                  style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                  {p.name}
                </Check>
              )
            })}
          </CheckBox>
        </div>

        <div className='column'>
          <p className='info'>Soluções:</p>
          <CheckBox style={{ marginTop: 5 }}>
            {solutionsName.map(p => {
              return (
                <Check
                  style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
                  {p.name}
                </Check>
              )
            })}
          </CheckBox>
        </div>

      </CardView>
    )

  }

  return (
    <Container>

      <Filter className={(showFilter) ? "active" : ""}>

        <div className='header'>
          <div className='filter' onClick={() => setShowFilter(!showFilter)}>
            <p>Encontramos: {toDo.length + scheduled.length + recents.length + longTime.length + isOk.length + stoped.length}</p>
            <FontAwesomeIcon icon={faSearchLocation} style={{ width: 50, height: 20, cursor: 'pointer' }} />
            {itemFilter.name !== "" && itemFilter.url !== "problems" && <Check
              style={{ border: "2px solid #000", background: "rgba(0,0,0,0.5)", height: 25, color: "#fff" }}>
              {itemFilter.name}
            </Check>}
          </div>
          <div className='button' onClick={() => openModal("NOVA MANUTENÇÃO", null)}>
            ADICIONAR
          </div>
        </div>

        <div className='itens'>

          <FieldHorizontal>
            <label>Suspeitas:</label>
            <CheckBox>
              {suspicions.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 20) ? e.name.substr(0, 19) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => handleFilter("suspitions", e)}
                      style={{ backgroundColor: (itemFilter.name === e.name) ? "rgba(0,0,0,0.5)" : "" }}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>
          </FieldHorizontal>

          <FieldHorizontal>
            <label>Problemas:</label>
            <CheckBox>
              {problems.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => handleFilter("problems", e)}
                      style={{ backgroundColor: (itemFilter.name === e.name) ? "rgba(0,0,0,0.2)" : "" }}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>
          </FieldHorizontal>

          {(itemFilter.url === "problems" || itemFilter.url === "subproblems") && <FieldHorizontal>
            <label>Subproblema:</label>
            <CheckBox>
              {subproblems.map((e, i) => {
                if (e.active && e.problem_niple_id === itemFilter.id || itemFilter.url === "subproblems") {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => handleFilter("subproblems", e)}
                      style={{ backgroundColor: (itemFilter.name === e.name) ? "rgba(0,0,0,0.5)" : "" }}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>
          </FieldHorizontal>}

          <FieldHorizontal>
            <label>Soluções:</label>
            <CheckBox>
              {solutions.map((e, i) => {
                if (e.active) {
                  const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                  return (
                    <Check
                      key={e.id}
                      onClick={() => handleFilter("solutions", e)}
                      style={{ backgroundColor: (itemFilter.name === e.name) ? "rgba(0,0,0,0.5)" : "" }}>
                      {name}
                    </Check>)
                }
              })}
            </CheckBox>
          </FieldHorizontal>

        </div>

      </Filter>

      <Totals>

        <div
          style={headerSelected === "OBSERVACAO" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("OBSERVACAO")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faSearchLocation} fontSize={iconsize} />
            <p className='total'>{observation.length}</p>
          </div>
          <p className='name'>Observação</p>
        </div>
        <span />

        <div
          style={headerSelected === "AGENDAR" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("AGENDAR")}
        >

          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faCalendarAlt} fontSize={iconsize} />
            <p className='total'>{toDo.length}</p>
          </div>
          <p className='name'>Agendar</p>
        </div>
        <span />

        <div
          style={headerSelected === "AGENDADOS" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("AGENDADOS")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faCalendarCheck} fontSize={iconsize} />
            <p className='total'>{scheduled.length}</p>
          </div>
          <p className='name'>Agendados</p>
        </div>
        <span />

        <div
          style={headerSelected === "RECENTES" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("RECENTES")}
        >

          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faBusinessTime} fontSize={iconsize} />
            <p className='total'>{recents.length}</p>
          </div>

          <p className='name'>Recentes</p>
        </div>
        <span />

        <div
          style={headerSelected === "ROTINA" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("ROTINA")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faHourglassEnd} fontSize={iconsize} />
            <p className='total'>{longTime.length}</p>
          </div>
          <p className='name'>+5 Meses</p>
        </div>
        <span />

        <div
          style={headerSelected === "CONFORTÁVEIS" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
          onClick={() => setHeaderSelected("CONFORTÁVEIS")}
        >

          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faHourglassStart} fontSize={iconsize} />
            <p className='total'>{isOk.length}</p>
          </div>

          <p className='name'>Confortáveis</p>
        </div>

      </Totals>

      <div style={{ height: "100%", overflow: "scroll" }}>
        <Cards>

          {!loaded &&
            [1, 2, 3, 4, 5, 6].map(() => (<LazYLoadDiv style={{ marginTop: 20, width: 250, height: 350, }} />))
          }

          {loaded && headerSelected === "OBSERVACAO" && observation.map(item => renderObservations(item))}
          {loaded && headerSelected === "AGENDAR" && toDo.map(item => renderToDo(item))}
          {loaded && headerSelected === "AGENDADOS" && scheduled.map(item => renderSchedule(item))}
          {loaded && headerSelected === "RECENTES" && recents.map(item => renderDoned(item))}
          {loaded && headerSelected === "ROTINA" && longTime.map(item => renderDoned(item))}
          {loaded && headerSelected === "CONFORTÁVEIS" && isOk.map(item => renderDoned(item))}

        </Cards>
        <div style={{ height: 200 }} />
      </div>


    </Container>
  )

}

export default MaintenanceTimelineNiple;
