import React from 'react';
import { useSelector } from 'react-redux';

import {
    Container, Info, TitleInfo
} from './style';

import PDFsSponsoring from './PDFsSponsoring';
import PDFsSponsoringTerm from "./PDFsSponsoringTerm";

const PDFs = () => {

    const page = useSelector(state => state.page);

    return (
        <Container>

            <TitleInfo>Geração de PDF: {page.data}</TitleInfo>

            <Info>

              {page.data === "Patrocínio publicitário" && <PDFsSponsoring />}
              {page.data === "Termo publicitário" && <PDFsSponsoringTerm />}

            </Info>

        </Container>
    )

}

export default PDFs;
