
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewMaintenance from '../NewMaintenance';
import NewObservation from '../NewObservation';
import { Body, Container, HeaderBar } from './style';


const selectedColor = "#000";

const ScheduleModal: React.FC = () => {

  const [headerSelected, setHeaderSelected] = useState("")

  const objSelected = useSelector(state => state.objSelected)

  useEffect(() => {

    if (Object.keys(objSelected).length > 0) {

      if (objSelected.view === "observation") {
        setHeaderSelected("OBSERVACAO")
      }
      else {
        setHeaderSelected("MANUTENÇÃO")
      }
    } else {
      setHeaderSelected("MANUTENÇÃO")
    }

  }, [objSelected])


  return (
    <Container>


      {(Object.keys(objSelected).length === 0) &&
        <HeaderBar>

          <div
            style={headerSelected === "MANUTENÇÃO" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
            onClick={() => setHeaderSelected("MANUTENÇÃO")}
          >
            Manutenção
          </div>

          <div
            style={headerSelected === "OBSERVACAO" ? { color: selectedColor, borderBottomColor: selectedColor } : null}
            onClick={() => setHeaderSelected("OBSERVACAO")}
          >
            Observação
          </div>

        </HeaderBar>}

      <Body>

        {headerSelected === "MANUTENÇÃO" &&
          <>

            <NewMaintenance />

          </>}


        {headerSelected === "OBSERVACAO" && <>

          <NewObservation />

        </>}

      </Body>

    </Container >
  )

}

export default ScheduleModal;