import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import DropDown from '../../DropDown';
import Modal from '../../Modal';
import {
  Bills, BillsInfo, Container, Date, Header, InfoHeader,
  InfoRow,
  Infos,
  RightButtonClick, Row,
  Row1,
  Row2,
  Total
} from './style';

const coloralert = "#bd5959";

const DROPDOWN_MONTH = [
  { name: "JANEIRO", number: '01' },
  { name: "FEVEREIRO", number: '02' },
  { name: "MARÇO", number: '03' },
  { name: "ABRIL", number: '04' },
  { name: "MAIO", number: '05' },
  { name: "JUNHO", number: '06' },
  { name: "JULHO", number: '07' },
  { name: "AGOSTO", number: '08' },
  { name: "SETEMBRO", number: '09' },
  { name: "OUTUBRO", number: '10' },
  { name: "NOVEMBRO", number: '11' },
  { name: "DEZEMBRO", number: '12' },
]

const DROPDOWN_YEAR = [
  { name: "2021" },
  { name: "2022" },
  { name: "2023" },
  { name: "2024" },
  { name: "2025" },
]

const FinancialCard = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)
  const objSelected = useSelector(state => state.objSelected)
  const search = useSelector(state => state.search)

  var myRef = React.createRef()

  const [monthSelected, setMonthSelected] = useState(false)
  const [yearSelected, setYearSelected] = useState(false)

  const [showRightClick, setShowRightClick] = useState(false)
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const [modal, setModal] = useState(false)
  const [bill_type, setBillType] = useState("PAGAR")

  const [arrayExpenses, setArrayExpenses] = useState([])

  const [totalPixelSicoob, setTotalPixelSicoob] = useState(0)
  const [totalPixelInter, setTotalPixelInter] = useState(0)
  const [totalOctopusInter, setTotalOctupusInter] = useState(0)

  const [daysToPay, setDaysToPay] = useState([])

  useEffect(() => {

    Moment.locale('pt-br');

    let date = Moment()
    let month = DROPDOWN_MONTH.find(m => m.number === date.format("MM"))
    setMonthSelected(month)

    let year = DROPDOWN_YEAR.find(y => y.name === date.format("YYYY"))
    setYearSelected(year)

    findExpenses(month, year)

  }, [])

  useEffect(() => {

    if (monthSelected && yearSelected) {
      findExpenses(monthSelected, yearSelected)
    }

  }, [client])

  useEffect(() => {

    var auxDays = [];

    var totalPixelSicoob = 0;
    var totalPixelInter = 0;
    var totalOctopusInter = 0;

    if (arrayExpenses.length > 0) {

      arrayExpenses.map(item => {

        if (!item.canceled && item.payment_type === "CARTÃO") {

          item.type = "expenses"

          if (!item.payment_date) {

            auxDays.push(item);

            if (item.financial_payer_id === 1) {
              totalPixelSicoob = totalPixelSicoob + parseFloat(item.document_value)
            }
            else if (item.financial_payer_id === 2) {
              totalPixelInter = totalPixelInter + parseFloat(item.document_value)
            }
            else if (item.financial_payer_id === 4) {
              totalOctopusInter = totalOctopusInter + parseFloat(item.document_value)
            }

          }

        }

      })


    }

    setDaysToPay(auxDays);
    setTotalOctupusInter(totalOctopusInter);
    setTotalPixelInter(totalPixelInter);
    setTotalPixelSicoob(totalPixelSicoob);

    dispatch({ type: "SET_LOADER", data: false })



  }, [arrayExpenses])

  useEffect(() => {
    if (!modal && !showRightClick) {
      dispatch({ type: "SET_OBJ_SELECTED", data: {} });
    }
  }, [showRightClick])

  function handlerClickRow(item, type) {
    item.clone = false;
    dispatch({ type: "SET_OBJ_SELECTED", data: item });
    setBillType(type)
    openModal()
  }

  function rightClick(e, item, type) {
    e.preventDefault();
    setMouseX(e.nativeEvent.x - 200)
    setMouseY((e.nativeEvent.y + myRef.current.scrollTop) - 130)
    setShowRightClick(true)
    dispatch({ type: "SET_OBJ_SELECTED", data: item });
    setBillType(type)
  }

  function handlerRightClick(item) {

    var obj = objSelected

    if (item === "CLONAR") {
      obj.clone = true;
    }

    if (item === "QUITAR") {
      obj.payoff = true;
    }

    dispatch({ type: "SET_OBJ_SELECTED", data: obj });
    openModal()
  }

  async function findExpenses(month, year) {

    setArrayExpenses([])

    try {

      let response = await fetch(DEFAULT_URL + 'expenses/date', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id,
          month: month.number,
          year: year.name,
        })
      });

      let responseJson = await response.json();

      if (responseJson.success) {
        setArrayExpenses(responseJson.data);
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  function openModal() {
    setModal(true)
    dispatch({ type: "SET_DARK", data: true })
  }

  return (
    <Container
      ref={myRef}
      onClick={(e) => {
        setShowRightClick(false);
        e.preventDefault();
      }}
    >

      {modal &&
        <Modal
          onClose={() => setModal(false)}
          layout={`CONTAS: ${bill_type}`}
          client_id={client.id}
          callback={() => {
            findExpenses(monthSelected, yearSelected)
          }}
          monthSelected={monthSelected}
          yearSelected={yearSelected}
        />}

      <Header>

        <Date>
          {monthSelected && <DropDown width={150} item={monthSelected.name} array={DROPDOWN_MONTH} onChange={(item) => {
            setMonthSelected(item)
            findExpenses(item, yearSelected)
          }} />}
          {yearSelected && <DropDown width={100} item={yearSelected.name} array={DROPDOWN_YEAR} onChange={(item) => {
            setYearSelected(item)
            findExpenses(monthSelected, item)
          }} />}
        </Date>

      </Header>

      {showRightClick &&
        <RightButtonClick style={{ top: `${mouseY}px`, left: `${mouseX}px` }}>

          <div onClick={() => handlerRightClick("QUITAR")}>QUITAR</div>
          <Row1 />
          <div onClick={() => handlerRightClick("CLONAR")}>CLONAR</div>
          {
            objSelected.system &&
            <>
              <Row1 />
              <div onClick={() => handlerRightClick("FATURA")}>FATURA</div>
            </>
          }
          {
            objSelected.payment_type === "BOLETO" && objSelected.remittance_number !== null ? (
              <>
                <Row1 />
                <div onClick={() => handlerRightClick("BOLETO")}>BOLETO</div>
                <Row1 />
                <div onClick={() => handlerRightClick("EMAIL")}>E-MAIL</div>
              </>
            ) : null
          }

        </RightButtonClick>
      }

      <BillsInfo>

        {client.id === 2 && <Bills style={{ width: "50%" }}>
          <label>SICOOB MÁXI CRÉDITO
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
              setBillType("CREDIT_CARD")
              openModal()
            }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />
          </label>
          <Row2 />
          <InfoHeader>
            <label>Cliente</label>
            <label>Tipo</label>
            <span>Vencimento</span>
            <strong>Valor</strong>
          </InfoHeader>

          <Infos>

            {daysToPay.map(item => {

              if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {

                if (item.financial_payer_id === 1) {

                  return (
                    <InfoRow key={`arrExpenses${item.id}`} style={{ color: 'red' }} onContextMenu={(e) => rightClick(e, item, "PAGAR")} onDoubleClick={() => handlerClickRow(item, "CREDIT_CARD")}>
                      {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                      <label>{item.provider.name} </label>
                      <label>{item.type_expense.name} </label>
                      <span style={{ color: (!Moment(item.due_date).isAfter(Moment())) ? coloralert : "" }}>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                      <strong>{formatNumber(item.document_value)}</strong>
                    </InfoRow>
                  )
                }
              }
            })}

          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalPixelSicoob)}</label>
            </div>

          </Total>

        </Bills>}

        {client.id === 2 && <Row />}

        {client.id === 2 && <Bills style={{ width: "50%" }}>
          <label>BANCO INTER
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
              setBillType("CREDIT_CARD")
              openModal()
            }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />
          </label>
          <Row2 />
          <InfoHeader>
            <label>Cliente</label>
            <label>Tipo</label>
            <span>Vencimento</span>
            <strong>Valor</strong>
          </InfoHeader>

          <Infos>

            {daysToPay.map(item => {
              if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {
                if (item.financial_payer_id === 2) {
                  return (
                    <InfoRow key={`arrExpenses${item.id}`} style={{ color: 'red' }} onContextMenu={(e) => rightClick(e, item, "PAGAR")} onDoubleClick={() => handlerClickRow(item, "CREDIT_CARD")}>
                      {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                      <label>{item.provider.name} </label>
                      <label>{item.type_expense.name} </label>
                      <span style={{ color: (!Moment(item.due_date).isAfter(Moment())) ? coloralert : "" }}>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                      <strong>{formatNumber(item.document_value)}</strong>
                    </InfoRow>
                  )
                }
              }
            })}

          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalPixelInter)}</label>
            </div>

          </Total>

        </Bills>}

        {client.id === 3 && <Bills style={{ width: "100%" }}>
          <label>BANCO INTER
            <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
              setBillType("CREDIT_CARD")
              openModal()
            }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />
          </label>
          <Row2 />
          <InfoHeader>
            <label>Cliente</label>
            <label>Tipo</label>
            <span>Vencimento</span>
            <strong>Valor</strong>
          </InfoHeader>

          <Infos>

            {daysToPay.map(item => {
              if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {
                if (!item.payment_date && item.financial_payer_id === 4) {
                  return (
                    <InfoRow key={`arrExpenses${item.id}`} style={{ color: 'red' }} onContextMenu={(e) => rightClick(e, item, "PAGAR")} onDoubleClick={() => handlerClickRow(item, "CREDIT_CARD")}>
                      {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                      <label>{item.provider.name} </label>
                      <label>{item.type_expense.name} </label>
                      <span style={{ color: (!Moment(item.due_date).isAfter(Moment())) ? coloralert : "" }}>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                      <strong>{formatNumber(item.document_value)}</strong>
                    </InfoRow>
                  )
                }
              }
            })}


          </Infos>

          <Total>
            <div>
              TOTAL:
              <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalPixelInter)}</label>
            </div>

          </Total>

        </Bills>}

      </BillsInfo>

    </Container >
  )
}


export default FinancialCard;
