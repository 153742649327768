import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    > label {
        font-size: 10px;
        text-align: center;
        width: 100%;
        background: #eee;
        padding: 5px;
    }
`;

export const Details = styled.div`
    width: 100%;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 10px;

    :hover {
        background-color: rgba(0,0,0,0.1);
    }
`;

export const SocialReason = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: 5px;
`;

export const Email = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow-y: hidden;
    white-space: nowrap;
`;

export const CPNJ = styled.div`
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow-y: hidden;
`;

export const Active = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center; 
`;