import styled, { css } from 'styled-components'


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%; 
    padding: 0px 0px 15px 0px;
`;

export const Legend = styled.div`
    margin: 0 0 0 35px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 35px;
    letter-spacing: 2px;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;


export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 20px;

    @media(max-width: 725px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

`;

export const Totals = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background: var(--secondary);
    justify-content: space-evenly;
    font-size: 13px;
    border-bottom: 2px solid var(--primary);
    > strong {
        text-align: left;
        width: 30%;
        padding: 10px;
        font-family: 'GothamProRegular';
    }
    >label {
        font-family: 'GothamProRegular';
        width: 35%;
        text-align: left;
        padding: 10px;
    }
    > span {
        font-family: 'GothamProRegular';
        width: 35%;
        text-align: right;
        padding: 10px;
        font-weight: bold;
    }
`;

export const List = styled.div`
    background: var(--white);
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 12px;

    > strong {
        width: 100%;
        padding: 10px;
    }

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;

        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            padding: 5px;
            
            > span, strong{
                text-align: left;
                width: 25%;
            }

            > label {
                text-align: right;
                font-weight: bold;
                width: 10%;
            }

    }

`;