import {
    ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js'
import Moment from 'moment/min/moment-with-locales'
import React, { useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_URL, DEFAULT_USER_STRUCT, financialPermission, formatNumber } from '../../helper'
import PDFs from "../PDF/Menu";
import Command from '../Command'
import Crud from '../Crud'
import Equipment from '../Equipment'
import Financial from '../Financial'
import Maintenances from '../Maintenances'
import Report from '../Report'
import SearchBar from '../SearchBar'
import Stock from '../Stock'
import {
    CircleActive, CircleDefault, CircleInactive,
    CircleLinked, Container, FinancialCards, FinancialInfos, FundoCardView, GraphsHalf, GraphsInfo, Header, LastInstallInfo, Legend1, Row,
    Row1, TitleInfo, Total
} from './style'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


var totalRevenuesFixed = 0;
var totalExpensesFixed = 0;

const Storage: React.FC = () => {

    const dispatch = useDispatch()
    const client = useSelector(state => state.client)
    const user = useSelector(state => state.user)

    const page = useSelector(state => state.page);

    const options = {
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de instalações (R$)',
            },
        },
    };

    const options2 = {

        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de instalações (quant.)',
            },
        },
    };

    const optionsGrowth = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de receitas mensais',
            },
        },
    };

    const optionsMaintenances = {
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de manutenções (quant.)',
            },
        },
    };

    const optionsMaintenances2 = {
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de manutenções (R$)',
            },
        },
    };

    const [dataGraphInstall, setDataGraphInstall] = useState(false)
    const [dataGraphInstall2, setDataGraphInstall2] = useState(false)

    const [dataGraphGrowth, setDataGraphGrowth] = useState(false)
    const [dataGraphGrowthDetail, setDataGraphGrowthDetail] = useState(false)
    const [totalInstalls, setTotalInstalls] = useState(0)
    const [arrPercentsInstalls, setArrPercentsInstalls] = useState([])
    const [arrPercentsGrowth, setArrPercentsGrowth] = useState([])

    const [totalThisMonth, setTotalThisMonth] = useState(0)
    const [lastInstall, setLastInstall] = useState(false)
    const [lastUninstall, setLastUninstall] = useState(false)

    const [lastMaintenaces, setLastMaintenances] = useState(false)
    const [scheduleMaintenances, setScheduleMaintenances] = useState(false)
    const [totalCostMaintenances, setTotalCostMaintenances] = useState(0)
    const [totalMaintenances, setTotalMaintenances] = useState(0)
    const [dataGraphMaintenances, setGraphMaintenances] = useState(false)
    const [dataGraphMaintenances2, setGraphMaintenances2] = useState(false)

    const [totalNewNiple, setTotalNewNiple] = useState(0)
    const [totalNewVehicle, setTotalNewVehicle] = useState(0)
    const [totalNewSaas, setTotalNewSaas] = useState(0)

    const [totalChip, setTotalChip] = useState(0)
    const [totalActiveAchip, setTotalActiveAchip] = useState(0)
    const [totalInactiveChip, setTotalInactiveChip] = useState(0)
    // const [totalCostChip, setTotalCostChip] = useState(0)

    const [fixedExpenses, setFixedExpenses] = useState([])

    const [totalAllRevenues, setTotalAllRevenues] = useState(0)
    const [totalAllExpenses, setTotalAllExpenses] = useState(0)

    const [totalNiple, setTotalNiple] = useState(0)
    const [totalActiveNiple, setTotalActiveNiple] = useState(0)
    const [totalInactiveNiple, setTotalInactiveNiple] = useState(0)
    const [totalCostNiple, setTotalCostNiple] = useState(0)

    const [totalTracker, setTotalTracker] = useState(0)
    const [totalActiveTracker, setTotalActiveTracker] = useState(0)
    const [totalInactiveTracker, setTotalInactiveTracker] = useState(0)
    const [totalCostTracker, setTotalCostTracker] = useState(0)

    const [totalSaas, setTotalSaas] = useState(0)
    const [totalActiveSaas, setTotalActiveSaas] = useState(0)
    const [totalInactiveSaas, setTotalInactiveSaas] = useState(0)
    const [totalCostSaas, setTotalCostSaas] = useState(0)

    useEffect(() => {
        Moment.locale('pt-br');

        totalRevenuesFixed = 0;
        totalExpensesFixed = 0;

        findClients()
    }, []);

    useEffect(() => {

        if (page.name === "DASHBOARD")
            findLoggedUser()

    }, [client])

    useEffect(() => {

        if (page.name === "DASHBOARD") {
            findLoggedUser()
        }

    }, [page]);

    function clearAll() {

        setTotalChip(0)
        setTotalActiveAchip(0)
        setTotalInactiveChip(0)
        // const [totalCostChip, setTotalCostChip] = useState(0)

        setFixedExpenses([])

        setTotalNiple(0)
        setTotalActiveNiple(0)
        setTotalInactiveNiple(0)
        setTotalCostNiple(0)

        setTotalTracker(0)
        setTotalActiveTracker(0)
        setTotalInactiveTracker(0)
        setTotalCostTracker(0)

        setTotalSaas(0)
        setTotalActiveSaas(0)
        setTotalInactiveSaas(0)
        setTotalCostSaas(0)

    }

    async function findLoggedUser() {

        clearAll()
        dispatch({ type: "SET_LOADER", data: true })

        totalRevenuesFixed = 0;
        totalExpensesFixed = 0;

        try {

            let response = await fetch(DEFAULT_URL + 'users/' + user.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            if (responseJson.success) {

                var respondeUser = responseJson.data.user
                respondeUser.token = user.token

                findInstalls()
                findGrowth()
                findLastInstalls()
                findLastUninstall()
                findMonthMaintenances()
                findMonthMaintenancesToSchedule()
                findFixedExpensesDetails()
                findMaintenances()
                findApproves()

                dispatch({ type: "SET_USER", data: respondeUser })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


        } catch (error) {
            console.error(error);
        }

    }

    async function findLastInstalls() {

        const d = `${Moment(new Date()).format('yyyy-MM')}-01`
        try {

            let response = await fetch(DEFAULT_URL + 'inventary/last_install', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    date: d
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var tvei = 0;
                var tniple = 0;
                var tsaas = 0;
                var tall = 0;

                responseJson.data.map(item => {

                    var tMensal = item.monthly_payment - 7.5;
                    if (item.cost !== null) tMensal = item.monthly_payment - item.cost;

                    tall = parseFloat(tall.toFixed(2)) + tMensal;

                    if (item.type === "CARRO") {
                        tvei++;
                    }

                    else if (item.type === "LIMPA-FOSSA") {
                        tniple++;
                    }

                    else {
                        tsaas++;
                    }

                });

                setTotalNewNiple(tniple)
                setTotalNewVehicle(tvei)
                setTotalNewSaas(tsaas)


                setTotalThisMonth(tall.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

                return
            }
        } catch (error) {
            console.error(error);
        }

    }

    async function findInstalls() {

        const d = `${Moment(new Date()).subtract(6, 'month').format('yyyy-MM')}-01`

        try {

            let response = await fetch(DEFAULT_URL + 'inventary/details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    date: d
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                setTotalInstalls(responseJson.data.length);

                var months = [];
                responseJson.data.map(item => {

                    let m = item.installation_date.split("/")[2] + item.installation_date.split("/")[1];
                    if (typeof months[m] === 'undefined') {
                        months[m] = [item];
                    }
                    else {
                        months[m].push(item)
                    }

                    if (item.uninstallation_date != null && item.uninstallation_date !== "") {
                        let mu = item.uninstallation_date.split("/")[2] + item.uninstallation_date.split("/")[1];
                        if (typeof months[mu] === 'undefined') {
                            months[mu] = [item];
                        }
                        else {
                            months[mu].push(item)
                        }
                    }
                })

                var auxNiple = [];
                var auxVehicle = [];
                var auxSaas = [];

                var auxNipleLength = [];
                var auxVehicleLength = [];
                var auxSaasLength = [];

                var labels = [];

                Object.keys(months).map(m => {

                    var moneyNiple = 0;
                    var moneyVehicle = 0;
                    var moneySaas = 0;

                    var tNiple = 0;
                    var tVehicle = 0;
                    var tSaas = 0;

                    months[m].map(item => {
                        if (item.type === "CARRO") {
                            moneyVehicle = parseFloat(moneyVehicle.toFixed(2)) + item.monthly_payment
                            tVehicle++;
                        }

                        else if (item.type === "LIMPA-FOSSA") {
                            moneyNiple = parseFloat(moneyNiple.toFixed(2)) + item.monthly_payment
                            tNiple++;
                        }

                        else {
                            moneySaas = parseFloat(moneySaas.toFixed(2)) + item.monthly_payment
                            tSaas++;
                        }

                    })

                    labels.push(m.substring(4, 6) + "/" + m.substring(0, 4));
                    auxNiple.push(moneyNiple);
                    auxVehicle.push(moneyVehicle);
                    auxSaas.push(moneySaas);

                    auxNipleLength.push(tNiple);
                    auxVehicleLength.push(tVehicle);
                    auxSaasLength.push(tSaas);

                })

                setDataGraphInstall({
                    labels,
                    datasets: [
                        {
                            label: 'Caminhões fossa',
                            data: auxNiple,
                            borderColor: 'rgb(44, 132, 214)',
                            backgroundColor: 'rgba(44, 132, 214, 0.9)',
                        },
                        {
                            label: 'Rastreamento',
                            data: auxVehicle,
                            borderColor: 'rgb(19, 167, 68)',
                            backgroundColor: 'rgba(19, 167, 68, 0.9)',
                        },
                        {
                            label: 'Software (Saas.)',
                            data: auxSaas,
                            borderColor: 'rgba(245, 136, 12)',
                            backgroundColor: 'rgba(245, 136, 12, 0.9)',
                        },
                    ],
                })

                setDataGraphInstall2({
                    labels,
                    datasets: [
                        {
                            label: 'Caminhões fossa',
                            data: auxNipleLength,
                            borderColor: 'rgb(44, 132, 214)',
                            backgroundColor: 'rgba(44, 132, 214, 0.9)',
                        },
                        {
                            label: 'Rastreamento',
                            data: auxVehicleLength,
                            borderColor: 'rgb(19, 167, 68)',
                            backgroundColor: 'rgba(19, 167, 68, 0.9)',
                        },
                        {
                            label: 'Software (Saas.)',
                            data: auxSaasLength,
                            borderColor: 'rgba(245, 136, 12)',
                            backgroundColor: 'rgba(245, 136, 12, 0.9)',
                        },
                    ],
                })

                return
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function findGrowth() {

        try {

            let response = await fetch(DEFAULT_URL + 'inventary/growth', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            });

            let responseJson = await response.json();

            var lastI = ""
            if (responseJson.success) {

                setTotalInstalls(responseJson.data.length);

                var months = [];
                responseJson.data.map(item => {

                    let m = item.installation_date.split("/")[2] + item.installation_date.split("/")[1];
                    if (typeof months[m] === 'undefined') {
                        months[m] = [item];
                    }
                    else {
                        months[m].push(item)
                    }

                    if (item.uninstallation_date != null && item.uninstallation_date !== "") {
                        var clone = Object.assign({}, item);
                        let mu = item.uninstallation_date.split("/")[2] + item.uninstallation_date.split("/")[1];
                        clone.uninstall = true;
                        if (typeof months[mu] === 'undefined') {
                            months[mu] = [clone]
                        }
                        else {
                            months[mu].push(clone)
                        }
                    }

                    if (new Date(item.to_date) > new Date() && lastI === "") {
                        lastI = item.to_date;
                    }

                })

                if (lastI === "") {
                    lastI = responseJson.data[responseJson.data.length - 1].to_date
                }

                if (new Date(lastI) > new Date()) {
                    setLastInstall(`E o trampo! Bora, próxima instalação ${Moment(new Date(lastI + ' 23:59:59')).fromNow()}!`)
                } else {
                    setLastInstall(`No have trampo.. estamos ${Moment(new Date(lastI + ' 23:59:59')).fromNow()} sem novas instalações!`)
                }

                var aux = [];
                var i = 0;
                var labels = [];
                Object.keys(months).map(m => {
                    var currentm = { date: m, data: months[m] };
                    if (i > 0) {
                        currentm.data = [...months[m], ...aux[i - 1].data];
                    }
                    aux.push(currentm)
                    i++;
                    labels.push(m.substring(4, 6) + "/" + m.substring(0, 4));
                })

                var totals = [];
                var totalsSubCost = [];
                var totalsCost = [];
                var lastGeral = 0;
                var lastGeralC = 0;
                var percents = [];

                i = 0;
                aux.map(month => {
                    var total = 0;
                    var totalSubCost = 0;
                    var totalCost = 0;
                    var totaldesconto = 0;

                    month.data.map(item => {

                        if (item.uninstall) {
                            total = parseFloat(total.toFixed(2)) - item.monthly_payment

                            if (item.cost) {
                                totalCost = parseFloat(totalCost.toFixed(2)) - item.cost
                                totalSubCost = parseFloat(totalSubCost.toFixed(2)) - (item.monthly_payment - item.cost)
                            }
                            else {
                                totalSubCost = parseFloat(totalSubCost.toFixed(2)) - (item.monthly_payment - 7.5);
                            }
                        }
                        else {

                            if (item.advance_payment_value && item.advance_payment_value > 0) {
                                var realV = (item.monthly_payment * item.advance_payment_value) / 100;
                                var tPercent = item.monthly_payment - parseFloat(realV);
                                total = parseFloat(total.toFixed(2)) + tPercent;
                                totaldesconto = totaldesconto + parseFloat(realV);
                            }
                            else {
                                total = parseFloat(total.toFixed(2)) + item.monthly_payment
                            }

                            if (item.cost) {
                                totalCost = parseFloat(totalCost.toFixed(2)) + item.cost
                                totalSubCost = parseFloat(totalSubCost.toFixed(2)) + (item.monthly_payment - item.cost)
                            } else {
                                totalSubCost = parseFloat(totalSubCost.toFixed(2)) + (item.monthly_payment - 7.5);
                            }

                        }
                    })

                    if (lastGeral !== 0) {

                        var p = ((parseFloat(total.toFixed(2)) / lastGeral) - 1) * 100;
                        var pc = ((parseFloat(totalSubCost.toFixed(2)) / lastGeralC) - 1) * 100;

                        percents.push({
                            index: labels[i],
                            percent: p,
                            total: total,
                            totalSubCost: totalSubCost,
                            percentSubCost: pc,
                            totalCost: totalCost
                        })
                    } else {
                        percents.push({
                            index: labels[i],
                            percent: 0,
                            total: total,
                            totalSubCost: totalSubCost,
                            percentSubCost: 0,
                            totalCost: totalCost
                        })
                    }

                    lastGeral = total;
                    lastGeralC = totalSubCost;
                    i++;

                    totals.push(total.toFixed(2))
                    totalsSubCost.push(totalSubCost.toFixed(2))
                    totalsCost.push(totalCost.toFixed(2))
                })

                percents = percents.reverse();
                setArrPercentsGrowth(percents)

                setDataGraphGrowth({
                    labels,
                    datasets: [
                        {
                            label: 'Mensalidades',
                            data: totals,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ],
                })

                setDataGraphGrowthDetail({
                    labels,
                    datasets: [
                        {
                            label: 'Mensalidades',
                            data: totals,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Mensalidade - Custos',
                            data: totalsSubCost,
                            borderColor: 'rgb(54, 162, 235)',
                            backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        },
                        {
                            label: 'Custo',
                            data: totalsCost,
                            borderColor: 'rgb(209, 16, 16)',
                            backgroundColor: 'rgba(209, 16, 16, 0.5)',
                        },
                    ],
                })

                return
            }
        } catch (error) {
            console.error(error);
        }

    }

    async function findLastUninstall() {

        try {

            let response = await fetch(DEFAULT_URL + 'inventary/last_uninstall', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            if (responseJson.success) {

                let lastD = responseJson.data[0].to_date
                if (new Date(lastD) > new Date()) {
                    setLastUninstall(`Só role errado.. Proxima desinstalação ${Moment(new Date(lastD + ' 23:59:59')).fromNow()}!`)
                } else {
                    setLastUninstall(`Estamos ${Moment(new Date(lastD + ' 23:59:59')).fromNow()} sem desinstalações!`)
                }

                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findMonthMaintenances() {

        const d = `${Moment(new Date()).format('yyyy-MM')}-01`
        var lastDay = new Date(Moment(new Date()).format('yyyy'), Moment(new Date()).format('MM'), 0);
        const f = `${Moment(new Date()).format('yyyy-MM')}-${lastDay.getUTCDate()}`

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_sheets/find_month', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    date_start: d,
                    date_end: f
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                if (responseJson.data.length > 0) {

                    var costAll = 0;
                    responseJson.data.map(item => {
                        costAll = costAll + item.cost;
                    });

                    setLastMaintenances(`Manutenções: ${responseJson.data.length} - ${formatNumber(costAll)}`);

                } else {
                    setLastMaintenances("Nenhuma manutenção realizada esse mês 👍")
                }

                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findMonthMaintenancesToSchedule() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_sheets/find_month_schedule', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                if (responseJson.data.length > 0) {
                    dispatch({ type: "SET_TO_SCHEDULE", data: responseJson.data });
                    setScheduleMaintenances(`Agendamentos em aberto: ${responseJson.data.length}`);
                } else {
                    setScheduleMaintenances("Nenhum agendamento solicitado 👍")
                }

                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findMaintenances() {

        const d = `${Moment(new Date()).subtract(6, 'month').format('yyyy-MM')}-01`;
        var lastDay = new Date(Moment(new Date()).format('yyyy'), Moment(new Date()).format('MM'), 0);
        const f = `${Moment(new Date()).format('yyyy-MM')}-${lastDay.getUTCDate()}`;

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_sheets/find_lasts', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    date_start: d,
                    date_end: f
                })
            });

            let responseJson = await response.json();
            if (responseJson.success) {

                if (responseJson.data.length > 0) {

                    var months = [];

                    responseJson.data.map(item => {

                        let m = item.end_date.split("-")[1] + item.end_date.split("-")[0];
                        if (typeof months[m] === 'undefined') {
                            months[m] = [item];
                        }
                        else {
                            months[m].push(item)
                        }
                    });

                    var labels = [];
                    var auxMain = [];
                    var auxMainLength = [];
                    var totalCost = 0;

                    Object.keys(months).map(m => {

                        var moneyMain = 0;
                        var mLength = 0;

                        months[m].map(item => {
                            totalCost = parseFloat(totalCost.toFixed(2)) + item.cost
                            moneyMain = parseFloat(moneyMain.toFixed(2)) + item.cost
                            mLength++;
                        })

                        labels.push(m.substring(0, 2) + "/" + m.substring(2, 6));
                        auxMain.push(moneyMain);

                        auxMainLength.push(mLength);

                    })

                    setTotalCostMaintenances(totalCost);
                    setTotalMaintenances(responseJson.data.length);

                    setGraphMaintenances({
                        labels,
                        datasets: [
                            {
                                label: 'Manutenções',
                                data: auxMainLength,
                                borderColor: 'rgb(255, 99, 132)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                        ],
                    })

                    setGraphMaintenances2({
                        labels,
                        datasets: [
                            {
                                label: 'Manutenções',
                                data: auxMain,
                                borderColor: 'rgb(255, 99, 132)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                        ],
                    })
                }

                return
            }

        } catch (error) {
            console.error(error);
        }


    }

    async function findFixedExpensesDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'fixed_expenses/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalExpenses = 0;

                var fixedExpenses = [];
                responseJson.data.map(item => {
                    if (item.active) {
                        if (!fixedExpenses[item.type_expense.id]) {
                            fixedExpenses[item.type_expense.id] = [item]
                        } else {
                            fixedExpenses[item.type_expense.id].push(item)
                        }

                        totalExpenses = parseFloat(totalExpenses) + parseFloat(item.document_value);
                    }
                })

                setFixedExpenses(fixedExpenses)
                totalExpensesFixed = totalExpensesFixed + totalExpenses
                setTotalAllExpenses(totalExpensesFixed.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

                findChipsDetails()
                return
            }


            dispatch({ type: "SET_LOADER", data: false })

            // setDataFinancial([
            //     ['', 'RECEITAS', 'DESPESAS'],
            //     ['RECEITAS', 0, 0],
            //     ['DESPESAS', 0, 0],
            // ])

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }


    }

    async function findChipsDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'chips/details/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalChip = 0;
                var totalActiveChip = 0;
                var totalInactiveChip = 0;
                // var totalChipCost = 0;
                var totalLinked = 0;
                var totalAvailable = 0;

                responseJson.data.map(chip => {

                    if (chip.active) {
                        totalChip = totalChip + 1
                        totalActiveChip = totalActiveChip + 1;
                        // totalChipCost = parseFloat(totalChipCost) + parseFloat(chip.cost);

                        if (chip.serial) {
                            totalLinked = totalLinked + 1;
                        } else {
                            totalAvailable = totalAvailable + 1;
                        }

                    }
                    else {
                        totalInactiveChip = totalInactiveChip + 1;
                    }
                })

                setTotalChip(totalChip)
                setTotalActiveAchip(totalActiveChip)
                setTotalInactiveChip(totalInactiveChip)
                // setTotalCostChip(totalChipCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
                // setTotalChipsAvailable(totalAvailable)
                // setTotalChipsLinked(totalLinked)
                // totalExpensesFixed = totalExpensesFixed + totalChipCost;

                findVehiclesDetails()

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


        } catch (error) {
            console.error(error);
        }
    }

    async function findVehiclesDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'vehicles/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalNiple = 0;
                var totalActiveNiple = 0;
                var totalInactiveNiple = 0;
                var totalCostNiple = 0;

                var totalTracker = 0;
                var totalActiveTracker = 0;
                var totalInactiveTracker = 0;
                var totalCostTracker = 0;

                responseJson.data.map(item => {

                    if (item.type === "LIMPA-FOSSA") {
                        totalNiple = totalNiple + 1;
                        if (item.active) {
                            totalActiveNiple = totalActiveNiple + 1;
                            totalCostNiple = parseFloat(totalCostNiple) + parseFloat(item.contract.monthly_payment)
                        } else {
                            totalInactiveNiple = totalInactiveNiple + 1;
                        }
                    }

                    if (item.type === "CARRO") {
                        totalTracker = totalTracker + 1;
                        if (item.active) {
                            totalActiveTracker = totalActiveTracker + 1;
                            totalCostTracker = parseFloat(totalCostTracker) + parseFloat(item.contract.monthly_payment)
                        } else {
                            totalInactiveTracker = totalInactiveTracker + 1;
                        }
                    }

                })

                setTotalNiple(totalNiple)
                setTotalActiveNiple(totalActiveNiple)
                setTotalInactiveNiple(totalInactiveNiple)
                setTotalCostNiple(totalCostNiple.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

                setTotalTracker(totalTracker)
                setTotalActiveTracker(totalActiveTracker)
                setTotalInactiveTracker(totalInactiveTracker)
                setTotalCostTracker(totalCostTracker.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
                totalRevenuesFixed = totalRevenuesFixed + totalCostNiple + totalCostTracker;

                findSaasDetails()

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


        } catch (error) {
            console.error(error);
        }

    }

    async function findSaasDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'saas/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalSaas = 0;
                var totalActiveSaas = 0;
                var totalInactiveSaas = 0;
                var totalCostSaas = 0;

                responseJson.data.map(item => {

                    totalSaas = totalSaas + 1;
                    if (item.active) {
                        totalActiveSaas = totalActiveSaas + 1;
                        totalCostSaas = parseFloat(totalCostSaas) + parseFloat(item.contract.monthly_payment)
                    } else {
                        totalInactiveSaas = totalInactiveSaas + 1;
                    }
                })

                setTotalSaas(totalSaas)
                setTotalActiveSaas(totalActiveSaas)
                setTotalInactiveSaas(totalInactiveSaas)
                setTotalCostSaas(totalCostSaas.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

                totalRevenuesFixed = totalRevenuesFixed + totalCostSaas;
                setTotalAllRevenues(totalRevenuesFixed.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

                // setDataFinancial([
                //     ['', 'RECEITAS', 'DESPESAS'],
                //     ['RECEITAS', totalRevenuesFixed, 0],
                //     ['DESPESAS', totalExpensesFixed, 0],
                // ])

                dispatch({ type: "SET_LOADER", data: false })

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }


        } catch (error) {
            console.error(error);
        }

    }

    async function findClients() {

        try {

            let response = await fetch(DEFAULT_URL + 'clients', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                dispatch({ type: "SET_CLIENTS", data: responseJson.data })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function findApproves() {

        try {

            let response = await fetch(DEFAULT_URL + 'financial_approvals/open/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                dispatch({ type: "SET_FINANCIAL_APPROVES", data: responseJson.data })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Header>
                <SearchBar />
            </Header>

            <Row />

            {page.name === "DASHBOARD" && !financialPermission(user.profile) &&
                <TitleInfo>Bem-vindo ao CLOCT</TitleInfo>
            }

            {page.name === "DASHBOARD" && financialPermission(user.profile) &&
                <>
                    <TitleInfo>Esse mês <span className={parseFloat(totalThisMonth.toString().replace(",", "")) > 0 ? 'green' : 'red'}>+R${totalThisMonth}</span></TitleInfo>
                    <GraphsInfo
                        style={{ justifyContent: 'space-around' }}>

                        <GraphsHalf>
                            <p>CAMINHÕES FOSSA</p>
                            <span>+{totalNewNiple}</span>
                        </GraphsHalf>

                        <GraphsHalf>
                            <p>RASTREAMENTO</p>
                            <span>+{totalNewVehicle}</span>
                        </GraphsHalf>

                        <GraphsHalf>
                            <p>SOFTWARE (Saas.)</p>
                            <span>+{totalNewSaas}</span>
                        </GraphsHalf>
                    </GraphsInfo>

                    <LastInstallInfo>
                        <div>
                            {lastInstall}
                        </div>

                        <div>
                            {lastUninstall}
                        </div>

                        <div>
                            {lastMaintenaces}
                        </div>

                        <div>{scheduleMaintenances}</div>

                    </LastInstallInfo>

                    <TitleInfo>Análise de crescimento (mensalidades)</TitleInfo>
                    <GraphsInfo>

                        {dataGraphGrowth &&
                            <>
                                <div className="totals">

                                    <div className="info-res">
                                        <p>Mês</p>
                                        <span>Valor</span>
                                        <span>Crescimento</span>
                                    </div>

                                    {arrPercentsGrowth.map((item, i) => {

                                        return (<div className="info-res" key={`key_percents_${i}`}>
                                            <p>{item.index}</p>

                                            <NumberFormat
                                                value={(item.total)}
                                                thousandSeparator={"."}
                                                prefix={'R$ '}
                                                style={{ textAlign: 'right', width: '35%', fontSize: 10, fontWeight: 'bold' }}
                                                decimalScale={2}
                                                placeholder={"R$"}
                                                decimalSeparator={","}
                                                fixedDecimalScale={true}
                                                disabled={true} />

                                            {/* <span className={(item.percent > 0 ? 'green span_percent' : 'red span_percent')}>{(item.percent > 0 ? "+" : "") + item.percent.toFixed(3).replace(".", ",")}%</span> */}
                                            <span className={item.percent > 0 ? 'green' : 'red'}>{(item.percent > 0 ? "+" : "") + item.percent.toFixed(3).replace(".", ",")}%</span>

                                        </div>)

                                    })}


                                </div>

                                <div className="graph">
                                    <Line options={optionsGrowth} data={dataGraphGrowth} />
                                </div>

                            </>
                        }
                    </GraphsInfo>

                    <TitleInfo>Análise detalhada de crescimento</TitleInfo>
                    <GraphsInfo>

                        {dataGraphGrowthDetail &&
                            <>
                                <div className="totals">

                                    <div className="info-res">
                                        <p>Mês</p>
                                        <span>Bruto</span>
                                        <span>Custo</span>
                                        <span>Liquido</span>
                                        {/* <span>Crescimento</span> */}
                                    </div>

                                    {arrPercentsGrowth.map((item, i) => {

                                        return (<div className="info-res" key={`growth_ana_${i}`}>
                                            <p>{item.index}</p>

                                            <NumberFormat
                                                value={(item.total)}
                                                thousandSeparator={"."}
                                                prefix={'R$ '}
                                                style={{ textAlign: 'right', width: '35%', fontSize: 10, fontWeight: 'bold' }}
                                                decimalScale={2}
                                                placeholder={"R$"}
                                                decimalSeparator={","}
                                                fixedDecimalScale={true}
                                                disabled={true} />

                                            {/* <span className={(item.percent > 0 ? 'green span_percent' : 'red span_percent')}>{(item.percent > 0 ? "+" : "") + item.percent.toFixed(3).replace(".", ",")}%</span> */}

                                            <NumberFormat
                                                value={(item.totalCost)}
                                                thousandSeparator={"."}
                                                prefix={'R$ '}
                                                style={{ textAlign: 'right', color: 'red', width: '35%', fontSize: 10, fontWeight: 'bold' }}
                                                decimalScale={2}
                                                placeholder={"R$"}
                                                decimalSeparator={","}
                                                fixedDecimalScale={true}
                                                disabled={true} />

                                            <NumberFormat
                                                value={(item.totalSubCost)}
                                                thousandSeparator={"."}
                                                prefix={'R$ '}
                                                style={{ textAlign: 'right', width: '35%', fontSize: 10, fontWeight: 'bold' }}
                                                decimalScale={2}
                                                placeholder={"R$"}
                                                decimalSeparator={","}
                                                fixedDecimalScale={true}
                                                disabled={true} />

                                            {/* <span className={item.percentSubCost > 0 ? 'green' : 'red'}>{(item.percentSubCost > 0 ? "+" : "") + item.percentSubCost.toFixed(3).replace(".", ",")}%</span> */}

                                        </div>)

                                    })}


                                </div>

                                <div className="graph">
                                    <Line options={optionsGrowth} data={dataGraphGrowthDetail} />
                                </div>

                            </>
                        }
                    </GraphsInfo>

                    <TitleInfo>Instalações (últ. 6 meses)</TitleInfo>
                    <GraphsInfo style={{ justifyContent: 'space-evenly', padding: 10 }}>

                        {dataGraphInstall && dataGraphInstall2 &&
                            <>

                                <div className="graph" style={{ width: '38vw' }}>
                                    <Bar options={options2} data={dataGraphInstall2} />
                                </div>
                                <div style={{ width: 5, background: '#fff' }} />
                                <div className="graph" style={{ width: '38vw' }}>
                                    <Bar options={options} data={dataGraphInstall} />
                                </div>

                            </>
                        }
                    </GraphsInfo>

                    <TitleInfo>Manutenções ({totalMaintenances}) - <span style={{ color: "red" }}>{formatNumber(totalCostMaintenances)}</span> </TitleInfo>
                    <GraphsInfo style={{ justifyContent: 'space-evenly', padding: 10 }}>
                        {dataGraphMaintenances && dataGraphMaintenances2 &&
                            <>
                                <div className="graph" style={{ width: '38vw' }}>
                                    <Bar options={optionsMaintenances} data={dataGraphMaintenances} />
                                </div>
                                <div style={{ width: 5, background: '#fff' }} />
                                <div className="graph" style={{ width: '38vw' }}>
                                    <Bar options={optionsMaintenances2} data={dataGraphMaintenances2} />
                                </div>
                            </>

                        }
                    </GraphsInfo>

                    <FinancialInfos>

                        <FinancialCards>
                            <TitleInfo>Receitas Fixas - R$ {totalAllRevenues}</TitleInfo>
                            <Legend1>
                                <span><CircleDefault /> <label>Total</label></span>
                                <span><CircleActive /> <label>Ativos</label></span>
                                <span><CircleInactive /> <label>Inativos</label></span>
                                <span><CircleLinked /> <label>Valores</label></span>
                            </Legend1>

                            <Total>

                                <div>

                                    <FundoCardView>
                                        <strong>CAMINHÕES FOSSA</strong>
                                        <span><CircleDefault />{totalNiple}</span>
                                        <Row1 />
                                        <span><CircleActive />{totalActiveNiple}</span>
                                        <Row1 />
                                        <span><CircleInactive />{totalInactiveNiple}</span>
                                        <Row1 />
                                        <span><CircleLinked />R$ {totalCostNiple}</span>
                                        <Row1 style={{ background: 'transparent' }} />
                                    </FundoCardView>

                                    <FundoCardView>

                                        <strong>RASTREAMENTO</strong>
                                        <span><CircleDefault />{totalTracker}</span>
                                        <Row1 />
                                        <span><CircleActive />{totalActiveTracker}</span>
                                        <Row1 />
                                        <span><CircleInactive />{totalInactiveTracker}</span>
                                        <Row1 />
                                        <span><CircleLinked />R$ {totalCostTracker}</span>
                                        <Row1 style={{ background: 'transparent' }} />
                                    </FundoCardView>

                                    <FundoCardView>
                                        <strong>SOFTWARE (Saas)</strong>
                                        <span><CircleDefault />{totalSaas}</span>
                                        <Row1 />
                                        <span><CircleActive />{totalActiveSaas}</span>
                                        <Row1 />
                                        <span><CircleInactive />{totalInactiveSaas}</span>
                                        <Row1 />
                                        <span><CircleLinked />R$ {totalCostSaas}</span>
                                        <Row1 style={{ background: 'transparent' }} />
                                    </FundoCardView>

                                </div>

                                {/* <div style={{ width: 300 }}>
                                    <Pie data={{
                                        labels: ['Caminhões fossa', 'Rastreamento', 'Software (Saas.)'],
                                        datasets: [
                                            {
                                                label: '# of Votes',
                                                data: [parseFloat(totalCostNiple.toString().replace(",", "")), parseFloat(totalCostTracker.toString().replace(",", "")), parseFloat(totalCostSaas.toString().replace(",", ""))],
                                                backgroundColor: [
                                                    'rgba(44, 132, 214, 0.3)',
                                                    'rgba(19, 167, 68, 0.3)',
                                                    'rgba(245, 136, 12, 0.3)'
                                                ],
                                                borderColor: [
                                                    'rgba(44, 132, 214, 1)',
                                                    'rgba(19, 167, 68, 1)',
                                                    'rgba(245, 136, 12, 1)'
                                                ],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }} />
                                </div> */}

                            </Total>

                            <Row />
                            <Row />

                            <TitleInfo>Despesas Fixas - R$ {totalAllExpenses}</TitleInfo>
                            <Legend1>
                                <span><CircleDefault /> <label>Quantidade</label></span>
                                <span><CircleInactive /> <label>Custo</label></span>
                            </Legend1>

                            <Total>

                                <div>

                                    {fixedExpenses.map(item => {

                                        var totalCost = 0;
                                        var totalItens = 0;
                                        var title = ""
                                        item.map(expense => {
                                            title = expense.type_expense.name
                                            totalItens++;
                                            totalCost = totalCost + expense.document_value
                                        })

                                        return (
                                            <FundoCardView key={`expense_${title}`}>
                                                <strong>{title}</strong>
                                                <span><CircleDefault />{totalItens}</span>
                                                <Row1 />
                                                <span><CircleInactive />R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                                <Row1 style={{ background: 'transparent' }} />
                                            </FundoCardView>
                                        )
                                    })}

                                </div>

                            </Total>

                        </FinancialCards>

                    </FinancialInfos>

                    <Row />
                    <Row />
                    <Row />
                    <Row />

                </>}

            {page.name === "CRUD" && <>
                <Crud />
            </>}

            {page.name === "REPORT" && <>
                <Report />
            </>}

            {page.name === "PDF" && <>
                <PDFs />
            </>}

            {page.name === "COMMAND" && <>
                <Command />
            </>}

            {page.name === "FINANCIAL" && <>
                <Financial />
            </>}

            {page.name === "STOCK" && <>
                <Stock />
            </>}

            {page.name === "EQUIPMENT" &&
                <Equipment />}

            {page.name === "MAINTENANCE" &&
                <Maintenances />}

        </Container>
    )

}

export default Storage;
