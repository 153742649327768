import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active,
    CPNJ,
    Container,
    Details, Email, SocialReason
} from './style';
import Modal from '../../Modal';


const GridRenter = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])
    const [selectedItens, setSelectedItens] = useState([])
    const [jumpItens, setJumpItens] = useState(0)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'renters', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                resetSelectedItens();
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    function resetSelectedItens() {
      setSelectedItens([]);
      setJumpItens(0);
    }

    function selectAllItens() {
      setSelectedItens(arrayItens.filter(x => x.active === true).slice());
    }

    function isSelectedItem(item) {
      const itens = selectedItens.slice();
      const index = itens.findIndex(x => x.id === item.id);
      if (index !== -1) return true;
      return false;
    }

    function addSelectedItem(item) {
      const itens = selectedItens.slice();
      const index = itens.findIndex(x => x.id === item.id);
      if (index !== -1) itens.splice(index, 1);
      else itens.push(item);
      setSelectedItens(itens);
    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {
            if (search === "" || item.name.toUpperCase().includes(search.toUpperCase()) || item.identifier.includes(search) || item.email.includes(search)) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    function openModal() {
      if (selectedItens.length === 0) return;
      setModal(true);
      dispatch({ type: "SET_DARK", data: true });
    }

    function closeModal() {
      setModal(false);
      resetSelectedItens();
    }

    return (
      <>
        {modal && (
            <Modal
              onClose={closeModal}
              layout={`IMPRIMIR ETIQUETAS`}
              selectedItens={selectedItens}
              jumpItens={jumpItens ?? 0}
            />
          )}

          <Container>
              <label>Total: {total}</label>
              <label style={{ marginLeft: "5px", cursor: "pointer" }} onClick={openModal}>Imprimir ({selectedItens.length})</label>
              <label style={{ marginLeft: "5px", cursor: "pointer", height: "20px", overflow: "hidden" }}>Pular: <input style={{ width: "50px", padding: 0, fontSize: "12px", lineHeight: 1, height: "20px", textAlign: "center" }} type="number" onChange={(e) => setJumpItens(e.target.value)} value={jumpItens} /></label>

              <div style={{ background: '#eeeeee' }}>
                  <Details>
                      <SocialReason style={{ fontWeight: 'bold' }}>Nome</SocialReason>
                      <CPNJ style={{ fontWeight: 'bold' }}>Identificador</CPNJ>
                      <Email style={{ fontWeight: 'bold' }}>Email</Email>
                      <Active style={{ fontWeight: 'bold' }}>Bom Pagador</Active>
                      <Active style={{ fontWeight: 'bold' }}>Status</Active>
                      <Active onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (selectedItens.length === arrayItens.filter(x => x.active === true).length) {
                          resetSelectedItens();
                          return;
                        }
                        selectAllItens();
                      }} style={{ fontWeight: 'bold' }}>Etiquetas</Active>
                  </Details>
              </div>

              {arrayItens.map((item, i) => {
                  if (search === "" || item.name.toUpperCase().includes(search.toUpperCase()) || item.identifier.includes(search) || item.email.includes(search)) {
                      return (
                          <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={((item === objSelected)) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                              <Details>
                                  <SocialReason>{item.name.substr(0, 35)}</SocialReason>
                                  <CPNJ>{item.identifier}</CPNJ>
                                  <Email>{item.email}</Email>
                                  <Active>{!item.bad_payer ? <CircleActive /> : <CircleInactive />}</Active>
                                  <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                                  <Active onClick={(e) => {
                                    if (item.active) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      addSelectedItem(item);
                                    }
                                  }}>{item.active ? (isSelectedItem(item) ? <CircleActive /> : <CircleInactive />) : "-"}</Active>
                              </Details>
                          </div>
                      )
                  }
              })}
          </Container>
      </>
    )

}

export default GridRenter;
