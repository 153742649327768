import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Container,
    ResponseAutoComplete,
    SelectAutoComplete
} from './style';

import { DEFAULT_URL } from '../../helper';

var timeout = null;
const AutoComplete: React.FC = ({ label, finder, item, setSelected }) => {

    // const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    // const [id, setId] = useState(0)
    // const [desc, setDesc] = useState("")
    const [searchAutoComplete, setSearchAutoComplete] = useState("")
    const [arrayAutoComplete, setArrayAutoComplete] = useState([])

    const [status, setStatus] = useState("")

    function handlerChange(text) {

        setStatus(" ...")
        setArrayAutoComplete([])
        setSearchAutoComplete(text)

        if (timeout)
            clearTimeout(timeout)

        if (text === '') {
            setStatus("")
            return
        }

        timeout = setTimeout(() => {
            var obj = { search: text }

            var url = finder;
            if (finder === "trackers_n") {
                url = "trackers"
                obj = { search: text, type: "fossa" }
            }
            if (finder === "trackers_v") {
                url = "trackers"
                obj = { search: text, type: "vehicle" }
            }

            autoComplete(url, obj)
        }, 1000);

    }

    async function autoComplete(url, request) {

        try {
            let response = await fetch(DEFAULT_URL + url + '/autocomplete/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify(request),
            });

            let responseJson = await response.json();
            if (responseJson.success) {
                setStatus(`(${responseJson.data.length})`);
                setArrayAutoComplete(responseJson.data)
            }


        } catch (error) {
            console.error(error);
        }
    }

    function selectAutoComplete(item) {
        // id = item.id)
        // setDesc(item.name);
        setSelected(item)
        setSearchAutoComplete("")
        setArrayAutoComplete([])
        setStatus("")
    }

    return (
        <Container>

            <label>{label}: {status !== "" && status}</label>

            {item.id === 0 &&
                <>
                    <input
                        type="text"
                        placeholder="Pesquisar.."
                        value={searchAutoComplete}
                        onChange={event => handlerChange(event.target.value)} />

                    {arrayAutoComplete.length > 0 &&
                        <ResponseAutoComplete>
                            {arrayAutoComplete.map(item => {
                                return (
                                    <span key={item.id} onClick={() => selectAutoComplete(item)}>
                                        {item.search}
                                    </span>
                                )
                            })}
                        </ResponseAutoComplete>}
                </>

            }

            {item.id !== 0 &&
                <SelectAutoComplete>{item.name}<span onClick={() => { setSelected({ id: 0, name: "" }) }}>x</span></SelectAutoComplete>
            }

        </Container>
    )

}

export default AutoComplete;
