import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../../helper';
import { CircleActive, CircleInactive } from '../../../../styles/CrudLayout';
import {
    Active, ChipDetais, Container, Number
} from './style';


const GridSuspicions: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'suspicions', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                dispatch({ type: "SET_SUSPICIONS", data: responseJson.data })
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {
            if (search === "" || item.name.includes(search)) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(chip) {

        if (chip === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: chip });
    }

    return (
        <Container>

            <label>Total: {total}</label>

            <div style={{ background: '#eeeeee' }}>
                <ChipDetais>
                    <Number style={{ fontWeight: 'bold' }}>Nome</Number>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </ChipDetais>
            </div>

            {arrayItens.map((item, i) => {

                if (search === "" || item.name.includes(search)) {
                    return (
                        <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9", border: (item === objSelected) ? '1px solid #e82653' : 'none' }} onClick={() => handlerClickRow(item)}>
                            <ChipDetais>
                                <Number>{item.name}</Number>
                                <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                            </ChipDetais>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridSuspicions;