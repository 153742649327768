import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

export const Pictures = styled.div`
    display: flex;
    flex-wrap: wrap;    
    overflow: auto;

    > div {
        height: 300px;
        width: 200px;
        border-radius: 10px;
        margin: 10px 0 0 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            cursor: pointer;
        }

        > video {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
`

