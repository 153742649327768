import { combineReducers } from 'redux'
import client from './client'
import clients from './clients'
import dark from './dark'
import financial from './financial'
import financialApproves from './financial_approves'
import loader from './loader'
import mail from './mail'
import maintenance from './maintenance'
import modal from './modal'
import objSelected from './objSelected'
import page from './page'
import problems from './problems'
import remittanceApi from './remittanceApi'
import remittanceFile from './remittanceFile'
import returnFile from './returnFile'
import search from './search'
import solutions from './solutions'
import subproblems from './subsproblems'
import suspicions from './suspicions'
import toSchedule from './toSchedule'
import typeExpenses from './type_expenses'
import typePayments from './type_payments'
import typeRevenues from './type_revenues'
import typeSaas from './type_saas'
import updateTimeline from './updateTimeline'
import user from './user'
import wpp from './wpp'

export default combineReducers({
  page,
  modal,
  client,
  clients,
  user,
  dark,
  search,
  objSelected,
  financial,
  financialApproves,
  mail,
  remittanceApi,
  remittanceFile,
  returnFile,
  maintenance,
  loader,
  suspicions,
  problems,
  solutions,
  updateTimeline,
  subproblems,
  toSchedule,
  typeExpenses,
  typePayments,
  typeRevenues,
  typeSaas,
  wpp
});
