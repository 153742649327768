import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: var(--primary);
    padding: 0px 0px 0px 0px;
`;

