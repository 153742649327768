import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    padding: 0px 0px 15px 0px;
    
    overflow-y: hidden;

    > .no-wrap {
        white-space: nowrap;
        overflow-x: auto;
    }

    .updates {
        display: flex;
        justify-content: space-between;
        margin: 0 35px 0 20px;  
        height: 50px;
        overflow: hidden;
        > div {
            font-size: 12px;
            font-weight: bold;

            > p {
                height: 25px;
            }
        }

        > button {
            cursor: pointer;
            background-color: var(--details);
            height: 40px;
            padding: 0 15px 0 15px;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 5px;
            font-size: 15px;
        }
    }
`;

export const Column = styled.div`

    display: inline-block;
    width: 200px;
    height: calc(100vh - 150px);
    background-color: #f7f9fc;
    border-radius: 5px;
    padding: 15px 5px;
    margin: 0 0 0 10px;
    overflow-y: auto;

    >  p {
        color: #222;
        font-size: 11px;
        font-weight: bold;
        margin-left: 5px;
    }
`;

export const CardMaintenance = styled.div`
    width: 190px;
    height: 115px;
    background-color: #fff;
    border-radius: 5px;
    margin: 10px 0 0 0;
    padding: 7px;
    box-shadow: rgba(30,30,30,0.2) 0px 0px 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    > div {
        border-radius: 3px;
        font-size: 9px;
        font-weight: bold;
        color: #fff;
        height: 20px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items:center;
    }

    > p {
        color: #222;
        font-weight: bold;
        font-size: 10px;
        margin: 5px 0 0 0;
    }

    .tooltiptext {
        visibility: hidden;
        background-color: rgba(255, 255, 255, 0.95);
        color: #000;
        padding: 5px;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 1;
        top: 0;
        width: 210px;
        height: 110px;
        font-size: 13px;
        flex-direction: column;
        
        > textarea {
            width: 190px;
            height: 105px;
            cursor: pointer;
            font-weight: normal;
        }
    }

    &:hover .tooltiptext {
        visibility: visible;
    }

`;