import { faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';


const ReportNiple: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    // const report = useSelector(state => state.report)

    const [totalItem, setTotalItem] = useState(0)
    const [totalLinked, setTotalLinked] = useState([])
    const [totalUnlinked, setTotalUnlinked] = useState([])
    const [totalInactives, setTotalInactives] = useState([])

    const [totalCost, setTotalCost] = useState(0)
    const [totalCostLinked, setTotalCostLinked] = useState(0)
    const [totalCostUnlinked, setTotalCostUnlinked] = useState(0)
    const [totalCostInactive, setTotalCostInactive] = useState(0)

    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        findDetails()
    }, [])

    function sumCost(item) {
        var total = 0;

        if (item.c_cost) total += parseFloat(item.c_cost);
        if (item.h_cost) total += parseFloat(item.h_cost);
        if (item.n_cost) total += parseFloat(item.n_cost);
        if (item.r_cost) total += parseFloat(item.r_cost);
        if (item.t_cost) total += parseFloat(item.t_cost);
        if (item.sum_n) total += parseFloat(item.sum_n);
        if (item.sum_h) total += parseFloat(item.sum_h);
        if (item.b_cost) total += parseFloat(item.b_cost);
        if (item.m_cost) total += parseFloat(item.m_cost);
        if (item.bo_cost) total += parseFloat(item.bo_cost);

        return total;
    }

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'niples/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            var totalItem = 0;
            var totalLinked = [];
            var totalUnlinked = [];
            var totalInactive = [];

            var totalCost = 0;
            var totalCostLinked = 0;
            var totalCostUnlinked = 0;
            var totalCostInactive = 0;

            if (responseJson.success) {

                responseJson.data.map(item => {

                    if (item.active) {
                        totalItem += 1;
                        totalCost = parseFloat(totalCost) + sumCost(item);

                        if (item.identifier) {
                            totalLinked.push(item);
                            totalCostLinked = parseFloat(totalCostLinked) + sumCost(item);
                        }
                        else {
                            totalUnlinked.push(item);
                            totalCostUnlinked = parseFloat(totalCostUnlinked) + sumCost(item);
                        }
                    }
                    else {
                        totalInactive.push(item);
                        totalCostInactive = parseFloat(totalCostInactive) + sumCost(item);
                    }

                })

                setTotalItem(totalItem)
                setTotalLinked(totalLinked)
                setTotalUnlinked(totalUnlinked)
                setTotalInactives(totalInactive)

                setTotalCost(totalCost)
                setTotalCostLinked(totalCostLinked)
                setTotalCostUnlinked(totalCostUnlinked)
                setTotalCostInactive(totalCostInactive)


                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>Niples Ativos</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Niples em Veículos</strong> <label>Total: {totalLinked.length}</label><span>R$ {totalCostLinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnlinked.length}</label><span style={{ color: 'red' }}>R$ {totalCostUnlinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativos</strong> <label>Total: {totalInactives.length}</label><span style={{ color: 'red' }}>R$ {totalCostInactive.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>

                <Totals><span></span></Totals>

                <List>

                    {/* <div style={{ display: 'flex', flexDirection: "row" }}> */}
                    <strong>Niple: Veículos <FontAwesomeIcon onClick={() => setShowInfo(!showInfo)} icon={faSearchDollar} style={{ width: 20, position: 'absolute', right: 35, height: 20, marginTop: 0, cursor: 'pointer' }} /></strong>

                    {/* </div> */}
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong style={{ width: '4%' }}>Posse</strong>
                            <strong style={{ width: '4%' }}>Veículo</strong>
                            <strong style={{ width: '4%' }}>Niple</strong>
                            <strong style={{ width: '3%' }}>Esct.</strong>
                            <strong style={{ width: '4%' }}>Rast.</strong>
                            <strong style={{ width: '7%' }}>Chip</strong>
                            <strong style={{ width: '7%' }}>Chassi</strong>
                            <strong style={{ width: '7%' }}>TAG</strong>
                            <strong style={{ width: '10%' }}>B. Bateria</strong>
                            <strong style={{ width: '10%' }}>Motor</strong>
                            <strong style={{ width: '10%' }}>Placa de sens.</strong>
                            <strong style={{ width: '7%' }}>Data Inst.</strong>
                            <strong style={{ width: '5%' }}>S. Backdoor</strong>
                            <label style={{ width: '5%' }}>Custo</label>
                        </div>

                        {totalLinked.map((item, i) => {

                            return (
                                <>
                                    <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                        <span style={{ width: '4%' }}>{item.social_reason}</span>
                                        <span style={{ width: '4%' }}>{item.identifier}</span>
                                        <span style={{ width: '4%' }}>{item.n_serial}</span>
                                        <span style={{ width: '3%' }}>{item.h_serial}</span>
                                        <span style={{ width: '4%' }}>{item.t_serial}</span>
                                        <span style={{ width: '7%' }}>{item.number}</span>
                                        <span style={{ width: '7%' }}>{item.c_serial}</span>
                                        <span style={{ width: '7%' }}>{item.r_serial}</span>
                                        <span style={{ width: '10%' }}>{item.b_lot_number}</span>
                                        <span style={{ width: '10%' }}>{item.m_lot_number}</span>
                                        <span style={{ width: '10%' }}>{item.bo_lot_number}</span>
                                        <span style={{ width: '7%' }}>{item.installation_date}</span>
                                        <span style={{ width: '5%' }}>{(item.backdoor_sensor) ? "ON" : "OFF"}</span>
                                        <label style={{ width: '5%' }}>R$ {parseFloat(sumCost(item)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                    </div>
                                    {showInfo && <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff", borderTop: (i % 2 === 1) ? '1px solid #fff' : '1px solid #eee' }}>
                                        <span style={{ width: '4%' }}></span>
                                        <span style={{ width: '4%' }}></span>
                                        <span style={{ width: '4%' }}>R$ {parseFloat(item.n_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '3%' }}>R$ {(item.h_cost) ? parseFloat(item.h_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '4%' }}>R$ {parseFloat(item.t_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '7%' }}></span>
                                        <span style={{ width: '7%' }}>R$ {(item.c_cost) ? parseFloat(item.c_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '7%' }}>R$ {parseFloat(item.r_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.b_cost) ? parseFloat(item.b_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.m_cost) ? parseFloat(item.m_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.bo_cost) ? parseFloat(item.bo_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '7%' }}></span>
                                        <span style={{ width: '5%' }}>R$ {(item.sum_h) ? parseFloat(item.sum_h).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + " + " : ""}  {parseFloat(item.sum_n).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <label style={{ width: '5%' }}></label>
                                    </div>}
                                </>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Niple: Disponíveis</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong style={{ width: '4%' }}>Posse</strong>
                            <strong style={{ width: '4%' }}>Niple</strong>
                            <strong style={{ width: '3%' }}>Esct.</strong>
                            <strong style={{ width: '4%' }}>Rast.</strong>
                            <strong style={{ width: '7%' }}>Chip</strong>
                            <strong style={{ width: '7%' }}>Chassi</strong>
                            <strong style={{ width: '7%' }}>TAG</strong>
                            <strong style={{ width: '10%' }}>B. Bateria</strong>
                            <strong style={{ width: '10%' }}>Motor</strong>
                            <strong style={{ width: '10%' }}>Placa de sens.</strong>
                            {/* <strong style={{ width: '7%' }}>Data Inst.</strong> */}
                            <strong style={{ width: '5%' }}>S. Backdoor</strong>
                            <label style={{ width: '5%' }}>Custo</label>
                        </div>

                        {totalUnlinked.map((item, i) => {
                            return (
                                <>
                                    <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                        <span style={{ width: '4%' }}>{item.social_reason}</span>
                                        <span style={{ width: '4%' }}>{item.n_serial}</span>
                                        <span style={{ width: '3%' }}>{item.h_serial}</span>
                                        <span style={{ width: '4%' }}>{item.t_serial}</span>
                                        <span style={{ width: '7%' }}>{item.number}</span>
                                        <span style={{ width: '7%' }}>{item.c_serial}</span>
                                        <span style={{ width: '7%' }}>{item.r_serial}</span>
                                        <span style={{ width: '10%' }}>{item.b_lot_number}</span>
                                        <span style={{ width: '10%' }}>{item.m_lot_number}</span>
                                        <span style={{ width: '10%' }}>{item.bo_lot_number}</span>
                                        {/* <span style={{ width: '7%' }}>{item.installation_date}</span> */}
                                        <span style={{ width: '5%' }}>{(item.backdoor_sensor) ? "ON" : "OFF"}</span>
                                        <label style={{ width: '5%' }}>R$ {parseFloat(sumCost(item)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                    </div>
                                    {showInfo && <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff", borderTop: (i % 2 === 1) ? '1px solid #fff' : '1px solid #eee' }}>
                                        <span style={{ width: '4%' }}></span>
                                        <span style={{ width: '4%' }}>R$ {parseFloat(item.n_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '3%' }}>R$ {(item.h_cost) ? parseFloat(item.h_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '4%' }}>R$ {parseFloat(item.t_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '7%' }}></span>
                                        <span style={{ width: '7%' }}>R$ {(item.c_cost) ? parseFloat(item.c_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '7%' }}>R$ {parseFloat(item.r_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.b_cost) ? parseFloat(item.b_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.m_cost) ? parseFloat(item.m_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        <span style={{ width: '10%' }}>R$ {(item.bo_cost) ? parseFloat(item.bo_cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""}</span>
                                        {/* <span style={{ width: '7%' }}></span> */}
                                        <span style={{ width: '5%' }}>R$ {(item.sum_h) ? parseFloat(item.sum_h).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + " + " : ""}  {parseFloat(item.sum_n).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                        <label style={{ width: '5%' }}></label>
                                    </div>}
                                </>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>H.Board: Inativas</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Niple</strong>
                            <strong>Escotilha</strong>
                            <strong>Rastreador</strong>
                            <strong>Chip</strong>
                            <strong>Chassi</strong>
                            <strong>TAG</strong>
                            <strong>S. Backdoor</strong>
                            <label>Custo</label>
                        </div>

                        {totalInactives.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.n_serial}</span>
                                    <span>{item.h_serial}</span>
                                    <span>{item.t_serial}</span>
                                    <span>{item.number}</span>
                                    <span>{item.c_serial}</span>
                                    <span>{item.r_serial}</span>
                                    <span>{(item.backdoor_sensor) ? "ON" : "OFF"}</span>
                                    <label>R$ {parseFloat(sumCost(item)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>



        </Container>
    )

}

export default ReportNiple;