import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    overflow: scroll;
    width: 100%;
`;

export const HeaderBar = styled.div`
    width: 100%;
    height: 50px;
    padding: 0 0px;
    align-items: center;
    display: flex;
    background: #fff;

    > div {
        width: 120px;
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #D1D1D1;
        font-weight: bold;
        font-size: 15px;
        border:3px solid #fff;
        height: 47px;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        cursor: pointer;
    }
`;

export const Body = styled.div`
    width: 100%;
    height: calc(100% - 0px);
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding:0px 15px;
    justify-content: center;
    background-color: #aaa;
    border-radius: 10px;
`;

export const Steps = styled.div`

    width: calc(100%);
    height: calc(100% - 70px);
    overflow-y: scroll;
    padding: 10px 0;
   
    .active {
        height: 550px;
    }

    > div {
        transition: all 0.2s;
        height: 70px;
        display: flex;
        margin: 20px 20px;
        padding: 0 20px;
        font-weight: bold;
        background-color: #fff;
        cursor: pointer;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 5px 15px -3px rgba(0,0,0,0.1);

        > .row {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .title {
                display: flex;
                align-items: center;

                > p {
                    margin-left: 15px;
                    font-size: 20px;
                }
            }

            .user_main {

                display: flex;
                flex-direction: column;

                > span {
                    font-weight: normal;
                    font-size: 15px;
                    text-align: right;
                    color: #aaa;
                }
            }
        }
    }
`;


export const SuspicionsView = styled.div`

    height: 100%;
    width: 100%;
    padding: 20px 0;
    overflow: hidden;
    
    > .title {
        display: flex;
        height: 35px;
        border-bottom: 2px solid #ddd;
        margin-bottom: 20px;
        margin-top: 5px;
        cursor: pointer;

        > p {
            margin-left: 15px;
            font-size: 20px;
        }
    }

    > .crud {

        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        > .maintenance {
            width: 65%;
            height: calc(100% - 80px);
            overflow: auto;
        }

        > .equipment {
            width: 30%;
            height: calc(100% - 80px);
            overflow: auto;
            cursor: not-allowed;

            > div {
                height: 30px;
                background-color: #f1f1f1;
                border-radius: 5px;
                padding: 0 10px;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                > label {
                    color: #222;
                    font-size: 10px;
                    font-weight: normal;
                }

                > span {
                    font-weight: bold;
                    font-size: 12px;
                }
            }
        }

        > .costs {
            width: 100%;
            height: calc(100% - 80px);
            overflow: auto;

            .money {
                text-align: right !important;
            }

            > .table-header {

                width: 100%;
                background-color: #222;
                height: 30px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                justify-content: space-between;
                padding: 0 20px 0 20px;

                > span {
                    text-align: center;
                    color: #fff;
                    width: 25%;
                }
            }

            > .table-body {
                
                margin-top: 10px;
                background-color: #eee;
                height: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                border-radius: 5px;
                justify-content: space-between;
                padding: 0 20px 0 20px;

                > span {
                    text-align: center;
                    width: 25%;
                    color: #000;
                    font-size: 13px;
                }

            }

            > .table-footer {
                
                margin-top: 10px;
                background-color: #ccc;
                height: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                border-radius: 5px;
                justify-content: space-between;
                padding: 0 20px 0 20px;
                
                > span {
                    text-align: center;
                    width: 25%;
                    color: #000;
                    font-size: 15px;
                }

            }

        }

    }

    .info {
        font-weight: normal;
        margin: 10px;
    }
    

`;


export const Observations = styled.div`

    width: calc(100%);
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding: 10px 0;

    > div {
            display: flex;
            margin: 20px 20px;
            padding: 0 20px;
            font-weight: bold;
            background-color: #fff;
            cursor: pointer;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0px 5px 15px -3px rgba(0,0,0,0.1);
        }
`;

export const Pictures = styled.div`
    display: flex;
    flex-wrap: wrap;    
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;

    > div {
        height: 300px;
        width: 200px;
        border-radius: 10px;
        margin: 15px 15px 0 0px;
        overflow-y: hidden;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            cursor: pointer;
        }

        > video {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
`