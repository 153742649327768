import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
       margin: 0;
       padding: 0;
       box-sizing: border-box;
       color: var(---black2);
    }
    html, body, #root {
        max-height: 100vh;
        max-width: 100vw;
        height: 100%;
        width: 100%;
    }
    *, button, input {
        border: 0;
        background: none;
        font-family: "Montserrat-UltraLight", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    input:focus{
        outline: none;
    }

    html {
        background: var(--primary);
    }
    :root {
        --primary: #fff;
        --secondary:#f8f8f8;
        --details: #2c83d6;
        --white:#fff;
        --black: #000000;
        --black2: #222222;
        --active: #0eed3f;
        --inactive: #ff1212;
        --linked: #ffbb00;
        --gray: #7A7A7A;
        --like: #e82653;
        --drak-hover: #011017;
        --outline: #2f3336;
        --newGray: #fdfdfd;
        --bancosicoob: #74bf44;
        --bancointer: #ff8801;
    }
`;
