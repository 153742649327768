import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active, Container, Cost,
    Day, Details, Provider,
    TypeExpense
} from './style';


const GridFixedExpenses: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)
    // const clients = useSelector(state => state.clients)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    const [totalValue, setTotalValue] = useState(0);

    // useEffect(() => {

    // }, []);

    useEffect(() => {
        find()
    }, [client])

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'fixed_expenses/' + client.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                var tVal = 0;
                responseJson.data.map(item => {
                    if (item.active) {
                        tVal = tVal + parseFloat(item.document_value);
                    }
                });
                setTotalValue(tVal);

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        var tVal = 0;
        arrayItens.map((item, i) => {

            const provider = (item.provider) ? (item.provider.name) : ''
            const identifier = (item.provider) ? (item.provider.identifier) : ''

            if (search === "" || provider.toUpperCase().includes(search.toUpperCase()) || identifier.includes(search)) {
                t++;
                if (item.active) {
                    tVal = tVal + parseFloat(item.document_value);
                }
            }
        })
        setTotal(t);
        setTotalValue(tVal);

    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total} - {formatNumber(totalValue)}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    {/* <Client style={{ fontWeight: 'bold' }}>Cliente</Client> */}
                    <Provider style={{ fontWeight: 'bold' }}>Fornedor</Provider>
                    <TypeExpense style={{ fontWeight: 'bold' }}>Tipo de Despesa</TypeExpense>
                    <Cost style={{ fontWeight: 'bold' }}>Valor</Cost>
                    <Day style={{ fontWeight: 'bold' }}>Vencimento</Day>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                const provider = (item.provider) ? (item.provider.name) : ''
                const identifier = (item.provider) ? (item.provider.identifier) : ''
                const type_expense = (item.type_expense) ? (item.type_expense.name) : ''

                if (search === "" || provider.toUpperCase().includes(search.toUpperCase()) || identifier.includes(search)) {

                    return (
                        <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                {/* <Client>{getClientName(clients, item.client_id)}</Client> */}
                                <Provider>{provider}</Provider>
                                <TypeExpense>{type_expense}</TypeExpense>
                                <Cost> <NumberFormat
                                    value={item.document_value}
                                    thousandSeparator={"."}
                                    decimalScale={2}
                                    prefix={"R$ "}
                                    disabled={true}
                                    style={{ textAlign: 'center', fontSize: 11, width: '100%' }}
                                    decimalSeparator={","}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    allowedDecimalSeparators={["."]}
                                /></Cost>
                                <Day>{item.expiration_day}</Day>
                                <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridFixedExpenses;