import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addMail: ["mail"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  mails: []
};

const addMail = (state = INITIAL_STATE, action) => {
  const { mail } = action;
  mail.ticket = Number(mail.ticket);

  let mails = state.mails;
  const index = mails.findIndex(x => x.ticket === mail.ticket);
  if (index !== -1) {
    mails[index] = mail; 
  }
  else {
    mails.push(mail);
  }

  return {
    ...state,
    mails
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_MAIL]: addMail
});