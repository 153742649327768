import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import { CircleActive, CircleInactive } from '../../../styles/CrudLayout';
import {
    Active, Chip, Container, Cost, Details, Model, NBoard, Serial
} from './style';


const GridTracker = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'trackers', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }
    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {
            const chip_n = (item.chip) ? item.chip.number : '';
            const n_board_s = (item.n_board) ? item.n_board.serial : '';
            if (search === "" || item.serial.includes(search) || (chip_n.includes(search)) || n_board_s.includes(search)) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    return (
        <Container>

            <label>Total: {total}</label>

            <div style={{ background: '#eeeeee' }}>
                <Details>
                    <Serial style={{ fontWeight: 'bold' }}>Serial</Serial>
                    <Chip style={{ fontWeight: 'bold' }}>Chip</Chip>
                    <NBoard style={{ fontWeight: 'bold' }}>NBoard</NBoard>
                    <Model style={{ fontWeight: 'bold' }}>Modelo</Model>
                    <Cost style={{ fontWeight: 'bold' }}>Custo</Cost>
                    <Active style={{ fontWeight: 'bold' }}>RS232</Active>
                    <Active style={{ fontWeight: 'bold' }}>Status</Active>
                </Details>
            </div>

            {arrayItens.map((item, i) => {

                const chip_n = (item.chip) ? item.chip.number : '';
                const n_board_s = (item.n_board) ? item.n_board.serial : '';

                if (search === "" || item.serial.includes(search) || (chip_n.includes(search)) || n_board_s.includes(search)) {

                    return (
                        <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9" }} className={(item === objSelected) ? 'selected' : ''} onClick={() => handlerClickRow(item)}>
                            <Details>
                                <Serial>{item.serial}</Serial>
                                <Chip>{chip_n}</Chip>
                                <NBoard>{n_board_s}</NBoard>
                                <Model> {item.model}</Model>
                                <Cost>
                                    <NumberFormat
                                        value={item.cost}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        prefix={"R$ "}
                                        disabled={true}
                                        style={{ textAlign: 'center', fontSize: 11, width: '100%' }}
                                        decimalSeparator={","}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        allowedDecimalSeparators={["."]}
                                    />
                                </Cost>
                                <Active>{item.allow_niple ? <CircleActive /> : <CircleInactive />}</Active>
                                <Active>{item.active ? <CircleActive /> : <CircleInactive />}</Active>
                            </Details>
                        </div>
                    )
                }
            })}

        </Container>
    )

}

export default GridTracker;