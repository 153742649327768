import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Chart } from "react-google-charts";
import { faCashRegister, faChartLine, faDonate, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import Modal from '../../Modal';
import {
  Container,
  ContentTotals,
  Header,
  Row,
  Yield
} from './style';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const redimentColor = "#2a7bcb"
const rgbaRendiment = "rgba(42, 123, 203, 0.2)";
const rgbaWithdraw = "rgba(255, 0, 0, 0.2)";

const FinancialInvestments = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const client = useSelector(state => state.client)

  const [modal, setModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("");

  const scrollView = useRef();

  const [arrRescues, setArrRescues] = useState([])
  const [arrApplicationsPay, setArrApplicationsPay] = useState([])

  const [total, setTotal] = useState(0)
  const [totalYield, setTotalYield] = useState(0)
  const [totalAports, setTotalAports] = useState(0)
  const [totalWithdraw, setTotalWithdraw] = useState(0)

  const [data, setData] = useState([])
  const [dataGraphGrowth, setDataGraphGrowth] = useState(false)

  const [groupsFinancial, setGroupsFinancial] = useState([])

  const optionsGrowth = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gráfico de redimento',
      },
    },
  };

  useEffect(() => {
    Moment.locale('pt-br');
    findRescues()
  }, [])

  useEffect(() => {

    var aux = [];
    arrRescues.map(item => {
      if (!item.canceled) {
        aux.push({
          type: "rescues",
          payment_value: parseFloat(item.payment_value),
          payment_date: item.payment_date
        })
      }
    })

    arrApplicationsPay.map(item => {
      if (!item.canceled) {
        aux.push({
          type: "payment",
          payment_value: parseFloat(item.payment_value),
          payment_date: item.payment_date
        })
      }
    })

    if (aux.length > 0) aux.sort((a, b) => {
      return a.payment_date.localeCompare(b.payment_date);
    })

    console.log(aux);

    var totals = [];
    var labels = [];

    var totalBalance = 0;
    var totalAport = 0;
    var totalWithdraw = 0;
    totals.push(totalBalance);
    labels.push("Primeiro");
    aux.map(item => {

      labels.push(Moment(item.payment_date).format("DD/MM/YYYY"));

      if (item.type == "payment") {
        totalAport = totalAport + item.payment_value;
        totalBalance = totalBalance + item.payment_value;
      }
      else {
        totalBalance = totalBalance - item.payment_value;
        totalWithdraw = totalWithdraw + item.payment_value;
      }

      totals.push(totalBalance.toFixed(2))

    })

    setTotal(totalBalance);
    setTotalAports(totalAport);
    setTotalWithdraw(totalWithdraw);

    setDataGraphGrowth({
      labels,
      datasets: [
        {
          label: 'Investimento',
          data: totals,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ],
    })


  }, [arrApplicationsPay, arrRescues])

  async function findApplicationsPay() {

    setArrApplicationsPay([])

    try {

      let response = await fetch(DEFAULT_URL + 'expenses/application_pay', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id
        })
      });

      let responseJson = await response.json();

      if (responseJson.success) {
        setArrApplicationsPay(responseJson.data);
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function findRescues() {

    setArrRescues([])

    try {

      let response = await fetch(DEFAULT_URL + 'revenues/rescues', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.token
        },
        body: JSON.stringify({
          client_id: client.id
        })
      });

      let responseJson = await response.json();

      if (responseJson.success) {
        setArrRescues(responseJson.data);
        findApplicationsPay();
        return
      }

      let { message } = responseJson.details;
      if (message === 'error_authorization') {
        dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
        localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
        return
      }

    } catch (error) {
      console.error(error);
    }

  }


  const openModal = (layout) => {

    dispatch({ type: "SET_DARK", data: true })
    setModalTitle(layout);
    setModal(true);
    scrollView.current.scrollIntoView()

  }

  return (
    <Container
      ref={scrollView}
    >

      {modal &&
        <Modal
          onClose={() => setModal(false)}
          layout={modalTitle}
          client_id={client.id}
          callback={() => {
            //findInvestments()
          }}
        />}

      <Row />

      <Header>

        <ContentTotals>

          <div className='totals'>

            <div>
              <FontAwesomeIcon icon={faWallet} style={{ width: 25, height: 25, position: 'absolute', right: 20 }} />
              <p>Carteira</p>
              <p className='info'>TOTAL</p>
              <p className='numeric'>{formatNumber(total)}</p>
            </div>

            <div>
              <FontAwesomeIcon icon={faDonate} style={{ width: 25, height: 25, position: 'absolute', right: 20 }} />
              <p>Aportes</p>
              <p className='info'>TOTAL</p>
              <p className='numeric'>{formatNumber(totalAports)}</p>
            </div>

            <div>
              <FontAwesomeIcon icon={faChartLine} style={{ width: 25, height: 25, position: 'absolute', right: 20 }} />
              <p>Rendimento</p>
              <p className='info'>TOTAL</p>
              <p className='numeric' style={{ color: redimentColor }}>{formatNumber(totalYield)}</p>
            </div>

            <div>
              <FontAwesomeIcon icon={faCashRegister} style={{ width: 25, height: 25, position: 'absolute', right: 20 }} />
              <p>Retiradas</p>
              <p className='info'>TOTAL</p>
              <p className='numeric' style={{ color: "#ff0000" }}>{formatNumber(totalWithdraw)}</p>
            </div>

          </div>

        </ContentTotals>

        <Yield>

          {dataGraphGrowth &&

            <div className="graph">
              <Line options={optionsGrowth} data={dataGraphGrowth} />
            </div>

          }

        </Yield>

      </Header>


    </Container >
  )

}

export default FinancialInvestments;
