import styled from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0px 0px 15px 0px;
    background-color: #f5f7f9;
    overflow: scroll;
`;


export const Row = styled.div`
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
`;

export const Buttons = styled.div`

    margin: 20px 0px 0 30px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    padding: 0px;

    > div {
        height: 40px;
        border-radius: 12px;
        width: 150px;
        margin: 0  20px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        font-weight: bold;

        transition: all 0.5s;

        :hover {
            scale: 1.05;
        }

    }


`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

export const ContentTotals = styled.div`

    width: calc(100vw - 200px);
    display: flex;
    flex-direction: row;

        .totals {

            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 20px 0;
            justify-content: center;
            align-items: center;

                > div {

                    height: 160px;
                    width: 250px;
                    border-radius: 10px;
                    background-color: #fff;
                    margin: 0 20px 20px 0;
                    border: 1px solid #eee;
                    padding: 20px;
                    position: relative;
                    transition: all 0.5s;

                    :hover {
                        scale: 1.05;
                    }

                    > p {
                        font-size: 20px;
                    }

                    .info {
                        text-align: right;
                        width: 100%;
                        font-weight: normal;
                        margin-top: 55px;
                        font-size: 15px;
                        color: #aaa;
                    }

                    .numeric {
                        text-align: right;
                        width: 100%;
                        font-weight: bold;
                        font-size: 25px;
                    }

                }
    }

`;

export const Yield = styled.div`
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 30px 0;
    overflow: scroll;

    > div {
        padding: 50px;
        width: 80%;
    }
`;

export const ContentHistory = styled.div`

    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    width: 350px;
    height: 820px;
    padding: 10px;

    > p {
        font-size: 20px;
        font-weight: bold;
    }

    > div {
        height: 330px;
        overflow-x: scroll;
    }
`;

export const CardList = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #f1f1f1;
    border-radius: 5px;
    justify-content: space-between;
    padding: 5px 10px;
    margin: 7px 0 0 0;
    position: relative;

    > .numeric {
        font-weight: bold;
    }

    > .tooltiptext {
        visibility: hidden;
        max-width: 250px;
        background-color: #fff;
        border: 2px solid #ddd;
        color: #000;
        text-align: center;
        padding: 7px 15px;
        font-weight: bold;
        border-radius: 5px;
        top: 30px;
        right: 10px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 2;
    }

    :hover .tooltiptext {
        visibility: visible;
    }

`;

export const GroupInfo = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 450px;
    overflow-x: scroll;

    > div {
        background-color: #fff;
        border-radius: 10px;
        border:1px solid #eee;
        width: 250px;
        height: 420px;
        margin: 20px 20px 0 0;
        overflow: hidden;

        > p {
            font-weight: bold;
            margin: 10px;
        }

        .table {
            height: 320px;
            width: 225px;
            margin: 10px;
        }

        .totals {
            background-color: #888;
            height: 50px;
            width: 250px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            color: #fff;
        }
    }
`;
