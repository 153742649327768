import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, formatNumber } from '../../../helper';
import {
    Check,
    CheckBox,
    Title
} from '../../../styles/CrudLayout';
import {
    Body,
    Button,
    Container,
    FieldHorizontal,
    Head,
    HorizontalField,
    Row,
    ViewButtons
} from './style';

const CreditCard = ({ client_id, callback }) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [textButton, setTextButton] = useState("BAIXAR DESPESAS")
    const [financial_payer_id, setFinancialPayerId] = useState(0)
    const [arrayFinancialPayer, setArrayFinancialPayer] = useState([])

    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {

        Moment.locale('pt-br');
        findFinancialPayer();

    }, [objSelected]) /* eslint-disable-line */

    useEffect(() => {

        var total = 0;
        objSelected?.map(item => {
            if (item.payment_type === "CARTÃO" && item.financial_payer_id === financial_payer_id) {
                total = total + parseFloat(item.document_value);
            }
        })


        setTotalCost(total);

    }, [financial_payer_id]) /* eslint-disable-line */

    async function findFinancialPayer() {

        try {

            let response = await fetch(DEFAULT_URL + 'financial_payer/actives', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            if (responseJson.success) {
                setArrayFinancialPayer(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    async function saveData(expense) {

        const url = `expenses/${expense.id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client_id,
                    provider_id: expense.provider_id,
                    financial_payer_id: financial_payer_id,
                    type_expense_id: expense.type_expense_id,
                    payment_type: "CARTÃO",
                    document_number: expense.document_number,
                    due_date: Moment(expense.due_date).format("YYYY-MM-DD"),
                    document_value: expense.document_value,
                    payment_value: expense.payment_value,
                    payment_date: Moment().format("YYYY-MM-DD"),
                    note: expense.note,
                    system: expense.system,
                    cancel_reason: expense.cancel_reason,
                    canceled: expense.canceled
                })
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                return 200
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return 500
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function handlePayments() {

        if (textButton === "BAIXAR DESPESAS") {

            setTextButton("AGUARDE..")

            const promisses = [];
            for (const key in objSelected) {

                const aux = objSelected;

                if (aux[key].payment_type === "CARTÃO" && aux[key].financial_payer_id === financial_payer_id) {

                    const body = {
                        id: aux[key].id,
                        provider_id: aux[key].provider_id,
                        type_expense_id: aux[key].type_expense_id,
                        document_number: aux[key].document_number,
                        document_value: aux[key].document_value,
                        due_date: new Date(aux[key].due_date),
                        payment_value: aux[key].document_value,
                        payment_date: aux[key].payment_date,
                        system: aux[key].system,
                        canceled: false,
                        cancelReason: null
                    };

                    promisses.push(new Promise((resolve, reject) => {
                        saveData(body).then(data => {
                            if (data === 200) resolve(data);
                            else reject(data);
                        });
                    }));
                }

            }

            await Promise.all(promisses);

            callback();
        }

    }



    return (
        <Container>

            <Title>DESPESAS CARTÃO DE CRÉDITO</Title>
            <Row />


            {financial_payer_id !== 0 && <ViewButtons>
                <Button onClick={() => handlePayments()}>{textButton}</Button>
            </ViewButtons>}

            <Row />

            <HorizontalField>
                <FieldHorizontal>
                    <label>Pagador:</label>
                    <CheckBox>
                        {arrayFinancialPayer.map((e, i) => {
                            if (e.active) {
                                return (
                                    <Check
                                        key={e.id}
                                        onClick={() => setFinancialPayerId(e.id)}
                                        style={{ border: (financial_payer_id === e.id) ? "2px solid #e82653" : "" }}>
                                        {e.name}
                                    </Check>)
                            }
                        })}
                    </CheckBox>
                </FieldHorizontal>


            </HorizontalField>

            <Row />

            <Head>
                <div style={{ width: "65%" }}>FORNECEDOR</div>
                <div>TIPO</div>
                <div>DATA</div>
                <strong>VALOR</strong>
            </Head>

            {financial_payer_id === 0 && <p style={{ fontSize: 20, margin: 10 }}>Selecione um pagador</p>}

            {Object.keys(objSelected).length > 0 && objSelected?.map((item, i) => {
                if (item.payment_type === "CARTÃO" && item.financial_payer_id === financial_payer_id) {
                    return (
                        <Body style={{ backgroundColor: (i % 2 === 0) ? "#fff" : "#f9f9f9" }}>
                            <div style={{ width: "65%" }}>{item.provider.name}</div>
                            <div>{item.type_expense.name}</div>
                            <div>{Moment(item.due_date).format("DD/MM/YYYY")}</div>
                            <strong>{formatNumber(item.document_value)}</strong>
                        </Body>
                    )
                }
            })}

            <Head>
                <div style={{ width: "65%" }}>TOTAL</div>
                <div></div>
                <div></div>
                <strong>{formatNumber(totalCost)}</strong>
            </Head>

        </Container >
    )

}

export default CreditCard;