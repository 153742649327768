import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Container,
    Details,
    Fields,
    Grid,
    Info,
    Length,
    Name
} from './style';

import {
    Alert,
    Button,
    Field,
    HorizontalField,
    Title,
    ViewSubmit
} from '../../../styles/CrudLayout';

import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import AutoComplete from '../../AutoComplete';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StockConjunts: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const search = useSelector(state => state.search)
    const objSelected = useSelector(state => state.objSelected)

    const [total, setTotal] = useState(0)
    const [arrayItens, setArrayItens] = useState([])

    const [id, setId] = useState(0)
    const [name, setName] = useState("")
    const [arrayComponents, setArrayComponents] = useState([])

    const [idAdd, setIdAdd] = useState(0)
    const [nameAdd, setNameAdd] = useState("")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    useEffect(() => {
        find()
    }, []);

    async function find() {

        try {

            let response = await fetch(DEFAULT_URL + 'group', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            console.log(responseJson)

            if (responseJson.success) {
                setArrayItens(responseJson.data);
                setTotal(responseJson.data.length);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {

        if (typeof objSelected.insert != 'undefined') {
            find()
            dispatch({ type: "SET_OBJ_SELECTED", data: {} })
        }

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {

            if (typeof objSelected.group != 'undefined') {
                setId(objSelected.group.id)
                setName(objSelected.group.name)
            }

            if (typeof objSelected.subcomponents != 'undefined') {
                setArrayComponents(objSelected.subcomponents)
            }

            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setName("")
            setArrayComponents([])
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    useEffect(() => {
        var t = 0;
        arrayItens.map((item, i) => {
            if (search === "" || item.name.includes(search)) {
                t++;
            }
        })
        setTotal(t);
    }, [search])

    function handlerClickRow(item) {

        if (item === objSelected) {
            dispatch({ type: "SET_OBJ_SELECTED", data: {} });
            return
        }

        dispatch({ type: "SET_OBJ_SELECTED", data: item });
    }

    function addComponent(item) {

        if (item.name === "") {
            setTextAlert("Informe o nome do componente.")
            return
        }

        if (arrayComponents.length === 0) {
            setArrayComponents([{ id: item.id, name: item.search }])
        } else {
            setArrayComponents([...arrayComponents, { id: item.id, name: item.search }])
        }

        setIdAdd(0)
        setNameAdd("")

    }

    function removeComponent(item) {
        var aux = arrayComponents;
        const index = aux.indexOf(item);
        if (index > -1) aux.splice(index, 1);
        setArrayComponents([...aux])
    }

    async function saveData() {

        if (name.trim() === "") {
            setTextAlert("Informe o campo Nome")
            return
        }


        if (arrayComponents.length === 0) {
            setTextAlert("Informe pelo menos um Subcomponente")
            return
        }


        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'group' : `group/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    name: name,
                    components: arrayComponents,
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                setArrayComponents([])
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Info>

                <Grid>

                    <label>Total: {total}</label>

                    <div style={{ background: '#eeeeee' }}>
                        <Details>
                            <Name style={{ fontWeight: 'bold' }}>Nome</Name>
                            <Length style={{ fontWeight: 'bold' }}>Itens</Length>
                        </Details>
                    </div>

                    {arrayItens.map((item, i) => {
                        if (search === "" || item.name.includes(search)) {
                            return (
                                <div key={i} style={{ background: (i % 2 === 1) ? '#fff' : "#f9f9f9", border: (item === objSelected) ? '1px solid #e82653' : 'none' }} onClick={() => handlerClickRow(item)}>
                                    <Details>
                                        <Name>{item.group.name}</Name>
                                        <Length>{item.subcomponents.length}</Length>
                                    </Details>
                                </div>
                            )
                        }
                    })}

                </Grid>

                <Fields>

                    <Title>{textDesc}</Title>

                    <Field>
                        <label>Nome:</label>

                        <input
                            type="text"
                            placeholder="Nome"
                            value={name}
                            onChange={event => setName(event.target.value)} />

                    </Field>

                    <Title>Itens</Title>

                    {arrayComponents.length > 0 && arrayComponents.map(item => {

                        return (
                            <HorizontalField>

                                <Field style={{ marginRight: 15 }}>
                                    <label>Nome:</label>

                                    <input
                                        type="text"
                                        readOnly
                                        style={{ background: "#f1f1f1" }}
                                        value={item.name} />

                                </Field>

                                <FontAwesomeIcon onClick={() => removeComponent(item)} icon={faTrashAlt} style={{ width: 20, height: 20, marginTop: 30, cursor: 'pointer' }} />

                            </HorizontalField>
                        )

                    })}

                    <HorizontalField>

                        <AutoComplete
                            label={"Subcomponente"}
                            finder={"subcomponents"}
                            item={{ id: idAdd, name: nameAdd }}
                            setSelected={(item) => {
                                setIdAdd(item.id)
                                setNameAdd(item.search)
                                addComponent(item)
                            }}
                        />
                        {/* <FontAwesomeIcon onClick={() => addComponent()} icon={faPlusSquare} style={{ width: 20, height: 20, marginTop: 30, marginLeft: 20, cursor: 'pointer' }} /> */}

                    </HorizontalField>

                    <ViewSubmit>

                        {textAlert !== "" &&
                            <Alert>
                                {textAlert}
                            </Alert>}
                        {textAlert === "" && <div />}

                        <Button onClick={() => saveData()}>
                            {textButton}
                        </Button>

                    </ViewSubmit>

                </Fields>

            </Info>

        </Container>
    )

}

export default StockConjunts;