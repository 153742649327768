import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName } from '../../helper';
import { Check, CheckBox, Field, GridItem, GridView, Select, Title } from '../../styles/CrudLayout';
import AutoComplete from '../AutoComplete';
import {
    Container,
    Pictures
} from './style';

const InfoMaintenance: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const clients = useSelector(state => state.clients)
    const objSelected = useSelector(state => state.objSelected)

    const [maintenance_services, setMaintenanceServices] = useState([])

    const [id, setId] = useState(0)
    const [vehicle_id, setVehicleId] = useState(0)
    const [vehicle_identifier, setVehicleIdentifier] = useState("")
    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")

    const [technician, setTechnician] = useState("")
    const [date, setDate] = useState("")
    const [service_rendered, setServiceRendered] = useState("")
    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [note, setNote] = useState("")
    const [routine, setRoutine] = useState(true)
    const [observation_date, setObservationDate] = useState("")
    const [services, setServices] = useState([]);
    const [status, setStatus] = useState("AGENDAR")

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    const [pictures, setPictures] = useState([]);

    useEffect(() => {
        Moment.locale('pt-br');
        findServices()
    }, [])

    useEffect(() => {

        setTextAlert("")

        console.log(objSelected)

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)
            setVehicleId(objSelected.vehicle_id)
            setVehicleIdentifier(objSelected.identifier)
            setClientId(objSelected.client_id)
            setClientName(getClientName(clients, objSelected.client_id))

            setTechnician(objSelected.technician)
            setDate(Moment(objSelected.pg_date).format("DD/MM/YYYY"))
            setServiceRendered(objSelected.service_rendered)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")
            setRoutine(objSelected.routine)

            if (objSelected.observation_date) {
                setObservationDate(Moment(objSelected.observation_date).format("DD/MM/YYYY"))
            }

            if (objSelected.services) {
                setServices(objSelected.services.split(","))
            } else {
                setServices([])
            }

            setStatus(objSelected.status)
            getPictures(objSelected.identifier)

        }

    }, [objSelected])

    async function findServices() {

        try {

            let response = await fetch(DEFAULT_URL + 'maintenance_service', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {
                setMaintenanceServices(responseJson.data);
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function getPictures(board) {

        try {

            let response = await fetch('https://apintracker.threepixels.com.br/api/v1/patrimony/find-all', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlYmM4MjI3NTZhYWUzMDAxMmQ0YThmNSIsImlzTG9naW4iOnRydWUsImlhdCI6MTYzOTM5NzQxNCwiZXhwIjozMTU3MzY3ODM5ODE0fQ.aPzrSBhUKxXr1uCV7HjZSUGyov9jUtI5zT0CXVGleBE',
                    'pin': '0000'
                },
                body: JSON.stringify({
                    limit: 1,
                    page: 1,
                    search: board
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                console.log(responseJson.data.docs[0].pictures);
                setPictures(responseJson.data.docs[0].pictures);
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Cliente"}
                finder={"clients"}
                item={{ id: client_id, name: client_name }}
                setSelected={(item) => {
                    setClientId(item.id)
                    setClientName(item.search)
                }}
            />

            <AutoComplete
                label={"Veículo"}
                finder={"vehicles"}
                item={{ id: vehicle_id, name: vehicle_identifier }}
                setSelected={(item) => {
                    setVehicleId(item.id)
                    setVehicleIdentifier(item.search)
                }}
            />

            <Field>
                <label>Data da ocorrência:</label>

                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data da ocorrência"
                    value={observation_date}
                    onChange={event => setObservationDate(event.target.value)} />

            </Field>

            <Field>
                <label>Último técnico:</label>
                <input
                    type="text"
                    placeholder="Técnico"
                    value={technician}
                    onChange={event => setTechnician(event.target.value)} />
            </Field>

            <Field>
                <label>Última manutenção:</label>
                <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="Data"
                    value={date}
                    onChange={event => setDate(event.target.value)} />
            </Field>

            <Field>
                <label>Serviços prestados:</label>

                <GridView>
                    {maintenance_services.map(item => {
                        return (
                            <GridItem className={services.find(s => s == item.id) ? "selected" : ""}>
                                <p>{item.name}</p>
                            </GridItem>
                        )
                    })}

                </GridView>

            </Field>

            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
            </Field>

            <Select>
                <label>Rotina:</label>
                <Switch checked={routine} />
            </Select>

            <Field>
                <label>Status:</label>
                <CheckBox>

                    <Check onClick={() => { setStatus("AGENDAR") }} style={{ border: (status === "AGENDAR") ? "2px solid #e82653" : "" }}>
                        AGENDAR
                    </Check>

                    <Check onClick={() => { setStatus("AGENDADO") }} style={{ border: (status === "AGENDADO") ? "2px solid #e82653" : "" }}>
                        AGENDADO
                    </Check>

                    <Check onClick={() => { setStatus("EM OBSERVAÇÃO") }} style={{ border: (status === "EM OBSERVAÇÃO") ? "2px solid #e82653" : "" }}>
                        EM OBSERVAÇÃO
                    </Check>

                    <Check onClick={() => { setStatus("CONCLUIDA") }} style={{ border: (status === "CONCLUIDA") ? "2px solid #e82653" : "" }}>
                        CONCLUIDA
                    </Check>

                </CheckBox>

            </Field>

            <label style={{ fontWeight: "bold", fontSize: 12 }}>Audios da manutenção:</label>

            <Pictures
                style={{ flexDirection: "column" }}
            >

                {pictures.map(picture => {
                    if (typeof picture.tag != "undefined") {
                        if (picture.tag.includes(`maintenance_${objSelected.id}`)) {
                            if (typeof picture.type != "undefined") {
                                if (picture?.type.includes("audio")) {
                                    return (
                                        <div key={`video_${picture.key}`} style={{ height: 60 }}>
                                            <audio controls>
                                                <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="audio/mp3" />
                                            </audio>
                                        </div>
                                    )
                                }
                            }
                        }
                    }
                })}
            </Pictures>

            <label
                style={{ fontWeight: "bold", fontSize: 12 }}
            >Videos da manutenção:</label>

            <Pictures>

                {pictures.map(picture => {

                    if (typeof picture.tag != "undefined") {
                        if (picture.tag.includes(`maintenance_${objSelected.id}`)) {

                            if (typeof picture.type != "undefined") {
                                if (picture?.type.includes("video")) {
                                    return (
                                        <div key={`video_${picture.key}`}>
                                            <video controls>
                                                <source src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} type="video/mp4" />
                                            </video>
                                        </div>);
                                }
                            }
                        }
                    }

                })}
            </Pictures>

            <label
                style={{ fontWeight: "bold", fontSize: 12 }}
            >Fotos da manutenção:</label>

            <Pictures>

                {pictures.map(picture => {

                    if (typeof picture.tag != "undefined") {
                        if (picture.tag.includes(`maintenance_${objSelected.id}`)) {

                            if (typeof picture.type != "undefined") {
                                if (!picture?.type.includes("video") && !picture?.type.includes("audio")) {

                                    return (
                                        <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                            window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                                        }} >
                                            <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                                        </div>
                                    );
                                }
                            } else {

                                return (
                                    <div key={`picture_${picture.key}`} onDoubleClick={() => {
                                        window.open(`https://apicloct.threepixels.com.br/ntracker/storage/patrimony/${picture.key}`, "_blank");
                                    }} >
                                        <img src={"https://apicloct.threepixels.com.br/ntracker/storage/patrimony/" + picture.key} alt={picture.tag} />
                                    </div>
                                );

                            }
                        }
                    }
                })}

            </Pictures>


        </Container>
    )

}

export default InfoMaintenance;
