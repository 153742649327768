import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';


const FieldsChassi: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")
    const [group_id, setGroupId] = useState(0)
    const [group_name, setGroupName] = useState("")
    const [ownership_id, setOwnershipId] = useState(0)
    const [ownership_name, setOwnershipName] = useState("")

    const [serial, setSerial] = useState("24274.")
    const [lot_number, setLotNumber] = useState("24274.")
    const [note, setNote] = useState("")
    //const [cost, setCost] = useState(1027.52)
    const [cost, setCost] = useState({ formattedValue: "500,00", value: 500, floatValue: 500 })
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {

            setId(objSelected.id)
            setSerial(objSelected.serial)
            setClientId(objSelected.client_id)
            setClientName((objSelected.social_reason) ? objSelected.social_reason : objSelected.name)

            if (objSelected.group_id) {
                setGroupId(objSelected.group_id)
                setGroupName(objSelected.g_name)
            } else {
                setGroupId(0)
                setGroupName("")
            }

            if (objSelected.ownership_id) {
                setOwnershipId(objSelected.ownership_id)
                setOwnershipName(objSelected.ownership_name)
            } else {
                setOwnershipId(0)
                setOwnershipName("")
            }

            setLotNumber(objSelected.lot_number)
            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")

            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setClientId(0)
            setClientName("")
            setGroupId(0)
            setGroupName("")
            setOwnershipId(0)
            setOwnershipName("")
            setSerial("24274.")
            setLotNumber("24274.")
            setNote("")
            setCost({ formattedValue: "500,00", value: 500, floatValue: 500 })
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (serial.trim() === "") {
            setTextAlert("Informe o campo Serial")
            return
        }

        if (client_id === 0) {
            setTextAlert("Informe o campo Cliente")
            return
        }

        if (lot_number.trim() === "" || lot_number.trim() == "24274.") {
            setTextAlert("Informe o campo Número do lote")
            return
        }

        if (lot_number.length < 6) {
            setTextAlert("Número de lote inválido")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'chassis' : `chassis/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    serial: serial,
                    client_id: (client_id === 0) ? null : client_id,
                    group_id: (group_id === 0) ? null : group_id,
                    ownership_id: (ownership_id === 0) ? null : ownership_id,
                    lot_number: lot_number,
                    note: note,
                    cost: cost.floatValue,
                    active: active
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_serial') {
                setTextAlert("Serial já existente")
                dispatch({ type: "SET_SEARCH", data: serial })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Serial:</label>

                <input
                    type="text"
                    placeholder="Serial"
                    value={serial}
                    onChange={event => setSerial(event.target.value)} />

            </Field>

            <AutoComplete
                label={"Cliente"}
                finder={"clients"}
                item={{ id: client_id, name: client_name }}
                setSelected={(item) => {
                    setClientId(item.id)
                    setClientName(item.search)
                }}
            />

            <AutoComplete
                label={"Posse"}
                finder={"users"}
                item={{ id: ownership_id, name: ownership_name }}
                setSelected={(item) => {
                    setOwnershipId(item.id)
                    setOwnershipName(item.search)
                }}
            />

            <Field>
                <label>Número do lote:</label>
                <input
                    type="text"
                    placeholder="Lote"
                    value={lot_number}
                    onChange={event => setLotNumber(event.target.value)} />
            </Field>

            <AutoComplete
                label={"Grupo de subcomponentes"}
                finder={"group"}
                item={{ id: group_id, name: group_name }}
                setSelected={(item) => {
                    setGroupId(item.id)
                    setGroupName(item.search)
                }}
            />

            <Field>
                <label>Note:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />
                {/* <input
                    type="text"
                    placeholder="Custo"
                    value={cost}
                    onChange={event => setCost(event.target.value)}
                /> */}
            </Field>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsChassi;