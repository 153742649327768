import styled, { css } from 'styled-components';


export const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: scroll;
`;

export const Legend = styled.div`
    margin: 0 0 0 35px;
    height: 30px;
    display: flex;
    flex-direction: row;

    > span {

        display: flex;
        flex-direction: row;
        margin-right: 15px;
        font-size: 13px;
        justify-content: space-between;

        > label {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
`;

export const TitleInfo = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin: 0 0 10px 35px;
    letter-spacing: 2px;
`;

const circleCss = css`
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 100px;
`;

export const CircleDefault = styled.div`
    ${circleCss}
    background: var(--gray);
`;

export const CircleActive = styled.div`
    ${circleCss}
    background: var(--active);
`;

export const CircleInactive = styled.div`
    ${circleCss}
    background: var(--inactive);
`;

export const CircleLinked = styled.div`
    ${circleCss}
    background: var(--linked);
`;

export const Header = styled.div`
    height: 40px;
    display: flex;  
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 22px 20px 17px;
`;

export const DateDiv = styled.div` 
    width: 260px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const BillsInfo = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 10px 0 10px;
    justify-content: baseline;
`;

export const Row = styled.div`
    width: 1px;
    margin: 0 10px 0 10px;
    border-radius: 50px;
    background-color: #ddd;
`;

export const Row1 = styled.div`
    height: 1px;
    background-color: #aaa;
`;

export const Row2 = styled.div`
    height: 1px;
    margin-top: 5px;
    border-radius: 2px;
    /* background-color: var(--like); */
    background-color: #222;
`;

export const RowWhite = styled.div`
    height: 5px;
    margin: 25px 10px 25px 10px;
    border-radius: 5px;
    /* background-color: var(--like); */
    background-color: #ccc;
`;

export const Bills = styled.div`
    position: relative;
    width: 33%;
    margin: 0 0px 0 0px;
    overflow: scroll;

    > label {
        color: var(--black);
        font-size: 15px;
        font-weight: bold;
        /* font-family: 'GothamProBold'; */
        
    }
`;

export const InfoHeader = styled.div`
    border-bottom: 1px dotted #ddd;
    height: 25px;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding-top: 8px;

    > label {
        width: 35%;
        /* font-family: 'GothamProBold'; */
        font-weight: bold;
    }
    
    > span {
        width: 20%;
        text-align: right;
        /* font-family: 'GothamProBold'; */
        font-weight: bold;
    }

    > strong {
        width: 20%;
        text-align: right;
        font-weight: bold;
        /* font-weight: bold; */
        /* font-family: 'GothamProBold'; */
    }
`;

export const Infos = styled.div`
    height: calc(100vh - 280px);
    overflow-y: scroll;
    position: relative;
    padding-bottom: 100px;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const InfoRow = styled.div`
    height: 30px;

    background-color: #fff;
    border-bottom: 1px solid #f9f9f9;
    padding: 5px;
    width: 100%;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    
    > label {
        width: 35%;
        white-space: nowrap;
        overflow: hidden;
        /* font-weight: bold; */
    }
    
    > span {
        width: 20%;
        text-align: right;
        /* font-weight: bold; */
    }

    > strong {
        width: 20%;
        font-weight: normal;
        text-align: right;
        /* font-family: 'GothamProRegular'; */
        font-weight: normal;
    }

    &.active {
        background: #ddd;
    }
    & .fa-check,
    & .fa-spinner,
    & .fa-exclamation-triangle {
        padding: 0;
        width: 17px;
        height: 17px;
        margin-right: 5px;
    }

    & .fa-check {
        color: #269A55;
    }
    & .fa-spinner {
        color: #333333;
        animation: rotating 2s linear infinite;
        transform-origin: center center;
        transition: all .50s cubic-bezier(0.32, 0.08, 0.24, 1);
    }
    & .fa-exclamation-triangle {
        color: #ff0000;
    }


    :hover {
        background-color: #f9f9f9;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

     /* Tooltip text */
     > .tooltiptext {
        visibility: hidden;
        max-width: 250px;
        background-color: #fff;
        border: 2px solid #ddd;
        color: #000;
        text-align: center;
        padding: 7px 15px;
        font-weight: bold;
        border-radius: 5px;
        top: 30px;
        left: 70px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 2;
    }

    :hover .tooltiptext {
        visibility: visible;
    }
`;

export const InfoRowExp = styled.div`
    
    border: 1px solid #ddd;
    border-top: none;
    height: 25px;
    width: 100%;
    font-size: 11px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 5px 5px 1px 5px;
    cursor: pointer;

    > label {
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
    }

    > strong {
        width: 30%;
        font-weight: normal;
        text-align: right;
        /* font-weight: bold; */
    }

    /* Tooltip text */
    > .tooltiptext {
        visibility: hidden;
        max-width: 250px;
        background-color: #fff;
        border: 2px solid #ddd;
        color: #000;
        text-align: center;
        padding: 7px 15px;
        font-weight: bold;
        border-radius: 5px;
        top: -30px;
        left: 0;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 2;
    }

    :hover .tooltiptext {
        visibility: visible;
    }

`;

export const Total = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    margin-top: 10px;
    border:1px solid #ccc;
    background-color: #ddd;
    border-radius: 2px;
    color: var(--black2);
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: bold;
    
    > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 25px;
        align-items: center;
    }
    
    
`;

export const RightButtonClick = styled.div`
    position: absolute;
    width: 120px;
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 13px;
    text-align: center;
    z-index: 3;
    padding: 2px 0 2px 0;

    > div {
        color: #222;
        margin: 5px;
        cursor: pointer;
        
        :hover {
            background: #eee;
        }
    }
`;


export const ProgressDraggable = styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    margin: auto;
    width: calc(100% - 30px);
    height: 20px;
    background: #eee;
    box-shadow: inset 2px 2px 5px rgba(0,0,0,.1);
    border: 1px solid #333;
    border-radius: 4px;
    overflow: hidden;
`;

export const ProgressDraggableBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: ${props => props.width || "0%"};
    height: 20px;
    background: rgba(38, 154, 85, .5);
    border: 1px solid rgba(38, 154, 85, 1);
    backdrop-filter: saturate(180%) blur(20px);
`;

export const ProgressDraggableText = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 20px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
`;


export const InfoReturns = styled.div`

    padding: 10px;
    padding-top: 25px;
    padding-bottom: 50px;

    > p {
        font-size: 15px;
        margin-bottom: 10px;
    }

    > div { 
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #999;
        margin-bottom: 5px;

        > p {
            overflow: hidden;
            height : 18px;
            font-size: 12px;
        }
    }
`