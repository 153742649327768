import { Document, Image, Page, PDFViewer, Text } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_URL } from '../../../helper';
import PDFStyle from '../../../styles/PDFStyle';
import logo from '../../../assets/images/logo.png';
const PDFInvoice: React.FC = ({ onClose, layout, client_id, callback }) => {

  const DROPDOWN_MONTH = [
    { name: "JANEIRO", number: '01' },
    { name: "FEVEREIRO", number: '02' },
    { name: "MARÇO", number: '03' },
    { name: "ABRIL", number: '04' },
    { name: "MAIO", number: '05' },
    { name: "JUNHO", number: '06' },
    { name: "JULHO", number: '07' },
    { name: "AGOSTO", number: '08' },
    { name: "SETEMBRO", number: '09' },
    { name: "OUTUBRO", number: '10' },
    { name: "NOVEMBRO", number: '11' },
    { name: "DEZEMBRO", number: '12' },
  ]

  const user = useSelector(state => state.user)
  const objSelected = useSelector(state => state.objSelected)

  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);

  var pages = 0;
  var totalItens = 0
  var total = 0
  var arrayPages = []
  var cntArray = 0
  var row = 0

  useEffect(() => {
    getRevenueDetails();
  }, []);

  const getRevenueDetails = async () => {

    let responseDetail = await fetch(DEFAULT_URL + 'revenues_details/' + objSelected.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      }
    });

    let responseJsonDetail = await responseDetail.json();
    if (responseJsonDetail.success) {

      var dt = [];
      Promise.all(responseJsonDetail.data.map(async item => {
        const v = await getVehicle(item.identifier)
        if (v.length > 0) {
          dt.push({ ...item, contract: v[0].contract_id })
        }
        else {
          dt.push({ ...item, contract: "" })
        }

      })).finally(item => {
        setData(dt);
      })


    }

  };

  const getVehicle = async (identifier) => {

    let responseDetail = await fetch(DEFAULT_URL + 'vehicles/find_by_identifier/' + identifier, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      }
    });

    let responseJsonDetail = await responseDetail.json();
    if (responseJsonDetail.success)
      return responseJsonDetail.data;

    return [];
  };


  data.map((item, i) => {

    totalItens++;
    row++;
    total = total + parseFloat(item.value);

    if (row <= 20) {
      if (typeof arrayPages[cntArray] === 'undefined') {
        arrayPages[cntArray] = [item]
      } else {
        arrayPages[cntArray].push(item)
      }
    } else {
      arrayPages[cntArray].push(item)
      cntArray++;
      row = 0;
    }

  })

  var lastDay = new Date(parseInt(objSelected.due_date.split("-")[0]), parseInt(objSelected.due_date.split("-")[1]), 0);
  lastDay.setDate(0);

  console.log(arrayPages);

  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document
        title="FATURA"
        author="THREE PIXELS SISTEMAS"
        creator="CLOCT"
        producer="CLOCT">

        <Page size="A4" style={{ ...PDFStyle.page, padding: 0, fontFamily: null }}>

          <div style={{ display: "flex", flexDirection: "row" }} >

            <div style={{ width: 100, height: 100, backgroundColor: "#FF5C5C" }} />
            <div style={{ width: 500, height: 600, backgroundColor: "#5271FF", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: 40, marginTop: 0, marginLeft: 20, width: 300, fontWeight: "bold", color: "#fff" }}>
                Fatura de
              </Text>
              <Text style={{ fontSize: 40, marginTop: 0, marginLeft: 20, fontWeight: "bold", color: "#fff" }}>
                Rastreamento
              </Text>
              <Text style={{ fontSize: 40, marginTop: 0, marginLeft: 20, fontWeight: "bold", color: "#fff" }}>
                Veicular
              </Text>
              <Text style={{ fontSize: 40, marginTop: 0, marginLeft: 20, marginBottom: 20, fontWeight: "bold", color: "#fff" }}>
                {DROPDOWN_MONTH.find(m => m.number === objSelected.due_date.split("-")[1]).name}
              </Text>
            </div>
          </div>

          <Image style={{ alignSelf: "flex-end", width: 200, height: 200, marginTop: -20 }} source={logo} />
          <Text style={{ fontSize: 20, marginTop: 0, marginLeft: 20, marginRight: 20, fontWeight: "bold", color: "#5271FF" }}>{objSelected.renter.name}</Text>
        </Page>

        {arrayPages.map((itemPage, page) => {

          return (

            <Page size="A4" style={{ ...PDFStyle.page, padding: 0 }}>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                <div style={{ width: 100, height: 100, backgroundColor: "#FF5C5C" }} />
                <div>
                  <Text style={{ fontSize: 40, width: 180, textAlign: "right", marginTop: 30, marginRight: 30, color: "#222" }}>FATURA</Text>
                  <Text style={{ fontSize: 10, width: 180, marginTop: 0, textAlign: "right", marginRight: 30, color: "#222" }}>Mês ref.: {DROPDOWN_MONTH[lastDay.getMonth()].name}</Text>
                </div>
              </div>

              <div style={{
                marginTop: 20,
                marginBottom: 20,
                height: 650,
                width: 550,
                backgroundColor: "#f8f8f8",
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30,
                alignSelf: "flex-end",
                padding: 30
              }}>

                <div style={{ display: "flex", flexDirection: "row", height: 40 }}>
                  <Text style={{ width: "35%", fontWeight: "bold", ...PDFStyle.title, fontSize: 13 }}>IDENTIFICAÇÃO</Text>
                  <Text style={{ width: "10%", textAlign: 'right', fontWeight: "bold", ...PDFStyle.title, fontSize: 13 }}>Nº</Text>
                  <Text style={{ width: "20%", textAlign: 'right', fontWeight: "bold", ...PDFStyle.title, fontSize: 13 }}>DIAS EM USO</Text>
                  <Text style={{ width: "30%", textAlign: "right", fontWeight: "bold", ...PDFStyle.title, fontSize: 13 }}>MENSALIDADE</Text>
                </div>

                {itemPage.map((item, i) => {

                  var name = (item.identifier.length < 55) ? item.identifier : item.identifier.substr(0, 55) + ".."

                  return (
                    <div style={{ ...PDFStyle.row, height: 25 }}>
                      <Text style={{ width: "35%", ...PDFStyle.title, fontSize: 13 }}>{name}</Text>
                      <Text style={{ width: "10%", textAlign: 'right', ...PDFStyle.title, fontSize: 13 }}>{item.contract}</Text>
                      <Text style={{ width: "20%", textAlign: 'right', ...PDFStyle.title, fontSize: 13 }}>{item.days_use}/{lastDay.getUTCDate()}</Text>
                      <Text style={{ width: "30%", textAlign: "right", ...PDFStyle.title, fontSize: 13, color: "#5271FF" }}>R$ {item.value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                    </div>
                  )
                })}

                {page === (arrayPages.length - 1) &&
                  <div style={{ ...PDFStyle.row, color: "#222", marginTop: 20 }}>
                    <Text style={{ width: "35%", ...PDFStyle.title, fontSize: 13 }}>TOTAL: {totalItens}</Text>
                    <Text style={{ width: "10%", textAlign: 'right', ...PDFStyle.title, fontSize: 13 }}></Text>
                    <Text style={{ width: "20%", textAlign: 'right', ...PDFStyle.title, fontSize: 13 }}></Text>
                    <Text style={{ width: "30%", textAlign: "right", ...PDFStyle.title, fontSize: 13 }}>R$ {total.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
                  </div>
                }

              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ backgroundColor: "#FFF952", height: 51, width: 500, justifyContent: "center" }}>
                  <Text style={{ color: "#555", fontSize: 15, marginLeft: 20 }}>3PIXELS SISTEMAS</Text>
                </div>
                <Text style={{ color: "#555", fontSize: 15, marginLeft: 45, marginTop: 14 }}>{page + 1}</Text>
              </div>

            </Page>)

        })}

      </Document>

    </PDFViewer >
  )
}

export default PDFInvoice;