import { Text } from '@react-pdf/renderer';
import React from 'react';
import PDFStyle from '../../../styles/PDFStyle';


const FooterFixed: React.FC = ({ page, pages }) => {

    return (

        <div style={{...PDFStyle.footerFixed, top: `${(page + 1) * 805}px`}}>
            <div>
                <Text style={{ fontSize: 9 }}>Página: {page + 1}/{pages}</Text>
            </div>
        </div>
    )
}

export default FooterFixed;
