import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, registerPermission } from '../../../helper';
import {
    Alert, Button, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';

const FieldsHBoard: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const objSelected = useSelector(state => state.objSelected)

    const [id, setId] = useState(0)
    const [adjunct_id, setAdjunctId] = useState(0)
    const [adjunct_name, setAdjunctName] = useState("")
    const [slave_id, setSlaveId] = useState(0)
    const [slave_serial, setSlaveSerial] = useState("")
    const [ownership_id, setOwnershipId] = useState(0)
    const [ownership_name, setOwnershipName] = useState("")

    const [serial, setSerial] = useState("")
    const [lot_number, setLotNumber] = useState("42824.")
    const [firmware_version, setFirmwareVersion] = useState("")
    const [cost, setCost] = useState({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
    const [note, setNote] = useState("")
    const [has_slave, setHasSlave] = useState(true)
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    function handlerSwitchDupla() {
        setHasSlave(!has_slave)
    }

    useEffect(() => {

        setTextAlert("")

        if (Object.keys(objSelected).length > 0) {
            setId(objSelected.id)

            if (objSelected.adjunct) {
                setAdjunctId(objSelected.adjunct.id)
                setAdjunctName(objSelected.adjunct.name)
            }
            else {
                setAdjunctId(0)
                setAdjunctName("")
            }

            if (objSelected.slave) {
                setSlaveId(objSelected.slave.id)
                setSlaveSerial(objSelected.slave.serial)
            }
            else {
                setSlaveId(0)
                setSlaveSerial("")
            }

            if (objSelected.ownership_id) {
                setOwnershipId(objSelected.ownership.id)
                setOwnershipName(objSelected.ownership.name)
            }
            else {
                setOwnershipId(0)
                setOwnershipName("")
            }


            setSerial(objSelected.serial)
            setLotNumber(objSelected.lot_number)
            setFirmwareVersion(objSelected.firmware_version)
            setCost({ formattedValue: objSelected.cost, value: objSelected.cost, floatValue: objSelected.cost })
            if (objSelected.note)
                setNote(objSelected.note)
            else
                setNote("")

            setHasSlave(objSelected.has_slave)
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setAdjunctId(0)
            setAdjunctName("")
            setSlaveId(0)
            setSlaveSerial("")
            setOwnershipId(0)
            setOwnershipName("")
            setSerial("")
            setLotNumber("42824.")
            setFirmwareVersion("")
            setCost({ formattedValue: "0,00", value: 0.0, floatValue: 0.0 })
            setNote("")
            setHasSlave(true)
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (serial.trim() === "") {
            setTextAlert("Informe o campo Serial")
            return
        }

        if (serial.length < 4) {
            setTextAlert("Serial inválido")
            return
        }

        if (lot_number.trim() === "" || lot_number.trim() === "42824.") {
            setTextAlert("Informe o campo Número do lote")
            return
        }

        if (lot_number.length < 6) {
            setTextAlert("Número de lote inválido")
            return
        }

        if (firmware_version.trim() === "") {
            setTextAlert("Informe o campo Versão")
            return
        }

        if (firmware_version.length < 3) {
            setTextAlert("Versão inválida")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'h_boards' : `h_boards/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    adjunct_id: (adjunct_id === 0) ? null : adjunct_id,
                    slave_id: (slave_id === 0) ? null : slave_id,
                    ownership_id: (ownership_id === 0) ? null : ownership_id,
                    serial: serial,
                    lot_number: lot_number,
                    firmware_version: firmware_version,
                    cost: cost.floatValue,
                    note: note,
                    has_slave: has_slave,
                    active: active
                })
            });

            let responseJson = await response.json();
            // console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            if (message === 'contains_serial') {
                setTextAlert("Serial já existente")
                dispatch({ type: "SET_SEARCH", data: serial })
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <Field>
                <label>Serial:</label>

                <input
                    type="text"
                    placeholder="Serial"
                    maxLength={5}
                    value={serial}
                    onChange={event => setSerial(event.target.value)} />

            </Field>

            <AutoComplete
                label={"Escravo"}
                finder={"h_boards"}
                item={{ id: slave_id, name: slave_serial }}
                setSelected={(item) => {
                    setSlaveId(item.id)
                    setSlaveSerial(item.search)
                }}
            />

            <AutoComplete
                label={"Posse"}
                finder={"users"}
                item={{ id: ownership_id, name: ownership_name }}
                setSelected={(item) => {
                    setOwnershipId(item.id)
                    setOwnershipName(item.search)
                }}
            />

            <AutoComplete
                label={"Complemento"}
                finder={"adjuncts"}
                item={{ id: adjunct_id, name: adjunct_name }}
                setSelected={(item) => {
                    setAdjunctId(item.id)
                    setAdjunctName(item.search)
                }}
            />

            <Field>
                <label>Número do lote:</label>
                <input
                    type="text"
                    placeholder="Lote"
                    value={lot_number}
                    onChange={event => setLotNumber(event.target.value)} />
            </Field>

            <Field>
                <label>Versão de firmware:</label>
                <input
                    type="text"
                    placeholder="Versão"
                    value={firmware_version}
                    onChange={event => setFirmwareVersion(event.target.value)} />
            </Field>

            <Field>
                <label>Custo:</label>
                <NumberFormat
                    value={cost.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setCost(values)
                    }} />

            </Field>

            <Field>
                <label>Observação:</label>
                <textarea
                    type="text"
                    placeholder="Adicione uma observação"
                    value={note}
                    onChange={event => setNote(event.target.value)}
                />
            </Field>

            <Select>
                <label>Dupla:</label>
                <Switch onChange={() => handlerSwitchDupla()} checked={has_slave} />
            </Select>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsHBoard;