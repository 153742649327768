import React from 'react';
import { useSelector } from 'react-redux';
import FieldsAccessories from '../Fields/FieldsAccessories';
import FieldsContract from '../Fields/FieldsContract';
import FieldsFixedExpenses from '../Fields/FieldsFixedExpenses';
import FieldsProviders from '../Fields/FieldsProviders';
import FieldsRenter from '../Fields/FieldsRenter';
import FieldsSaas from '../Fields/FieldsSaas';
import FieldsTypeExpenses from '../Fields/FieldsTypeExpenses';
import FieldsTypeInvestiments from '../Fields/FieldsTypeInvestments';
import FieldsTypePayments from '../Fields/FieldsTypePayments';
import FieldsTypeRevenues from '../Fields/FieldsTypeRevenues';
import FieldsTypeSaas from '../Fields/FieldsTypeSaas';
import FieldsVehicle from '../Fields/FieldsVehicle';

import GridAccessories from '../Grids/GridAccessories';
import GridContract from '../Grids/GridContract';
import GridFixedExpenses from '../Grids/GridFixedExpenses';
import GridProviders from '../Grids/GridProviders';
import GridRenter from '../Grids/GridRenter';
import GridSaas from '../Grids/GridSaas';
import GridTypeExpenses from '../Grids/GridTypeExpenses';
import GridTypeInvestments from '../Grids/GridTypeInvestments';
import GridTypePayments from '../Grids/GridTypePayments';
import GridTypeRevenues from '../Grids/GridTypeRevenues';
import GridTypeSaas from '../Grids/GridTypeSaas';
import GridVehicle from '../Grids/GridVehicle';

import {
  CircleActive,
  CircleInactive, Container, Fields, Grid, Info, Legend, TitleInfo
} from './style';


const Crud: React.FC = () => {

  const page = useSelector(state => state.page);

  return (
    <Container>

      <TitleInfo>{page.data}</TitleInfo>
      <Legend>
        <span><CircleActive /> <label>Ativos</label></span>
        <span><CircleInactive /> <label>Inativos</label></span>
      </Legend>

      <Info>

        <Grid>
          {page.data === "Acessórios" && <GridAccessories />}

          {page.data === "Locatários" && <GridRenter />}
          {page.data === "Contratos" && <GridContract />}
          {page.data === "Veículos" && <GridVehicle />}
          {page.data === "Fornecedores" && <GridProviders />}

          {page.data === "Tipo de Despesa" && <GridTypeExpenses />}
          {page.data === "Tipo de Investimento" && <GridTypeInvestments />}
          {page.data === "Tipo de Receita" && <GridTypeRevenues />}
          {page.data === "Tipo de Pagamento" && <GridTypePayments />}
          {page.data === "Tipo de Saas" && <GridTypeSaas />}

          {page.data === "Software (Saas)" && <GridSaas />}
          {page.data === "Despesas Fixas" && <GridFixedExpenses />}

        </Grid>

        <Fields>
          {page.data === "Acessórios" && <FieldsAccessories />}

          {page.data === "Locatários" && <FieldsRenter />}
          {page.data === "Contratos" && <FieldsContract />}
          {page.data === "Veículos" && <FieldsVehicle />}
          {page.data === "Fornecedores" && <FieldsProviders />}

          {page.data === "Tipo de Despesa" && <FieldsTypeExpenses />}
          {page.data === "Tipo de Investimento" && <FieldsTypeInvestiments />}
          {page.data === "Tipo de Receita" && <FieldsTypeRevenues />}
          {page.data === "Tipo de Pagamento" && <FieldsTypePayments />}
          {page.data === "Tipo de Saas" && <FieldsTypeSaas />}

          {page.data === "Software (Saas)" && <FieldsSaas />}

          {page.data === "Despesas Fixas" && <FieldsFixedExpenses />}

        </Fields>

      </Info>

    </Container>
  )

}

export default Crud;
