import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Container,
    Row,
    Title
} from './style';

import Moment from 'moment/min/moment-with-locales';
// import { Chart } from "react-google-charts";

const GraphExpensesGroup: React.FC = ({ client_id, callback }) => {

    const objSelected = useSelector(state => state.objSelected)

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    useEffect(() => {

        Moment.locale('pt-br');

        var arrayAux = []
        var arrayAux2 = []

        objSelected.map((item, i) => {
            item.map(it => {
                if (typeof arrayAux[i] === 'undefined') {
                    if (it.payment_date) {
                        arrayAux[i] = [parseFloat(it.payment_value), parseFloat(it.payment_value)]
                        arrayAux2[i] = [it.type_expense.name, parseFloat(it.payment_value)]
                    } else {
                        arrayAux[i] = [parseFloat(it.document_value), parseFloat(it.document_value)]
                        arrayAux2[i] = [it.type_expense.name, parseFloat(it.document_value)]
                    }
                }
                else {

                    if (it.payment_date) {
                        arrayAux[i][0] = arrayAux[i][0] + parseFloat(it.payment_value)
                        arrayAux[i][1] = arrayAux[i][1] + parseFloat(it.payment_value)
                        arrayAux2[i][1] = arrayAux2[i][1] + parseFloat(it.payment_value)
                    } else {
                        arrayAux[i][0] = arrayAux[i][0] + parseFloat(it.document_value)
                        arrayAux[i][1] = arrayAux[i][1] + parseFloat(it.document_value)
                        arrayAux2[i][1] = arrayAux2[i][1] + parseFloat(it.document_value)
                    }
                }
            })
        })

        var filtered = arrayAux.filter(function (el) {
            return el != null;
        });
        var filtered2 = arrayAux2.filter(function (el) {
            return el != null;
        });

        var aux = []
        filtered.map((item, i) => {
            aux[i] = [`R$ ${item[0].toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`, item[1]]
        })

        console.log(aux)

        aux = [["Valor", "Quantidade"], ...aux]
        filtered2 = [["Tipo de receita", "Quantidade"], ...filtered2]

        setData(aux)
        setData2(filtered2)

    }, [objSelected])

    return (
        <Container>

            <Title>ANÁLISES: DESPESAS POR GRUPO</Title>
            <Row />

            {/* <Graph>

                <ViewGraph>

                    {data.length > 0 && <Chart
                        width={'100%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div>Carregando gráfico</div>}
                        data={data}
                        options={{
                            title: 'Valores',
                            pieHole: 0.7,
                            pieSliceText: 'none'
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />}
                </ViewGraph>

                <Row2 />

                <ViewGraph>
                    {data2.length > 0 && <Chart
                        width={'100%'}
                        height={'100%'}
                        chartType="PieChart"
                        loader={<div>Carregando gráfico</div>}
                        data={data2}
                        options={{
                            title: 'Grupos',
                            pieHole: 0.7,
                            pieSliceText: 'none'
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />}
                </ViewGraph>
            </Graph> */}

        </Container>
    )

}

export default GraphExpensesGroup;