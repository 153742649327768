import React from 'react';
import { useSelector } from 'react-redux';
import StockConjunts from './StockConjunts';
import StockSubcomponents from './StockSubcomponents';
import StockTechnical from './StockTechnical';
import {
    Container, Info, TitleInfo
} from './style';

const Stock: React.FC = () => {

    const page = useSelector(state => state.page)

    return (
        <Container>

            <TitleInfo>Estoque: {page.data}</TitleInfo>

            <Info>

                {page.data === "Subcomponentes" && <StockSubcomponents />}
                {page.data === "Conjuntos" && <StockConjunts />}
                {page.data === "Técnicos" && <StockTechnical />}

            </Info>

        </Container>
    )

}

export default Stock;