import { StyleSheet, Font } from '@react-pdf/renderer';

const PDFStyle = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: 20,
        color: "#000",
        fontFamily: 'Arial'
        // fontFamily: 'GothamProBold'
    },
    header: {
        height: 75,
        border: "1.5px solid #444",
        borderRadius: 2,
    },
    infoHeader: {
        padding: "5px 0px 5px 5px",
        display: "flex",
        flexDirection: "column",
    },
    body: {
        border: "1.5px solid #444",
        borderRadius: 2,
        height: 690,
        marginTop: 10,
        overflow: "hidden"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    title: {
        fontSize: 10,
        marginBottom: 4,
        paddingTop: 3,
        color: "#000"
    },
    desc: {
        fontSize: 10,
        marginBottom: 4,
        paddingTop: 5
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 3
    },
    footerFixed: {
      position: "absolute",
      right: 0,
      left: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "0 10px"
  }
});

export default PDFStyle
