import { DEFAULT_URL } from "../../helper";

export async function RequestLogsCommands(token, body) {

    try {
        console.log(`${DEFAULT_URL}log_commands`)

        const response = await fetch(`${DEFAULT_URL}log_commands`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(body)
        })

        const responseJson = await response.json();
        return responseJson

    } catch (e) {
        console.log(e)
    }

}