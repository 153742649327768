import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import { DEFAULT_URL, DEFAULT_USER_STRUCT, getClientName, registerPermission } from '../../../helper';
import {
    Alert, Button, Check, CheckBox, Field, Select, Title, ViewSubmit
} from '../../../styles/CrudLayout';
import AutoComplete from '../../AutoComplete';
import {
    Container
} from './style';


const FieldsContract = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const clients = useSelector(state => state.clients)
    const objSelected = useSelector(state => state.objSelected)
    const arrTypePayments = useSelector(state => state.typePayments)

    const [id, setId] = useState(0)
    const [renter_id, setRenterId] = useState(0)
    const [renter_name, setRenterName] = useState("")
    const [client_id, setClientId] = useState(0)
    const [client_name, setClientName] = useState("")

    const [type, setType] = useState("C")
    const [name, setName] = useState("")
    const [bill_type, setBillType] = useState("MENSAL")
    const [expiration_day, setExpirationDay] = useState(5)
    const [expiration_month, setExpirationMonth] = useState(1)
    const [monthly_payment, setMonthlyPayment] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
    const [yearly_payment, setYearlyPayment] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
    const [installation, setInstallation] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
    const [uninstallation, setUninstallation] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
    const [payment_type, setPaymentType] = useState("BOLETO")
    const [fidelity, setFidelity] = useState(12)
    const [assessment, setAssessment] = useState(true)
    const [advance_payment, setAdvancePayment] = useState(true)
    const [advance_payment_type, setAdvancePaymentType] = useState("PER")
    const [advance_payment_value, setAdvancePaymentValue] = useState({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
    const [active, setActive] = useState(true)

    const [textDesc, setTextDesc] = useState("Adicionar")
    const [textAlert, setTextAlert] = useState("")
    const [textButton, setTextButton] = useState("Salvar")

    function handlerSwitch() {
        setActive(!active)
    }

    function handlerAssessment() {
        setAssessment(!assessment)
    }

    function handlerPayment() {
        if (advance_payment) {
            setAdvancePaymentType("PER");
            setAdvancePaymentValue({ formattedValue: "", value: 0, floatValue: 0 });
        }
        else {
            if (advance_payment_value.formattedValue === "") {
                setAdvancePaymentValue({ formattedValue: "5", value: 5, floatValue: 5 });
            }
        }
        setAdvancePayment(!advance_payment)
    }

    useEffect(() => {

        setTextAlert("")

        if (arrTypePayments.length === 0) {
            findTypePayments()
        }

        if (Object.keys(objSelected).length > 0 && !objSelected?.insert) {
            setId(objSelected.id)

            if (objSelected.renter) {
                setRenterId(objSelected.renter.id)
                setRenterName(objSelected.renter.name)
            }
            else {
                setRenterId(0)
                setRenterName("")
            }

            setClientId(objSelected.client_id)
            setClientName(getClientName(clients, objSelected.client_id))
            setType(objSelected.type)
            setName(objSelected.name)
            setBillType(objSelected.bill_type)
            setExpirationDay(objSelected.expiration_day)
            setExpirationMonth(objSelected.expiration_month)
            setMonthlyPayment({ formattedValue: objSelected.monthly_payment, value: objSelected.monthly_payment, floatValue: objSelected.monthly_payment })
            setYearlyPayment({ formattedValue: objSelected.yearly_payment, value: objSelected.yearly_payment, floatValue: objSelected.yearly_payment })
            setInstallation({ formattedValue: objSelected.installation, value: objSelected.installation, floatValue: objSelected.installation })
            setUninstallation({ formattedValue: objSelected.uninstallation, value: objSelected.uninstallation, floatValue: objSelected.uninstallation })
            setPaymentType(objSelected.payment_type)
            setFidelity(objSelected.fidelity)
            setAssessment(objSelected.assessment)
            setAdvancePayment(objSelected.advance_payment)
            setAdvancePaymentType(objSelected.advance_payment_type)
            setAdvancePaymentValue({ formattedValue: objSelected.advance_payment_value, value: objSelected.advance_payment_value, floatValue: objSelected.advance_payment_value })
            setActive(objSelected.active)
            setTextDesc("Atualizar")
        }
        else {
            setId(0)
            setRenterId(0)
            setRenterName("")
            setClientId(0)
            setClientName("")
            setType("C")
            setName("")
            setBillType("MENSAL")
            setExpirationDay(5)
            setExpirationMonth(1)
            setMonthlyPayment({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
            setYearlyPayment({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
            setInstallation({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
            setUninstallation({ formattedValue: "0,00", value: 0.00, floatValue: 0.00 })
            setPaymentType("BOLETO")
            setFidelity(12)
            setAssessment(true)
            setAdvancePayment(true)
            setAdvancePaymentType("PER")
            setAdvancePaymentValue({ formattedValue: "5,00", value: 5.00, floatValue: 5.00 })
            setActive(true)
            setTextDesc("Adicionar")
        }

    }, [objSelected])

    async function findTypePayments() {

        try {

            let response = await fetch(DEFAULT_URL + 'type_payments', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();
            if (responseJson.success) {
                dispatch({ type: "SET_TYPE_PAYMENTS", data: responseJson.data })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }

    }

    async function saveData() {

        if (!registerPermission(user.profile)) {
            setTextAlert("Usuário sem permissão para cadastros")
            return
        }

        if (name.trim() === "") {
            setTextAlert("Informe o campo Nome")
            return
        }

        if (expiration_day.length === 0) {
            setTextAlert("Informe o campo Dia de Vencimento")
            return
        }

        if (fidelity.length === 0) {
            setTextAlert("Informe o campo Fidelidade")
            return
        }

        if (client_id === 0) {
            setTextAlert("Informe o campo Cliente")
            return
        }

        if (advance_payment && advance_payment_value.formattedValue === "") {
            setTextAlert("Informe o valor do desconto")
            return
        }

        setTextAlert("")
        setTextButton("Aguarde..")

        const url = (id === 0) ? 'contracts' : `contracts/${id}/update`

        try {

            let response = await fetch(DEFAULT_URL + url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: (client_id === 0) ? null : client_id,
                    renter_id: (renter_id === 0) ? null : renter_id,
                    type: type,
                    name: name,
                    bill_type: bill_type,
                    expiration_day: expiration_day,
                    expiration_month: (expiration_month) ? expiration_month : 0,
                    monthly_payment: monthly_payment.floatValue,
                    yearly_payment: (yearly_payment.floatValue) ? yearly_payment.floatValue : 0.0,
                    installation: installation.floatValue,
                    uninstallation: uninstallation.floatValue,
                    payment_type: payment_type,
                    fidelity: fidelity,
                    assessment: assessment,
                    advance_payment: advance_payment,
                    advance_payment_type: advance_payment_type,
                    advance_payment_value: advance_payment_value.floatValue,
                    active: active
                })
            });

            let responseJson = await response.json();
            //console.log(responseJson)

            setTextButton("Salvar")

            if (responseJson.success) {
                dispatch({ type: "SET_OBJ_SELECTED", data: { insert: true } })
                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

            setTextAlert("Erro ao efetuar operação.")

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <Container>

            <Title>{textDesc}</Title>

            <AutoComplete
                label={"Cliente"}
                finder={"clients"}
                item={{ id: client_id, name: client_name }}
                setSelected={(item) => {
                    setClientId(item.id)
                    setClientName(item.search)
                }}
            />

            <Field>
                <label>Nome:</label>

                <input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={event => setName(event.target.value)} />

            </Field>

            <Field>
                <label>Tipo:</label>
                <CheckBox>
                    <Check onClick={() => setType("C")} className={(type === "C") ? 'selected' : ''}>COMODATO</Check>
                    <Check onClick={() => setType("V")} className={(type === "V") ? 'selected' : ''}>VENDA</Check>
                </CheckBox>
            </Field>

            <AutoComplete
                label={"Locatário"}
                finder={"renters"}
                item={{ id: renter_id, name: renter_name }}
                setSelected={(item) => {
                    setRenterId(item.id)
                    setRenterName(item.search)
                }}
            />

            <Field>
                <label>Cobrança:</label>
                <CheckBox>
                    <Check onClick={() => setBillType("MENSAL")} className={(bill_type === "MENSAL") ? 'selected' : ''}>MENSAL</Check>
                    <Check onClick={() => setBillType("ANUAL")} className={(bill_type === "ANUAL") ? 'selected' : ''}>ANUAL</Check>
                </CheckBox>
            </Field>

            <Field>
                <label>Vencimento:</label>
                <input
                    type="text"
                    placeholder="Vencimento"
                    value={expiration_day}
                    onChange={event => setExpirationDay(event.target.value)} />
            </Field>

            {bill_type === "MENSAL" &&
                <Field>
                    <label>Mensalidade:</label>
                    <NumberFormat
                        value={monthly_payment.formattedValue}
                        thousandSeparator={"."}
                        prefix={'R$ '}
                        decimalScale={2}
                        placeholder={"R$"}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowedDecimalSeparators={["."]}
                        onValueChange={values => {
                            setMonthlyPayment(values)
                        }} />
                </Field>
            }

            {bill_type === "ANUAL" &&
                <>
                    <Field>
                        <label>Mês de pagamento:</label>
                        <CheckBox>
                            <Check onClick={() => setExpirationMonth(1)} className={(expiration_month === 1) ? 'selected' : ''}>JANEIRO</Check>
                            <Check onClick={() => setExpirationMonth(2)} className={(expiration_month === 2) ? 'selected' : ''}>FEVEREIRO</Check>
                            <Check onClick={() => setExpirationMonth(3)} className={(expiration_month === 3) ? 'selected' : ''}>MARÇO</Check>
                            <Check onClick={() => setExpirationMonth(4)} className={(expiration_month === 4) ? 'selected' : ''}>ABRIL</Check>
                            <Check onClick={() => setExpirationMonth(5)} className={(expiration_month === 5) ? 'selected' : ''}>MAIO</Check>
                            <Check onClick={() => setExpirationMonth(6)} className={(expiration_month === 6) ? 'selected' : ''}>JUNHO</Check>
                            <Check onClick={() => setExpirationMonth(7)} className={(expiration_month === 7) ? 'selected' : ''}>JULHO</Check>
                            <Check onClick={() => setExpirationMonth(8)} className={(expiration_month === 8) ? 'selected' : ''}>AGOSTO</Check>
                            <Check onClick={() => setExpirationMonth(9)} className={(expiration_month === 9) ? 'selected' : ''}>SETEMBRO</Check>
                            <Check onClick={() => setExpirationMonth(10)} className={(expiration_month === 10) ? 'selected' : ''}>OUTUBRO</Check>
                            <Check onClick={() => setExpirationMonth(11)} className={(expiration_month === 11) ? 'selected' : ''}>NOVEMBRO</Check>
                            <Check onClick={() => setExpirationMonth(12)} className={(expiration_month === 12) ? 'selected' : ''}>DEZEMBRO</Check>
                        </CheckBox>
                    </Field>

                    <Field>
                        <label>Anualidade:</label>
                        <NumberFormat
                            value={yearly_payment.formattedValue}
                            thousandSeparator={"."}
                            prefix={'R$ '}
                            decimalScale={2}
                            placeholder={"R$"}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowedDecimalSeparators={["."]}
                            onValueChange={(values) => {
                                setYearlyPayment(values)
                            }} />
                    </Field>
                </>
            }

            <Field>
                <label>Valor da instalação:</label>
                <NumberFormat
                    value={installation.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setInstallation(values)
                    }} />
                {/* <input
                    type="text"
                    placeholder="Valor da instalação"
                    value={installation}
                    onChange={event => setInstallation(event.target.value)}
                /> */}
            </Field>

            <Field>
                <label>Valor da desinstalação:</label>
                <NumberFormat
                    value={uninstallation.formattedValue}
                    thousandSeparator={"."}
                    prefix={'R$ '}
                    decimalScale={2}
                    placeholder={"R$"}
                    decimalSeparator={","}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowedDecimalSeparators={["."]}
                    onValueChange={(values) => {
                        setUninstallation(values)
                    }} />
                {/* <input
                    type="text"
                    placeholder="Valor da desinstalação"
                    value={uninstallation}
                    onChange={event => setUninstallation(event.target.value)}
                /> */}
            </Field>

            <Field>
                <label>Meio de pagamento:</label>
                <CheckBox>
                    {arrTypePayments.map((e, i) => {
                        if (e.active) {
                            const name = (e.name.length > 16) ? e.name.substr(0, 15) + ".." : e.name
                            return (
                                <Check
                                    key={e.id}
                                    onClick={() => setPaymentType(e.name)}
                                    className={(payment_type === e.name) ? 'selected' : ''}>
                                    {name}
                                </Check>)
                        }
                    })}
                </CheckBox>

            </Field>

            <Field>
                <label>Fidelidade:</label>
                <input
                    type="text"
                    placeholder="Fidelidade"
                    value={fidelity}
                    onChange={event => setFidelity(event.target.value)}
                />
            </Field>

            <Select>
                <label>Desconto:</label>
                <Switch onChange={() => handlerPayment()} checked={advance_payment} />
            </Select>

            {
                (advance_payment && (
                    <Field>
                        <label>Tipo de desconto:</label>
                        <CheckBox>
                            <Check onClick={() => setAdvancePaymentType("PER")} className={(advance_payment_type === "PER") ? 'selected' : ''}>PERCENTUAL</Check>
                            <Check onClick={() => setAdvancePaymentType("FIX")} className={(advance_payment_type === "FIX") ? 'selected' : ''}>VALOR FIXO</Check>
                        </CheckBox>
                    </Field>
                ))
            }

            {
                (advance_payment && (
                    <Field>
                        <label>Valor do desconto:</label>
                        <NumberFormat
                            value={advance_payment_value.formattedValue}
                            thousandSeparator={"."}
                            prefix={advance_payment_type === "PER" ? "% " : "R$ "}
                            decimalScale={2}
                            placeholder={advance_payment_type === "PER" ? "%" : "R$"}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowedDecimalSeparators={["."]}
                            onValueChange={(values) => setAdvancePaymentValue(values)}
                        />
                    </Field>
                ))
            }

            <Select>
                <label>Multa:</label>
                <Switch onChange={() => handlerAssessment()} checked={assessment} />
            </Select>

            <Select>
                <label>Status:</label>
                <Switch onChange={() => handlerSwitch()} checked={active} />
            </Select>

            <ViewSubmit>

                {textAlert !== "" &&
                    <Alert>
                        {textAlert}
                    </Alert>}
                {textAlert === "" && <div />}

                <Button onClick={() => saveData()}>
                    {textButton}
                </Button>

            </ViewSubmit>


        </Container>
    )

}

export default FieldsContract;