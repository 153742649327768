import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT, DROPDOWN_MONTH, DROPDOWN_YEAR, formatNumber } from '../../../helper';
import DropDown from '../../DropDown';
import Modal from '../../Modal';
import {
    Bills, BillsInfo, Container, Date, Header, InfoHeader,
    InfoRow,
    Infos,
    Row,
    Row2,
    Total
} from './style';

const coloralert = "#bd5959";

const FinancialRequests = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const client = useSelector(state => state.client)
    const objSelected = useSelector(state => state.objSelected)
    const search = useSelector(state => state.search)

    var myRef = React.createRef()

    const [monthSelected, setMonthSelected] = useState(false)
    const [yearSelected, setYearSelected] = useState(false)

    const [modal, setModal] = useState(false)

    const [arrToApprove, setArrToApprove] = useState([])
    const [arrResolved, setArrResolved] = useState([])

    const [totalToApprove, setTotalToApprove] = useState(0)

    useEffect(() => {

        Moment.locale('pt-br');

        let date = Moment()
        let month = DROPDOWN_MONTH.find(m => m.number === date.format("MM"))
        setMonthSelected(month)

        let year = DROPDOWN_YEAR.find(y => y.name === date.format("YYYY"))
        setYearSelected(year)

        findFinancialApprovals(year)

    }, [])

    useEffect(() => {

        if (yearSelected) {
            findFinancialApprovals(yearSelected)
        }

    }, [client])

    function handlerClickRow(item) {
        item.clone = false;
        dispatch({ type: "SET_OBJ_SELECTED", data: item });
        openModal()
    }

    async function findFinancialApprovals(year) {

        setArrResolved([])
        setArrToApprove([])

        try {

            let response = await fetch(DEFAULT_URL + 'financial_approvals/date', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
                body: JSON.stringify({
                    client_id: client.id,
                    year: year.name
                })
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var opens = [];
                var tOpen = 0;
                var finished = [];

                responseJson.data.map(item => {

                    if (item.status === "EM ABERTO") {
                        opens.push(item)
                        tOpen = tOpen + parseFloat(item.document_value);
                    } else {
                        finished.push(item)
                    }

                })

                setTotalToApprove(tOpen);
                setArrToApprove(opens);
                setArrResolved(finished);

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    function openModal() {
        setModal(true)
        dispatch({ type: "SET_DARK", data: true })
    }

    return (
        <Container
            ref={myRef}
            onClick={(e) => {
                e.preventDefault();
            }}
        >

            {modal &&
                <Modal
                    onClose={() => setModal(false)}
                    layout={"SOLICITAR DESPESA"}
                    client_id={client.id}
                    callback={() => {
                        findFinancialApprovals(yearSelected)
                    }}
                    monthSelected={monthSelected}
                    yearSelected={yearSelected}
                />}

            <Header>

                <Date>
                    {yearSelected && <DropDown width={100} item={yearSelected.name} array={DROPDOWN_YEAR} onChange={(item) => {
                        setYearSelected(item)
                        findFinancialApprovals(item)
                    }} />}
                </Date>

            </Header>

            <BillsInfo>

                <Bills style={{ width: "49%" }}>
                    <label>REQUISIÇÕES
                        <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
                            openModal()
                        }} style={{ width: 17, height: 17, position: 'absolute', right: 1, cursor: 'pointer' }} />

                    </label>
                    <Row2 />
                    <InfoHeader>
                        <label>Cliente</label>
                        <label>Atualizado em</label>
                        <label>Tipo</label>
                        <span>Vencimento</span>
                        <strong>Valor</strong>
                    </InfoHeader>

                    <Infos>

                        {arrToApprove.map(item => {

                            if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {

                                return (
                                    <InfoRow key={`arrExpenses${item.id}`} onDoubleClick={() => handlerClickRow(item)}>
                                        {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                                        <label>{item.provider.name} </label>
                                        <label>{Moment(item.updatedAt).format("DD/MM/YYYY")} </label>
                                        <label>{item.type_expenses.name} </label>
                                        <span>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                                        <strong>{formatNumber(item.document_value)}</strong>
                                    </InfoRow>
                                )
                            }

                        })}

                    </Infos>

                    <Total>
                        <div>
                            TOTAL:
                            <label style={{ textAlign: 'right', width: '30%' }}>{formatNumber(totalToApprove)}</label>
                        </div>
                    </Total>

                </Bills>

                <Row />

                <Bills style={{ width: "49%" }}>
                    <label>RESULTADO</label>
                    <Row2 />
                    <InfoHeader>
                        <label>Cliente</label>
                        <label>Tipo</label>
                        <span>Vencimento</span>
                        <strong>Valor</strong>
                        <strong>Status</strong>
                    </InfoHeader>

                    <Infos>

                        {arrResolved.map(item => {

                            if (search === "" || item.provider.name.toLowerCase().includes(search.toLowerCase())) {

                                return (
                                    <InfoRow key={`arrExpenses${item.id}`} style={{ color: (item.status === "REJEITADA") ? 'red' : "black" }}>
                                        {item.note !== "" && <div className='tooltiptext'>{item.note}</div>}
                                        <label>{item.provider.name} </label>
                                        <label>{item.type_expenses.name} </label>
                                        <span>{Moment(item.due_date).format("DD/MM/YYYY")}</span>
                                        <strong>{formatNumber(item.document_value)}</strong>
                                        <strong>{item.status}</strong>
                                    </InfoRow>
                                )

                            }
                        })}

                    </Infos>

                </Bills>

            </BillsInfo>

        </Container >
    )
}


export default FinancialRequests;