import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_URL, DEFAULT_USER_STRUCT } from '../../../helper';
import {
    Container,
    Info, List, Totals
} from './style';


const ReportTracker: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    // const report = useSelector(state => state.report)

    const [totalItem, setTotalItem] = useState(0)
    const [totalRs232, setTotalRs232] = useState([])
    const [totalTrack, setTotalTrack] = useState([])
    const [totalLinkedVehicle, setTotalLinkedVehicle] = useState([])
    const [totalLinkedNiple, setTotalLinkedNiple] = useState([])
    const [totalUnlinked, setTotalUnlinked] = useState([])
    const [totalInactives, setTotalInactives] = useState([])


    const [totalCost, setTotalCost] = useState(0)
    const [totalCostLinkedNiple, setTotalCostLinkedNiple] = useState(0)
    const [totalCostLinkedVehicle, setTotalCostLinkedVehicle] = useState(0)
    const [totalCostRs232, setTotalCostRs232] = useState(0)
    const [totalCostTrack, setTotalCostTrack] = useState(0)
    const [totalCostUnlinked, setTotalCostUnlinked] = useState(0)
    const [totalCostInactive, setTotalCostInactive] = useState(0)

    useEffect(() => {
        findDetails()
    }, [])

    async function findDetails() {

        try {

            let response = await fetch(DEFAULT_URL + 'trackers/details', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                },
            });

            let responseJson = await response.json();

            if (responseJson.success) {

                var totalItem = 0;
                var totalLinkedNiple = [];
                var totalLinkedVehicle = [];
                var totalRs232 = [];
                var totalTrack = [];

                var totalUnlinked = [];
                var totalInactive = [];
                var totalLots = [];
                var maxLot = 0;

                var totalCost = 0;
                var totalCostLinkedNiple = 0;
                var totalCostLinkedVehicle = 0;
                var totalCostRs232 = 0;
                var totalCostTrack = 0;
                var totalCostUnlinked = 0;
                var totalCostInactive = 0;

                responseJson.data.map(item => {

                    if (item.active) {
                        totalItem += 1;
                        totalCost = parseFloat(totalCost) + parseFloat(item.cost);

                        if (item.niple) {
                            totalLinkedNiple.push(item);
                            totalCostLinkedNiple = parseFloat(totalCostLinkedNiple) + parseFloat(item.cost);
                        }
                        else if (item.identifier) {
                            totalLinkedVehicle.push(item);
                            totalCostLinkedVehicle = parseFloat(totalCostLinkedVehicle) + parseFloat(item.cost);
                        } else {
                            totalUnlinked.push(item)
                            totalCostUnlinked = parseFloat(totalCostUnlinked) + parseFloat(item.cost);
                        }

                        if (item.allow_niple) {
                            totalCostRs232 = parseFloat(totalCostRs232) + parseFloat(item.cost);
                            totalRs232.push(item);
                        } else {
                            totalCostTrack = parseFloat(totalCostTrack) + parseFloat(item.cost);
                            totalTrack.push(item)
                        }

                    }
                    else {
                        totalInactive.push(item);
                        totalCostInactive = parseFloat(totalCostInactive) + parseFloat(item.cost);
                    }

                })

                setTotalItem(totalItem)
                setTotalLinkedNiple(totalLinkedNiple)
                setTotalLinkedVehicle(totalLinkedVehicle)
                setTotalRs232(totalRs232)
                setTotalTrack(totalTrack)
                setTotalUnlinked(totalUnlinked)
                setTotalInactives(totalInactive)

                setTotalCost(totalCost)
                setTotalCostLinkedNiple(totalCostLinkedNiple)
                setTotalCostLinkedVehicle(totalCostLinkedVehicle)
                setTotalCostRs232(totalCostRs232)
                setTotalCostTrack(totalCostTrack)
                setTotalCostUnlinked(totalCostUnlinked)
                setTotalCostInactive(totalCostInactive)

                return
            }

            let { message } = responseJson.details;
            if (message === 'error_authorization') {
                dispatch({ type: "SET_USER", data: DEFAULT_USER_STRUCT })
                localStorage.setItem('@cloctLog', JSON.stringify({ con: 0, by: 0 }));
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container>

            <Info>

                <Totals><strong>Rastreadores Ativos</strong> <label>Total: {totalItem}</label><span>R$ {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Rastreadores RS232</strong> <label>Total: {totalRs232.length}</label><span>R$ {totalCostRs232.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Rastreadores Track</strong> <label>Total: {totalTrack.length}</label><span>R$ {totalCostTrack.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>

                <Totals><span></span></Totals>
                <Totals><strong>Rastreadores em Niple</strong> <label>Total: {totalLinkedNiple.length}</label><span>R$ {totalCostLinkedNiple.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Rastreadores em Veículos</strong> <label>Total: {totalLinkedVehicle.length}</label><span>R$ {totalCostLinkedVehicle.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Disponíveis</strong> <label>Total: {totalUnlinked.length}</label><span style={{ color: 'red' }}>R$ {totalCostUnlinked.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><strong>Inativos</strong> <label>Total: {totalInactives.length}</label><span style={{ color: 'red' }}>R$ {totalCostInactive.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></Totals>
                <Totals><span></span></Totals>

                <List>

                    <strong>Rastreador: Niple</strong>

                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Rastreador</strong>
                            <strong>Chip</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalLinkedNiple.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.serial}</span>
                                    <span>{item.number}</span>
                                    <span>{item.note}</span>
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Rsatreador: Veículo</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Serial</strong>
                            {/* <strong>Placa</strong> */}
                            <strong>Chip</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalLinkedVehicle.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.serial}</span>
                                    {/* <span>{item.identifier}</span> */}
                                    <span>{item.number}</span>
                                    <span>{item.note}</span>
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Rsatreador: Disponíveis</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Serial</strong>
                            <strong>Chip</strong>
                            <strong>Observação</strong>
                            <strong>RS232</strong>
                            <label>Custo</label>
                        </div>

                        {totalUnlinked.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.serial}</span>
                                    <span>{item.number}</span>
                                    <span>{item.note}</span>
                                    <span>{(item.allow_niple ? 'ON' : 'OFF')}</span>
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

                <List>
                    <strong>Rastreador: Inativos</strong>
                    <div>

                        <div style={{ background: "#eee" }}>
                            <strong>Serial</strong>
                            <strong>Observação</strong>
                            <label>Custo</label>
                        </div>

                        {totalInactives.map((item, i) => {
                            return (
                                <div style={{ background: (i % 2 === 1) ? '#eee' : "#fff" }}>
                                    <span>{item.serial}</span>
                                    <span>{item.note}</span>
                                    <label>R$ {parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                    </div>

                </List>

            </Info>

        </Container>
    )

}

export default ReportTracker;